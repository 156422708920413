/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { Component, useRef, useContext, useState } from 'react';
import {
    Button,
    Input,
    InputLabel,
    Modal,
    Icon,
    Notification,
} from '@scuf/common';
import { ConfirmSignIn } from 'aws-amplify-react';
import './CustomAuth.css';
import { Auth } from 'aws-amplify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import * as HQS_API from '../utils/api';
import * as API_LOGIN from '../utils/api-login'
import useAuth from '../../hooks/useAuth';
import AuthContext from '../../context/AuthProvider';

import Pdf from '../../../public/user_terms_and_conditions.pdf';
import { MsalContext } from '@azure/msal-react';
import {
    authChallenges,
    agreementChallenges,
    isLicensedPlan,
    getEula,
    getEulaDescription,
    isSoftwareChallenge,
    isBetaChallenge,
    getSoftwareFromChallenge,
    handleSignIn,
    signOutCognito,
    findLocalItems
} from '../utils/helpers';
import {  useMsal, useIsAuthenticated } from "@azure/msal-react";

const logo = require('./QuantinuumLogo.svg');
const inquantoLogo = require('./InQuantoLogo.svg');
// const { auth } = useAuth();


const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

export default function CustomRequireTermsAndConditions() {
    const contextType = MsalContext;
    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [termsMode, setTermsMode] = useState('confirm')
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [answer, setAnswer] = useState('')
    const [agreementName, setAgreementName] = useState('')
    const [loading, setLoading] = useState(false)
    const { instance, accounts, inProgress } = useMsal();

    const {auth} = useContext(AuthContext);

    function getAgreementName() {
        //default name
        let agreementName = 'Hardware';

        // const user = this.props.authData;
        let auth_challenge = sessionStorage.getItem("challenge")
        let software = getSoftwareFromChallenge(auth_challenge);

        if (software == 'inquanto') {
            agreementName = 'InQuanto';
        }
        // const user = {}
        // //if we hit a challenge a custom challenge
        // if (
        //     user.challengeName !== undefined &&
        //     user.challengeName === authChallenges.custom
        // ) {
        //     // let's check the status of the user's 'auth-challenge' value. This will tell us how to answer the challenge
        //     let auth_challenge = sessionStorage.getItem("challenge")

        //     let software = getSoftwareFromChallenge(auth_challenge);

        //     if (software == 'inquanto') {
        //         agreementName = 'InQuanto';
        //     }
        // }

        return agreementName;
    }

    function getAuthChallenge() {
        //default name
        let auth_challenge = sessionStorage.getItem("challenge")

        // // const user = this.props.authData;
        // const user = {}

        // //if we hit a challenge a custom challenge
        // if (
        //     user.challengeName !== undefined &&
        //     user.challengeName === authChallenges.custom
        // ) {
        //     //let's get the auth challenge  name
        //     auth_challenge = user.challengeParam['auth-challenge'];
        // }

        return auth_challenge;
    }

    function inGracePeriod(){
        let inGracePeriod = (sessionStorage.getItem("in-grace-period") == 'true')
  
        return inGracePeriod
    }

    function isFederated(){
        let federated = (localStorage.getItem("federated") == 'true')
        return federated
    }

    function genLogo() {
        //dynamically show the main content
        let auth_challenge = getAuthChallenge();

        let contentList = <div></div>;
        const content = [];

        //if we're passed the validation step

        let software = getSoftwareFromChallenge(auth_challenge);

        if (software == 'inquanto') {
            content.push(<img className="logo" src={inquantoLogo}></img>);
        } else {
            // the default quantinuum logo

            content.push(<img className="logo" src={logo}></img>);
        }

        contentList = <div>{content}</div>;

        return contentList;
    }

    function genAgreements() {
        //dynamically show the main content
        let auth_challenge = getAuthChallenge();
        let contentList = <div></div>;
        const content = [];

        let agreementName = getAgreementName();

        let isSoftware = isSoftwareChallenge(auth_challenge);

        if (isSoftware) {
            let software = getSoftwareFromChallenge(auth_challenge);

            let isBeta = isBetaChallenge(auth_challenge);

            content.push(
                <p>
                    Our <b>{agreementName}</b>{' '}
                    <b>
                        <a
                            className="forgot-password-link"
                            href={getEula(software, auth_challenge)}
                            target="_blank"
                        >
                            {' '}
                            {getEulaDescription(software, auth_challenge)}
                        </a>
                    </b>{' '}
                    has changed recently. Please take a moment to review the
                    updated information now before logging in.
                </p>,
            );
        } else {
            content.push(
                <p>
                    Our <b>{agreementName}</b>{' '}
                    <b>
                        <a
                            className="forgot-password-link"
                            href={Pdf}
                            target="_blank"
                        >
                            {' '}
                            Policy & Terms of Use{' '}
                        </a>
                    </b>{' '}
                    have changed recently. Please take a moment to review the
                    updated information now before logging in.
                </p>,
            );
        }

        contentList = <div>{content}</div>;

        return contentList;
    }


    async function acceptTerms(answer) {
        // const user = this.props.authData;
        const user = {}
        let username = sessionStorage.getItem("username");
        let pwd = sessionStorage.getItem("pwd");
        let challenge = sessionStorage.getItem("challenge")
        let federated = localStorage.getItem("federated")
        let mfaCode = sessionStorage.getItem("code")
        if (federated === 'true'){
            let tokenKeys = findLocalItems("msal.token.keys")
            if (tokenKeys.length > 0){
                pwd = JSON.parse(sessionStorage.getItem(tokenKeys[0]["val"]["idToken"][0]))["secret"]

            }
        }
        
        setLoading(true)
        API_LOGIN.login(username, pwd, federated, challenge, answer, mfaCode)
            .then((response) => {

                handleSignIn(response)
                
            })
            .catch((err) => {
                console.log(err)
                if (err !== undefined) {

                    if (err.response.status === 401) {
                        //handle the next authorization challenge
                        handleSignIn(err.response, username);

                    } else {
                        //show the error
                        err = err.response.data.error
                        const title = "Unable to complete action";
                        let details = err.text
                        toast(
                            <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="critical"
                            />
                        );
                    }
                }
                
            }).finally(() => {
                setLoading(false)
            });
    }


    const backToSignIn = async() => {

        if (isFederated()){

            const currentAccount = accounts[0]

            //sign out of microsoft, this will ensure users have to re-enter their password
            //then sign out of cognito
            await instance.logoutRedirect({ account: currentAccount }).then( signOutCognito());
        }else{
            //sign out of cognito
            signOutCognito()
        }
   
    }


    function createView() {
        
        let view = (
            <div className="custom-sign-in">
                <div className="terms-and-conditions-form">
                    <div className="form-header">{genLogo()}</div>
                    <div className="credentials">
                        <div>
                            <br />
                            {genAgreements()}
                        </div>
                        <div className="change-password">
                            {!inGracePeriod()? 
                                <div><Button
                                    type="primary"
                                    onClick={() => acceptTerms('True')}
                                    content="Accept"
                                />
                                <Button
                                    type="secondary"
                                    onClick={() => backToSignIn()}
                                    content="Decline"
                                />
                                </div>
                            :
                                <div>
                                <Button
                                type="primary"
                                onClick={() => acceptTerms('True')}
                                content="Accept Now"
                                />
                                <Button
                                    type="secondary"
                                    onClick={() => acceptTerms('False')}
                                    content="Remind Me Later"
                                    title="Proceed to application, but you'll be prompted again on your next login"
                                />
                                </div>
                            }
                            <p className="forgot-password center-text">
                                <a
                                    className="forgot-password-link"
                                    onClick={() => backToSignIn()}
                                >
                                    Back to sign in?
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    closeButton={false}
                    newestOnTop={true}
                    position="bottom-right"
                    toastClassName="toast-notification-wrap"
                />
            </div>
        );
        if (loading) {
            view = (
                <div className="custom-sign-up">
                    <div className="sign-up-form" style={{ height: 300 }}>
                        <p className="form-completed-p">
                            <Icon
                                name="refresh"
                                size="xlarge"
                                loading={true}
                                color="#1274B7"
                            />
                        </p>
                    </div>
                </div>
            );
        }
        return view

    }

    return(
        createView()
    )
}