import React, { Component } from 'react';
import NbViewer from 'react-nbviewer';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy as syntaxStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Document, Page, pdfjs } from 'react-pdf/dist/umd/entry.webpack';

import 'react-nbviewer/dist/index.css';

class CodeViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
        };
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    componentDidMount() {
        pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
    }

    onDocumentLoadSuccess({ numPages: nextNumPages }) {
        this.setState({ numPages: nextNumPages });
    }

    render() {
        if (this.props.fileFormat === 'ipynb') {
            return <NbViewer source={this.props.fileData} markdown={Markdown} code={SyntaxHighlighter} />;
        }

        if (this.props.fileFormat === 'markup') {
            const renderers = {
                code: ({ language, value }) => {
                    return <SyntaxHighlighter style={syntaxStyle} language={language} children={value} />;
                },
            };

            return <Markdown renderers={renderers}>{this.props.fileData}</Markdown>;
        }

        if (this.props.fileFormat === 'html') {
            let htmlObject = { __html: this.props.fileData };
            return <div dangerouslySetInnerHTML={htmlObject} />;
        }

        if (this.props.fileFormat === 'whl') {
            let htmlObject = { __html: this.props.fileData };
            return (
                <div>
                    <h3>File format not supported</h3>
                </div>
            );
        }

        if (this.props.fileFormat === 'pdf') {
            return (
                <Document file={this.props.fileURL} onLoadSuccess={this.onDocumentLoadSuccess}>
                    {Array.from(new Array(this.state.numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} renderMode={'svg'} scale={1.5} />
                    ))}
                </Document>
            );
        }

        return (
            <SyntaxHighlighter
                showLineNumbers
                showInlineLineNumbers
                language={this.props.fileFormat}
                style={syntaxStyle}>
                {this.props.fileData}
            </SyntaxHighlighter>
        );
    }
}

export default CodeViewer;
