// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React, { useEffect } from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Input, InputLabel, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const UpdateHqcQuotaForm = (props) => {
    const { quota } = props;
    const [show, setShow] = useState(false);
    const [hqcQuota, setHqcQuota] = useState(quota);
    const handleClose = () => {
        setShow(false);
        setHqcQuota(quota);
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        setHqcQuota(quota);
    }, [quota]);


    function handleUpdate() {
        // set org's hqc quota
        let body = {};
        body['hqc_quota'] = Number(hqcQuota);

        // update org
        HQS_API.updateOrganizationQuota(props.org, body)
            .then((response) => {
                if (props.callBack) {
                    props.callBack();
                }
                const title = 'Org Quota Updated';
                const details = 'Org HQC quota has been updated successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />,
                );
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });

        handleClose()
    }

    return (
        <div>
            <div onClick={handleShow}>
                <div style={{ cursor: 'pointer' }} className="hqs-blue-icon">
                    update
                </div>
                &nbsp;
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon
                            root="common"
                            name="portfolio-cost"
                            size="medium"
                        ></Icon>
                        &nbsp;&nbsp;Update HQC quota
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        Update the existing HQC quota value for &quot;{props.org}&quot;.
                    </p>
                    <InputLabel label="Credits:" />
                    <Input
                        key={props.org}
                        type="number"
                        min="0"
                        value={hqcQuota}
                        onChange={(quota) => setHqcQuota(quota)}
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button 
                        type="primary"
                        onClick={() => handleUpdate(hqcQuota)}
                    >
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateHqcQuotaForm;
