// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Checkbox, Notification, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const logo = require('../icons/icon_alert_critical.svg');

const ToastNotification = ({ closeToast, title, details }) => (
    <Notification
        className="toast-notification"
        severity="success"
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

// Custom hook for handling org access form
const useOrgAccessForm = (closeCallback, props) => {

    async function changeOrgAccess(orgName, action) {
        HQS_API.organizationAccess(orgName, action)
            .then((response) => {
                const title = 'Org Access Updated';
                const details = `The system has successfully updated acccess for all users under ${orgName}`;
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                    />
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unkown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            });
    }

    const handleAccess = (event) => {
        if (event) {
            event.preventDefault();
        }
        let action = 'enable';
        if (props.org.enabled) {
            action = 'disable';
        }
        changeOrgAccess(props.org.name, action);

        closeCallback();
    };

    return { handleAccess };
};

const OrgAccessForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleAccess } = useOrgAccessForm(handleClose, props);
    let modalClass = 'hqs-umui-information-modal';
    let title = 'Enable Organization';
    let details =
        'This will give access to all users under this organization. Are you sure?';
    let actionDescription = 'Enable';
    let actionName = 'Enable';
    let iconName = 'check';

    if (props.org.enabled) {
        modalClass = 'hqs-umui-delete-modal';
        title = 'Disable Organization';
        details =
            'This will remove access for all users under this organization. Are you sure?';
        actionDescription = 'Disable';
        actionName = 'Disable';
        iconName = 'close';
    }

    return (
        <div>
            <div onClick={handleShow}>
                <Checkbox checked={props.org.enabled}></Checkbox>
            </div>

            <Modal open={show} onClose={handleClose} size="small" className={modalClass}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">{title}</div>
                </Modal.Header>
                <Modal.Content>
                    <p>{details}</p>
                    <Button type="primary" size="small" onClick={handleAccess}>
                        {actionDescription}
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default OrgAccessForm;
