/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

// import { API } from 'aws-amplify';
// import { getIdToken, makeCancelable } from './helpers';
import axios from 'axios';
import { AmplifyConfig } from '../config';
import * as LOGIN_API from './api-login';

export const HQS_UI_API = axios.create({
    baseURL: AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_UI_API').endpoint,
    timeout: 30000,
});

HQS_UI_API.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        console.log('API Interceptor called');
        let loginAPI = AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_MACHINE_API').endpoint;
        const originalRequest = error.config;
        console.log('Response config: ' + JSON.stringify(originalRequest));
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            console.log('Handling response error');
            // get new id token
            const refreshToken = localStorage.getItem('refresh-token');
            if (refreshToken === 'undefined') {
                window.location.replace('/login');
            } else {
                console.log('Trying to refresh access token');
                const federated = localStorage.getItem('federated');
                const username = localStorage.getItem('username');
                const data = {
                    'refresh-token': refreshToken,
                };
                if (federated === 'true') {
                    data['email'] = username;
                }
                const res = await fetch(loginAPI + '/login', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                });
                const jsonData = await res.json();
                if ('id-token' in jsonData) {
                    localStorage.setItem('id-token', jsonData['id-token']);
                    originalRequest.headers['Authorization'] = jsonData['id-token'];
                    return HQS_UI_API(originalRequest);
                }
            }
        }
        console.log('Interceptor did not do anything');
        return Promise.reject(error);
    },
);

const passError = (error) => {
    //log the error
    console.log(error);

    // throw the error and include the response to let the calling functoins handle this
    // necessary to show toast messages

    //using in a select few places while we find a long-term solution

    throw {
        response: error.response,
        error: new Error(),
    };
};

const getAuthHeader = (queryStringParameters = {}) => {
    let config = {
        headers: {
            Authorization: `${localStorage.getItem('id-token')}`,
        },
    };

    if (queryStringParameters) {
        config['params'] = queryStringParameters;
    }
    console.log('calling getAuthHeader: ' + JSON.stringify(config));
    return config;
};

const getPageKeyHeader = (key, queryStringParameters = {}) => {
    let header = getAuthHeader(queryStringParameters);
    if (key) {
        header['headers']['Page-Key'] = key;
    }

    return header;
};

// user
export const createUser = async (body) => {
    try {
        const response = await HQS_UI_API.post('/users', body, getAuthHeader());

        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const userRegistration = async (body) => {
    try {
        const response = await HQS_UI_API.post('/registration', body);
        // console.log(response.data)

        return response.data;
    } catch (err) {
        passError(err);
    }
};

export const getUsers = async (filters) => {
    try {
        const response = await HQS_UI_API.get('/users', getAuthHeader(filters));

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getSelfUser = async () => {
    try {
        const response = await HQS_UI_API.get('/user', getAuthHeader());
        // console.log(response.data)

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getUser = async (username) => {
    try {
        const response = await HQS_UI_API.get(`/users/${username}`, getAuthHeader());

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateSelfUser = async (userDetails) => {
    try {
        const attributeKeys = Object.keys(userDetails);
        const body = {};

        attributeKeys.forEach((attribute) => {
            body[attribute] = userDetails[attribute];
        });
        const response = await HQS_UI_API.put('/user', body, getAuthHeader());

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const examples = async (userDetails) => {
    try {
        const response = await HQS_UI_API.get('/user/examples', getAuthHeader());

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getOrganization = async (org_name, mode) => {
    try {
        const response = await HQS_UI_API.get(`/organizations/${org_name}?mode=${mode}`, getAuthHeader());

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getSelfNotificationPreferences = async () => {
    try {
        const response = await HQS_UI_API.get('/user/notifications', getAuthHeader());

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateUser = async (username, userDetails) => {
    try {
        const body = {
            'first-name': userDetails.first_name,
            'last-name': userDetails.last_name,
            email: userDetails.email,
            'phone-number': userDetails.phone_number,
        };

        const response = await HQS_UI_API.put(`/users/${username}`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateUserGroupId = async (username, userDetails) => {
    try {
        const response = await HQS_UI_API.put(`/users/${username}`, userDetails, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteUser = async (username) => {
    try {
        const response = await HQS_UI_API.delete(`/users/${username}`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateUserPermissions = async (username, body) => {
    try {
        const response = await HQS_UI_API.put(`/users/permissions/${username}`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const resendUserCredentials = async (username) => {
    try {
        const response = await HQS_UI_API.put(`/users/credentials/${username}`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const recoverUserCredentials = async (username, body) => {
    try {
        const response = await HQS_UI_API.put(`/users/credentials/${username}/recover`, body);
        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const updateSelfNotificationPreferences = async (notificationPreferences) => {
    try {
        const response = await HQS_UI_API.put('/user/notifications', notificationPreferences, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getSelfSecurityPreferences = async () => {
    try {
        const response = await HQS_UI_API.get('/user/security', getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateSelfSecurityPreferences = async (securityPreferences) => {
    try {
        const response = await HQS_UI_API.put('/user/security', securityPreferences, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getSelfSoftware = async () => {
    try {
        const response = await HQS_UI_API.get('/user/software', getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const createOrganization = async (org_name, customer_id, org_type) => {
    try {
        let body = {
            org_name,
            customer_id,
            type: org_type,
        };
        const response = await HQS_UI_API.post('/organizations', body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateBatchMaxHQC = async (org_name, batch_max_hqc) => {
    try {
        const body = {
            batch_max_hqc: batch_max_hqc,
        };
        const response = await HQS_UI_API.put(`/organizations/${org_name}`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getOrganizations = async () => {
    try {
        const response = await HQS_UI_API.get(`/organizations`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getOrganizationsPlans = async () => {
    try {
        const response = await HQS_UI_API.get('/organizations?plans=true', getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getOrganizationsPlansByMachine = async (machineName) => {
    try {
        const response = await HQS_UI_API.get(
            `/organizations?plans=true&fq=true&machine=${machineName}`,
            getAuthHeader(),
        );
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getUserGroups = async (org_name) => {
    try {
        const response = await HQS_UI_API.get(`/organizations/${org_name}/user-group`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const addUserGroup = async (org_name, body) => {
    try {
        const response = await HQS_UI_API.post(`/organizations/${org_name}/user-group`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteUserGroup = async (org_name, group_id) => {
    try {
        const response = await HQS_UI_API.delete(`/organizations/${org_name}/user-group/${group_id}`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateUserGroup = async (org_name, group_id, body) => {
    try {
        const response = await HQS_UI_API.put(
            `/organizations/${org_name}/user-group/${group_id}`,
            body,
            getAuthHeader(),
        );
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const enableUserGroupQuota = async (org_name, group_id, body) => {
    try {
        const response = await HQS_UI_API.put(
            `/organizations/${org_name}/user-group/${group_id}/quota?action=enable`,
            body,
            getAuthHeader(),
        );
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const disableUserGroupQuota = async (org_name, group_id, body) => {
    try {
        const response = await HQS_UI_API.put(
            `/organizations/${org_name}/user-group/${group_id}/quota?action=disable`,
            body,
            getAuthHeader(),
        );
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateUserGroupQuota = async (org_name, group_id, body) => {
    try {
        const response = await HQS_UI_API.put(
            `/organizations/${org_name}/user-group/${group_id}/quota?action=update`,
            body,
            getAuthHeader(),
        );
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteOrganization = async (org_name) => {
    try {
        const response = await HQS_UI_API.delete(`/organizations/${org_name}`, getAuthHeader());
        return response.data;
    } catch (err) {
        passError(err);
    }
};

export const updateOrganization = async (org_name, body) => {
    try {
        const response = await HQS_UI_API.put(`/organizations/${org_name}`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        passError(err);
    }
};

export const organizationAccess = async (org_name, action) => {
    try {
        const body = {};
        const response = await HQS_UI_API.put(
            `/organizations/${org_name}/access?action=${action}`,
            body,
            getAuthHeader(),
        );
        return response.data;
    } catch (err) {
        passError(err);
    }
};

export const addOrgMachinePlan = async (org_name, body) => {
    try {
        const response = await HQS_UI_API.post(`/organizations/${org_name}/plan`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const removeOrgMachinePlan = async (org_name, plan_id) => {
    try {
        const response = await HQS_UI_API.delete(`/organizations/${org_name}/plan/${plan_id}`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const updateOrgPlan = async (org_name, plan_id, body) => {
    try {
        const response = await HQS_UI_API.put(`/organizations/${org_name}/plan/${plan_id}`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const enableOrganizationQuota = async (org_name, body) => {
    try {
        const response = await HQS_UI_API.put(`/organizations/${org_name}/quota?action=enable`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const disableOrganizationQuota = async (org_name, body) => {
    try {
        const response = await HQS_UI_API.put(`/organizations/${org_name}/quota?action=disable`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateOrganizationQuota = async (org_name, body) => {
    try {
        const response = await HQS_UI_API.put(`/organizations/${org_name}/quota?action=set`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getOrgMachineAccess = async (org_name) => {
    try {
        const response = await HQS_UI_API.get(`/organizations/${org_name}/machines`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateOrgMachineAccess = async (org_name, action_machines) => {
    try {
        const response = await HQS_UI_API.put(`/organizations/${org_name}/machines`, action_machines, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getMachines = async () => {
    try {
        const response = await HQS_UI_API.get(`/machine`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateMachine = async (machine) => {
    try {
        const response = await HQS_UI_API.put(`/machine`, machine, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const listMachineEvents = async (queryStringParameters = {}) => {
    try {
        const response = await HQS_UI_API.get('/reservation', getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const listAuditLogs = async (queryStringParameters = {}, pageKey) => {
    try {
        const response = await HQS_UI_API.get('/audit', getPageKeyHeader(pageKey, queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const listAuditActions = async (queryStringParameters = {}) => {
    try {
        const response = await HQS_UI_API.get('/audit?action=list-audit-actions', getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const createMachineEvent = async (eventData) => {
    try {
        const response = await HQS_UI_API.post('/reservation', eventData, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const deleteMachineEvent = async (event_id, delete_series = false) => {
    try {
        const response = await HQS_UI_API.delete(
            `/reservation/${event_id}?delete-series=${delete_series}`,
            getAuthHeader(),
        );
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateMachineEvent = async (event_id, event_data) => {
    try {
        const response = await HQS_UI_API.put(`/reservation/${event_id}`, event_data, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateQuota = async (username, action, body) => {
    try {
        const queryStrParams = { action: action };
        const response = await HQS_UI_API.put(`/users/${username}/quota`, body, getAuthHeader(queryStrParams));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateUserPriority = async (username, priority) => {
    try {
        const body = { priority: priority };
        const response = await HQS_UI_API.put(`/users/${username}`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getTermsAndConditions = async (queryStringParameters) => {
    try {
        const response = await HQS_UI_API.get(`/settings/terms`, getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const createTermsAndConditions = async (queryStringParameters, body) => {
    try {
        const response = await HQS_UI_API.post(`/settings/terms`, body, getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateTermsAndConditions = async (id, queryStringParameters, body) => {
    try {
        const response = await HQS_UI_API.put(`/settings/terms/${id}`, body, getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteTermsAndConditions = async (id, queryStringParameters) => {
    try {
        const response = await HQS_UI_API.delete(`/settings/terms/${id}`, getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getUserGroupsAdmin = async (queryStringParameters = {}, pageKey) => {
    try {
        const response = await HQS_UI_API.get('/user-groups', getPageKeyHeader(pageKey, queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getTags = async (queryStringParameters = {}) => {
    try {
        let config = getAuthHeader(queryStringParameters);
        console.log('calling getTags: ' + JSON.stringify(config));
        const response = await HQS_UI_API.get(`/settings/tag`, getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const createTag = async (queryStringParameters, body = {}) => {
    try {
        const response = await HQS_UI_API.post(`/settings/tag`, body, getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateTag = async (id, queryStringParameters, body = {}) => {
    try {
        const response = await HQS_UI_API.put(`/settings/tag/${id}`, body, getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const deleteTag = async (id, queryStringParameters) => {
    try {
        const response = await HQS_UI_API.delete(`/settings/tag/${id}`, getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const HQS_MACHINE_API = axios.create({
    baseURL: AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_MACHINE_API').endpoint,
    timeout: 30000,
});

HQS_MACHINE_API.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        console.log('API Interceptor called');
        let loginAPI = AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_MACHINE_API').endpoint;
        const originalRequest = error.config;
        console.log('Response config: ' + JSON.stringify(originalRequest));
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            console.log('Handling response error');

            // get new id token
            const refreshToken = localStorage.getItem('refresh-token');
            if (refreshToken === 'undefined') {
                window.location.replace('/login');
            } else {
                console.log('Trying to refresh access token');
                const federated = localStorage.getItem('federated');
                const username = localStorage.getItem('username');
                const data = {
                    'refresh-token': refreshToken,
                };
                if (federated === 'true') {
                    data['email'] = username;
                }
                const res = await fetch(loginAPI + '/login', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                });
                const jsonData = await res.json();
                if ('id-token' in jsonData) {
                    localStorage.setItem('id-token', jsonData['id-token']);
                    originalRequest.headers['Authorization'] = jsonData['id-token'];
                    return HQS_UI_API(originalRequest);
                }
            }
        }
        console.log('Interceptor did not do anything');
        return Promise.reject(error);
    },
);

export const listMachines = async () => {
    try {
        const response = await HQS_MACHINE_API.get('/machine', getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const listJobs = async (queryStringParameters = {}, pageKey) => {
    try {
        const response = await HQS_MACHINE_API.get('/job', getPageKeyHeader(pageKey, queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const listBatchRequests = async (queryStringParameters = {}) => {
    try {
        const response = await HQS_MACHINE_API.get('/batch', getAuthHeader(queryStringParameters));
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const updateBatch = async (batchId, body) => {
    try {
        const response = await HQS_MACHINE_API.put(`/batch/${batchId}`, body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const cancelJob = async (jobId) => {
    try {
        const response = await HQS_MACHINE_API.post(`/job/${jobId}/cancel`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const pauseJob = async (jobId) => {
    try {
        const response = await HQS_MACHINE_API.post(`/job/${jobId}/pause`, {}, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const resumeJob = async (jobId) => {
    try {
        const response = await HQS_MACHINE_API.post(`/job/${jobId}/resume`, {}, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
        passError(err);
    }
};

export const changePassword = async (body) => {
    try {
        const response = await HQS_MACHINE_API.post('/login/password', body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const lisAdminReports = async () => {
    try {
        const response = await HQS_MACHINE_API.get(`/job/reports?mode=admin`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const listReports = async () => {
    try {
        const response = await HQS_MACHINE_API.get(`/job/reports`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getReport = async (id) => {
    try {
        const response = await HQS_MACHINE_API.get(`/job/reports/${id}`, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const createReport = async (report_name, start, end, orgName, azure, expiration) => {
    try {
        const body = {
            'report-name': report_name,
            start: start,
            end: end,
            expiration: expiration,
        };
        if (orgName != 'None') {
            body['org'] = orgName;
        }
        if (azure === true) {
            body['azure'] = true;
        }

        const response = await HQS_MACHINE_API.post('/job/reports', body, getAuthHeader());
        return response.data;
    } catch (err) {
        console.log(err);
    }
};
