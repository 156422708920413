// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import DeleteTermsAndConditionsForm from '../Forms/DeleteTermsAndConditions';
import ManageTermsAndConditionsForm from '../Forms/ManageTermsAndConditions';
import Dropdown from 'react-bootstrap/Dropdown';

const generateOptions = (selectedRow, mode, active, callback) => {
    let actionsList = [];

    if (selectedRow !== undefined && selectedRow !== null && selectedRow.length > 0) {
        const termsDetails = {
            id: selectedRow[0]['id'],
            effective_date: selectedRow[0]['effective-date'],
            status: selectedRow[0]['status'],
            grace_period_date:  selectedRow[0]['grace-period-date'] !== "" ? selectedRow[0]['grace-period-date']: "",
            end_date: selectedRow[0]['end-date'] !== "" ? selectedRow[0]['end-date']: "",
            version: selectedRow[0]['version'],
            agreement_type: 'agreement-type' in selectedRow[0] && selectedRow[0]['agreement-type'] != ""? selectedRow[0]['agreement-type']: "hardware"
        };


        const userMode = mode
        
        // only allow deletes for inactive terms and schedules
        if (!active){
            actionsList.push(
                <Dropdown.Item className="hqs-custom-actions-select-option">
                    <DeleteTermsAndConditionsForm
                        show_icon={true}
                        terms={termsDetails}
                        mode={userMode}
                        callback={callback}
                    />
                </Dropdown.Item>,
            );
        }
        //  add manage permissions
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <ManageTermsAndConditionsForm
                    terms={termsDetails}
                    mode={userMode}
                    callback={callback}
                    active={active}
                />
            </Dropdown.Item>
        );

        
    } else {
        actionsList.push(
            <Dropdown.Item
                className="hqs-custom-actions-select-option"
                disabled={true}
            >
                <div>Select a row...</div>
            </Dropdown.Item>,
        );
    }

    return actionsList;
};

const AdminSettingsActionSelect = (props) => {
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className="ui small button primary hqs-custom-dropdown"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            &#x25bd;&nbsp;
            {children}
        </div>
    ));
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {generateOptions(
                    props.selectedRow,
                    props.mode,
                    props.active,
                    props.callback,
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AdminSettingsActionSelect;
