// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const logo = require('../icons/icon_alert_critical.svg');

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

// Custom hook for handling jobs cancel
const useCancelJobsForm = (closeCallback, props) => {

    const cancelJobs = (jobs) => {
        jobs.forEach(job => cancelJob(job))
        const title = 'Jobs Cancelling';
        const details = 'Attempting to cancel all selected jobs';
        if (props.callback) {
            props.callback();
        }
        toast(
            <ToastNotification
                closeToast={false}
                title={title}
                details={details}
            />
        );
    }

    async function cancelJob(jobId) {
        HQS_API
            .cancelJob(jobId)
            .then((response) => {
                // do nothing
            })
            .catch((error) => {
                var title = 'Error, job id: ' + jobId.substring(0,9);
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.message;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            });
    }

    const handleCancel = (event) => {
        if (event) {
            event.preventDefault();
        }
        cancelJobs(props.jobs);
        closeCallback();
    };

    return { handleCancel };
};

const CancelJobsForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (props.jobs.size > 0) {
            setShow(true);
        }
    }

    const { handleCancel } = useCancelJobsForm(handleClose, props);

    return (
        <div>
            <div
                onClick={handleShow}
                style={{
                    cursor: 'pointer',
                    padding: '8px 0px 0px 5px',
                }}
            >
                <Icon root="building" name="badge-stop" size="medium" />
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-delete-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Cancel Jobs</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        This will send a request to cancel the selected jobs.
                        Are you sure?
                    </p>
                    <Button type="primary" size="small" onClick={handleCancel}>
                        Yes
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default CancelJobsForm;
