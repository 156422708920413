/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { Component } from 'react';
import {
    Button,
    Input,
    InputLabel,
    Modal,
    Icon,
    Notification,
} from '@scuf/common';
import { ConfirmSignIn } from 'aws-amplify-react';
import './CustomAuth.css';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import * as HQS_API from '../utils/api';
import Pdf from '../../../public/user_terms_and_conditions.pdf';
import { MsalContext } from '@azure/msal-react';
import {
    authChallenges,
    agreementChallenges,
    agreementTypes,
    isLicensedPlan,
    getEula,
    getEulaDescription,
    isBetaChallenge,
    getSoftwareFromChallenge,
    handleSignIn, 
    signOutCognito,
    findLocalItems
} from '../utils/helpers';
import * as API_LOGIN from '../utils/api-login';

const logo = require('./QuantinuumLogo.svg');
const inquantoLogo = require('./InQuantoLogo.svg');

const inquantoProductLink =
    'https://www.quantinuum.com/computationalchemistry/inquanto';
const inquantoGettingStartedLink =
    'https://inquanto.quantinuum.com/introduction/getting_started.html';
const qcSupport = 'qcsupport@quantinuum.com';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const steps = {
    start: 1,
    loading: 2,
    end: 3,
};

class CustomSoftwareSignUp extends Component {
    static contextType = MsalContext;

    constructor(props) {
        super(props);
        this._validAuthStates = ['customSoftwareSignUp'];
        this.state = {
            show: false,
            disabled: true,
            termsMode: 'confirm',
            acceptedTerms: false,
            answer: '',
            agreementName: '',
            loading: false,
            generated: false,
            step: steps.start,
        };
        this.continueLogin = this.continueLogin.bind(this);
        this.trySignOutAzure = this.trySignOutAzure.bind(this);
        this.getAgreementName = this.getAgreementName.bind(this);
        this.generateLicense = this.generateLicense.bind(this);
        this.isTermsExempt = this.isTermsExempt.bind(this);
        this.inGracePeriod = this.inGracePeriod.bind(this);
        this.isCompliant = this.isCompliant.bind(this)
        this.setStep = this.setStep.bind(this);
        this.showContent = this.showContent.bind(this);
        this.showCompleted = this.showCompleted.bind(this);
        this.showAgreement = this.showAgreement.bind(this);
        this.genContent = this.genContent.bind(this);
        this.genLogo = this.genLogo.bind(this);
    }

    setStep(step) {
        this.setState({ step: step });
    }

    showContent() {
        //dynamically show the main content

        // let auth_challenge = this.getAuthChallenge()
        let auth_challenge = sessionStorage.getItem('challenge');
        let software = getSoftwareFromChallenge(auth_challenge);

        switch (this.state.step) {
            case steps.end:
                return this.showCompleted(software);
            case steps.start:
            default:
                return this.showAgreement(software);
        }
    }

    showCompleted(software) {
        return (
            <div className="credentials">
                <div className="agreement-confirm">
                    {this.genCompletedContent(software)}
                </div>
                <div className="change-password">
                    <Button
                        type="primary"
                        onClick={() => this.continueLogin("True")}
                        content="Continue"
                    />
                </div>
            </div>
        );
    }

    showLoading() {
        return (
            <div className="custom-sign-up">
                <div className="sign-up-form" style={{ height: 400 }}>
                    <div className="form-header">{this.genLogo()}</div>
                    <p className="form-completed-p">
                        <Icon
                            name="refresh"
                            size="xlarge"
                            loading={true}
                            color="#1274B7"
                        />
                    </p>
                </div>
            </div>
        );
    }

    showAgreement(software) {
        return (
            <div className="credentials">
                <div className="agreement-confirm">
                    {this.genContent(software)}
                </div>
                <div className="change-password">
                    {this.isTermsExempt() || this.isCompliant()? (
                        <div>
                            <Button
                                type="primary"
                                onClick={() => this.generateLicense()}
                                content={!this.isUpgradable()? "Get Key": "Upgrade Key"}
                            />
                            {/* if we need the ability to defer the software then uncomment this 
            <Button type="secondary" onClick={() => this.continueLogin("False")} content="Remind Me Later" />
            */}
                            <Button
                                type="secondary"
                                onClick={() => this.backToSignIn()}
                                content="Decline"
                            />
                        </div>
                    ) : (
                        <div>
                            <Button
                                type="primary"
                                onClick={() => this.generateLicense()}
                                content={!this.isUpgradable()? "Accept & Get Key": "Accept & Upgrade Key"}
                            />
                            <Button
                                type="secondary"
                                onClick={() => this.backToSignIn()}
                                content="Decline"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    genLogo() {
        //dynamically show the main content
        let auth_challenge = this.getAuthChallenge();
        let software = getSoftwareFromChallenge(auth_challenge);

        let contentList = <div></div>;
        const content = [];

        //if we're passed the validation step
        if (software !== undefined && software !== '') {
            // process the agreements
            if (agreementTypes[software]) {
                if (software == 'inquanto') {
                    content.push(
                        <img className="logo" src={inquantoLogo}></img>,
                    );
                }
            }
        } else {
            // the default quantinuum logo

            content.push(<img className="logo" src={logo}></img>);
        }

        contentList = <div>{content}</div>;

        return contentList;
    }

    genContent(software) {
        let contentList = <div></div>;
        const content = [];

        // process the agreements
        if (agreementTypes[software]) {
            if (software == 'inquanto') {
                let auth_challenge = sessionStorage.getItem('challenge');

                let isBeta = isBetaChallenge(auth_challenge);

                content.push(
                    <div key={software} className="software-confirm">
                        <p>
                            Welcome{isBeta ? <b> beta tester</b> : ''}! You have
                            been invited to access{' '}
                            <a href={inquantoProductLink} target="_blank">
                                <b>InQuanto</b>
                            </a>
                            , Quantinuum's state-of-the-art quantum
                            computational chemistry platform.
                        </p>
                        {!this.isTermsExempt() && !this.isCompliant()? (
                            <p>
                                If you haven't already, please take a moment to
                                review and accept our{' '}
                                <b>
                                    <a
                                        className="forgot-password-link"
                                        href={getEula(software, auth_challenge)}
                                        target="_blank"
                                    >
                                        {' '}
                                        {getEulaDescription(software, auth_challenge)}
                                    </a>
                                </b>{' '}
                                in order to {!this.isUpgradable()? 'generate': 'upgrade'} your license key and finish
                                logging in
                            </p>
                        ) : (
                            !this.isUpgradable()? <p>
                                When you're ready, please click <b>Get Key</b>{' '}
                                to generate your license key and finish logging
                                in
                            </p>: <p>
                                When you're ready, please click <b>Upgrade Key</b>{' '}
                                to upgrade your license key and finish logging
                                in
                            </p>
                        )}
                    </div>,
                );
            }
        }

        contentList = <div>{content}</div>;

        return contentList;
    }

    genCompletedContent(software) {
        let contentList = <div></div>;
        const content = [];

        // process the agreements
        if (agreementTypes[software]) {
            if (software == 'inquanto') {
                content.push(
                    <div className="software-confirm">
                        <p>
                            You're all set! Keep an eye out for an email
                            containing your{' '}
                            <a href={inquantoProductLink} target="_blank">
                                <b>InQuanto</b>
                            </a>{' '}
                            license key and instructions on how to get started.
                        </p>
                        <p>
                            When you're ready, please click <b>Continue</b> to
                            finish logging into the application
                        </p>
                    </div>,
                );
            }
        }

        contentList = <div>{content}</div>;

        return contentList;
    }

    getAgreementName() {
        //default name
        let agreementName = 'Hardware';

        const user = this.props.authData;
        //if we hit a challenge a custom challenge
        if (
            user.challengeName !== undefined &&
            user.challengeName === authChallenges.custom
        ) {
            // let's check the status of the user's 'auth-challenge' value. This will tell us how to answer the challenge
            let auth_challenge = user.challengeParam['auth-challenge'];

            let software = getSoftwareFromChallenge(auth_challenge);

            if (software == 'inquanto') {
                agreementName = 'InQuanto';
            }
        }

        return agreementName;
    }

    getAuthChallenge() {
        //default name
        let auth_challenge = '';

        const user = this.props.authData;
        //if we hit a challenge a custom challenge
        if (
            user !== undefined &&
            user.challengeName !== undefined &&
            user.challengeName === authChallenges.custom
        ) {
            //let's get the auth challenge  name
            auth_challenge = user.challengeParam['auth-challenge'];
        }

        return auth_challenge;
    }


    inGracePeriod(){
        let inGracePeriod = (sessionStorage.getItem("in-grace-period") == 'true')
  
        return inGracePeriod
    }

    isCompliant(){
        let acceptedLatest = (sessionStorage.getItem("accepted-latest") == 'true')
  
        return acceptedLatest
    }

    isFederated(){
        let federated = (localStorage.getItem("federated") == 'true')
        return federated
    }

    isUpgradable(){
        let upgradable = (sessionStorage.getItem("upgrade") == 'true')
  
        return upgradable
    }

    isTermsExempt() {
        //default name
        let exempt = false;

        let auth_challenge = this.getAuthChallenge();

        if (auth_challenge.includes('EXEMPT')) {
            exempt = true;
        }

        return exempt;
    }

    async generateLicense() {
        this.setState({ loading: true });

        const user = this.props.authData;

        let invite_id = '';
        // let auth_challenge = this.getAuthChallenge()
        let auth_challenge = sessionStorage.getItem('challenge');
        let software = getSoftwareFromChallenge(auth_challenge);

        // let email = user.username
        let email = sessionStorage.getItem('username');
        //if it's not in the session storage, check local
        if (email == undefined){
            email = localStorage.getItem('username')
        }
        invite_id = sessionStorage.getItem('invite-id');

        let action = 'signup' //default
        if (this.isUpgradable()){
            action = 'upgrade'
        }

        if (invite_id != '' && software !== '') {
            const body = {
                'invite-id': invite_id,
                email: email,
                software: software,
                action: action,
            };
            HQS_API.userRegistration(body)
                .then((response) => {
                    this.setState({ generated: true, loading: false });
                    this.setStep(steps.end);

                    let action = 'generated'
                    if (this.isUpgradable()){
                        action = 'upgraded'
                    }
                    const title = 'Successfully ' + action + ' key';
                    let details = 'Please check your email';
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="success"
                        />,
                    );
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log(error);
                    //show the fialure page
                    if (error !== undefined) {
                        error = error.response.data.error;
                        const title = 'Unable to generate key';
                        let details = error.text;
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="critical"
                            />,
                        );
                    }
                });
        }
    }


    async continueLogin(answer) {
        // const user = this.props.authData;
        const user = {}
        let username = sessionStorage.getItem("username");
        let pwd = sessionStorage.getItem("pwd");
        let federated = localStorage.getItem("federated")
        let challenge = sessionStorage.getItem("challenge")
        if (federated === 'true'){
            let tokenKeys = findLocalItems("msal.token.keys")
            if (tokenKeys.length > 0){
                pwd = JSON.parse(sessionStorage.getItem(tokenKeys[0]["val"]["idToken"][0]))["secret"]

            }
        }

        //setLoading(true)
        //try logging in now to see if there are any more challenges beyond this software challenge
        API_LOGIN.login(username, pwd, federated, challenge, answer)
            .then((response) => {

                handleSignIn(response)
                
            })
            .catch((err) => {
                console.log(err)
                if (err !== undefined) {
                    err = err.response.data.error
                    const title = "Unable to complete action";
                    let details = err.text
                    toast(
                      <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                      />
                    );
                  }
            });
    }

    async backToSignIn() {

        if (this.isFederated()){

            //const currentAccount = accounts[0]

            //sign out of microsoft, this will ensure users have to re-enter their password
            //then sign out of cognito
            //await instance.logoutRedirect({ account: currentAccount }).then( signOutCognito());

            signOutCognito()
        }else{
            //sign out of cognito
            signOutCognito()
        }
   
    }

    async trySignOutAzure() {
        //check if we're were using a federated login
        const isAuthenticated = this.context.accounts.length > 0;
        if (isAuthenticated) {
            const currentAccount = this.context.accounts[0];

            //sign out of microsoft, this will ensure users have to re-enter their password
            await this.context.instance.logoutRedirect({
                account: currentAccount,
            });
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="custom-sign-up">
                    <div className="sign-up-form" style={{ height: 300 }}>
                        <p className="form-completed-p">
                            <Icon
                                name="refresh"
                                size="xlarge"
                                loading={true}
                                color="#1274B7"
                            />
                        </p>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="custom-sign-in">
                    <div className="terms-and-conditions-form">
                        <div className="form-header">{this.genLogo()}</div>
                        <div className="credentials">
                            {this.showContent()}
                            <div>
                                <p className="forgot-password center-text">
                                    <a
                                        className="forgot-password-link"
                                        onClick={() => this.backToSignIn()}
                                    >
                                        Back to sign in?
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        hideProgressBar={true}
                        closeOnClick={false}
                        closeButton={false}
                        newestOnTop={true}
                        position="bottom-right"
                        toastClassName="toast-notification-wrap"
                    />
                </div>
            );
        }
    }
}

export default CustomSoftwareSignUp;
