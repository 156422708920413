// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************
/* eslint no-restricted-globals:0 */

import React, { Component } from 'react';
import * as HQS_API from '../utils/api';
import moment from 'moment';
import CreateCalendarEventForm from '../Forms/CreateCalendarEvent';
import CalendarEvent from './CalendarEvent';
import CalendarEventUser from '../Event/CalendarEventUser';
import CalendarLegend from './CalendarLegend';
import { ToastContainer } from 'react-toastify';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { isElevatedViewMode, toLocalTime } from '../utils/helpers';
import './CalendarTab.css';
import '../../main.scss'; // webpack must be configured to do this

class CalendarTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: props.mode,
            orgNames: props.orgNames,
            allMachines: props.allMachines,
            organization: props.organization,
            selectedDate: moment().format('dddd, MMMM DD yyyy'),
            calendarEvents: [],
            calendarWeekends: true,
            isElevatedViewMode: isElevatedViewMode(props.mode),
            legend: {
                online: true,
                reservation: true,
                reservation_you: true,
                maintenance: true,
            },
            dates: {
                start: null,
                end: null,
            },
        };

        this.calendarComponentRef = React.createRef();

        this.getTodaysEvents = this.getTodaysEvents.bind(this);
        this.getCalendarEvents = this.getCalendarEvents.bind(this);
        this.getOrganizations = this.getOrganizations.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
    }

    componentDidMount() {
        this.getCalendarEvents();
        //fetch the orgs in case we didn't get the results in time from the initial page load of the organizations tab
        //some users don't wait for the page to load all data and jump straight to calendar, causing them to miss org name data when they want to schedule an event
        if (this.state.orgNames == null || this.state.orgNames.length == 0) {
            this.getOrganizations();
        }
    }

    handleDateClick = (arg) => {
        this.setState({ selectedDate: moment(arg.date).format('dddd, MMMM DD yyyy') }, this.getTodaysEvents);
    };

    handleEventClick = (info) => {
        this.setState(
            {
                selectedDate: moment(info.event.start).format('dddd, MMMM DD yyyy'),
            },
            this.getTodaysEvents,
        );
    };

    async getOrganizations() {
        HQS_API.getOrganizations()
            .then((response) => {
                const orgNames = [];
                response.forEach((org) => {
                    orgNames.push(org.org_name);
                });

                this.setState({
                    orgNames: orgNames,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getTodaysEvents() {
        const todaysEvents = [];
        let dateFilter = moment(this.state.selectedDate);
        this.state.calendarEvents.forEach((item) => {
            let eventStart = moment(item.start);
            if (dateFilter.isSame(eventStart, 'day')) {
                let event = undefined;
                if (!this.state.isElevatedViewMode) {
                    //users/orgs get a scaled down event
                    let userEvent = false;
                    if (item['extendedProps'].organization === this.state.organization) {
                        userEvent = true;
                    }
                    event = (
                        <CalendarEventUser
                            title={item.title}
                            startTime={item.start}
                            endTime={item.end}
                            eventType={item['extendedProps'].type}
                            userEvent={userEvent}
                        />
                    );
                } else {
                    //operators and admins get the full calendar event
                    event = (
                        <CalendarEvent
                            key={moment().format() + item.id}
                            id={item.id}
                            title={item.title}
                            startTime={item.start}
                            endTime={item.end}
                            eventType={item['extendedProps'].type}
                            reservationType={item['extendedProps'].reservationType}
                            machine={item['extendedProps'].machine}
                            selectedOrg={item['extendedProps'].organization}
                            canceledDate={item['extendedProps'].canceledDate}
                            orgs={this.state.orgNames}
                            allMachines={this.state.allMachines}
                            recurringID={item['extendedProps'].recurringID}
                            callBack={this.getCalendarEvents.bind(this)}
                        />
                    );
                }
                if (event != undefined) {
                    todaysEvents.push(event);
                }
            }
        });
        todaysEvents.sort((a, b) => a.props.startTime.localeCompare(b.props.startTime));

        return todaysEvents;
    }

    async getCalendarEvents(start, end) {
        // only get calendar data if we know our date range
        if (start == undefined || end == undefined) {
            //get the api to control the calendar
            let calendar = this.calendarComponentRef.current.getApi();
            //advance to the next view of the calendar (either monthly or weekly)
            start = calendar.view.activeStart;
            end = calendar.view.activeEnd;
        }

        let queryParams = {
            mode: this.state.mode,
            start: moment(start).format('YYYY-MM-DD'),
            end: moment(end).format('YYYY-MM-DD'),
        };

        // get a list of the actively selected legend filters
        let active = Object.keys(this.state.legend).filter((s) => this.state.legend[s] === true);

        if (active.length != Object.keys(this.state.legend).length) {
            //build the list of events to filter on
            let types = [...active].join(',');
            //if all are turned off, then we need filter out everything
            if (active.length == 0) {
                types = 'none';
            }
            //pass the types into the query parameters
            if (types) {
                // the reservation (you) filter is only applicable to user/org modes
                if (!this.state.isElevatedViewMode) {
                    //check if this was reservation (you)
                    if (this.state.legend.reservation_you && !this.state.legend.reservation) {
                        types = types.replace('reservation_you', 'reservation');
                        //tell the request to filter on the requester's org in the result
                        queryParams.self = true;
                    }

                    if (!this.state.legend.reservation_you && this.state.legend.reservation) {
                        //tell the request to exclude the requester's org in the result
                        queryParams.self = false;
                    }
                }

                queryParams.type = types;
            }
        }

        //refresh the calendar
        HQS_API.listMachineEvents(queryParams)
            .then((response) => {
                const offset = moment().utcOffset();
                const calEvents = [];
                response.forEach((event) => {
                    let extendedProps = {
                        type: event['event-type'],
                        organization: event['organization'],
                    };

                    //only include machine and reservation type information for operators and admins
                    if (this.state.isElevatedViewMode) {
                        extendedProps['machine'] = event['machine'];
                        extendedProps['reservationType'] =
                            'reservation-type' in event ? event['reservation-type'] : 'native';
                        extendedProps['recurringID'] = 'recurring-id' in event ? event['recurring-id'] : undefined;
                        extendedProps['canceledDate'] = 'canceled-date' in event ? event['canceled-date'] : undefined;
                    }

                    let startDate = toLocalTime(event['start-date']);

                    let endDate = toLocalTime(event['end-date']);

                    let backgroundColor = this.getEventColor(event);
                    let eventTitle = this.getEventTitle(event);

                    let newEvent = {
                        id: event.id,
                        title: eventTitle,
                        start: startDate,
                        end: endDate,
                        backgroundColor: backgroundColor,
                        textColor: 'black',
                        extendedProps: extendedProps,
                    };
                    calEvents.push(newEvent);
                });

                //hold onto the currently selected dates so the legend knows which dates to filter on
                let dates = {
                    start: start,
                    end: end,
                };

                this.setState({ calendarEvents: calEvents, dates: dates });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getEventColor(event) {
        let backgroundColor = '';

        if (event['event-type'] === 'reservation') {
            //if we know the organization
            if (event['organization'] === this.state.organization) {
                //yellow
                backgroundColor = '#ffc627';
            } else {
                //blue/green
                backgroundColor = '#57cfb6';
            }
        } else if (event['event-type'] === 'online') {
            //light green
            backgroundColor = '#A0F18C';
        } else {
            //Unavailable or Maintenance event
            //gray
            backgroundColor = '#c0c0c0';
        }
        return backgroundColor;
    }

    getEventTitle(event) {
        let title = event['event-type'].charAt(0).toUpperCase() + event['event-type'].slice(1);

        let eventTitle = title;

        if (this.state.isElevatedViewMode) {
            let title = event['event-type'].charAt(0).toUpperCase() + event['event-type'].slice(1);

            eventTitle =
                event['event-type'] === 'reservation'
                    ? event['organization'] + '\n' + event['machine']
                    : title + '\n' + event['machine'];
        } else {
            if (event['event-type'] === 'reservation') {
                //if we know the organization
                if (event['organization'] === this.state.organization) {
                    eventTitle = event['machine'] + '\n' + event['organization'];
                } else {
                    eventTitle = event['machine'];
                }
            } else if (event['event-type'] === 'online') {
                eventTitle = event['machine'] + '\n' + 'online';
            } else {
                eventTitle = event['machine'] + '\n' + 'unavailable';
            }
        }

        if (event.hasOwnProperty('canceled-date')) {
            eventTitle = eventTitle + ' (canceled)';
        }
        return eventTitle;
    }

    handleChecked(name, checked) {
        //update the legend filters
        let legend = this.state.legend;
        legend[name] = checked;
        this.setState({ legend: legend });
        //refresh the calendar with these events (reuse the currently selected dates)
        this.getCalendarEvents(this.state.dates.start, this.state.dates.end);
    }

    render() {
        let allMachines = this.state.allMachines;
        let orgNames = this.state.orgNames;

        var addEvent = '';
        if (this.state.isElevatedViewMode) {
            addEvent = (
                <div>
                    <div className="hqs-form-button-wrapper">
                        <CreateCalendarEventForm
                            orgs={orgNames}
                            allMachines={allMachines}
                            isHqsAdmin={false}
                            callBack={this.getCalendarEvents.bind(this)}
                        />
                    </div>
                    <ToastContainer
                        hideProgressBar={true}
                        closeOnClick={false}
                        closeButton={false}
                        newestOnTop={true}
                        position="bottom-right"
                        toastClassName="toast-notification-wrap"
                    />
                </div>
            );
        }

        let view = (
            <div className="hqs-calendar-view">
                <div className="hqs-calendar-card">
                    <div className="hqs-umui-card-header">MACHINE AVAILABILITY</div>
                    <FullCalendar
                        defaultView="dayGridMonth"
                        header={{
                            left: 'prev,title,next',
                            // center: 'prev,title',
                            right: 'dayGridMonth timeGridWeek today',
                        }}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        datesRender={(arg) => {
                            // Triggered when a new set of dates has been rendered.
                            // update the calendar events based on the current start and end date range
                            // Note: Change to "datesSet" when upgrading FullCalendar to v5
                            this.getCalendarEvents(arg.view.activeStart, arg.view.activeEnd);
                        }}
                        ref={this.calendarComponentRef}
                        weekends={this.calendarWeekends}
                        events={this.state.calendarEvents}
                        dateClick={this.handleDateClick}
                        eventClick={this.handleEventClick}
                        selectable={true}
                        height={672}
                    />
                </div>
                <div className="hqs-calendar-card">
                    <div className="hqs-umui-card-header">DAILY EVENTS</div>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 3 }}>
                            <CalendarLegend
                                name="online"
                                label="Online"
                                mode={this.state.mode}
                                checked={this.state.legend.online}
                                onChange={this.handleChecked}></CalendarLegend>
                            <CalendarLegend
                                name="reservation"
                                label="Reservation"
                                mode={this.state.mode}
                                checked={this.state.legend.reservation}
                                onChange={this.handleChecked}></CalendarLegend>
                            {!this.state.isElevatedViewMode ? (
                                <CalendarLegend
                                    name="reservation_you"
                                    label="Reservation (You)"
                                    mode={this.state.mode}
                                    checked={this.state.legend.reservation_you}
                                    onChange={this.handleChecked}></CalendarLegend>
                            ) : (
                                ''
                            )}
                            <CalendarLegend
                                name="maintenance"
                                label="Maintenance"
                                mode={this.state.mode}
                                checked={this.state.legend.maintenance}
                                onChange={this.handleChecked}></CalendarLegend>
                        </div>
                    </div>
                    <h3>{this.state.selectedDate}</h3>
                    <div className="events-list">
                        <ul>{this.getTodaysEvents()}</ul>
                    </div>
                    {addEvent}
                </div>
            </div>
        );

        return view;
    }
}

export default CalendarTab;
