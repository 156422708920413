// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const logo = require('../icons/icon_alert_critical.svg');

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

// Custom hook for handling job cancel
const useCancelJobForm = (closeCallback, props) => {

    async function cancelJob(jobId) {
        HQS_API
            .cancelJob(jobId)
            .then((response) => {
                if (props.callback) {
                    props.callback();
                }
                const title = 'Job Cancelling';
                const details = 'A request to cancel your job was submitted';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                    />
                );
            })
            .catch((error) => {
                if (props.callback) {
                    props.callback();
                }
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.message;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            });
    }

    const handleCancel = (event) => {
        if (event) {
            event.preventDefault();
        }
        cancelJob(props.jobId);
        closeCallback();
    };

    return { handleCancel };
};

const CancelJobForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleCancel } = useCancelJobForm(handleClose, props);

    return (
        <div>
            <div onClick={handleShow} style={{display: 'flex', cursor: 'pointer' }}>
                <div>
                    <Icon
                        root="common"
                        name="badge-stop"
                        size="small">    
                    </Icon>
                </div>
                &nbsp;
                <div style={{ paddingTop: '2px' }}>
                    Cancel
                </div>
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-delete-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Cancel Job</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        This will send a request to cancel the selected job. Are you sure?
                    </p>
                    <Button type="primary" size="small" onClick={handleCancel}>
                        Yes
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default CancelJobForm;
