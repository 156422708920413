/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2021-2023 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React from 'react';
import UpdateMachineTotalJobsForm from '../Forms/UpdateMachineTotalJobs';
import Dropdown from 'react-bootstrap/Dropdown';

const generateOptions = (selectedRow, callback) => {
    let actionsList = [];

    if (selectedRow !== undefined && selectedRow !== null) {
        const machineDetails = {
            name: selectedRow[0].name,
            normal: selectedRow[0]['active_jobs'],
            reserved: selectedRow[0]['reserved_jobs'],
            total: selectedRow[0]['total_jobs'],
            capacity: selectedRow[0]['capacity']
        };

        // update total jobs
        actionsList.push(
            <Dropdown.Item className='hqs-custom-actions-select-option'>
                <UpdateMachineTotalJobsForm
                    machineDetails={machineDetails}
                    callback={callback}
                />
            </Dropdown.Item>
        )

    } else {
        actionsList.push(
            <Dropdown.Item
                className="hqs-custom-actions-select-option"
                disabled={true}
            >
                <div>Select a row...</div>
            </Dropdown.Item>,
        );
    }

    return actionsList;
};

const AdminMachineActionSelect = (props) => {
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className="ui small button primary hqs-custom-dropdown"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            &#x25bd;&nbsp;
            {children}
        </div>
    ));
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {generateOptions(
                    props.selectedRow,
                    props.callback,
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AdminMachineActionSelect;
