import React from 'react';

function NextFQReset(props) {
    return (
        <div>
            <div className="hqs-fq-container">
            <div className="hqs-fq-header">NEXT ACCUMULATED HQC RESET</div>
                <div className="hqs-fq-values">{props.nextFQReset}</div>
            </div>
        </div>
        
    );
}

export default NextFQReset;
