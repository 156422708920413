/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import {
    Button,
    Badge,
    Icon,
    InputLabel,
    Notification,
    Modal,
    Input,
} from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details }) => (
    <Notification
        className="toast-notification"
        severity="success"
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const UpdateMachineTotalJobsForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [total_jobs, setTotalJobs] = useState(props['machineDetails']['total']);


    function handleCountChange(total_jobs) {
        setTotalJobs(total_jobs);
    }

    function handleClose() {
        setShow(false);
        setTotalJobs(props['machineDetails']['total']);

    }

    function handleUpdate() {
        const body = {}
        body['total_jobs'] = parseInt(total_jobs)
        body['machine'] = props['machineDetails']['name']
        
        HQS_API.updateMachine(body)
            .then(() => {
                const title = 'Total Jobs Updated';
                const details = 'The system has successfully updated the total jobs count';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                    />
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                console.log(error)
            });

        handleClose();
    }

    return (
        <div>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="communication"
                    size="small"></Icon>
                &nbsp;Update Total Jobs
            </div>

            <Modal
                open={show}
                className="hqs-umui-information-modal"
                onClose={handleClose}
                size="small"
                style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="display" size="medium"></Icon>
                        &nbsp;&nbsp;Update Total Jobs Count
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                <p>
                    <Badge color="red">WARNING! </Badge>
                    &nbsp;Updating this value can have implications on Fair Queueing. Please be careful while updating it! 
                </p>
                <InputLabel label="Total Jobs:" />
                    <Input
                        type="number"
                        placeholder="0"
                        min="0"
                        value={total_jobs}
                        onChange={(total_jobs) =>
                            handleCountChange(total_jobs)
                        }
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateMachineTotalJobsForm;