// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React, { useState, useRef } from 'react';
import {
    Button,
    DatePicker,
    Modal,
    Checkbox,
    Icon,
    Loader,
    InputLabel,
} from '@scuf/common';
import { AmplifyConfig } from '../config';
import { getIdToken } from '../utils/helpers';
import { Notification } from '@scuf/common';
import moment from 'moment';


export const GenerateUsageReportForm = (props) => {
    const [show, setShow] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState({
        error: false,
        severity: 'important',
        title: '',
        text: '',
    });
    const [paramState, setParamState] = useState({
        to: '',
        from: '',
        dateError: 'select range',
        allOrgs: false,
    });
    const blobRef = useRef();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const getISODate = (date, replace = false) =>
        replace
            ? date.toISOString().split('T')[0].replace(/-/g, '')
            : date.toISOString().split('T')[0];
    const fetchCsv = async (endpoint, filename) => {
        setFetching(true);
        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    Authorization: await getIdToken(),
                },
            });
            if (!response.ok) throw (await response.json()).error.text;
            if (typeof blobRef.current === 'object') {
                const blob = await response.blob();
                if (blob.size > 2) {
                    const url = URL.createObjectURL(blob);
                    blobRef.current.href = url;
                    blobRef.current.download = filename;
                    blobRef.current.click();
                    URL.revokeObjectURL(url);
                    setError({
                        ...error,
                        error: false,
                    });
                } else {
                    setError({
                        error: true,
                        severity: 'important',
                        title: 'No records found',
                        text: '',
                    });
                }
            }
        } catch (err) {
            setError({
                error: true,
                severity: 'critical',
                title: `Report generation failed: ${err}`,
                text: '',
            });
        }
        setFetching(false);
    };
    const fetchJobs = async () => {
        const { dateError, from, to, allOrgs } = paramState;
        if (dateError) {
            setError({
                error: true,
                title: 'Date Error',
                severity: 'critical',
                text: dateError,
            });
            return;
        }
        let startTemp = moment(from).startOf('day')
        let endTemp = moment(to).endOf('day')
        const start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        const end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        let endpoint = `${
            AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_MACHINE_API')
                .endpoint
        }/job/reporting?start=${start}&end=${end}&status=completed,canceled`;
        if (!allOrgs) endpoint += '&org=' + encodeURIComponent(props.organization);
        await fetchCsv(endpoint, `job_usage_report_${getISODate(new Date(), true)}.csv`);
    };
    const fetchAzureJobs = async () => {
        const { dateError, from, to, allOrgs } = paramState;
        if (dateError) {
            setError({
                error: true,
                title: 'Date Error',
                severity: 'critical',
                text: dateError,
            });
            return;
        }
        let startTemp = moment(from).startOf('day')
        let endTemp = moment(to).endOf('day')
        const start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        const end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        let endpoint = `${
            AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_MACHINE_API')
                .endpoint
        }/job/reporting?azure=true&start=${start}&end=${end}&status=completed,canceled`;
        await fetchCsv(
            endpoint,
            `azure_usage_report_${getISODate(new Date(), true)}.csv`
        );
    };
    const fetchReservations = async () => {
        const { dateError, from, to, allOrgs } = paramState;
        if (dateError) {
            setError({
                error: true,
                title: 'Date Error',
                severity: 'critical',
                text: dateError,
            });
            return;
        }
        const start = getISODate(from);
        const end = getISODate(to);
        let endpoint = `${
            AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_UI_API').endpoint
        }/reservation?mode=${props.mode}&start=${start}&end=${end}&format=csv`;
        if (!allOrgs) endpoint += '&org=' + encodeURIComponent(props.organization);
        await fetchCsv(
            endpoint,
            `reservation_usage_report_${getISODate(new Date(), true)}.csv`
        );
    };
    const onRangeSelect = ({ to, from }) => {
        const newState = {
            ...paramState,
            to,
            from,
            dateError: to && from ? '' : 'select range',
        };
        setParamState(newState);
    };
    const { to, from, dateError, allOrgs } = paramState;
    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow}>
                Usage Report
            </Button>
            <Modal
                open={show}
                onClose={handleClose}
                className="hqs-umui-modal"
                size="small">
                <Modal.Header>
                    <Icon
                        root="common"
                        name="document-report"
                        size="medium"
                        style={{ paddingRight: '14px' }}></Icon>
                    <div className="hqs-umui-modal-header ">
                        Usage Report - {allOrgs ? 'All Orgs' : props.organization}
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <Loader
                        loading={fetching}
                        overlayColor="#a0a0a0"
                        text="Fetching report"
                        className="hqs-umui-loader-wrap">
                        <div className="hqs-form-group">
                            {error.error && (
                                <Notification
                                    severity={error.severity}
                                    hasIcon={true}
                                    title={error.title}
                                    onCloseClick={() =>
                                        setError({ ...error, error: false })
                                    }>
                                    {error.text}
                                </Notification>
                            )}
                        </div>
                        <p>
                            The usage reports include records for{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {props.organization}
                            </span>{' '}
                            within the date range. Checking the all orgs box will include
                            records for all orgs.
                        </p>
                        <div className="hqs-form-group">
                            <DatePicker
                                label="Report Dates"
                                type="daterange"
                                indicator="required"
                                placeholder="select date range"
                                error={dateError}
                                rangeValue={{ from, to }}
                                closeOnSelection={false}
                                closeOnDocumentClick={true}
                                onRangeSelect={onRangeSelect}
                                onTextChange={(value, error) =>
                                    setParamState({
                                        ...paramState,
                                        dateError: error,
                                    })
                                }
                            />
                            <Checkbox
                                label="All Orgs"
                                checked={allOrgs}
                                onChange={(checked) =>
                                    setParamState({
                                        ...paramState,
                                        allOrgs: checked,
                                    })
                                }
                            />
                        </div>
                        <div className="hqs-form-group">
                            <InputLabel label="Click links below to download reports" />
                            <a
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    fetchAzureJobs();
                                }}
                                className="hqs-blue-icon">
                                Azure Usage
                            </a>
                            <a
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    fetchJobs();
                                }}
                                className="hqs-blue-icon">
                                Job Usage
                            </a>
                            <a
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    fetchReservations();
                                }}
                                className="hqs-blue-icon">
                                Reservation Usage
                            </a>
                        </div>
                    </Loader>
                </Modal.Content>
                <Modal.Footer>
                    <a style={{ display: 'none' }} href="" ref={blobRef} download="" />
                    <Button type="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
