// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { DataTable } from '@scuf/datatable';
import { Button, Icon, Input, InputLabel, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import {
    roundTwoDecimalPlaces,
    isLicensedPlan,
    softwareMap
} from '../utils/helpers';

const EnableUserQuotaForm = (props) => {
    const [quota, setQuota] = useState(undefined);
    const [selectedMachinePlan, setMachinePlan] = useState([]);
    const [machinePlanError, setMachinePlanError] = useState();
    const [quotaError, setQuotaError] = useState('');
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    function handleQuotaChange(val) {
        setQuota(val);
        setQuotaError('');
    }

    function handleMachinePlan(e) {
        //clear error if any
        setMachinePlanError('');
        setMachinePlan(e);
    }

    function handleClose() {
        //reset state props when closing form
        setMachinePlan([]);
        setMachinePlanError('');
        setShow(false);
    }

    function validateFromValues() {
        let validData = true;

        // positive quota value
        if(quota === undefined || quota < 0 ){
            setQuotaError('Invalid Quota Value!');
            validData = false;
        }

        // machine plan
        if (selectedMachinePlan === undefined || selectedMachinePlan.length === 0) {
            setMachinePlanError('Machine Plan Selection Missing');
            validData = false;
        }

        return validData;
    }

    function machinesRenderer(cellData) {
        const data = cellData.value;
        return <span>{Object.keys(data).join(', ')}</span>;
    }


    function creditsRenderer(cellData) {
        var credits = 'NA';
        var availableCredits = 0;
        if ("rowData" in cellData) {
            const row = cellData["rowData"]

            if ('unlimited' in row && row['unlimited']){
                credits = "Unlimited"
            }else{
           
                if ("available_hqc" in row && row['available_hqc'] !== 'NA'){
                    availableCredits = row["available_hqc"];
                    credits = roundTwoDecimalPlaces(availableCredits);
                }
            }
            
        }

        return <span>{credits}</span>;
    }

    function enableRenderer(cellData) {
        const data = cellData.value;
        return <span>{data? "True": "False"}</span>;
    }

    function softwareRenderer(cellData) {
        const data = cellData.value;

        if(isLicensedPlan(data)){

            return <span>{softwareMap[data]}</span>;
        }else{
            return <span>{data}</span>;
        }

    }

    function handleUpdate() {
        if(validateFromValues()) {
            // increment available HQC
            let body = {};
            body['hqc_quota'] = Number(quota);
            body['plan_id'] = selectedMachinePlan[0]['id'];

            // add user quota
            HQS_API.updateQuota(props.user, 'enable', body)
                .then((response) => {
                    if (props.callBack) {
                        props.callBack();
                    }
                    const title = 'Quota Added';
                    const details = 'Quota has been added successfully';
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="success"
                        />
                    );
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unknown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="critical"
                        />
                    );
                });
            handleClose();
        }
    }

    return (
        <div>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="tag-found"
                    size="small"
                ></Icon>
                &nbsp;{props.children || 'Add User Quota'}
                &nbsp;
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="medium"
                className="hqs-umui-information-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon
                            root="common"
                            name="portfolio-savings"
                            size="medium"
                        ></Icon>
                        &nbsp;&nbsp;Add HQC quota
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        This will add a quota restriction for &quot;{props.user}
                        &quot;.
                    </p>
                    <InputLabel label="Quota Amount:" indicator="required" />
                    <Input
                        type="number"
                        min="0"
                        onChange={(quota) => handleQuotaChange(quota)}
                    />
                    <div className="below-text">
                        <span className="ui error-message">{quotaError}</span>
                    </div>
                    <InputLabel label="Select Machine" indicator="required" />
                    <DataTable
                        data={props.org_plans}
                        scrollable={true}
                        scrollHeight="250px"
                        selection={selectedMachinePlan}
                        onSelectionChange={(e) => handleMachinePlan(e)}
                        selectionMode="single"
                        showHeader={true}
                        scrollWidth='750px'
                        resizableColumns={true}   
                    >
                        <DataTable.Column 
                            header="MACHINE"
                            field="machine_name" 
                            initialWidth={200}
                            renderer={machinesRenderer}
                        />
                        <DataTable.Column
                            header="ENABLED"
                            field="enabled" 
                            renderer={enableRenderer}
                        />
                        <DataTable.Column
                            header="CREDITS AVAILABLE"
                            field="available_hqc" 
                            initialWidth={200}
                            renderer={creditsRenderer}
                        />
                         <DataTable.Column
                            header="SOFTWARE"
                            field="software" 
                            initialWidth={150}
                            renderer={softwareRenderer}
                        />
                    </DataTable>
                    <div className="below-text">
                        <span className="ui error-message">{machinePlanError}</span>
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleUpdate}>
                        Add
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EnableUserQuotaForm;
