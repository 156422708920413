// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';

const logo = require('../icons/icon_alert_critical.svg');

const useDeleteOrgForm = (closeCallback, props) => {
    const deleteOrganization = async (org) => {
        // check if there are users associated to an org before deleting
        const filters = {
            organization: org,
        };
        const users = await HQS_API.getUsers(filters).catch((error) => {
            console.log(error);
        });

        // delete users
        if (users.length != 0) {
            for (let i = 0; i < users.length; i++) {
                await deleteUser(users[i]);
            }
        }

        // delete access plans
        if (props.orgPlans.length != 0) {
            for (let i = 0; i < props.orgPlans.length; i++) {
                await deletePlan(org, props.orgPlans[i]);
            }
        }

        deleteOrg(org, props);
    };

    const deleteUser = async (user) => {
        await HQS_API.deleteUser(user['email']).catch((error) => {
            error = error.response.data.error;
            if (error.code === ErrorCodes.UserDoesNotExist) {
                alert(error.text);
            }
            if (error.code === ErrorCodes.CannotDeleteSelf) {
                alert(error.text);
            }
        });
    };

    const deletePlan = async (org, plan) => {
        await HQS_API.removeOrgMachinePlan(org, plan['id']).catch((error) => {
            error = error.response.data.error;
            const title = 'Error Deleting Organization Plan';
            const details = error.text;
            toast(<ToastNotification severity="critical" closeToast={false} title={title} details={details} />);
        });
    };

    const deleteOrg = (org, props) => {
        HQS_API.deleteOrganization(org)
            .then((response) => {
                if (props.callBack) {
                    props.callBack();
                }
                const title = 'Org Deleted';
                const details = `The system has successfully removed organization: ${org}`;
                toast(<ToastNotification closeToast={false} title={title} details={details} />);
            })
            .catch((error) => {
                const title = 'Error Deleting Organization';
                const details = error.response.data.error.text;
                toast(<ToastNotification severity="critical" closeToast={false} title={title} details={details} />);
            });
    };

    const handleDelete = (event) => {
        if (event) {
            event.preventDefault();
        }
        deleteOrganization(props.org);
        closeCallback();
    };

    return { handleDelete };
};

const DeleteOrgForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleDelete } = useDeleteOrgForm(handleClose, props);

    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow}>
                Delete Org
            </Button>

            <Modal open={show} onClose={handleClose} size="small" className="hqs-umui-delete-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Delete Organization</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        This will disable and remove all current administrator and user accounts under this
                        organization.
                    </p>
                    <Button type="primary" size="small" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default DeleteOrgForm;
