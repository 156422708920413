/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React from "react";
import { Button, Input, InputLabel, Modal, Icon, Notification } from '@scuf/common';
import { ForgotPassword } from "aws-amplify-react";
import './CustomAuth.css';
import { useState } from 'react';
import { Auth } from 'aws-amplify'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import * as HQS_API from '../utils/api';
import { recoveryActions } from '../utils/helpers';

const logo = require('./QuantinuumLogo.svg');

class CustomConfirmForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = [recoveryActions.confirmPwd, recoveryActions.confirmMfa];

    this.state = {
      code: '',
      password: '',
      confirmPassword: '',
      disabled: true,
      passwordMatch: false,
      recoverAction: sessionStorage.getItem("recover-action"),
      email: sessionStorage.getItem("username")
    }
    this.updateCode = this.updateCode.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.updateConfirmPassword = this.updateConfirmPassword.bind(this)
    this.tryEnable = this.tryEnable.bind(this);
    this.confirmForgotPassword = this.confirmForgotPassword.bind(this);
    this.resendVerificationCode = this.resendVerificationCode.bind(this);
    this.setPasswordMatch = this.setPasswordMatch.bind(this);
    


  }

  updateCode(value) {
    this.setState({ code: value })
    this.tryEnable(value, this.state.password, this.state.confirmPassword)
  }

  updatePassword(value) {
    this.setState({ password: value })
    this.tryEnable(this.state.code, value, this.state.confirmPassword)

  }

  updateConfirmPassword(value) {
    this.setState({ confirmPassword: value })
    this.tryEnable(this.state.code, this.state.password, value)

  }



  tryEnable(code, password, confirmPassword) {
    //update the password match state
    this.setPasswordMatch(password, confirmPassword)
    //verify the code exists and there's a password
    let enabled = code.length > 0 && password.length > 0 && confirmPassword.length > 0
    this.setState({ disabled: !enabled })
  }

  setPasswordMatch(password, confirmPassword) {
    let match = password == confirmPassword
    this.setState({ passwordMatch: match })

  }

  reset(){
     //before we return to the sign in page, make sure we reset the state
     this.setState({code: '', password: '', confirmPassword: '', disabled: true, passwordMatch: false})
  }

  backToSignIn(){
    //before we return to the sign in page, make sure we reset the state
    this.reset()
    window.location.replace("/login")
  }



  async confirmForgotPassword() {

    let password = this.state.password
    let code = this.state.code

    //default action - confirmPassword
    let action = recoveryActions.confirmPwd

    //change the action and next state based on current auth state
    if (this.state.recoverAction == recoveryActions.confirmMfa){
      action = recoveryActions.confirmMfa
    }

    if (!this.state.passwordMatch) {
      const title = 'Invalid Password!';
      const details = `Passwords do not match. Please try again`;
      toast(
        <ToastNotification
          closeToast={false}
          title={title}
          details={details}
          severity="critical"
        />
      );
    } else {

      let body = {
        code: code,
        password: password,
        action: action
      }

      HQS_API.recoverUserCredentials(this.state.email, body)
        .then((response) => {
          const title = 'Success!';
          const details = `Password successfully reset. Please login again with your updated credentials.`;
          toast(
            <ToastNotification
              closeToast={false}
              title={title}
              details={details}
              severity="success"
            />
          );

          this.backToSignIn()
        })
        .catch((error) => {
          console.log(error)
          const title = 'Error!';
          var details = 'Unknown Error!';
          if (error.response !== undefined) {
            details = error.response.data.error.text;
          }
          toast(
            <ToastNotification
              closeToast={false}
              title={title}
              details={details}
              severity="critical"
            />
          );
        });
    }
  }

  async resendVerificationCode() {

    const email = this.state.email

    //default state
    let action = recoveryActions.forgotPwd

    //update the state
    if (this.state.recoverAction == recoveryActions.confirmMfa){
      action = recoveryActions.forgotMfa
    }

    let body = {
      action: action
    }

    HQS_API.recoverUserCredentials(email, body)
      .then((response) => {
        const title = 'Success!';
        const details = `Verification code sent. Please check your email.`;
        toast(
          <ToastNotification
            closeToast={false}
            title={title}
            details={details}
            severity="success"
          />
        );

      })
      .catch((error) => {
        console.log(error)
        const title = 'Error!';
        var details = 'Unknown Error!';
        if (error.response !== undefined) {
          details = error.response.data.error.text;
        }
        toast(
          <ToastNotification
            closeToast={false}
            title={title}
            details={details}
            severity="critical"
          />
        );
      });
  }


  render() {

    return (
      <div className="custom-sign-in">
        <div className="forgot-password-form">
          <div className="form-header">
            <img className="logo" src={logo}></img>
          </div>
          <div className="credentials">
            <div className="mfa-confirm">
              <h4>Account Recovery</h4>
              <div className="mfa-steps">
                <ol>
                  <li>Enter the code we sent to your email</li>
                  {this.state.recoverAction !=  recoveryActions.confirmMfa ?
                    <li>Enter and confirm your new password.</li>
                    :
                    <li>For your security, we also ask that you take moment to update your password in order complete this process.</li>
                  }
                </ol>
              </div>
            </div>

            <div className="new-password">
              <Input className="input-form" type="text" fluid="true" onChange={(value) => this.updateCode(value)} label="Code" placeholder="Enter the verification code" />
              <br />
              <p className="resend-password center-text">

                Didn't receive a code? Click <a className="resend-code-link" onClick={() => this.resendVerificationCode()}>
                  here</a> to resend</p>

            </div>

            <div className="new-password">
              <Input className="input-form" type="password" fluid="true" onChange={(value) => this.updatePassword(value)} label="New Password" placeholder="Enter your new password" />
            </div>
            <div className="new-password">
              <Input className="input-form" type="password" fluid="true" onChange={(value) => this.updateConfirmPassword(value)} label="Confirm Password" placeholder="Confirm your new password" />
            </div>
            <div className="change-password">
              <br />

              <Button type="primary" onClick={() => this.confirmForgotPassword()} content="Confirm" disabled={this.state.disabled} />

              <p className="forgot-password center-text">

                <a className="forgot-password-link" onClick={() => this.backToSignIn()}>
                  Back to sign in?
                </a>
              </p>
            </div>
          </div>
        </div>
        <ToastContainer
          hideProgressBar={true}
          closeOnClick={false}
          closeButton={false}
          newestOnTop={true}
          position="bottom-right"
          toastClassName="toast-notification-wrap"
        />
      </div>
    )
  }
}

export default CustomConfirmForgotPassword;