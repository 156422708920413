// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState, useEffect } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Modal, Badge } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import { isNullOrEmpty, isLicensedPlan, softwareMap } from '../utils/helpers';

const logo = require('../icons/icon_alert_critical.svg');

// Custom hook for handling the delete plan form
const useDeleteOrgPlanForm = (closeCallback, props) => {
    async function deletePlan(org, id) {
        HQS_API.removeOrgMachinePlan(org, id)
            .then((response) => {

                const title = 'Plan Deleted';
                const details = 'The system has successfully removed the plan';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                    />,
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                var details = 'Unknown Error!';

                if (error.response !== undefined) {
                    const title = 'Unable to delete plan';

                    details = error.response.data.error.text;
                    console.log(details);
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity={"critical"}
                        />,
                    );
                } else {
                    console.log(error);
                }
            });
    }

    const handleDelete = (event) => {
        if (event) {
            event.preventDefault();
        }
        deletePlan(props.org, props.id);
        closeCallback();
    };

    return { handleDelete };
};

const DeleteOrgPlanForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleDelete } = useDeleteOrgPlanForm(handleClose, props);
    const [software, setSoftware] = useState(props['software'])
    const [orgLicenses, setOrgLicenses] = useState(props['licenses'])

    useEffect(() => {
        setSoftware(props['software'])
        setOrgLicenses(props['licenses']);
    }, [props['software'], props['licenses']]);

    return (
        <div>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="delete"
                    size="small"></Icon>
                &nbsp;{props.children || 'Delete'}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Delete Plan</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                    <Badge color="red">WARNING!</Badge> This will delete the selected plan from your organization. Please ensure their are <b>no active quotas</b> assigned to users or groups before deleting this plan. The request will be denied until those have been removed or their balances set to 0.
                    </p>
                    {!isNullOrEmpty(software) && isLicensedPlan(software.name) && !isNullOrEmpty(orgLicenses) ? 
                        <p><Badge color="blue">IMPORTANT</Badge> If any users are setup with access to <b>{softwareMap[software.name]}</b> those assignments will be removed and any issued license keys will be blocked even if they haven't expired yet. If multiple <b>{softwareMap[software.name]}</b> plans exist for this org, this clean up will only happen once when the last plan is removed.</p> : ""}
                    <p>
                    Are you sure you want to continue?
                    </p>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="primary" size="small" onClick={handleDelete}>
                        Delete Plan
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DeleteOrgPlanForm;
