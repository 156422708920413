/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2021-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import Form from 'react-bootstrap/Form';
import { Auth } from 'aws-amplify';
import {
    Button,
    Modal,
    Input,
    Badge,
} from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import {handleNotAuthorized} from '../utils/helpers';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";


const EmailChangeForm = (props) => {
    const [show, setShow] = useState(false);
    const [applyLoading, setApplyLodading] = useState(false);
    const handleShow = () => setShow(true);
    const [applyDisabled, setApplyDisabled] = useState(true);
    const [email, setEmail] = useState(props.email);
    const [emailError, setEmailError] = useState('');
    const [isFederated, setIsFederated] = useState(useIsAuthenticated());
    const { instance, accounts } = useMsal();

    const history = useHistory();
    function handleClose() {
        setEmail(props.email);
        setEmailError('');
        setShow(false);
        setApplyDisabled(true);
        setApplyLodading(false);

    }

    function validateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true
        }
        return false
    }

    function handleEmail(value) {
        setApplyDisabled(false);
        setEmail(value);
    }

    //get a reference to the UMUI api

    async function onSignOutClick() {
        await Auth.signOut()
            .then((data) => routeChange())
            .catch((err) => console.log("ERROR: " + err));
    }

    function routeChange() {
        console.log("changing route")
        
        let path = 'user';
        history.push(path);

    }

    // handle changing the user email
    async function handleChangeEmail() {

        if(validateEmail(email)) {
            setEmailError('');
            setApplyLodading(true);

            //define the body of the request 
            const requestPayload = {
                'email': email
            };

            //try to change the email
            HQS_API.updateSelfUser(requestPayload)
                .then(response => {
                    handleClose();
                    onSignOutClick();
                })
                .catch((error) => {
                    if (error !== undefined) {
                        error = error.response.data.error
                        const title = "Unable to change password";
                        let details = error.text
                        if (error.code === ErrorCodes.NotAuthorizedException) {
                            details = "Current password is incorrect. Please try again."
                        }
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="critical"
                            />
                        );
                    }

                });
        } else {
            setEmailError("Invalid Email Address!")
        }
    }

    //render the main content of the popup/modal
    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow} disabled={isFederated} title={isFederated? 'Email cannot be changed while logged in with a federated or social account': ''}>

                {props.children || 'Change Email'}
            </Button>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Change Email
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>
                        Complete this form to change the email used to sign into your account
                    </p>
                    <p>
                        <Badge color="red">WARNING!! </Badge> This action will sign you out automatically from the system!
                    </p>
                    <Form.Group>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        ></div>
                        <Input
                            name="userEmail"
                            label="User Email"
                            placeholder=""
                            value={email}
                            onChange={handleEmail}
                            fluid={true}
                            error={emailError}
                        />
                    </Form.Group>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" disabled={applyDisabled} loading={applyLoading} onClick={handleChangeEmail} >
                        Apply
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EmailChangeForm;
