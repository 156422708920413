/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React from "react";
import { Button, Input, InputLabel, Modal, Icon, Notification } from '@scuf/common';
import { ConfirmSignIn } from "aws-amplify-react";
import '../CustomAuth/CustomAuth.css';
import { useState } from 'react';
import { Auth } from 'aws-amplify'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import * as HQS_API from '../utils/api';
import Pdf from '../../../public/user_terms_and_conditions.pdf';
import { MsalContext } from "@azure/msal-react";
import { authChallenges, agreementChallenges, agreementTypes, isValidEmail, getEula, getEulaDescription, isBetaChallenge, getSoftwareFromChallenge } from '../utils/helpers';
import { useMsal } from "@azure/msal-react";
import './SignUp.css';

const logo = require('./QuantinuumLogo.svg');
const inquantoLogo = require('./InQuantoLogo.svg');

const ToastNotification = ({ closeToast, title, details, severity }) => (
  <Notification
    className="toast-notification"
    severity={severity}
    onCloseClick={closeToast}
    hasIcon={true}
    title={title}>
    {details}
  </Notification>
);


const SignUpSoftware = (props) => {


  const [inviteId, setInviteId] = useState(props.inviteId)
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [software, setSoftware] = useState(props.software)
  const [authChallenge, setAuthChallenge] = useState("");

  const [termsExempt, setTermsExempt] = useState(false);
  const [isBetaTester, setIsBetaTester] = useState(false);
  const [isCompliant, setIsCompliant] = useState(false);
  const [needsUpgrade, setNeedsUpgrade] = useState(false);

  const [formHeight, setFormHeight] = useState('300px');
  const [homePage, setHomePage] = useState(props.homePage);
  const [formLoading, setFormLoading] = useState(false);

  const inquantoProductLink = "https://www.quantinuum.com/computationalchemistry/inquanto"
  const inquantoGettingStartedLink = "https://inquanto.quantinuum.com/introduction/getting_started.html"
  const qcSupport = "qcsupport@quantinuum.com"

  const { instance } = useMsal();


  const steps = {
    start: 1,
    agreement: 2,
    end: 3

  }

  const [step, setStep] = useState(steps.start);

  function showContent() {
    //dynamically show the main content

    switch (step) {
      case steps.agreement:
        return showAgreement();
      case steps.end:
        return showCompleted();
      case steps.failure:
        return showFailure();
      case steps.start:
      default:
        return showStart();
    }
  }


  async function validateEmail() {
    const queryParams = new URLSearchParams(window.location.search);
    let id = queryParams.get('id');
    let software = queryParams.get('software')

    const body = {
      "invite-id": id,
      "email": email,
      "software": software,
      "action": "validate"
    };
    setFormLoading(true);
    HQS_API.userRegistration(body)
      .then((response) => {
        //if successful, then update the page

        let auth_challenge = response['auth-challenge']

        setAuthChallenge(auth_challenge)

        if (auth_challenge.includes('EXEMPT')){
          setTermsExempt(true)
        } else if (auth_challenge.includes('BETA')){
          setIsBetaTester(true)
        }

        if ('accepted-latest' in response){
          let accepted_latest = response['accepted-latest']
          setIsCompliant(accepted_latest)
        }

        if ('upgrade' in response){
          let upgrade = response['upgrade']
          setNeedsUpgrade(upgrade)
        }

        //prompt the user to accept terms and conditions
        setStep(steps.agreement)

        setFormLoading(false);
      })
      .catch((error) => {
        setFormLoading(false);
        if (error !== undefined) {
          error = error.response.data.error
          const title = "Unable to complete registration";
          let details = error.text
          toast(
            <ToastNotification
              closeToast={false}
              title={title}
              details={details}
              severity="critical"
            />
          );
        }
      });

  }



  async function acceptTerms(accept) {
    const queryParams = new URLSearchParams(window.location.search);
    let id = queryParams.get('id');
    let software = queryParams.get('software')

    let action = 'signup' //default
    if (needsUpgrade){
        action = 'upgrade'
    }

    const body = {
      "invite-id": id,
      "email": email,
      "software": software,
      "action": action,
    };
    setFormLoading(true);
    HQS_API.userRegistration(body)
      .then((response) => {

        //show the success page

        setFormLoading(false);
        setStep(steps.end)
        let action = "generated"
        if (needsUpgrade){
          action = 'upgraded'
        }

        const title = "Successfully " + action + " key";
        let details = "Please check your email"
        toast(
          <ToastNotification
            closeToast={false}
            title={title}
            details={details}
            severity="success"
          />
        );

      })
      .catch((error) => {
        setFormLoading(false);
        //show the fialure page
        setStep(steps.failure)
        if (error !== undefined) {
          error = error.response.data.error
          const title = "Unable to complete registration!";
          let details = error.text
          toast(
            <ToastNotification
              closeToast={false}
              title={title}
              details={details}
              severity="critical"
            />
          );
        }
      });


  }

  function handleEmail(emailValue) {
    setEmail(emailValue.trim().toLowerCase());
    let isEmail = isValidEmail(emailValue.trim().toLowerCase())
    if (!isEmail) {
      setEmailError("Please enter a valid email address!")
      setValidEmail(false);
    } else {
      setValidEmail(true);
      setEmailError('');
    }


  }

  function genLogo() {
    const queryParams = new URLSearchParams(window.location.search);
    let software = queryParams.get('software')
    if (software === null){
      let auth_challenge = sessionStorage.getItem("challenge")
      software = getSoftwareFromChallenge(auth_challenge);
  }
    let contentList = <div></div>
    const content = []

      // process the agreements
      if (agreementTypes[software]) {

        if (software == 'inquanto') {

          content.push(
            <img className="logo" src={inquantoLogo}></img>

          )
        }
      
    }else {
      // the default quantinuum logo
   
      content.push(
        <img className="logo" src={logo}></img>
      )
    }

    contentList = <div>{content}</div>;


    return contentList


  }


  function genContent(software) {
    let contentList = <div></div>
    const content = []

    // process the agreements
    if (agreementTypes[software]) {

      if (software == 'inquanto') {

        content.push(
          <div key={software} className="software-confirm">
            <p>Welcome{isBetaTester? <b> beta tester</b>: ""}! You have been invited to access <a href={inquantoProductLink} target="_blank"><b>InQuanto</b></a>, Quantinuum's state-of-the-art quantum computational chemistry platform.</p>
            {!termsExempt && !isCompliant? <p>If you haven't already, please take a moment to review and accept our <b><a className="forgot-password-link" href={getEula(software, authChallenge)} target="_blank">{' '}{getEulaDescription(software, authChallenge)}</a></b> to {!needsUpgrade? 'generate': 'upgrade'} your license key</p>: 
            !needsUpgrade? <p>Please click <b>Get Key</b> to generate your license key</p>: <p>Please click <b>Upgrade Key</b> to upgrade your existing license key</p> }
            </div>
        )
       
      }
    }

    contentList = <div>{content}</div>;


    return contentList


  }

  function genCompletedContent(software) {
    let contentList = <div></div>
    const content = []

    // process the agreements
    if (agreementTypes[software]) {

      if (software == 'inquanto') {

        content.push(
          <div className="software-confirm">
            <p>You're all set! Keep an eye out for an email containing your <a href={inquantoProductLink} target="_blank"><b>InQuanto</b></a> license key and instructions on how to get started.</p>
          </div>
        )
      }
    }

    contentList = <div>{content}</div>;


    return contentList


  }

  function genFailureContent() {
    let contentList = <div></div>
    const content = []

    content.push(
      <div className="software-confirm">
        <p>Something went wrong while trying to generate a license key. Please contact us at <a href={"mailto:" + qcSupport}>{qcSupport}</a> or your organization's administrator.</p>
      </div>
    )
      
    contentList = <div>{content}</div>;
    return contentList
  }


  function backToSignIn() {
    //update window to return to login page
    window.location.replace("/login");
  
  }

  function showCompleted() {
    const queryParams = new URLSearchParams(window.location.search);
    let software = queryParams.get('software')

    if (software === null){
      let auth_challenge = sessionStorage.getItem("challenge")
      software = getSoftwareFromChallenge(auth_challenge);
  }

    return (<div className="credentials">
      <div className="agreement-confirm">
        {genCompletedContent(software)}
      </div>
    </div>

    )
  }

  function showFailure() {
    return (<div className="credentials">
      <h4>That didn't go as planned!</h4>
      <div className="agreement-confirm">
        {genFailureContent(software)}
      </div>
    </div>

    )
  }

  function showAgreement() {
    const queryParams = new URLSearchParams(window.location.search);
    let software = queryParams.get('software')
    if (software === null){
      let auth_challenge = sessionStorage.getItem("challenge")
      software = getSoftwareFromChallenge(auth_challenge);
  }
    return (
      <div>
        <div className="credentials">
          <div className="agreement-confirm">
            {genContent(software)}
          </div>
          <div className="change-password">
            {termsExempt ||isCompliant?  
            <Button type="primary" onClick={() => acceptTerms(false)} content={!needsUpgrade? "Get Key": "Upgrade Key"} /> : 
            <Button type="primary" onClick={() => acceptTerms(true)} content={!needsUpgrade? "Accept & Get Key": "Accept & Upgrade Key"} />
            }
            <Button type="secondary" onClick={() => setStep(steps.start)} content="Back" />
          </div>
      </div>
    </div>
    )
  }

  function showStart() {
    return (<div className="credentials">
      <p>Welcome, please enter your email to help us confirm your account</p>
      <div className="agreement-confirm ">
        <Input
          className="input-form"
          indicator={"required"}
          fluid="true"
          id="username"
          key="username"
          name="username"
          type="text"
          autoFocus
          pattern="[^\s]+"
          label="Email"
          value={email}
          error={emailError}
          onChange={handleEmail}
        />
      </div>
      <br />
      <div className="change-password">
        <Button type="primary" onClick={() => validateEmail()} content="Continue" />
      </div>
    </div>
    )
  }



  if (formLoading) {
    return (
      <div className="custom-sign-up">
        <div className="sign-up-form" style={{ height: formHeight }}>
          <p className="form-completed-p">
            <Icon name="refresh" size="xlarge" loading={true} color="#1274B7" />
          </p>

        </div>
      </div>
    )
  }


  else if (inviteId === null) {
    return (
      <div className="custom-sign-up">
        <div className="sign-up-form" style={{ height: formHeight }}>
          <div className="form-header">
            <img className="logo" src={logo}></img>
          </div>
          <p >Invalid Invitation!</p>
          <p>Please contact Quantinuum at qcsupport@quantinuum.com and your organization administrator.</p>
        </div>
      </div>
    )
  } else {



    return (
      <div className="custom-sign-in">
        <div className="terms-and-conditions-form">
          <div className="form-header">
            {genLogo()}
          </div>
          <div className="credentials">
            {showContent()}
            <p className="forgot-password center-text">
              {step != steps.end ?
              <a className="forgot-password-link" onClick={() => backToSignIn()}>
                Back to sign in?
              </a>
              : <Button type="primary" onClick={() => backToSignIn()} content="Return to Login" />}
            </p>
          </div>
        </div>
        <ToastContainer
          hideProgressBar={true}
          closeOnClick={false}
          closeButton={false}
          newestOnTop={true}
          position="bottom-right"
          toastClassName="toast-notification-wrap"
        />
      </div>
    )

  }

}

export default SignUpSoftware;