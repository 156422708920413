/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2024 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { useEffect } from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Checkbox, InputLabel, Modal, Badge, Select } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import { orgTypes } from '../utils/helpers';
const ToastNotification = ({ closeToast, title, details }) => (
    <Notification
        className="toast-notification"
        severity="success"
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const UpdateOrgTypeForm = (props) => {
    const { type } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => { 
        setOrgType(type);
        setShow(false)
    };
    const handleShow = () => setShow(true);
    const [orgType, setOrgType] = useState(type);
    const [orgTypeError, setOrgTypeError] = useState('');

    useEffect(() => {
        setOrgType(type);
    }, [type]);

    function handleUpdate() {
        //  chanage the org type
        let body = {};
        body['type'] = orgType;

        // update org
        HQS_API.updateOrganization(props.org, body)
            .then((response) => {
                if (props.callBack) {
                    props.callBack();
                }
                const title = 'Org Type Updated';
                const details = 'Org Type has been updated successfully';
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="success" />);
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
            });
        handleClose();
    }

    function generateOrgTypes() {
        let orgTypeList = <div></div>;
        const o = [];

        let options = [];

        if (orgTypes !== undefined){
        
            Object.keys(orgTypes).map((key, index) => {
               
                //skip azure since only azure jobs will create an org with this type
                if (key != 'azure'){
                    if (key == 'external'){
                        options.push({ value: key, text: orgTypes[key] + " (Default)"})
                    }else{
                        options.push({ value: key, text: orgTypes[key] })
                    
                    }
                }
            });
        }
        
        o.push(
            <Select
                options={options}
                value={orgType}
                onChange={handleOrgType}
                fluid={true}
                search={true}
                id="short-select"
                placeholder="Select Org Type"
                label="Org Type"
                error={orgTypeError}
            />,
        );

        orgTypeList = (
            <div
                style={{
                    'padding-right': '20px',
                    width: '50%',
                }}
            >
                {o}
            </div>
        );

    
        return orgTypeList;
    }

    function handleOrgType(orgType) {
        setOrgType(orgType);
    }

    return (
        <div>
            <div onClick={handleShow}>
                <div style={{ cursor: 'pointer' }} className="hqs-blue-icon">
                    update
                </div>
                &nbsp;
            </div>
            <Modal open={show} onClose={handleClose} size="small" style={{
                    padding: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="descending" size="medium"></Icon>
                        &nbsp;&nbsp;Update Org Type
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>Update the Org Type for &quot;<b>{props.org}</b>&quot;.</p>
                    <p>
                        The <b>org type</b> is used to quickly identify the relationship an organization has with Quantinuum. 
                    </p>
                    {generateOrgTypes()}
                    <InputLabel label="Description" />
                    {orgType == 'external'? 
                    <div>
                        <ul>
                            <li>The <b>default</b> org type.</li>
                            <li>Intended for external facing customers</li>
                            <li>This org is <b>not allowed</b> to enable <b>internal</b> features on their plans.</li>
                        </ul>
                       
                    </div>: ""}
                    {orgType == 'internal'? 
                    <div>
                        <ul>
                            <li>Intended for internal use only</li>
                            <li>This org is  <b>allowed</b> to enable <b>internal</b> features on their plans.</li>
                        </ul>
                        
                    </div>: ""}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateOrgTypeForm;
