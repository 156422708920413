/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2021-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React, { useEffect } from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Input, InputLabel, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const UpdateOrgBatchMaxHQCForm = (props) => {
    const { batchMax } = props;
    const [show, setShow] = useState(false);
    const [batchMaxHQC, setBatchMaxHQC] = useState(batchMax);
    const handleClose = () => {
        setShow(false);
        setBatchMaxHQC(batchMax);
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        setBatchMaxHQC(batchMax);
    }, [batchMax]);


    function handleUpdate() {
        // set org's hqc quota

        // update org
        HQS_API.updateBatchMaxHQC(props.org, Number(batchMaxHQC))
            .then((response) => {
                if (props.callBack) {
                    props.callBack();
                }
                const title = 'Org Quota Updated';
                const details = 'Org HQC quota has been updated successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />,
                );
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });

        handleClose()
    }

    return (
        <div>
            <div onClick={handleShow}>
                <div style={{ cursor: 'pointer' }} className="hqs-blue-icon">
                    update
                </div>
                &nbsp;
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon
                            root="common"
                            name="portfolio-cost"
                            size="medium"
                        ></Icon>
                        &nbsp;&nbsp;Update HQC quota
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        Update the existing HQC quota value for &quot;{props.org}&quot;.
                    </p>
                    <InputLabel label="Credits:" />
                    <Input
                        key={props.org}
                        type="number"
                        min="0"
                        value={batchMaxHQC}
                        onChange={(maxHQC) => setBatchMaxHQC(maxHQC)}
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button 
                        type="primary"
                        onClick={() => handleUpdate(batchMaxHQC)}
                    >
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateOrgBatchMaxHQCForm;
