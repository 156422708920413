import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { roundTwoDecimalPlaces } from '../utils/helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

export function FQTable(props) {
    const customCaret = (order, column) => {
        if (!order)
            return (
                <span className="order">
                    <span className="dropdown">
                        <span className="caret"></span>
                    </span>
                    <span className="dropup">
                        <span className="caret"></span>
                    </span>
                </span>
            );
        else if (order === 'asc') return <span className="caret"></span>;
        else if (order === 'desc')
            return (
                <span className="dropup">
                    <span className="caret"></span>
                </span>
            );
        return null;
    };

    const machinesRenderer = (cell) => {
        const data = cell;
        
        return <span>{Object.keys(data).join(', ')}</span>;

    }

    const hqcValueRenderer = (cell, row) => {
        const data = cell;
        var hqcValue = 0;

        if (data) {
            hqcValue = roundTwoDecimalPlaces(data);
        }

        return <span>{hqcValue}</span>;
    };

    const availableHqcRenderer = (cellData) => {
    
        const row = cellData;
       
        var credits = 'NA';
        var availableCredits = 0;

        if (row !== undefined){
        
            if ('unlimited' in row && row['unlimited']){
                credits = "Unlimited"
            }else{
        
                if ('available' in row && row['available'] !== 'NA') {
                    availableCredits = row['available'];
                }
                credits = roundTwoDecimalPlaces(availableCredits);
            }
        }
    

        return <span>{credits}</span>;
    }


    const { SearchBar } = Search;
    const tableOptions = {
        // pageStartIndex: 0,
        custom: true,
        sizePerPage: 30,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
    };
    const OrgsColumns = [
        {
            dataField: 'name',
            text: 'ORGANIZATION NAME',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                padding: '5px 10px 5px 10px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'customerId',
            text: 'CUSTOMER ID',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                padding: '5px 10px 5px 10px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'plan.machines',
            formatter: machinesRenderer,
            text: 'MACHINE',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                padding: '5px 10px 5px 10px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'hqc_accumulation',
            text: 'ACCUMULATED HQC',
            formatter: hqcValueRenderer,
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                padding: '5px 10px 5px 10px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'plan.credits',
            text: 'CREDITS AVAILABLE',
            hidden: false,
            formatter: availableHqcRenderer,
            style: {
                whiteSpace: 'nowrap',
                padding: '5px 10px 5px 10px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        }
    ];
    const [columns, setColumns] = useState([...OrgsColumns]);

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div style={{ border: '1px solid #d0d0d0', height: '625px' }}>
            <ToolkitProvider
                keyField="id"
                data={props.orgs}
                columns={columns}
                columnToggle
                search
            >
                {(toolkitprops) => (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '50px',
                                borderBottom: '1px solid #d0d0d0',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '400px',
                                    padding: '5px 0px 0px 5px',
                                }}
                            >
                                <SearchBar
                                    className="hqs-custom-font"
                                    {...toolkitprops.searchProps}
                                />
                                <div style={{ padding: '10px 0px 0px 10px' }}>
                                    {' '}
                                    {props.orgs.length + ' Total'}
                                </div>
                            </div>
                        </div>
                        <div className="hqs-admin-fq-data">
                            <BootstrapTable
                                noDataIndication="No records found"
                                striped
                                hover
                                headerStyle={{ background: 'red' }}
                                height="515px"
                                rowClasses="hqs-custom-table-row"
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
            <div className="hqs-custom-table-pagination">
                <PaginationListStandalone {...paginationProps} />
            </div>
        </div>
    );

    return (
        <div>
            <div>
                <PaginationProvider
                    pagination={paginationFactory(tableOptions)}
                >
                    {contentTable}
                </PaginationProvider>
            </div>
            <ToastContainer
                hideProgressBar={true}
                closeOnClick={false}
                closeButton={false}
                newestOnTop={true}
                position="bottom-right"
                toastClassName="toast-notification-wrap"
            />
        </div>
    );
}
