/*****************************************************************************
 *
 * 2023 @ Quantinuum LLC.
 * This software and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 * All Rights Reserved.
 *
 *****************************************************************************/

import moment from 'moment-timezone';
/* this helper file was designed to help the generalized access plans */
import { isNullOrEmpty, toISODate } from '../utils/helpers';

const refreshEndModes = {
    none: '',
    date: 'date',
    occurrences: 'occurrences',
};

const licenseEndModes = {
    none: '',
    date: 'date',
    duration: 'duration',
};

const softwareProductLinks = {
    'inquanto' : 'https://www.quantinuum.com/computationalchemistry/inquanto'
}


function getDate(date) {
    let value = '';
    if (date !== '' && date !== undefined) {
        let offset = moment(date).utcOffset();
        value = moment.utc(date).utcOffset(offset).toDate();
    }
    return value;
}


const getLicenseEndMode = (software) => {
    //software is a dict
    let mode = licenseEndModes.none
    
    let hasValidDate = false
    let hasValidDuration = false

    if ('expiration-date' in software  && getDate(software['expiration-date'])){
        hasValidDate = true
    }
    if ('duration'  in software  && software['duration'] > 0){
        hasValidDuration = true
    }

    if(hasValidDate && !hasValidDuration){
        mode = licenseEndModes.date
    }else{
        mode = licenseEndModes.duration
    }
    

    return mode

}


const getFirstOfNextMonth = () => {
    //the default date for the refresh start date
    return moment().add(1, 'M').startOf('month');
}


const hasDate = (date) =>  {
   
    if (date != undefined && getDate(date)) {
        return true;
    } else {
        return false;
    }
}

const getTimeRemainingInDays = (expirationDate) => {
    let value = 0;
    if (hasDate(expirationDate)) {
        let today = getDate(moment());

        // To calculate the time difference of two dates
        var time_diff = expirationDate.getTime() - today.getTime();

        // To calculate the no. of days between two dates
        var day_diff = time_diff / (1000 * 3600 * 24);

        value = Math.ceil(day_diff);
    }
    return value;
}


const getNumberOfRefreshes = (refreshStartDate, refreshStopDate) => {
    let nRefreshes = 0;

    let monthsDiff = 0;
    if (refreshStartDate && refreshStopDate) {
        monthsDiff = moment(refreshStopDate).diff(moment(refreshStartDate),'months',true);
    }

    if (Math.floor(monthsDiff) < 0) {
        return 0;
    }

    nRefreshes = Math.floor(monthsDiff);
    
    return nRefreshes;
}


const getLastRefreshDate = (refreshStopMode, refreshStartDate, refreshStopDate, refreshOccurrences, timeZone) => {
    let refreshDate = '';
    
    //assume none
    let lastDate = '';
    refreshDate = 'None';

    if(!timeZone){
        //then guess
        timeZone = moment.tz.guess(true)
    }
    

    if (refreshStopMode == refreshEndModes.date) {
        if (refreshStartDate && refreshStopDate) {
            //figure out the last refresh date while considering the refresh start date

            let nRefreshes = getNumberOfRefreshes(refreshStartDate, refreshStopDate);
            lastDate = moment(refreshStartDate).add(nRefreshes,'months');
            refreshDate = toISODate(lastDate, '', timeZone);
        } else {
            refreshDate = 'TBD';
        }
    } else if (refreshStopMode == refreshEndModes.occurrences) {
        if (refreshStartDate && refreshOccurrences > 0) {
            lastDate = moment(refreshStartDate).add(refreshOccurrences,'months');
            refreshDate = toISODate(lastDate, '', timeZone);
        } else {
            refreshDate = 'TBD';
        }
    }
    

    return refreshDate;
}


const getRefreshSchedule = (refreshStartDate, timeZone) => {
    let message = 'TBD';
    if(!timeZone){
        //then guess
        timeZone = moment.tz.guess(true)
    }
    
    if (hasDate(refreshStartDate)) {
        
        message =
            moment(refreshStartDate).tz(timeZone).format('Do') +
            ' of every month at ' +
            moment(refreshStartDate).tz(timeZone).format('HH:mm');
        
    }
    return message;
}


//render functions

const getRefreshStopModeSummary = (refreshStopMode) => {
    let message = 'None';

    if (refreshStopMode != refreshEndModes.none) {
        message = 'By ' + refreshStopMode;
    }
    

    return message;
}

function getNumberOfRefreshesSummary(refreshStopMode, refreshStartDate, refreshStopDate, refreshOccurrences, scheduledEnd, deactivationDate) {
    let message = 'None';
   
    if (refreshStopMode !== refreshEndModes.none) {
        if (refreshStopMode == refreshEndModes.date) {
            message = getNumberOfRefreshes(refreshStartDate, refreshStopDate);
        } else {
            message = refreshOccurrences;
        }
    } else {
        message = 'Unlimited';
        if (scheduledEnd && deactivationDate) {
            message = 'Unlimited until deactivation';
        }
    }
    

    return message;
}


const renderActivationDate = (scheduledStart, createDate, activationDate, enabled, summaryMode, timeZone) => {

    if(!timeZone){
        //then guess
        timeZone = moment.tz.guess(true)
    }
    
    if (scheduledStart && activationDate) {
        return toISODate(moment(activationDate), '', timeZone);
    } else {

        //if there isn't a scheduled start date/activation date, but it's now enabled
        if(summaryMode){
            //then show the activation date as the create date for this plan since it's taking effect immediately upon creation
            return toISODate(moment(createDate), '', timeZone);
        }else {
            return "Now"
        }
       
    }
}

const renderDeactivationDate = (scheduledEnd, deactivationDate, timeZone) => {
    if(!timeZone){
        //then guess
        timeZone = moment.tz.guess(true)
    }
    
    if (scheduledEnd && deactivationDate) {
        return toISODate(moment(deactivationDate), '', timeZone);
    } else {
        return 'None';
    }
}

const renderDate = (date, timeZone) => {

    if(!timeZone){
        //then guess
        timeZone = moment.tz.guess(true)
    }
    
    if (date) {
        return toISODate(moment(date), '', timeZone);
    } else {
        return 'None';
    }
}


const renderBool = (bool) => {
    if(bool){
        return "True"
    }else{
        return "False"
    }
}

const getTimeZoneSummary = (timeZone) =>  {
    if (timeZone) {
        return '(UTC' + moment().tz(timeZone).format('Z') + ') ' + timeZone;
    }
}

//the list of defaults to use when creating a brand new plan
const defaults = {
    
    machines:  [], //by default we don't know the machines they want add
    internal:  false, //assume it's not internal by default
    credits: {
        monthly:  0, //assume no credits
        available: 0, //assume no credits
        unlimited: false, //assume it's not unlimited credits by default
    },
    fairQueuing: {
        multiplier: {
            active: 1,// the default active is the default multiplier
            default:  1, // the baseline
            extended: 20, // significantly higher to slow down queueing when exceed
        },
        extended: false, //by default we don't want to allow throttling
    },
    dates: {
        scheduledStart:  false, // by default it will start immediately,
        scheduledEnd:  false, // by default it doesn't have an end
        refreshEnabled:  false, //by false it doesn't end because by default we don't have an end date
        refreshStopMode: '', // can be '' (unlimited), date, or occurrences
        activationDate:  '', //by default, the activation date is now (or empty)
        refreshStartDate:  getFirstOfNextMonth(), //by default assume the first of next month
        refreshStopDate:  '', //by default, none
        refreshOcccurences:  0,
        deactivationDate:  '', //by default, there isn't a deactivation date (none)
        createDate: '',
        lastUpdate: ''
    },
    software: {
        name: 'none', //by default we don't have software
        licenses:  0,
        customerType:  '',
        expirationDate:  '',
        duration:  30, //assume a 30  day trial
        licenseEndMode: licenseEndModes.none
    },
    tags: {
        data: [],
    },
    
};




export {
    refreshEndModes,
    licenseEndModes,
    defaults,
    getDate,
    hasDate,
    getLicenseEndMode,
    getFirstOfNextMonth,
    getTimeRemainingInDays,
    getNumberOfRefreshes,
    getLastRefreshDate,
    getRefreshSchedule,
    getRefreshStopModeSummary,
    getTimeZoneSummary,
    getNumberOfRefreshesSummary,
    renderActivationDate,
    renderDeactivationDate,
    renderDate,
    renderBool,
    softwareProductLinks
};
