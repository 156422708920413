import React, { Component } from 'react';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import * as HQS_API from '../utils/api';
import CreateReportForm from '../Forms/CreateReport';
import { Select, DatePicker, Checkbox, Icon, Popup, Accordion } from '@scuf/common';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toISODate } from '../utils/helpers';
import moment from 'moment';

const ALL_OWNERS_TEXT = 'All record owners';
const { SearchBar } = Search;
const tableOptions = {
    custom: true,
    sizePerPage: 30,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
};

class ReportsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOwner: '',
            ownerName: '',
            ownerOptions: [],
            reports: props.reports,
            filteredReports: props.reports,
            rangeValue: { to: '', from: '' },
            orgs: props.orgs,
            mode: props.mode,
            toUTC: undefined,
            fromUTC: undefined,
            columns: [
                {
                    dataField: 'id',
                    text: 'REPORT ID',
                    style: {
                        whiteSpace: 'nowrap',
                        width: '60px',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    sort: false,
                },
                {
                    dataField: 'report-name',
                    text: 'REPORT NAME',
                    style: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                },
                {
                    dataField: 'report-type',
                    text: 'REPORT TYPE',
                    style: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                },
                {
                    dataField: 'requested-by',
                    text: 'REQUESTED BY',
                    style: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                },
                {
                    dataField: 'report-dates',
                    text: 'REPORT DATES',
                    style: {
                        whiteSpace: 'nowrap',
                        width: '60px',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    formatter: this.dateRangeRenderer,
                },
                {
                    dataField: 'date',
                    text: 'CREATED DATE',
                    style: {
                        whiteSpace: 'nowrap',
                        width: '60px',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    sort: true,
                    formatter: this.dateRenderer,
                },
                {
                    dataField: 's3-expiration-date',
                    text: 'EXPIRATION DATE',
                    style: {
                        whiteSpace: 'nowrap',
                        width: '60px',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    sort: true,
                    formatter: this.dateRenderer,
                },
                {
                    dataField: 'status',
                    text: 'STATUS',
                    style: {
                        whiteSpace: 'nowrap',
                        width: '60px',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                },
                {
                    dataField: '',
                    text: 'DOWNLOAD',
                    hidden: false,
                    style: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        width: '60px',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    formatter: this.downloadActionRenderer,
                },
            ],
        };

        this.getOwnerOptions = this.getOwnerOptions.bind(this);
        this.downloadActionRenderer = this.downloadActionRenderer.bind(this);
        this.dateRenderer = this.dateRenderer.bind(this);
        this.dateRangeRenderer = this.dateRangeRenderer.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.getFile = this.getFile.bind(this);
        this.filterReports = this.filterReports.bind(this);
        this.contentTable = this.contentTable.bind(this);
    }

    componentDidMount() {
        this.getOwnerOptions();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            reports: nextProps.reports,
            filteredReports: nextProps.reports,
        });
        this.getOwnerOptions();
    }

    getOwnerOptions() {
        // populate record owner dropdown
        const ownerOptions = [{ value: 0, text: ALL_OWNERS_TEXT }];
        this.state.reports.forEach((report, index) => {
            // check if record owner already exists in array
            let ownerExists = ownerOptions.some((owner) => owner.text == report.record_owner);
            if (!ownerExists) {
                ownerOptions.push({ value: index + 1, text: report['record_owner'] });
            }
        });
        this.setState({
            ownerOptions: ownerOptions,
        });
    }

    downloadActionRenderer = (cell, row) => {
        const jobId = row.id;
        // only show download action for reports existing in S3
        if (typeof row['s3_path'] == 'undefined') {
            return '';
        }

        return (
            <div style={{ cursor: 'pointer' }}>
                <Icon root="common" name="file-download" size="small" onClick={() => this.handleDownload(jobId)}></Icon>
            </div>
        );
    };

    dateRenderer(cellData) {
        const data = cellData;
        var date = '';

        if (data) {
            date = toISODate(data);
        }
        return <span>{date}</span>;
    }

    dateRangeRenderer = (cell, row) => {
        const startData = row['start-date'];
        const endData = row['end-date'];
        var startDate = '';
        var endDate = '';
        console.log(row);

        if (startData) {
            startDate = toISODate(startData);
            endDate = toISODate(endData);

            return <span>{startDate + ' to ' + endDate}</span>;
        }
        return <span>{'NA'}</span>;
    };

    onOwnerSelect = (owner_index) => {
        let ownerName = this.state.ownerOptions.find((owner) => owner.value == owner_index)['text'];

        this.setState(
            {
                selectedOwner: owner_index,
                ownerName: ownerName,
            },
            () => {
                this.filterReports();
            },
        );
    };

    filterReports() {
        console.log('Calling filter reports');
        let filteredReports = this.state.reports;

        // filter by date
        if (this.state.toUTC !== undefined && this.state.fromUTC !== undefined) {
            console.log('here');
            let filteredDateReports = [];

            filteredReports.forEach((report) => {
                let reportDate = new Date(report['date']).getTime();
                console.log(reportDate);
                if (reportDate >= this.state.fromUTC && reportDate <= this.state.toUTC) {
                    console.log('found report');
                    filteredDateReports.push(report);
                }
            });
            filteredReports = filteredDateReports;
        }

        this.setState({
            filteredReports: filteredReports,
        });
    }

    async handleDownload(reportId) {
        HQS_API.getReport(reportId)
            .then((response) => {
                this.getFile(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getFile(response) {
        const options = {
            headers: new Headers({ 'content-type': 'application/json' }),
            mode: 'no-cors',
        };
        window.open(response['url']);
    }

    onRangeSelect = (range, formattedRange) => {
        this.setState(
            {
                toUTC: formattedRange['utc']['to'],
                fromUTC: formattedRange['utc']['from'],
                rangeValue: range,
            },
            () => {
                this.filterReports();
            },
        );
    };

    contentTable = ({ paginationProps, paginationTableProps }) => (
        <div style={{ border: '1px solid #d0d0d0', height: '682px' }}>
            <ToolkitProvider
                keyField="id"
                data={this.state.filteredReports}
                columns={this.state.columns}
                columnToggle
                search={true}>
                {(toolkitprops) => (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '50px',
                                borderBottom: '1px solid #d0d0d0',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '400px',
                                    padding: '5px 0px 0px 5px',
                                }}>
                                <SearchBar className="hqs-custom-font" {...toolkitprops.searchProps} />
                            </div>
                            <div
                                id="hqs-admin-jobs-table-options"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    width: '100%',
                                    padding: '5px 0px 0px 5px',
                                }}>
                                <div>
                                    <CreateReportForm
                                        callback={this.props.callback}
                                        orgs={this.state.orgs}
                                        mode={this.state.mode}
                                        isOrgAdmin={this.props.isOrgAdmin}
                                    />
                                </div>
                                <div
                                    onClick={this.props.callback}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '6px 0px 0px 5px',
                                    }}>
                                    <Icon
                                        root="building"
                                        name="refresh"
                                        size="large"
                                        loading={this.props.fetchingReports}
                                    />
                                </div>
                                {/* <div style={{ paddingLeft: '15px' }}>
                                    <DatePicker
                                        type="daterange"
                                        placeholder="Select date range"
                                        closeOnSelection={true}
                                        closeOnDocumentClick={true}
                                        disableFuture={true}
                                        onRangeSelect={this.onRangeSelect}
                                        rangeValue={this.state.rangeValue}
                                        timezone='UTC'
                                    />
                                </div> */}
                                {/* <div className="hqs-table-dropdown-option">
                                    <Select
                                        placeholder="Select record owner"
                                        options={this.state.ownerOptions}
                                        onChange={this.onOwnerSelect}
                                        value={this.state.selectedOwner}
                                    />
                                </div> */}
                            </div>
                        </div>
                        <div className="hqs-admin-fq-data">
                            <BootstrapTable
                                noDataIndication="No records found"
                                striped
                                hover
                                headerStyle={{ background: 'red' }}
                                height="515px"
                                rowClasses="hqs-custom-table-row"
                                defaultSortName="date"
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
            <div className="hqs-custom-table-pagination">
                <PaginationListStandalone {...paginationProps} />
            </div>
        </div>
    );

    render() {
        return (
            <div className="hqs-admin-view">
                <div className="hqs-admin-jobs-card">
                    <div id="product-table-wrapper">
                        <PaginationProvider pagination={paginationFactory(tableOptions)}>
                            {this.contentTable}
                        </PaginationProvider>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportsTab;
