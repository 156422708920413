// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState, useEffect } from 'react';
import * as HQS_API from '../utils/api';
import {
    Button,
    Input,
    InputLabel,
    Icon,
    Notification,
    Modal,
    Select,
    DatePicker,
    Checkbox,
    Badge,
    Card,
    Popup,
    Grid,
} from '@scuf/common';
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import { maxTagKeyLength, maxTagDescLength, maxTagValueLength, maxTagAllowedValues } from '../utils/helpers';

const CreateTagForm = (props) => {
    const tags = props.tags
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [key, setKey] = useState('');
    const [keyError, setKeyError] = useState();
    const [hasValidKey, setValidKey] = useState(false);
    const [desc, setDesc] = useState('');
    const [isDisabled, setDisabled] = useState(!(hasValidKey));
    const [allowed, setAllowed] = useState(new Set());
    const [allowedValue, setAllowedValue] = useState('');
    const [allowedError, setAllowedError] = useState('');

    function clearInputs() {
        setKey('');
    }

    function handleClose() {
        //reset state props when closing form
        setKey('')
        setKeyError('')
        setShow(false);
    }

    function handleKey(key) {

        setKey(key);
        setKeyError('');

        isKeyValid(key)
    }

    function handleDesc(desc) {

        setDesc(desc);

    }

    // function to validate all form fields
    function validateFormValues() {

        let validKey = isKeyValid(key)

        return validKey;
    }

    function isKeyValid(key) {
        let valid = true
        setKeyError('')

        //validate the version number
        if (key !== undefined && key !== "") {
            valid = true
        } else {
            setKeyError('A key name is required');
            valid = false
        }


        setValidKey(valid)

        return valid
    }


    function handleAllowed(value) {

        setAllowedValue(value);

    }

    function addAllowedValue() {
        if (!allowed.has(allowedValue)){
            allowed.add(allowedValue)
            setAllowed(allowed)
            setAllowedValue("")
            setAllowedError("")
            
        }else{
       
            setAllowedError("Value already used. Please try again")

        }

    }


    function removeAllowedValue(item) {

        let newAllowed = new Set([...allowed])
        newAllowed.delete(item)

        setAllowed(newAllowed)
    }

    function generateAllowed() {
        let tagList = <div></div>
        const tags = []

        allowed.forEach((item, index) => {
            tags.push(
                <div className="tag-item">
                    {item}
                    <Button
                        type="primary"
                        className="button"
                        onClick={() => removeAllowedValue(item)}
                    >
                        X
                    </Button>
                    
                </div>
            )
        }
        )

        tagList = <div>{tags}</div>;

        return tagList

    }



    function handleCreate() {

        if (validateFormValues()) {
            let queryParams = {
                mode: props.mode
            }

            let body = {
                'key': key.trim(), // make sure to remove any trailing whitespace
                'desc': desc.trim()
            }

            if (allowed.size > 0){
                body['allowed'] = [...allowed]
            }

            HQS_API.createTag(queryParams, body)
                .then(() => {
                    const title = 'Success!';
                    const details = "Created tag: " + body['key'];
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="success"
                        />
                    );
                    clearInputs();
                    if (props.callback) {
                        props.callback();
                    }
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unkown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="critical"
                        />
                    );
                });

            handleClose();
        }
    }

    function handleKeyDown(evt) {
        if (['Enter'].includes(evt.key)) {
            evt.preventDefault();
            // make sure there is a value for this record and make sure that adding this record wouldn't push it over the maximum
            if (allowedValue !== undefined && allowedValue !== "" && allowed.size + 1 <=  maxTagAllowedValues){
                addAllowedValue()
            }
            else{
                setAllowedError("")
            }
        }
    };



    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow} disabled={props.disabled}>
                <Icon root="common" name="tag" size="small"></Icon>
                &nbsp;&nbsp;Create
            </Button>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-settings-modal">
                <Modal.Header closeButton>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="tag" size="medium"></Icon>
                        &nbsp;&nbsp;Create
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <div>
                        <p>
                            <span>Specify a key name describing this new tag. It should be <b>unique</b> and it is <b>case-sensitive</b>.</span>
                        </p>
                    </div>
                    <br />


                    <Form.Group controlId="formVersion">
                        <Input
                            style={{
                                width: '24rem'
                            }}
                            type="text"
                            label="Key"
                            placeholder={"Value (" + maxTagKeyLength + " chars max)"}
                            name="key"
                            onChange={handleKey}
                            value={key}
                            fluid={true}
                            error={keyError}
                            indicator="required"
                            title="The unique key for this tag"
                            maxLength={maxTagKeyLength}
                        />
                        <Input
                            style={{
                                width: '24rem'
                            }}
                            type="text"
                            label="Description"
                            placeholder={"Value (" + maxTagDescLength + " chars max)"}
                            name="desc"
                            onChange={handleDesc}
                            value={desc}
                            fluid={true}
                            indicator="optional"
                            title="The description of this tag"
                            maxLength={maxTagDescLength}
                        />





                    </Form.Group>
                    <Form.Group>
                        <InputLabel label="Allowed Values" indicator="optional"></InputLabel>
                        <p>Define a set of allowed values you would like users to choose from when something is being tagged. If nothing is defined, users will have the option to use freeform text instead</p>
                        {allowed.size == 0? 
                            <p>No values are defined.</p> : ""}
                        {generateAllowed()}
                        <Input
                            style={{
                                width: '24rem'
                            }}
                            type="text"
                            placeholder={"Type a value and press 'Enter' to confirm"}
                            name="allowed"
                            onChange={handleAllowed}
                            onKeyPress={handleKeyDown}
                            value={allowedValue}
                            fluid={true}
                            title="A value allowed for this tag"
                            maxLength={maxTagValueLength}
                            error={allowedError}
                        />
                        <p>Note: You can have a maximum of <b>{maxTagAllowedValues}</b> unique values for this tag. <b>{maxTagAllowedValues - allowed.size}</b> remaining </p>

                    </Form.Group>

                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleCreate} disabled={!(hasValidKey)}>
                        Create
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreateTagForm;
