/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/
/* eslint no-restricted-globals:0 */

import React, { Component } from 'react';
import * as HQS_API from '../utils/api';
import moment from 'moment';
import './Product.css';
import { Tab, Icon } from '@scuf/common';
import '../../main.scss'; // webpack must be configured to do this
import ReportsTab from '../Reports/ReportsTab';
import { DataTable } from '@scuf/datatable';
import CreateOrgForm from '../../components/Forms/CreateOrganization';
import { ToastContainer } from 'react-toastify';
import { GenerateUsageReportForm } from '../Forms/GenerateUsageReport';
import { toISODate } from '../utils/helpers';

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            organizations: [],
            selectedOrg: '',
            selectedOrgDetails: {},
        };
        this.fetchReports = this.fetchReports.bind(this);
        this.mode = 'product';
    }

    componentDidMount() {
        this.fetchReports();
        this.getOrganizations();
    }

    shortDateRenderer = (cell, row) => {
        const data = cell.value;
        var date = '';

        if (data) {
            date = toISODate(data, 'short');
        }
        return <span>{date}</span>;
    };

    fetchReports() {
        HQS_API.listReports()
            .then((response) => {
                response.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });
                // format report text before passing to ReportsTab
                response.forEach(function (report) {
                    // format report type
                    let report_type = report['report_type'];
                    if (report_type === 'single') {
                        report['report_type'] = 'Single Day';
                    } else if (report_type === 'multi') {
                        report['report_type'] = 'Multiple Day';
                    }

                    // format record type
                    let record_type = report['record_type'];
                    if (record_type === 'org') {
                        report['record_type'] = 'organization';
                    }

                    // format date
                    let date = report['date'];
                    report['date'] = date.split(' ')[0];
                });
                this.setState({
                    reports: response,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingJobs: false });
                this.render();
            });
    }

    async getOrganizations() {
        HQS_API.getOrganizations()
            .then((response) => {
                const orgs = [];
                const orgNames = [];
                const offset = moment().utcOffset();
                response.forEach((org) => {
                    if (['external', 'internal'].includes(org.type)) {
                        var newOrg = {
                            name: org.org_name,
                            created: 'create_date' in org ? org['create_date'] : '',
                            customerId: org.customer_id,
                            enabled: 'enabled' in org ? org['enabled'] : false,
                            id: org.id,
                        };
                        orgs.push(newOrg);
                    }
                    orgNames.push(org.org_name);
                });
                orgs.sort((a, b) => a.name.localeCompare(b.name));
                this.setState({
                    organizations: orgs,
                    orgNames: orgNames,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getOrganizationDetails(organization) {
        this.setState({
            selectedOrg: organization,
        });
    }

    generateTabs() {
        var tabEntries = [];
        /** Reports Tab **/
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="folder" size="small">
                        &nbsp;Reports
                    </Icon>
                }>
                <ReportsTab reports={this.state.reports} />
            </Tab.Pane>,
        );
        /** Orgs */
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="sites" size="small">
                        &nbsp;Organizations
                    </Icon>
                }>
                <div className="hqs-admin-view">
                    <div className="hqs-admin-card">
                        <DataTable
                            key="organizations-table-key"
                            data={this.state.organizations}
                            scrollable={true}
                            scrollHeight="590px"
                            onRowClick={(data) => this.getOrganizationDetails(`${data.data.name}`)}
                            search={true}
                            searchPlaceholder="Search Records">
                            <DataTable.Column field="name" header="NAME" sortable={true} />
                            <DataTable.Column
                                field="created"
                                header="DATE ADDED"
                                sortable={true}
                                renderer={this.shortDateRenderer}
                            />
                            <DataTable.Column field="customerId" header="Customer ID" sortable={true} />
                            <DataTable.Column
                                field="enabled"
                                header="ENABLED"
                                align="center"
                                sortable={true}
                                renderer={this.statusRenderer}
                            />
                        </DataTable>
                    </div>
                    <div className="hqs-admin-card">
                        <div
                            className="hqs-admin-centered"
                            style={{
                                display: this.state.selectedOrg !== '' ? 'none' : '',
                            }}>
                            Select an organization on the left to see more details
                        </div>
                        <div
                            style={{
                                fontSize: '18px',
                                display: this.state.selectedOrg !== '' ? '' : 'none',
                            }}>
                            {this.state.selectedOrg}
                        </div>
                        <div
                            style={{
                                display: this.state.selectedOrg !== '' ? '' : 'none',
                            }}>
                            <div className="hqs-admin-subcard">
                                <GenerateUsageReportForm organization={this.state.selectedOrg} mode={this.state.mode} />
                            </div>
                        </div>
                        <ToastContainer
                            hideProgressBar={true}
                            closeOnClick={false}
                            closeButton={false}
                            newestOnTop={true}
                            position="bottom-right"
                            toastClassName="toast-notification-wrap"
                        />
                    </div>
                </div>
            </Tab.Pane>,
        );
        return tabEntries;
    }

    render() {
        let welcomeMessage = 'Welcome, Product Team!';
        let view = (
            <div className="hqs-prodct">
                <div className="Product">
                    <div className="View-header">{welcomeMessage}</div>
                    <Tab defaultActiveIndex={0}>{this.generateTabs()}</Tab>
                </div>
            </div>
        );

        return view;
    }
}

export default Product;
