// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

const AmplifyConfig = {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_LrMmj359B',
        userPoolWebClientId: '31it3j7fi21sf702n58fip51dq',
        identityPoolId: 'us-west-2:a8cb6334-bcac-4525-95fd-9bdd364b1a83',
    },
    API: {
        endpoints: [
            {
                name: 'HQS_UI_API',
                endpoint: 'https://ui.qapi.quantinuum.com/beta',
            },
            {
                name: 'HQS_MACHINE_API',
                endpoint: 'https://qapi.quantinuum.com/v1',
            },
        ],
    },
};

const MsalConfig = {
    auth: {
        clientId: "4ae73294-a491-45b7-bab4-945c236ee67a", //Quantinuum
        authority: "https://login.microsoftonline.com/common", //all users,
        redirectUri: "https://um.qapi.quantinuum.com/",
        postLogoutRedirectUri: "https://um.qapi.quantinuum.com/",

    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const MsalLoginRequest = {
    scopes: ["User.Read"], // allows app to read profile
    prompt: "select_account" // provides the user with the option to select an account if already in session. Otherwise prompt for username/password
};

const ErrorCodes = {
    OrganizationAlreadyExists: 24,
    UserAlreadyExists: 25,
    OrganizationDoesNotExist: 26,
    UserDoesNotExist: 29,
    BadEmailAddress: 30,
    UnconfirmedEmailSubscription: 31,
    BadPhoneNumber: 32,
    OrganizationContainsUsers: 33,
    NotAuthorizedException: 34,
    CannotDeleteSelf: 36,
};

const APIExamplesBucket = 'hqs-api-examples-prod'

const Domain = "um.qapi.quantinuum.com"

export { AmplifyConfig, ErrorCodes, APIExamplesBucket, MsalConfig, MsalLoginRequest, Domain};

