// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { Notification } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';


const ToastNotification = ({ severity, closeToast, title, details }) => (
    <Notification
        className="toast-notification"
        severity={severity == undefined? "success": severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

export default ToastNotification;