// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';

const logo = require('../icons/icon_alert_critical.svg');

const ResendInviteForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    function resendInvite() {
        let user = props.user;
        HQS_API.resendUserCredentials(user['email'])
            .then((response) => {
                const title = 'Success!';
                const details = `A new invitation has been sent to ${user['email']}!`;
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            });
        if (props.callback) {
            props.callback();
        }
        handleClose();
    }

    return (
        <div>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="building"
                    name="email"
                    size="small"></Icon>
                &nbsp;{props.children || 'Resend Invitation'}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                className="hqs-umui-information-modal"
                size="small">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Resend Invitation</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        This will resend the invitation to complete account registration for this user:
                    </p>
                    <p>
                        <b>{props.user['email']}</b>
                    </p>
                    <p>Are you sure?</p>
                    <Button type="primary" size="small" onClick={resendInvite}>
                        Resend
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default ResendInviteForm;
