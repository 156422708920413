// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import Form from 'react-bootstrap/Form';
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Icon,
    InputLabel,
    Input,
    Radio,
    Notification,
    Modal,
    Select,
    Badge,
} from '@scuf/common';

import { DataTable } from '@scuf/datatable';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import moment from 'moment';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const UpdateCalendarEventForm = (props) => {
    const AZURE_ORG = 'azure';
    let existingTenantId = props.reservationType === AZURE_ORG ? props.selectedOrg : '';
    let selectedOrg = props.reservationType === AZURE_ORG ? AZURE_ORG : props.selectedOrg;
    const [show, setShow] = useState(false);
    const [eventType, setEventType] = useState(props.eventType);
    const [org, setOrg] = useState(selectedOrg);
    const [orgError, setOrgError] = useState('');
    const [selectedMachine, setMachine] = useState(props.machine);
    const [machineError, setMachineError] = useState();
    const [startTime, setStartTime] = useState(moment(props.startTime).toDate());
    const [startTimeError, setStartTimeError] = useState();
    const [endTime, setEndTime] = useState(moment(props.endTime).toDate());
    const [endTimeError, setEndTimeError] = useState();
    const [eventId, setEventId] = useState(props.id);
    const [minuteStep, setMinuteStep] = useState(15);
    const [reservationType, setReservationType] = useState(props.reservationType);
    const [tenantID, setTenantID] = useState(existingTenantId);
    const [tenantIDError, setTenantIDError] = useState('');

    function handleClose() {
        setShow(false);
        setEventType(props.eventType);
    }

    function handleTenantID(tenantIdValue) {
        //clear error if any
        setTenantIDError('');
        setTenantID(tenantIdValue);
    }

    function genOrgOptions(orgs) {
        const formattedOrgs = [];
        orgs.forEach((org) => {
            let formattedOrg = { value: org, text: org };
            formattedOrgs.push(formattedOrg);
        });

        formattedOrgs.push({ value: 'azure', text: 'Azure' });
        formattedOrgs.sort((a, b) => a.value.localeCompare(b.value));

        return (
            <Select
                error={orgError}
                options={formattedOrgs}
                placeholder="No organizations needed for Maintenance events"
                onChange={(text) => handleOrg(text)}
                reserveSpace={true}
                fluid={true}
                value={reservationType === AZURE_ORG ? AZURE_ORG : org}
            />
        );
    }

    function updateMachine(selectedMachine) {
        setMachineError('');
        setMachine(selectedMachine);
    }

    function handleShow() {
        setEventType(props.eventType);
        setReservationType(props.reservationType);
        let org = props.reservationType === AZURE_ORG ? AZURE_ORG : props.selectedOrg;
        setOrg(org);
        let existingTenantId = props.reservationType === AZURE_ORG ? props.selectedOrg : '';
        setTenantID(existingTenantId);
        setMachine(props.machine);
        setStartTime(moment(props.startTime).toDate());
        setEndTime(moment(props.endTime).toDate());
        setEventId(props.id);
        setShow(true);
    }

    function handleOrg(orgValue) {
        //clear error if any
        setOrgError('');
        setOrg(orgValue);

        //check if this is for azure
        if (orgValue === AZURE_ORG) {
            setReservationType('azure');
        } else {
            setReservationType('native');
        }
    }

    function handleStartTime(date) {
        //clear error if any
        setStartTime(date);
        setStartTimeError('');
    }

    function handleEndTime(date) {
        //clear error if any
        setEndTimeError('');
        setEndTime(date);
    }

    function handleEventType(eventValue) {
        //clear org error if switching to maintenance
        if (eventValue === 'maintenance') {
            handleOrg('');
        }
        setEventType(eventValue);
    }

    function handleToggle(checked) {
        if (checked) {
            //set dates to start and end of day
            //only set start time if it's not selected already
            if (startTime === undefined) {
                handleStartTime(moment().startOf('day').toDate());
            }
            handleEndTime(moment().endOf('day').toDate());
        }
    }

    function genMachineOptions(machines) {
        const formattedMachines = [];
        machines.forEach((record) => {
            let formattedMachine = { value: record.name, text: record.name };
            formattedMachines.push(formattedMachine);
        });

        // formattedMachines.sort((a, b) => a.value.localeCompare(b.value));

        return (
            <Select
                error={machineError}
                options={formattedMachines}
                placeholder="Machine Name"
                onChange={(text) => updateMachine(text)}
                reserveSpace={true}
                fluid={true}
                value={selectedMachine}
                search={true}
            />
        );
    }

    // function to validate all form fields
    function valiteFormValues() {
        let validData = true;
        // start time
        if (startTime === undefined) {
            setStartTimeError('Start Time Missing');
            validData = false;
        }

        // end time
        if (endTime === undefined) {
            setEndTimeError('End Time Missing');
            validData = false;
        }

        // make sure end date is not before or equal to start date
        if (startTime !== undefined && endTime !== undefined) {
            var timeDiff = moment(endTime).diff(moment(startTime), 'minutes');
            if (timeDiff <= 0) {
                validData = false;
                setEndTimeError('Select time greater than start');
            }
        }

        // org is required for reservation
        if (eventType === 'reservation') {
            if (org === undefined || org === '') {
                setOrgError('Organization Missing');
                validData = false;
            } else if (org === AZURE_ORG) {
                //make sure we have a tenant id
                if (tenantID === undefined || tenantID === '') {
                    validData = false;
                    setTenantIDError('Tenant ID is required for Azure reservations');
                } else {
                    //validate format
                    const regexPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/g;
                    if (!tenantID.match(regexPattern)) {
                        setTenantIDError('Invalid Subcription ID Format');
                        validData = false;
                    }
                }
            }
        }

        // machine
        if (selectedMachine === undefined) {
            setMachineError('Machine Missing');
            validData = false;
        }

        return validData;
    }
    function handleUpdate() {
        if (valiteFormValues()) {
            let duration = Math.round((endTime.getTime() - startTime.getTime()) / 1000);
            let start_date = moment.utc(startTime).format('YYYY-MM-DD[T]HH:mm:ss');
            let end_date = moment.utc(endTime).format('YYYY-MM-DD[T]HH:mm:ss');

            const body = {
                machine: selectedMachine,
                organization: reservationType == 'azure' ? tenantID : org,
                duration: duration.toString(),
                'event-type': eventType,
                'start-date': start_date,
                'end-date': end_date,
                'reservation-type': eventType == 'reservation' ? reservationType : '',
            };

            if (props.recurringID !== undefined) {
                delete body['start-date'];
                delete body['end-date'];
                delete body['duration'];
            }

            HQS_API.updateMachineEvent(eventId, body)
                .then((response) => {
                    props.callBack();

                    const title = 'Event Updated';
                    const details = 'Event has been updated successfully';
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="success" />);
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unknown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                });
            handleClose();
        }
    }

    return (
        <div>
            <div onClick={handleShow}>
                <Icon style={{ cursor: 'pointer' }} root="common" name="edit" size="small"></Icon>
                &nbsp;
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="calendar" size="medium"></Icon>
                        &nbsp;&nbsp;Update Event
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>Updated fields will be replaced for this event.</p>
                    <Form.Group controlId="eventType">
                        <InputLabel label="Event Type" indicator="required" />
                        <Radio
                            label="Maintenance"
                            name="EventTypeGroup"
                            checked={eventType === 'maintenance'}
                            onChange={() => handleEventType('maintenance')}
                        />
                        <Radio
                            label="Reservation"
                            name="EventTypeGroup"
                            checked={eventType === 'reservation'}
                            onChange={() => handleEventType('reservation')}
                        />
                        <Radio
                            label="Online"
                            name="EventTypeGroup"
                            checked={eventType === 'online'}
                            onChange={() => handleEventType('online')}
                        />
                    </Form.Group>
                    <div style={{ display: eventType === 'reservation' ? '' : 'none' }}>
                        <InputLabel label="Select Organization" indicator="required" />
                        <div style={{ 'max-height': '50px', 'margin-bottom': '10px' }}>
                            {genOrgOptions(props.orgs || [])}
                        </div>
                    </div>
                    <div
                        style={{
                            display: reservationType === 'azure' ? '' : 'none',
                        }}>
                        <Input
                            placeholder="Tenant ID (UUID Version 4)"
                            onChange={(subId) => handleTenantID(subId)}
                            label="Tenant ID"
                            indicator="required"
                            value={tenantID}
                            fluid={true}
                            error={tenantIDError}
                        />
                    </div>
                    <InputLabel label="Select Machine" indicator="required" />
                    <div>{genMachineOptions(props.allMachines)}</div>
                    <Divider fitted />

                    <Divider fitted />
                    {props.recurringID !== undefined ? (
                        ''
                    ) : (
                        <div>
                            <InputLabel label="Date and Time" />
                            <Checkbox label="All Day" toggle={true} onChange={handleToggle} />
                            <InputLabel label="Starts" indicator="required" />
                            <DatePicker
                                key={startTime.toString()}
                                type="datetime"
                                displayFormat="MM/DD/YY, h:mm a"
                                placeholder="MM/DD/YY, h:mm a"
                                minuteStep={minuteStep}
                                disablePast={true}
                                onChange={handleStartTime}
                                value={startTime}
                                error={startTimeError}
                            />
                            <InputLabel label="Ends" indicator="required" />
                            <DatePicker
                                key={endTime.toString()}
                                indicator="required"
                                type="datetime"
                                displayFormat="MM/DD/YY, h:mm a"
                                placeholder="MM/DD/YY, h:mm a"
                                minuteStep={minuteStep}
                                disablePast={true}
                                onChange={handleEndTime}
                                value={endTime}
                                error={endTimeError}
                            />
                        </div>
                    )}

                    {props.recurringID !== undefined ? (
                        <div>
                            <p>
                                <Badge color="red" title="">
                                    NOTE:
                                </Badge>
                                This entry is part of a recurring event. Any updates will be applied to all entries in
                                the series!
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleUpdate}>
                        Update Event
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateCalendarEventForm;
