/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2021-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React, { useEffect } from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Input, InputLabel, Modal, Select } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const UpdateUserGroupNameForm = (props) => {
    const { user } = props;
    // let initialDefaultGroup = user['default_group'] !== 'none' ? useState(user['default_group']) : null;
    const [show, setShow] = useState(false);
    const [userGroups, setUserGroups] = useState(user['user_groups']);
    const [defaultGroup, setDefaultGroup] = useState(user['default_group'] !== 'none' ? user['default_group'] : null);
    const [userGroupsError, setUserGroupsError] = useState('');
    const [defaultGroupError, setDefaultGroupError] = useState('');

    const handleClose = () => {
        setShow(false);
        setUserGroups(user['user_groups']); 
        setDefaultGroup(user['default_group']);
        setDefaultGroupError('');
    };
    const handleShow = () => {
        setShow(true);
        return true;
    };

    useEffect(() => {
        setUserGroups(userGroups);
    }, [userGroups]);

    useEffect(() => {
        setDefaultGroup(defaultGroup);
    }, [defaultGroup]);

    function genGroupOptions(groups) {
        const formattedGroups = [];
       
        groups.forEach((group) => {
            let formattedGroup = { value: group.id, text: group.name };
            formattedGroups.push(formattedGroup);
        });
        formattedGroups.sort((a, b) => a.text.localeCompare(b.text));
        return (
            <Select
                options={formattedGroups}
                placeholder="Select Group(s)"
                onChange={(ids) => handleUserGroups(ids)}
                reserveSpace={true}
                fluid={true}
                search={true}
                multiple={true}
                value={userGroups}
            />
        );
    }

    function genDefaultGroupOptions(groups) {
        const formattedGroups = [];
       
        groups.forEach((group) => {
            let formattedGroup = { value: group.id, text: group.name };
            formattedGroups.push(formattedGroup);
        });
        formattedGroups.sort((a, b) => a.text.localeCompare(b.text));
        return (
            <Select
                options={formattedGroups}
                placeholder="Select Default Group"
                onChange={(id) => handleDefaultGroup(id)}
                reserveSpace={true}
                fluid={true}
                search={true}
                error={defaultGroupError}
                value={defaultGroup}
            />
        );
    }

    function handleDefaultGroup(groupId) {
        setDefaultGroup(groupId);
        //check if default group is already added to user group list
        if (!userGroups.includes(groupId)) {
            setUserGroups([...userGroups,groupId])
            setDefaultGroupError('');
        } else {
            setDefaultGroupError('');
        }
    }

    function handleUserGroups(groups) {
        setUserGroups(groups);
        // clear default group if groups is empty
        if (groups.length === 0) {
            setDefaultGroup(null);
            setDefaultGroupError('');
        } 
        else if (groups.length === 1) {
            setDefaultGroup(groups[0]);
            setDefaultGroupError('');
        }
        else {
            if (groups.includes(defaultGroup)) {
                setDefaultGroupError('');
            }
        }
    }

    function groupsValidation(groups, defaultId) {
        /** Check groups and default group. 
         * We need to makes sure defaultGroup is one of the item in user groups
        */
        let validGroups = true;
        if (groups !== undefined && groups.length > 0) {
            if (!groups.includes(defaultId)) {
                validGroups = false;
                setDefaultGroupError("Please add this value to the group list above or update it!");
            } else {
                setDefaultGroupError("");
            }
        }
        return validGroups;
    }
     
    function handleUpdate() {
        if(groupsValidation(userGroups, defaultGroup)) {
            // set user group id
            let body = {};
            body['user-groups'] = userGroups;
            if (defaultGroup !== undefined || defaultGroup !== null){
                body['default-user-group'] = defaultGroup;
            }

            // update user group
            HQS_API.updateUserGroupId(props['user']['email'], body)
                .then((response) => {
                    const title = 'Group Names Updated';
                    const details = 'Group Names has been updated successfully';
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="success"
                        />,
                    );
                    if (props.callback) {
                        props.callback();
                    }
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unknown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="critical"
                        />,
                    );
                });

            handleClose();
        }
        
    }

    return (
        <div>
            <div onClick={handleShow}>
                <div style={{ cursor: 'pointer' }}>
                    <Icon
                        style={{ cursor: 'pointer' }}
                        root="common"
                        name="grid"
                        size="small"
                    ></Icon>
                    &nbsp;{props.children || 'Update Groups'}
                </div>
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="grid" size="medium"></Icon>
                        &nbsp;&nbsp;Update Groups
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>
                        Update or set the groups for &quot;
                        {props.user['email']}&quot;.
                    </p>
                    <Form.Group>
                        <InputLabel label="Group Name(s)" indicator="required" />
                        <div>
                            {genGroupOptions(props.org_groups || [])}
                        </div>
                        <InputLabel label="Default Group" indicator="required" />
                        <div style={{ height: '90px' }}>
                            {genDefaultGroupOptions(props.org_groups || [])}
                        </div>
                        <div></div>
                    </Form.Group>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={() => handleUpdate()}>
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateUserGroupNameForm;
