// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import DeleteOrgPlanForm from '../Forms/DeleteOrgPlan';
import Dropdown from 'react-bootstrap/Dropdown';
import ManageAnyPlanForm from '../Forms/ManageAnyPlan';

import {
    isLicensedPlan,
    isNullOrEmpty
} from '../utils/helpers';

const generateOptions = (selectedRow, allMachines, allTags, licenses, mode, callback) => {
    let actionsList = [];
    if (selectedRow !== undefined && selectedRow !== null) {

        let id = selectedRow[0].id;
        let org = selectedRow[0].org_name;
        let orgType = selectedRow[0].org_type;
        let rawPlan = 'raw' in selectedRow[0] ? selectedRow[0].raw : {}
        let enabled = 'enabled' in rawPlan ? rawPlan['enabled'] : false;
        let credits = 'credits' in rawPlan ? rawPlan['credits'] : {};
        let machines = 'machines' in rawPlan ? rawPlan['machines'] : {};
        let internal = 'internal' in rawPlan ? rawPlan['internal'] : false;
        let fairQueuing = 'fair-queuing' in rawPlan ? rawPlan['fair-queuing'] : {};
        let dates = 'dates' in rawPlan ? rawPlan['dates'] : {};
        let tags = 'tags' in rawPlan ? rawPlan['tags'] : {};
        
        //if this plan is a licensed based plan, get the license information for this org 
        let softwareName = 'software' in rawPlan ? rawPlan['software'] : "";

        let license = (isLicensedPlan(softwareName) && licenses !== undefined && licenses[softwareName])? licenses[softwareName]: {}
        let software = {}
        //prepare the license to fit the form data
        if (!isNullOrEmpty(license)){
            software = {
                "name": softwareName,
                "licenses": license['max'],
                "customer-type": license['customer'],
                "expiration-date": license['expiration'],
                "duration": license['duration']
            }
        }
     
        const planDetails = {
            id: id,
            org: org,
            orgType: orgType,
            enabled: enabled,
            credits: credits,
            machines: machines, 
            internal: internal,
            fairQueuing: fairQueuing,
            dates: dates,
            software: software,
            tags: tags
        };

        // always show option to view summary 
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <ManageAnyPlanForm
                    key={planDetails.org  + planDetails.id}
                    org={planDetails.org}
                    id={planDetails.id}
                    mode={"summary"}
                    credits={planDetails.credits}
                    machines={planDetails.machines}
                    internal={planDetails.internal}
                    fairQueuing={planDetails.fairQueuing}
                    dates={planDetails.dates}
                    software={planDetails.software}
                    tags={planDetails.tags}
                    enabled={planDetails.enabled}
                    allLicenses={licenses}
                    callBack={callback}
                />
            </Dropdown.Item>
        );

        // only show option to edit/delete if the mode == admin
        if (mode === 'admin'){
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <ManageAnyPlanForm
                    key={planDetails.org + planDetails.id}
                    org={planDetails.org}
                    id={planDetails.id}
                    mode={"edit"}
                    credits={planDetails.credits}
                    machines={planDetails.machines}
                    internal={planDetails.internal}
                    fairQueuing={planDetails.fairQueuing}
                    dates={planDetails.dates}
                    software={planDetails.software}
                    tags={planDetails.tags}
                    enabled={planDetails.enabled}
                    orgType={planDetails.orgType}
                    allLicenses={licenses}
                    allMachines={allMachines}
                    allTags={allTags}
                    callBack={callback}
                />
            </Dropdown.Item>
        );
        
        
        // always show delete
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <DeleteOrgPlanForm
                    org={planDetails.org}
                    id={planDetails.id}
                    software={planDetails.software}
                    licenses={licenses}
                    callback={callback}
                />
            </Dropdown.Item>,
        );
        }

        

    } else {
        actionsList.push(
            <Dropdown.Item
                className="hqs-custom-actions-select-option"
                disabled={true}
            >
                <div>Select a row...</div>
            </Dropdown.Item>,
        );
    }

    return actionsList;
};

const PlanActionSelect = (props) => {
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className="ui small button primary hqs-custom-dropdown"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            &#x25bd;&nbsp;
            {children}
        </div>
    ));
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {generateOptions(
                    props.selectedRow,
                    props.allMachines,
                    props.allTags,
                    props.licenses,
                    props.mode,
                    props.callback,
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default PlanActionSelect;
