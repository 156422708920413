/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React from "react";
import { Button, Input, InputLabel, Modal, Icon, Notification } from '@scuf/common';
import { ConfirmSignIn } from "aws-amplify-react";
import '../CustomAuth/CustomAuth.css';
import { useState } from 'react';
import { Auth } from 'aws-amplify'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import * as HQS_API from '../utils/api';
import Pdf from '../../../public/user_terms_and_conditions.pdf';
import { MsalContext } from "@azure/msal-react";
import { authChallenges, agreementChallenges, agreementTypes, isLicensedPlan, getEula, getEulaDescription, getSoftwareFromChallenge, isBetaChallenge } from '../utils/helpers';
import { useMsal } from "@azure/msal-react";
import './SignUp.css';

const logo = require('./QuantinuumLogo.svg');

const ToastNotification = ({ closeToast, title, details, severity }) => (
  <Notification
    className="toast-notification"
    severity={severity}
    onCloseClick={closeToast}
    hasIcon={true}
    title={title}>
    {details}
  </Notification>
);


const SignUpAgreement = (props) => {

   
    const [inviteId, setInviteId] = useState(props.inviteId)
    const [email, setEmail] = useState(props.email)
    const [agreements, setAgreements] = useState(props.agreements)

    const [formHeight, setFormHeight] = useState('400px');
    const [homePage, setHomePage] = useState(props.homePage);
    const [formLoading, setFormLoading] = useState(false);

    const { instance } = useMsal();


  async function acceptTerms(accept) {
      const body = {
        "invite-id": inviteId,
        "email": email,
        "auth-challenge": agreements,
        "action": 'accept'
    };
    setFormLoading(true);
    HQS_API.userRegistration(body)
        .then((response) => {

            //prompt the user to accept terms and conditions

            setFormLoading(false);
            window.location.replace("/registration-completed");

        })
        .catch((error) => {
            setFormLoading(false);
            if (error !== undefined) {
                error = error.response.data.error
                const title = "Unable to accept agreements!";
                let details = error.text
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            }
        });
      

  }

  function backToSignIn() {
    //update window to return to login page
    window.location.replace("/login");
  
  }


  function genAgreements() {
    let softwareList = <div></div>
    const software = []
    const hardware =  <p><b>{agreementTypes['hardware']}</b><a className="forgot-password-link" href={Pdf}  target = "_blank"> Policy & Terms of Use </a></p>

    // process the agreements
    if (agreements && agreements !== undefined ){
      
        let terms = agreements.split(",")

        //meaning they have more than just a hardware agreement o accept
        if (terms.length > 0){

          terms.forEach((item, index) => {
            let software_name = getSoftwareFromChallenge(item)
            let isBeta = isBetaChallenge(item)
            // only include the agreements types that are valid
            if (software_name){
              software.push(
                  <p><b>{agreementTypes[software_name]}</b><a className="forgot-password-link" href={getEula(software_name, item)}  target = "_blank">{' '}{getEulaDescription(software_name, item)}</a></p>

                  )
              }
          })
        }
    }

    const softwareNote = []
    if (software.length > 0){
      softwareNote.push(<p><b>Note:</b> If your account was setup with additional software please keep a lookout for a welcome email with next steps</p>)
    }

    softwareList = <div>
                    <p>Please take a moment to review and accept the following agreement(s) before logging in</p>
                      <div>
                        {hardware}
                        {software}
                        {softwareNote}
                      </div>
                  </div>;

    return softwareList


}



  if (formLoading) {
    return (
      <div className="custom-sign-up">
        <div className="sign-up-form" style={{ height: 300 }}>
          <p className="form-completed-p">
            <Icon name="refresh" size="xlarge" loading={true} color="#1274B7" />
          </p>

        </div>
      </div>
    )
  }else{





    return (
      <div className="custom-sign-in">
        <div className="terms-and-conditions-form">
          <div className="form-header">
            <img className="logo" src={logo}></img>
          </div>
          <div className="credentials">
              <h4>Thank you for completing your registration!</h4>
            <div className="agreement-confirm">
                {genAgreements()}
            </div>
              <div className="change-password">
                <Button type="primary" onClick={() => acceptTerms(true)} content="Accept"  />
                <p className="forgot-password center-text">
                <a className="forgot-password-link" onClick={() => backToSignIn()}>
                  Back to sign in?
                </a>
                </p>
            </div>
          </div>
        </div>
        <ToastContainer
          hideProgressBar={true}
          closeOnClick={false}
          closeButton={false}
          newestOnTop={true}
          position="bottom-right"
          toastClassName="toast-notification-wrap"
        />
      </div>
    )
  }
  
}

export default SignUpAgreement;
