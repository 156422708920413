// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Input, InputLabel, Icon, Notification, Modal, Select } from '@scuf/common';
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { arrayRange } from '../utils/helpers';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const AddUserGroupForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [userGroupName, setUserGroup] = useState('');
    const [userGroupError, setUserGroupError] = useState('');
    const [priority, setPriority] = useState(5);

    function handleClose() {
        setUserGroup('');
        setUserGroupError('');
        setPriority(5);
        setShow(false);
    }

    const genPriorityOptions = () => {
        const formattedPriorities = [];
        const priorityArray = arrayRange(1, 10, 1);
        priorityArray.forEach((priority) => {
            let formattedPriority = { value: priority, text: priority };
            formattedPriorities.push(formattedPriority);
        });

        return (
            <Select
                id="selectPriority"
                options={formattedPriorities}
                placeholder="Select Priority"
                onChange={(text) => setPriority(text)}
                reserveSpace={true}
                fluid={true}
                search={true}
                indicator="required"
                value={priority}
            />
        );
    };

    function validateFormValues() {
        let validForm = true;
        if (userGroupName === undefined || userGroupName === '') {
            validForm = false;
            setUserGroupError('Group name is missing!');
        }

        return validForm;
    }

    function handleAdd() {
        if (validateFormValues()) {
            let body = {
                group_name: userGroupName,
                priority: priority,
            };
            HQS_API.addUserGroup(props.org, body)
                .then(() => {
                    const title = 'Group added';
                    const details = `Group was added to ${props.org} successfully!`;
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="success" />);
                    if (props.callback) {
                        props.callback();
                    }
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unkown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                });

            handleClose();
        }
    }

    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow}>
                <Icon root="common" name="slidercontrols-plus" size="small"></Icon>
                &nbsp;&nbsp;Add User Group
            </Button>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
                style={{
                    padding: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Modal.Header closeButton>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="grid" size="medium"></Icon>
                        &nbsp;&nbsp;Add User Group
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>A new user group will be added to your organization.</p>
                    <Form.Group>
                        <Input
                            type="text"
                            label="Group Name"
                            placeholder="Enter a group name"
                            name="userGroupName"
                            onChange={(text) => setUserGroup(text)}
                            value={userGroupName}
                            fluid={true}
                            indicator="required"
                            error={userGroupError}
                        />
                    </Form.Group>
                    <Form.Group>
                        <div style={{ 'max-height': '80px' }}>
                            <InputLabel label="Job Execution Priority" indicator="required" />
                            <p>
                                Select a priority value between 1 and 10.
                                <br /> 1 is the "highest" priority and 10 is the "lowest" priority
                            </p>
                            {genPriorityOptions()}
                        </div>
                        <div></div>
                    </Form.Group>
                    <Form.Group>
                        {' '}
                        <InputLabel label="Job Execution Priority" indicator="required" />
                    </Form.Group>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleAdd}>
                        Add
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddUserGroupForm;
