/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import DeleteUserGroupForm from '../Forms/DeleteUserGroup';
import DisableUserGroupQuotaForm from '../Forms/DisableUserGroupQuota';
import SetUserGroupQuotaForm from '../Forms/SetUserGroupQuota';
import UpdateUserGroupQuotaForm from '../Forms/UpdateUserGroupQuota';
import Dropdown from 'react-bootstrap/Dropdown';
import UpdateUserGroupPriorityForm from '../Forms/UpdateUserGroupPriority';
import { Button } from '@scuf/common';

const generateOptions = (selectedRow, orgPlans, orgName, callback) => {
    let actionsList = [];

    if (selectedRow !== undefined && selectedRow !== null) {
        let groupId = selectedRow[0]['id'];
        let groupName = selectedRow[0]['name'];
        let quota = selectedRow[0]['quota'];
        let quotaEnabled = selectedRow[0]['quota-enabled'];
        let priority = selectedRow[0]['priority'];

        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" key="deleteUserGroup">
                <DeleteUserGroupForm org_name={orgName} group_id={groupId} callback={callback} />
            </Dropdown.Item>,
        );

        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" key="setUserGroup">
                <SetUserGroupQuotaForm
                    org_name={orgName}
                    group_name={groupName}
                    org_plans={orgPlans}
                    group_id={groupId}
                    callback={callback}
                />
            </Dropdown.Item>,
        );
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" key="setUserGroup">
                <UpdateUserGroupPriorityForm
                    org_name={orgName}
                    group_name={groupName}
                    group_id={groupId}
                    priority={priority}
                    callback={callback}
                />
            </Dropdown.Item>,
        );
    } else {
        actionsList.push(
            <Dropdown.Item key="selectRow" className="hqs-custom-actions-select-option" disabled={true}>
                <div>Select a row...</div>
            </Dropdown.Item>,
        );
    }

    return actionsList;
};

const UserGroupActionSelect = (props) => {
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className="ui small button primary hqs-custom-dropdown"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>
            &#x25bd;&nbsp;
            {children}
        </div>
    ));
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {generateOptions(props.selectedRow, props.org_plans, props.org_name, props.callback)}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserGroupActionSelect;
