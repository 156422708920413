// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import Form from 'react-bootstrap/Form';
import { passwordPolicy } from '../utils/helpers';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import {
    Button,
    Icon,
    InputLabel,
    Notification,
    Modal,
    Select,
    Input,
    Checkbox,
} from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';

const ChangePasswordForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordAttributes, setPasswordAttributes] = useState('');
    const [passwordMatch, setPasswordMatch] = useState('');
    const [isFederated, setIsFederated] = useState(useIsAuthenticated());
    const { instance, accounts } = useMsal();
    const [mfaEnabled, setMFAEnabled] = useState(props.mfaEnabled)
    const [mfaCode, setMFACode] = useState('');


    //handle user input into the current password text field
    function handleCurrentPassword(value) {
        setCurrentPassword(value);
    }

    //handle user input into the new password text field
    function handleNewPassword(value) {
        setNewPassword(value);
        validateNewPassword(value);
        //check if the new confirm password still matches the new password
        setPasswordMatch(confirmPassword == value)
    }

    //handle user input into the confirm password text field
    function handleConfirmPassword(value) {
        setConfirmPassword(value);
        //check if the new password still matches the confirm password
        setPasswordMatch(newPassword == value)
    }

     //handle user input into the confirm password text field
     function handleMFACode(value) {
        setMFACode(value);
    }


    //handle what happens when the change password popup/modal is closed
    function handleClose() {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setPasswordAttributes('');
        setMFACode('')
        setShow(false)
    }

    // handle changing the user password and letting them know what's happened
    async function handleChangePassword() {

        //define the body of the request 
        let requestPayload = {
            'current_password': currentPassword,
            'new_password': newPassword
        };

        if (mfaEnabled){
            // add the mfa code and make sure it's a string
            requestPayload['code'] = mfaCode.toString()
        }

        //try to change the password
        HQS_API
            .changePassword(requestPayload)
            .then(response => {
                const title = 'Password successfully updated';
                const details = response
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />
                );
                if (props.callback) {
                    props.callback();
                }
                handleClose();
            })
            .catch((error) => {
                if (error !== undefined) {
                    error = error.response.data.error
                    const title = "Unable to change password";
                    let details = error.text
                    if (error.code === ErrorCodes.NotAuthorizedException) {
                        details = "Current password is incorrect. Please try again."
                    }
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="critical"
                        />
                    );
                }

            });

    }

    //validate whether or not the new password meets the password policy and any other requirements
    function validateNewPassword(password) {

        let attributes = {
            length: password.length,
            anyUpper: hasUpperCase(password),
            anyLower: hasLowerCase(password),
            anyNumber: hasNumber(password),
            isNew: (password != currentPassword)
        }

        setPasswordAttributes(attributes);
    }

    //evalute if the new password is valid
    function isPasswordValid() {

        //met length requirement
        let meetsLength = (passwordAttributes.length >= passwordPolicy.minLength)

        //met uppercase requirement
        let meetsUpper = (passwordAttributes.anyUpper && passwordPolicy.upperCase)

        //met lowercase requirement
        let meetsLower = (passwordAttributes.anyLower && passwordPolicy.lowerCase)

        //met number requirement
        let meetsNumber = (passwordAttributes.anyNumber && passwordPolicy.number)

        let passwordValid = (meetsLength && meetsUpper && meetsLower && meetsNumber && passwordAttributes.isNew && passwordMatch)
        
        //check if mfa is valid if it's enabled. If enabled and not valid, then let's tell their the user this is still invalid
        if (!isMFAValid()){
            return false
        }

        return passwordValid
    }

    function isMFAValid(){
        if (mfaEnabled){
             //verify the code is 6 digits
            return mfaCode.length == 6
        }else{
            return true
        }
    }



    //tests if a string contains a number
    function hasNumber(value) {
        return /\d/.test(value);
    }

    //tests if a string contains a lowercase letter
    function hasLowerCase(value) {
        return (/[a-z]/.test(value));
    }

    //tests if a string contains a uppercase letter
    function hasUpperCase(value) {
        return (/[A-Z]/.test(value));
    }

    //determines which validation color to return 
    function getValidationColor(value) {
        if (value) {
            return "green"
        } else {
            return "red"
        }
    }

    //define the rules we need to communicate to the user
    function buildPasswordPolicy() {

        let rules = []

        let lengthRule = {
            id: 1,
            rule: "Be at least " + passwordPolicy.minLength + " characters",
            passed: passwordAttributes.length >= passwordPolicy.minLength
        }
        let upperRule = {
            id: 2,
            rule: "Contain at least 1 uppercase letter",
            passed: passwordAttributes.anyUpper && passwordPolicy.upperCase
        }
        let lowerRule = {
            id: 3,
            rule: "Contain at least 1 lowercase letter",
            passed: passwordAttributes.anyLower && passwordPolicy.lowerCase
        }
        let numberRule = {
            id: 4,
            rule: "Contain at least 1 number",
            passed: passwordAttributes.anyNumber && passwordPolicy.number
        }

        let newRule = {
            id: 5,
            rule: "Is different from the current password",
            passed: passwordAttributes.isNew
        }

        let matchRule = {
            id: 6,
            rule: "Passwords match",
            passed: passwordMatch
        }

        rules.push(lengthRule);
        rules.push(upperRule);
        rules.push(lowerRule);
        rules.push(numberRule);
        rules.push(newRule);
        rules.push(matchRule);

        return rules
    }

    function genPolicyValidations(){
        let policies = buildPasswordPolicy()
        const listItems = policies.map((policy) =>
            <li key={policy.id}>
                {policy.rule}&nbsp;&nbsp;
                {passwordAttributes.length > 0 ? (
                    <span>
                        <Icon
                            root="common"
                            name="badge-check"
                            size="small"
                            color={getValidationColor(policy.passed)}
                        />
                    </span>) : ''
                }
            </li>
        );
        return listItems
    }


    //generate the password policy section so the user knows if the new password has met the requirements
    function genPasswordPolicy() {
        
        return (
            <div>
                <p>The new password must meet these requirements:</p>
                <ul>
                    {genPolicyValidations()}
                </ul>
            </div>
        );
    }

    //render the main content of the popup/modal
    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow} disabled={isFederated} title={isFederated? 'Password cannot be changed while logged in with a federated or social account': ''}>

                {props.children || 'Change Pwd'}
            </Button>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Change Pwd
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>
                        Complete this form to change the password used to sign into your account
                    </p>
                    {genPasswordPolicy()}
                    <Form.Group controlId="formBasicCurrentPassword">
                        <Input
                            indicator="required"
                            label="Current Password"
                            type="password"
                            placeholder="Please enter your current password"
                            name="currentPassword"
                            value={currentPassword}
                            fluid={true}
                            onChange={handleCurrentPassword}

                        />

                    </Form.Group>
                    <Form.Group controlId="formBasicNewPassword">
                        <Input
                            indicator="required"
                            label="New Password"
                            type="password"
                            placeholder="Please enter your new password"
                            name="newPassword"
                            value={newPassword}
                            fluid={true}
                            onChange={handleNewPassword}
                        />

                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmPassword">
                        <Input
                            indicator="required"
                            label="Confirm Password"
                            type="password"
                            placeholder="Please re-enter your new password"
                            name="confirmPassword"
                            value={confirmPassword}
                            fluid={true}
                            onChange={handleConfirmPassword}
                        />

                    </Form.Group>
                    
                    {mfaEnabled? 
                    <div>
                        <p>Multi-factor authentication (MFA) is currently enabled. </p>
                        <ol className="mfa-steps">
                            <li>Open the authenticator app using the phone or mobile device you used when setting up MFA</li>
                            <li>Enter the verification code below to continue</li>
                        </ol>
                        <Form.Group controlId="formBasicVerificationCode">
                            <Input
                                indicator="required"
                                label="Verification Code"
                                type="text"
                                placeholder="Please enter your 6-digit code"
                                name="verificationCode"
                                value={mfaCode}
                                fluid={true}
                                onChange={handleMFACode}
                            />

                        </Form.Group>
                    </div>: ""}   
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleChangePassword} disabled={!isPasswordValid()}>
                        Apply
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ChangePasswordForm;
