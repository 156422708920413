// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Modal, Checkbox, InputLabel, Badge } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import Form from 'react-bootstrap/Form'
import { isNullOrEmpty, getFriendlySoftwareDescription, toISODate, softwareMap } from '../utils/helpers';
import moment from 'moment';


// Custom hook for handling the delete user form
const useManageUserSoftwareForm = (closeCallback, props, software) => {

    function generateActions() {
        let actions = []
        //only do work if something was clicked
        Object.keys(props.licenses).forEach((s) => {

            //if software in licenses, then promote
            if (software.has(softwareMap[s])){
                //no action if user already has this
                if(!props.software.includes(softwareMap[s])){
                    actions.push({'action': 'promote', 'software': s })

                }
            }else{
                //software was removed from the list, need to remove permissions
                actions.push({'action': 'demote', 'software': s })

            }

        })

        return actions
    }


    async function manageUserSoftware(user) {

        let actions = generateActions();

        if (actions.length > 0) {
            for (let i = 0; i < actions.length; i++) {

                await HQS_API.updateUserPermissions(user['email'], actions[i])
                    .catch((error) => {
                        //catch and report any errors to the user
                        const title = 'Error!';
                        var details = 'Unknown Error!';
                        if (error.response !== undefined) {
                            details = error.response.data.error.text;
                        }
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="critical"
                            />,
                        );
                    })
                    

            }
        }
                
            
        const title = 'Success!';
        const details = 'Software updated successfully!';
        toast(
            <ToastNotification
                closeToast={false}
                title={title}
                details={details}
                severity="success"
            />
        );

        //close out the window and refresh the page
        if (props.callback) {
            props.callback();
        }
        
        
    }

    const handleSoftware = (event) => {
        if (event) {
            event.preventDefault();
        }
        manageUserSoftware(props.user);
        closeCallback();
    };

    return { handleSoftware };
};


const ManageUserSoftwareForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [licenses, setLicenses] = useState(props.licenses)
    const [userSoftware, setUserSoftware] = useState(new Set(props.software));

    const { handleSoftware } = useManageUserSoftwareForm(handleClose, props, userSoftware);

    function handleClose() {
        setShow(false);
        //revert to original
        setUserSoftware(new Set(props.software))

    }

    function handleSoftwareChecked(checked, item) {

        let pending = new Set([...userSoftware])
        let name = softwareMap[item]
        if(checked){
            if (!pending.has(name)){
                //add to the set of user software
                pending.add(name)
            }

        }else{
            if (pending.has(name)){
                //remove from the set of user software
                pending.delete(name)
            }

        }

        setUserSoftware(pending)


    }

    function isSoftwareDisabled(software){
        let disabled = false
        let available = licenses[software]['max'] - licenses[software]['users']
    
        if (available <= 0){

            //if the user doesn't already have access to this software
            if(!props.software.includes(softwareMap[software])){
                disabled = true // then the user is not allowed to make changes
            }
        }

        let expired = isSoftwareExpired(software)

        if (expired){
            disabled = true
        }


        return disabled
    
    }

    function isSoftwareExpired(software){
        let expired = false
        if (software && licenses){
            //check if it's expired
            let expires = licenses[software]['expiration']
        
            //check if expiration date has passed
            expired = moment() >= (moment(expires))

        }
        return expired
    
    }


    function shortDateRenderer(date) {
        let formatDate = ""
        if (date) {
            formatDate = toISODate(date, 'short')
        }
        return <span>{formatDate}</span>;
    }

    function isSoftwareChecked(software){
        let checked = false

        if(userSoftware.has(softwareMap[software])){
            checked = true
        }

        return checked

    }



    function genOrgLicenseOptions() {
        let softwareList = <div></div>
        const software = []

        if (licenses){

            Object.keys(licenses).forEach((item, index) => {
                software.push(
                <div title={getFriendlySoftwareDescription(item)}>
                    <Form.Group controlId="software">
                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                        <div style={{'width': '100%' }}>
                            <Checkbox
                                label={softwareMap[item]}
                                checked={isSoftwareChecked(item)}
                                onChange={(checked) => handleSoftwareChecked(checked, item)}
                                disabled={isSoftwareDisabled(item)}
                            />
                        </div>
                        <div style={{ 'width': '100%' }} title="The total number software licenses used out of what's available">
                            <p><b>Licenses Used:</b> {licenses[item]['users'] + "/" + licenses[item]['max']}</p>
                        </div>
                        {licenses[item]['customer'] != 'trial'? 
                        <div style={{ 'width': '100%' }} title="The date the software license will expire">
                            <p><b>Expires: </b> {shortDateRenderer(licenses[item]['expiration'])}</p>
                        </div>
                        :
                        <div style={{ 'width': '100%' }} title="The duration of the software license">
                            <p><b>Duration: </b> {(licenses[item]['duration'])} day(s)</p>
                        </div>}
                    </div>
                    </Form.Group>
                </div>
                    )
                })
        }
        

        if (software.length == 0){
            software.push(<p>None</p>)
        }

        softwareList = <div>{software}</div>;

        return softwareList


    }


    return (
        <div>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="credit-card"
                    size="small"></Icon>
                &nbsp;{props.children || 'Manage Software'}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Manage Software</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        Your organization has access to the following software.
                    </p>
                    <Form.Group controlId="software">
                        {genOrgLicenseOptions()}
                    </Form.Group>
                    <p><b><Badge color="red">Important</Badge></b></p>
                    <ul>
                        <li>Granting access sends a welcome email to the user with instructions on how to obtain a license key</li>
                        <li>Once the the user level agreement has been accepted, the user will receive a license key via email.</li>
                        <li>Removing access will <b>block</b> any previously issued license keys for this user</li>
                        <li>Restoring access will issue a <b>new</b> license key once the user agreement has been accepted</li>
                    </ul>
                </Modal.Content>
                <Modal.Footer>
                <Button type="primary" size="small" onClick={handleSoftware}>
                        Update
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ManageUserSoftwareForm;
