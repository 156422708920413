import React, { Component } from 'react';
import { Button, Input, InputLabel, Modal, Icon, Checkbox } from '@scuf/common';
import { Auth } from 'aws-amplify';
import CodeViewer from '../user/CodeViewer';
import Pdf from '../../../public/user_terms_and_conditions.pdf';

class TermsOfUse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            disabled: true,
            mode: this.props.mode,
            accepted: false
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.generateTermsModal = this.generateTermsModal.bind(this);
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }


    generateTermsModal = (show) => {
        return (<Modal
            open={show}
            onClose={this.handleClose}
            size="small"
            style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
            <Modal.Header>
                <div className="hqs-umui-modal-header ">
                    <Icon root="common" name="document-certification" size="medium"></Icon>
                    &nbsp;&nbsp;Policy &amp; Terms of Use
                </div>
            </Modal.Header>
            <Modal.Content scrolling={true}>
                {/* not being used
                <CodeViewer
                    fileFormat={this.state.fileFormat}
                    fileURL={this.state.fileURL}
                />
                */}
            </Modal.Content>
            <Modal.Footer>
                <Button type="secondary" onClick={this.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        )
    }


    render() {
        if (this.state.mode == "accept"){
            //view to show if they need to accept the terms
            return (
                <div className="disclaimer">
                    <div class="d-flex">
                        <Checkbox label=""
                            checked={this.props.accepted}
                            onChange={(checked) => this.props.onAccept(checked)} />
                        <p>
                            By checking this box you agree to Quantinuum's <br />
                            <a className="forgot-password-link" href={Pdf}  target = "_blank"> Policy & Terms of Use </a>
                        </p>
                    </div>
                    {
                    /*not being used
                        this.generateTermsModal(this.state.show)
                    */}
                </div>)

                }else if (this.state.mode == "confirm"){
            //view to show if they need to accept the terms
            return (
                <div className="terms-disclaimer">
                    <div class="d-flex">
                        <p>
                            <a className="forgot-password-link" href={Pdf}  target = "_blank"> Policy & Terms of Use </a>
                        </p>
                    </div>
                    {
                    /*not being used
                        this.generateTermsModal(this.state.show)
                    */}
                </div>)
        }else{
            //default
            return (
                <div className="disclaimer">
                    <p>
                        By clicking this button you agree to Quantinuum's
                        <a className="forgot-password-link" href={Pdf}  target = "_blank"> Policy & Terms of Use </a>
                    </p>
                    {
                    /*not being used
                        this.generateTermsModal(this.state.show)
                    */}
                </div>)
        }


    }

}


export default TermsOfUse;