/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import {
    Button,
    Icon,
    InputLabel,
    Modal,
    Select,
    Input,
    Checkbox,
} from '@scuf/common';
import Form from 'react-bootstrap/Form';

const logo = require('../icons/icon_alert_critical.svg');

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const UpdateBatchStatusForm = (props) => {
    const [show, setShow] = useState(false);
    const [batchStatus, setBatchStatus] = useState(props.selectedRowStatus);
    const [batchId, setBatchId] = useState(props.selectedRow)
    const handleShow = () => setShow(true);

    function handleClose(){
        
        setBatchStatus(props.selectedRowStatus)
        setBatchId(props.selectedRow)
        setShow(false)
    }

    async function handleUpdate() {
        const body = {
            'status': batchStatus
        }
        HQS_API.updateBatch(batchId, body)
            .then((response) => {
                if (props.callback) {
                    props.callback();
                }
                const title = 'Batch Status Update';
                const details = 'Batch status changed successfully!';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />,
                );
            })
            .catch((error) => {
                if (props.callback) {
                    props.callback();
                }
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.message;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });
            handleClose();
    }

    function generateStatuses() {
        let formattedOptions = [
            {value: 'pending', text: 'pending'},
            {value: 'active', text: 'active'},
            {value: 'completed', text: 'completed'},
            {value: 'expired', text: 'expired'}
        ]
        return (
            <Select
                id="selectOrg"
                options={formattedOptions}
                placeholder="New Status"
                label="Batch Status"
                onChange={(text) => setBatchStatus(text)}
                reserveSpace={true}
                fluid={false}
                search={true}
                value={batchStatus}
            />
        );
    }

    return (
        <div>
            <div
                onClick={handleShow}
                style={{ display: 'flex', cursor: 'pointer' }}
            >
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="communication"
                    size="small"
                >
                    &nbsp;Update Status
                </Icon>
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
                style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        Update Batch Status
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>
                        This action will update the status of batch id:{' '}
                        <b>{props.selectedRow}</b>
                    </p>
                    <Form.Group controlId="batch-status">
                        <div style={{ 'max-height': '500px' }}>
                            {generateStatuses()}
                        </div>
                    </Form.Group>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary"  onClick={handleUpdate}>
                        Yes
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateBatchStatusForm;
