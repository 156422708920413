// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import DeleteUserForm from '../Forms/DeleteUser';
import ResendInviteForm from '../Forms/ResendInvite';
import ManageUserPermissionsForm from '../Forms/ManageUserPermisions';
import Dropdown from 'react-bootstrap/Dropdown';
import ManageUserSoftwareForm from '../Forms/ManageUserSoftware';
import UpdateUserPriorityForm from '../Forms/UpdateUserPriority';
import { isNullOrEmpty } from '../utils/helpers';

const generateOptions = (selectedRow, orgLicenses, callback) => {
    let actionsList = [];

    if (selectedRow !== undefined && selectedRow !== null) {
        const userDetails = {
            email: selectedRow[0].email,
            organization: selectedRow[0].organization,
            permissions: selectedRow[0].groups,
            software: 'software' in selectedRow[0] ? selectedRow[0].software : '',
            status: selectedRow[0]['login-status'],
            quotaEnabled: selectedRow[0]['quota-enabled'],
            quota: selectedRow[0].quota,
            priority: 'priority' in selectedRow[0] ? selectedRow[0].priority : 0,
        };

        //find the licenses for the selected user's org
        let licenses = {};
        let found = orgLicenses.filter((s) => s.name === userDetails.organization);

        if (found.length > 0) {
            licenses = found[0]['licenses'];
        }

        // always show delete
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <DeleteUserForm show_icon={true} user={userDetails} callback={callback} />
            </Dropdown.Item>,
        );

        const permissionsList = userDetails.permissions.split(',').map((item) => item.trim());

        const softwareList = userDetails.software.split(',').map((item) => item.trim());

        //  add manage permissions
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <ManageUserPermissionsForm permissions={permissionsList} user={userDetails} callback={callback} />
            </Dropdown.Item>,
        );

        //only add the ability to manage software if this org has any licenses in their plan
        if (!isNullOrEmpty(licenses)) {
            actionsList.push(
                <Dropdown.Item
                    className="hqs-custom-actions-select-option"
                    key={'promoteUserSoftware-' + userDetails.email}>
                    <ManageUserSoftwareForm
                        user={userDetails}
                        callback={callback}
                        software={softwareList}
                        licenses={licenses}
                    />
                </Dropdown.Item>,
            );
        }

        var resendInvite = '';
        if (userDetails.status !== 'confirmed') {
            resendInvite = (
                <Dropdown.Item className="hqs-custom-actions-select-option">
                    <ResendInviteForm user={userDetails} callback={callback} />
                </Dropdown.Item>
            );
            // add resend password action
            actionsList.push(resendInvite);
        }

        //  add update priority
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <UpdateUserPriorityForm user={userDetails.email} priority={userDetails.priority} callback={callback} />
            </Dropdown.Item>,
        );
    } else {
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" disabled={true}>
                <div>Select a row...</div>
            </Dropdown.Item>,
        );
    }

    return actionsList;
};

const AdminUserActionSelect = (props) => {
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className="ui small button primary hqs-custom-dropdown"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>
            &#x25bd;&nbsp;
            {children}
        </div>
    ));
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>{generateOptions(props.selectedRow, props.orgLicenses, props.callback)}</Dropdown.Menu>
        </Dropdown>
    );
};

export default AdminUserActionSelect;
