// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Notification, Modal, Input, Icon } from '@scuf/common';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';


const ChangeCustomerIdForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [customerID, setcustomerID] = useState(props.customerID);
    const [inputError, setInputError] = useState('');

    function changeCustomerID() {
        let body = {
            customer_id: customerID,
        };
        HQS_API.updateOrganization(props.org, body)
            .then((response) => {
                if (props.callBack) {
                    props.callBack();
                }

                const title = 'Customer ID Updated';
                const details = 'Customer ID has been updated successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />
                );
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            });
        handleClose();
    }

    function handleIDChange(idValue) {
        setcustomerID(idValue);
    }

    return (
        <div>
            <div id="handle-show" onClick={handleShow}>
                <div style={{ cursor: 'pointer' }} className="hqs-blue-icon">
                    update
                </div>
                &nbsp;
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal">
                <Modal.Header closeButton>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="building" name="user" size="medium"></Icon>
                        &nbsp;&nbsp;Change Customer ID
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        Please consult with your operations team before changing a
                        customer identifier.
                    </p>
                    <Form.Group controlId="formCustomerID">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Input
                                label="Customer ID"
                                onChange={handleIDChange}
                                name="customerID"
                                value={customerID}
                                error={inputError}
                                fluid={true}
                            />
                        </div>
                    </Form.Group>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={changeCustomerID}>
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ChangeCustomerIdForm;
