/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2022 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { Component } from 'react';
import { Button, Input, InputLabel, Modal, Icon, Notification } from '@scuf/common';
import { ForgotPassword } from 'aws-amplify-react';
import './CustomAuth.css';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import * as HQS_API from '../utils/api';
import { recoveryActions } from '../utils/helpers';

const logo = require('./QuantinuumLogo.svg');

class CustomForgotPassword extends Component {
    constructor(props) {
        super(props);
        this._validAuthStates = [recoveryActions.forgotPwd, recoveryActions.forgotMfa];

        this.state = {
            email: '',
            disabled: true,
            recoverAction: sessionStorage.getItem('recover-action'),
        };
        this.updateEmail = this.updateEmail.bind(this);
        this.tryEnable = this.tryEnable.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
    }

    updateEmail(value) {
        this.setState({ email: value });
        this.tryEnable(value);
    }

    tryEnable(value) {
        //verify the code is 6 digits
        let enabled = value.length > 0;
        this.setState({ disabled: !enabled });
    }

    reset() {
        //clears the state to original settings
        this.setState({ email: '', disabled: true });
    }

    backToSignIn() {
        //before we return to the sign in page, make sure we reset the state
        this.reset();
        window.location.replace('/login');
    }

    async forgotPassword() {
        const email = this.state.email.toLowerCase();

        //default action - forgetPassword
        let action = recoveryActions.forgotPwd;
        let nextState = recoveryActions.confirmPwd;

        //change the action and next state based on current auth state
        if (this.state.recoverAction == recoveryActions.forgotMfa) {
            action = recoveryActions.forgotMfa;
            nextState = recoveryActions.confirmMfa;
        }

        let body = {
            action: action,
        };

        HQS_API.recoverUserCredentials(email, body)
            .then((response) => {
                const title = 'Success!';
                const details = `Verification code sent. Please check your email.`;
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="success" />);

                sessionStorage.setItem('recover-action', nextState);
                sessionStorage.setItem('username', this.state.email.toLowerCase());
                //clear out the old state while we transition states
                this.reset();
                //update the state and passover the email as 'authData'

                window.location.replace('/recover-confirm');
            })
            .catch((error) => {
                console.log(error);
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
            });
    }

    render() {
        return (
            <div className="custom-sign-in">
                <div className="forgot-password-form">
                    <div className="form-header">
                        <img className="logo" src={logo}></img>
                    </div>
                    <div className="credentials">
                        <div className="mfa-confirm">
                            <h4>Account Recovery</h4>
                            <div className="mfa-steps">
                                <ol>
                                    <li>Enter your account's verified email address to get started.</li>
                                    {this.state.recoverAction != recoveryActions.forgotMfa ? (
                                        <li>
                                            We'll send you a temporary code which you'll need to reset your password.
                                        </li>
                                    ) : (
                                        <li>
                                            We'll send you a temporary code which you'll need to recover your account.
                                        </li>
                                    )}
                                </ol>
                            </div>
                        </div>
                        <div className="new-password">
                            <Input
                                className="input-form"
                                type="text"
                                fluid="true"
                                onChange={(value) => this.updateEmail(value)}
                                label="Email"
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className="change-password">
                            <br />

                            <Button
                                type="primary"
                                onClick={() => this.forgotPassword()}
                                content="Send Code"
                                disabled={this.state.disabled}
                            />
                            <p className="forgot-password center-text">
                                <a className="forgot-password-link" onClick={() => this.backToSignIn()}>
                                    Back to sign in?
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    closeButton={false}
                    newestOnTop={true}
                    position="bottom-right"
                    toastClassName="toast-notification-wrap"
                />
            </div>
        );
    }
}

export default CustomForgotPassword;
