// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import {
    Button,
    Checkbox,
    Icon,
    Notification,
    Modal,
} from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';

const ColumnToggleList = (props) => {
    const userFields = ['id', 'name', 'machine', 'batch-id', 'submit-date', 'start-date', 'result-date', 'cost', 'count', 'status']
    const orgFields = ['id', 'name', 'user', 'machine', 'batch-id', 'submit-date', 'start-date', 'result-date', 'count', 'cost', 'status']
    const operatorFields = ['id', 'name', 'user', 'machine', 'batch-id', 'submit-date', 'start-date', 'result-date', 'cost', 'status', 'org', 'priority', 'count', 'last-shot', 'system-family-target']
    const adminFields = ['id', 'name', 'user', 'machine', 'batch-id', 'submit-date', 'start-date', 'result-date', 'cost', 'status', 'org', 'priority', 'count', 'last-shot', 'system-family-target']

    const allowedFields = {
        user: userFields,
        org: orgFields,
        operator: operatorFields,
        admin: adminFields
    }

    const [show, setShow] = useState(false);
    const [mode, setMode] = useState(props.mode);

    const handleShow = () => setShow(true);
    const tableColumns = 'hqs-' + props.mode + '-jobs-table-columns';

    function handleClose() {
        //reset state props when closing form
        setShow(false);
    }

    function handleColumnToggle(checked, columnData) {
        let columnToggles = JSON.parse(sessionStorage.getItem(tableColumns))
        props.columns.forEach((column) => {
            if (column.dataField === columnData) {
                column.hidden = checked ? false : true;
                columnToggles[column.dataField] = column.hidden
            }
        });
        sessionStorage.setItem(tableColumns, JSON.stringify(columnToggles))
        props.onColumnToggle(columnData);
    }
    
    return (
        <div>
            <div onClick={handleShow}>
                <Icon root="common" name="adjustments" size="medium"></Icon>
            </div>
            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                style={{
                    padding: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        &nbsp;&nbsp;Select Columns To Display
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={true}>
                    <div
                        className="btn-group btn-group-toggle btn-group-vertical"
                        data-toggle="buttons"
                    >
                        {props.columns
                            .map((column) => ({
                                ...column,
                                hidden: column.hidden,
                            }))
                            .map((column) => (
                                <Checkbox
                                    label={column.text}
                                    key={column.dataField}
                                    checked={column.hidden ? false : true}
                                    onChange={(checked) =>
                                        handleColumnToggle(
                                            checked,
                                            column.dataField,
                                        )
                                    }
                                    hidden= {allowedFields[mode].indexOf(column.dataField) > -1 ? false : true}
                                />

                            ))}
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ColumnToggleList;
