// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';

import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Icon,
    InputLabel,
    Input,
    Radio,
    Notification,
    Modal,
    Select,
    Popup,
    Badge,
    Card
} from '@scuf/common';
import moment from 'moment';
import ToastNotification from '../Notifications/ToastNotification';
import { maxTagKeyLength, maxTagDescLength, maxTagValueLength, maxTagAllowedValues } from '../utils/helpers';


const ManageTagForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [oldKey, setOldKey] = useState(props.tag.key);
    const [key, setKey] = useState(props.tag.key);
    const [desc, setDesc] = useState(props.tag.description);
    const [keyError, setKeyError] = useState();
    const [hasValidKey, setValidKey] = useState(false);
    const [isDisabled, setDisabled] = useState(!(hasValidKey));
    const [allowed, setAllowed] = useState(new Set(props.tag.allowed));
    const [allowedValue, setAllowedValue] = useState('');
    const [allowedError, setAllowedError] = useState('');


    function handleKey(key) {
        //clear error if any
        setKey(key);
        setKeyError('');

        isKeyValid(key)
    }


    function handleDesc(desc) {
        //clear error if any
        setDesc(desc);
        isKeyValid(key)

    }

    function isKeyValid(key) {
        let valid = true
        setKeyError('')

        //validate the version number
        if (key !== undefined && key !== "") {
            valid = true
        } else {
            setKeyError('A key name is required');
            valid = false
        }


        setValidKey(valid)

        return valid
    }


    function clearInputs() {
        setKey('');
        setKeyError('')


    }

    function handleClose() {
        setShow(false);
    }

    function handleAllowed(value) {

        setAllowedValue(value);

    }

    function addAllowedValue() {
        if (!allowed.has(allowedValue)) {
            allowed.add(allowedValue)
            setAllowed(allowed)
            setAllowedValue("")
            setAllowedError("")
        } else {
            setAllowedError("Value already used. Please try again")

        }

    }


    function removeAllowedValue(item) {

        let newAllowed = new Set([...allowed])
        newAllowed.delete(item)

        setAllowed(newAllowed)
    }


    function generateAllowed() {
        let tagList = <div></div>
        const tags = []

        allowed.forEach((item, index) => {
            tags.push(
                <div className="tag-item">
                    {item}
                    <Button
                        type="primary"
                        className="button"
                        onClick={() => removeAllowedValue(item)}
                    >
                        X
                    </Button>

                </div>
            )
        }
        )

        tagList = <div> <p>
            <Badge color="red">WARNING!</Badge> If you remove a value below any items actively tagged with that value will be automatically removed as well.
        </p>{tags}</div>;

        return tagList

    }

    function handleKeyDown(evt) {
        if (['Enter'].includes(evt.key)) {
            evt.preventDefault();
             // make sure there is a value for this record and make sure that adding this record wouldn't push it over the maximum
            if (allowedValue !== undefined && allowedValue !== "" && allowed.size + 1 <=  maxTagAllowedValues){
                addAllowedValue()
            }
            else {
                setAllowedError("")
            }
        }
    };




    // function to validate all form fields
    function validateFormValues() {

        let validKey = isKeyValid(key)

        return validKey;
    }







    function handleUpdate() {

        if (validateFormValues()) {
            let queryParams = {
                mode: props.mode
            }

            let body = {
                'desc': desc,
                'allowed': [...allowed]
            }



            const id = props.tag.id

            HQS_API.updateTag(id, queryParams, body)
                .then(() => {
                    const title = 'Success!';
                    const details = `Updated tag: ` + key;

                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="success"
                        />
                    );
                    clearInputs();
                    if (props.callback) {
                        props.callback();
                    }
                    handleClose();
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unkown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="critical"
                        />
                    );
                });

        }
    }

    return (
        <div onKeyDown={(e => e.stopPropagation())}>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="edit"
                    size="small"></Icon>
                &nbsp;{props.children || 'Update'}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-settings-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="tag" size="medium"></Icon>
                        &nbsp;&nbsp;Update
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <div>

                        <span><p>Update attributes for key: <b>{key}</b></p></span>
                    </div>
                    <br />

                    <Form.Group controlId="formVersion">


                        <Input
                            style={{
                                width: '24rem'
                            }}
                            type="text"
                            label="Description"
                            placeholder={"Value (" + maxTagDescLength + " chars max)"}
                            name="desc"
                            onChange={handleDesc}
                            value={desc}
                            fluid={true}
                            indicator="optional"
                            title="The description of this tag"
                            maxLength={maxTagDescLength}
                        />
                    </Form.Group>
                    <Form.Group>
                        <InputLabel label="Allowed Values" indicator="optional"></InputLabel>
                        <p>Define a set of allowed values you would like users to choose from when something is being tagged. If nothing is defined, users will have the option to use freeform text instead</p>
                        {allowed.size == 0 ?
                            <p>No values are defined.</p> : ""}
                        {generateAllowed()}
                        <Input
                            style={{
                                width: '24rem'
                            }}
                            type="text"
                            placeholder={"Type a value and press 'Enter' to confirm"}
                            name="allowed"
                            onChange={handleAllowed}
                            onKeyPress={handleKeyDown}
                            value={allowedValue}
                            fluid={true}
                            title="A value allowed for this tag"
                            maxLength={maxTagValueLength}
                            error={allowedError}
                        />
                        <p>Note: You can have a maximum of <b>{maxTagAllowedValues}</b> unique values for this tag. <b>{maxTagAllowedValues - allowed.size}</b> remaining </p>

                    </Form.Group>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" size="small" onClick={handleUpdate} >
                        Update
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ManageTagForm;
