/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/
import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from '@scuf/datatable';
import { ToastContainer } from 'react-toastify';
import { Button, Select, DatePicker, Checkbox, Icon, BadgedIcon, Popup, Accordion } from '@scuf/common';
import CancelJobForm from '../Forms/CancelJob';
import CancelJobsForm from '../Forms/CancelJobs';
import PauseJobsForm from '../Forms/PauseJobs';
import ResumeJobsForm from '../Forms/ResumeJobs';
import JobErrorForm from '../Forms/JobError';
import {
    jobEndStates,
    roundTwoDecimalPlaces,
    validJson,
    isElevatedViewMode,
    isNullOrEmpty,
    allFilterSelections,
    toISODate,
    getTotalCost,
} from '../utils/helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ColumnToggleList from '../Other/ColumnToggleList';
import './JobsTab.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';

const FilterPanel = DataTable.HeaderBar.FilterPanel;

export function JobsTab(props) {
    const orgOptions = [{ value: 0, text: 'All Orgs'}];
    const orgTypeOptions = [{ value: 0, text: 'All Types', type: null },{ value: 1, text: 'Azure', type: 'azure'}, { value: 2, text: 'Internal', type: 'internal'}, { value: 3, text: 'External', type: 'external'}]
    const pageOptions = [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 200, text: '200' },
    ];
    const page = props.page;
    const keys = props.page.keys;
    const visited = props.page.visited;
    const maxPages = 5;
    const maxFilterPanelHeight = '325px';

    const END_STATUSES = ['canceled', 'completed', 'failed', 'canceling'];

    // setup session storage names
    const rangeTo = 'hqs-' + props.mode + '-jobs-table-range-to';
    const rangeFrom = 'hqs-' + props.mode + '-jobs-table-range-from';
    const tableOrg = 'hqs-' + props.mode + '-admin-jobs-table-org';
    const tableOrgType = 'hqs-' + props.mode + '-admin-jobs-table-org-type';
    const tableOrgOptions = 'hqs-' + props.mode + '-admin-jobs-table-org-options';
    const tablePageSize = 'hqs-' + props.mode + '-admin-jobs-table-page-size';
    const tablePageSort = 'hqs-' + props.mode + '-admin-jobs-table-page-sort';
    const tablePageSortBy = 'hqs-' + props.mode + '-admin-jobs-table-page-sortBy';
    const tablePage = 'hqs-' + props.mode + '-admin-jobs-table-page';
    const tableFilter = 'hqs-' + props.mode + '-jobs-table-filter-';
    const tableColumns = 'hqs-' + props.mode + '-jobs-table-columns';
    const tableFilters = 'hqs-' + props.mode + '-jobs-table-filters';

    // allowed fields for user
    const userFields = [
        'id',
        'name',
        'machine',
        'batch-id',
        'group',
        'submit-date',
        'start-date',
        'result-date',
        'cost',
        'count',
        'status',
        'last-shot',
    ];
    const orgFields = [
        'id',
        'name',
        'user',
        'machine',
        'batch-id',
        'group',
        'submit-date',
        'start-date',
        'result-date',
        'cost',
        'count',
        'status',
        'last-shot',
    ];
    const operatorFields = [
        'id',
        'name',
        'user',
        'machine',
        'batch-id',
        'group',
        'submit-date',
        'start-date',
        'result-date',
        'cost',
        'status',
        'org',
        'priority',
        'count',
        'last-shot',
    ];
    const adminFields = [
        'id',
        'name',
        'user',
        'machine',
        'batch-id',
        'group',
        'submit-date',
        'start-date',
        'result-date',
        'cost',
        'status',
        'org',
        'priority',
        'count',
        'last-shot',
    ];

    const allowedFields = {
        user: userFields,
        org: orgFields,
        operator: operatorFields,
        admin: adminFields,
    };

    // allowed fields for org

    if (!isElevatedViewMode(props.mode)) {
        sessionStorage.setItem(tableOrg, props.organizations[0]);
    }

    props.organizations.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
    props.organizations.forEach((oo, ii) => {
        orgOptions.push({ value: ii + 1, text: oo });
    });

    // Check if we have any values stored in session storage
    let toValue = sessionStorage.getItem(rangeTo) !== null ? moment(sessionStorage.getItem(rangeTo)) : moment(); // use the current date as the end date
    let fromValue = sessionStorage.getItem(rangeFrom) !== null ? moment(sessionStorage.getItem(rangeFrom)) : moment().subtract(1, 'week') // go back a week to set the start date

    let dateErrorValue = ' ';
    if (toValue !== '' && fromValue !== '') {
        dateErrorValue = '';
    }

    let selectedOrgValue = sessionStorage.getItem(tableOrg) !== null ? parseInt(sessionStorage.getItem(tableOrg)) : 0; //default to all orgs
    let selectedOrgTypeValue = sessionStorage.getItem(tableOrgType) !== null ? parseInt(sessionStorage.getItem(tableOrgType)) : 0; //default to all types

    let selectedOrgError = '';
    let selectedOrgTypeError = ''
    if (isElevatedViewMode(props.mode)) {
        selectedOrgError = selectedOrgValue < 0 ? ' ' : '';
        selectedOrgTypeError = selectedOrgTypeValue < 0 ? ' ' : '';
    }

    let defaultPage = {
        number: 1,
        size: 25,
        keys: { 1: null },
        visited: new Set([1]),
        client: false,
        reverse: true, // by default we want newest to oldest
        search: null,
    };

    let selectedPageSizeValue =
        sessionStorage.getItem(tablePageSize) !== null ? parseInt(sessionStorage.getItem(tablePageSize), 10) : 25; //default

    let selectedPageSortValue =
        sessionStorage.getItem(tablePageSort) !== null ? JSON.parse(sessionStorage.getItem(tablePageSort)) : true; //default is reverse

    let selectedPageSortByValue =
        sessionStorage.getItem(tablePageSortBy) !== null
            ? JSON.parse(sessionStorage.getItem(tablePageSortBy))
            : 'submit-date'; //default is submit-date

    let selectedPageValue =
        sessionStorage.getItem(tablePage) !== null ? JSON.parse(sessionStorage.getItem(tablePage)) : defaultPage;

    let selectedFilters =
        sessionStorage.getItem(tableFilters) !== 'undefined'
            ? JSON.parse(sessionStorage.getItem(tableFilters))
            : {
                  users: {},
                  machines: {},
                  statuses: {},
                  priorities: {},
                  orgs: {},
                  groups: {},
              };

    let orgOptionsFilteredValue =
              sessionStorage.getItem(tableOrgOptions) !== null ? JSON.parse(sessionStorage.getItem(tableOrgOptions)) : [{ value: 0, text: 'All Orgs'}];

    const [paramState, setParamState] = useState({
        to: toValue,
        from: fromValue,
        dateError: dateErrorValue,
        orgError: selectedOrgError,
        orgTypeError: selectedOrgTypeError,
        search: props.search,
        selectedOrg: selectedOrgValue,
        selectedOrgType: selectedOrgTypeValue,
        selectedPageSize: selectedPageSizeValue,
        selectedPageSort: selectedPageSortValue,
        selectedPageSortBy: selectedPageSortByValue,
        mode: props.mode,
        orgOptions,
        orgOptionsFiltered: orgOptionsFilteredValue,
        orgTypeOptions,
        pageOptions,
        page: selectedPageValue,
        filters: selectedFilters,
    });
    const fetchTimer = useRef(-1);

    //define a quick representation of the first page
    const firstPage = {
        number: 1,
        size: paramState.selectedPageSize,
        keys: { 1: null },
        visited: new Set([1]),
        client: false,
        reverse: paramState.selectedPageSort,
        search: null,
        sort: paramState.selectedPageSortBy,
    };

    // This function will check if we have any filter values
    // currently stored in session storage
    const fetchSavedFilters = (filterName) => {
        // fetch saved filters
        let savedFilters = sessionStorage.getItem(tableFilter + filterName);
        let savedFilterObject = JSON.parse(savedFilters);
        if (savedFilterObject == null) {
            savedFilterObject = {};
        }
        return savedFilterObject;
    };

    const fetchSavedSort = () => {
        // fetch saved filters
        let savedFilters = sessionStorage.getItem(tablePageSort);
        let savedFilterObject = JSON.parse(savedFilters);
        if (savedFilterObject == null) {
            savedFilterObject = true;
        }
        return savedFilterObject;
    };

    const fetchSavedSortBy = () => {
        // fetch saved filters
        let savedFilters = sessionStorage.getItem(tablePageSortBy);
        let savedFilterObject = JSON.parse(savedFilters);
        if (savedFilterObject == null) {
            //default to submit-date if we couldn't find anything
            savedFilterObject = 'submit-date';
        }
        return savedFilterObject;
    };

    const [filterState, setFilterState] = useState({
        users: fetchSavedFilters('users'),
        machines: fetchSavedFilters('machines'),
        statuses: fetchSavedFilters('statuses'),
        priorities: fetchSavedFilters('priorities'),
        orgs: fetchSavedFilters('orgs'),
        groups: fetchSavedFilters('groups'),
    });

    let selectedJobs = new Set();
    const [refreshEnabled, setRefreshEnabled] = useState(false);
    const onRangeSelect = ({ to, from }) => {
        //if they are changing the date, we should always initialize it to reverse sorting
        let newPage = firstPage;
        newPage.reverse = true;
        const newState = {
            ...paramState,
            to: to,
            from: from,
            dateError: to && from ? '' : ' ',
            page: newPage,
            selectedPageSort: true,
            selectedPageSortBy: 'submit-date',
            search: null,
        };
        setParamState(newState);
        fetchIfValid(newState);
        sessionStorage.setItem(rangeFrom, from !== undefined ? from.toISOString() : '');
        sessionStorage.setItem(rangeTo, to !== undefined ? to.toISOString() : '');
        //update the page sort
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));
    };

    const onRefresh = () => {
        //a refresh implies we are getting the latest (page 1).
        //we should make sure to keep any existing filters that have been applied instead of wiping them out
        let newPage = firstPage;
        if (jobs == undefined || jobs.length == 0) {
            //ensure default sort order when loading page for first time.
            newPage.reverse = true;
        }
        onPageChange(newPage);
    };

    const jobActionsRenderer = (cell, row) => {
        const jobId = row.id;
        const jobStatus = row.status;
        var jobErrorObject = { code: 'NA', text: '' };
        if ('error' in row) {
            if (!validJson(row.error)) {
                jobErrorObject.text = row.error;
            } else {
                jobErrorObject = JSON.parse(row.error);
            }
        }

        var cancelJob = jobEndStates.includes(jobStatus) ? '' : <CancelJobForm jobId={jobId} />;

        var jobError = jobStatus === 'failed' ? <JobErrorForm jobError={jobErrorObject} /> : '';

        return (
            <div style={{ display: 'flex' }}>
                {cancelJob}
                {jobError}
            </div>
        );
    };

    const onSearch = (search) => {
        let filters = {
            users: {},
            machines: {},
            statuses: {},
            priorities: {},
            orgs: {},
            groups: {},
        };

        let newPage = firstPage;
        newPage.reverse = true;

        const newState = {
            ...paramState,
            search: search,
            filters: filters,
            selectedOrg: '',
            selectedOrgType: '',
            orgError: selectedOrg < 0 ? ' ' : '',
            orgTypeError: selectedOrgType < 0 ? ' ' : '',
            dateError: ' ',
            page: newPage,
            from: '',
            to: '',
        };

        setParamState(newState);
        setFilterState(filters);
        fetchIfValid(newState);
    };

    const onOrgSelect = (selectedOrg) => {
        let filters = {
            users: {},
            machines: {},
            statuses: {},
            priorities: {},
            orgs: {},
            groups: {},
        };

        let newPage = firstPage;
        newPage.reverse = true;

        const newState = {
            ...paramState,
            selectedOrg,
            selectedPageSort: true,
            selectedPageSortBy: 'submit-date',
            orgError: selectedOrg < 0 ? ' ' : '',
            orgTypeError: selectedOrgType < 0 ? ' ' : '',
            page: newPage,
            filters: filters,
            search: null,
        };

        setParamState(newState);
        setFilterState(filters);
        fetchIfValid(newState);

        //update the session state
        sessionStorage.setItem(tableOrg, selectedOrg);
        sessionStorage.setItem(tablePage, JSON.stringify(newPage));
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));
    };

    const onOrgTypeSelect = (selectedOrgType) => {
        let filters = {
            users: {},
            machines: {},
            statuses: {},
            priorities: {},
            orgs: {},
            groups: {},
        };

        let newPage = firstPage;
        newPage.reverse = true;

        //find the type for with this selection
        let type =  orgTypeOptions[selectedOrgType]['type']

        //add the default all orgs, but give it a specific type since we know it refers to all orgs of this type
        let orgsWithType = [{ value: 0, text: 'All Orgs', type: type}]

        //process each org and filter out hte ones that don't match this type
        props.organizations.forEach((oo, ii) => {
            let orgType = getSelectedOrgType(oo)
        
            if (orgType == type){
                orgsWithType.push({ value: ii + 1, text: oo });
            }
                
        });
        

        
        const newState = {
            ...paramState,
            selectedOrg: 0, //clear out the selected org when changing the org type
            selectedOrgType,
            selectedPageSort: true,
            selectedPageSortBy: 'submit-date',
            orgError: '', //clear out the error since we are setting it to the all orgs
            orgTypeError: selectedOrgType < 0 ? ' ' : '',
            orgOptionsFiltered: orgsWithType,
            page: newPage,
            filters: filters,
            search: null,
        };

        setParamState(newState);
        setFilterState(filters);
        fetchIfValid(newState);


        //change the org options
        
        

        //update the session state
        sessionStorage.setItem(tableOrg, 0); //reset to zero
        sessionStorage.setItem(tableOrgType, selectedOrgType);
        sessionStorage.setItem(tablePage, JSON.stringify(newPage));
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));
        sessionStorage.setItem(tableOrgOptions, JSON.stringify(orgsWithType));
    };

    const onPageSizeSelect = (size) => {
        //if we're on the first page already and the next page doesn't point to anything, then we know we have all the data.
        //when this happens there's no need to fetch new data until the page size is smaller than the total jobs found
        //otherwise, just fetch new data
        if (page.number == 1 && page.keys[page.number + 1] == undefined && size > jobs.length) {
            const newState = {
                ...paramState,
                selectedPageSize: size,
            };

            //update the state
            setParamState(newState);

            //update the session's page size
            sessionStorage.setItem(tablePageSize, size);
        } else {
            //jump to first page to get the latest and the new page keys
            //Reset the visited lookup since there could be new data

            let newPage = firstPage;
            newPage.size = size;

            //set the reverse setting to be the current
            newPage.reverse = page.reverse;

            //fall back on client page sizing when we don't know the org
            if (selectedOrg == 0) {
                newPage.client = true;
            }

            //remember the provided job Id if it's there
            newPage.search = search;

            onPageChange(newPage);
        }
    };

    const onPagePrev = () => {
        // decrement the page number, reuse page size, get the prev page reference
        if (keys[page.number - 1] !== undefined) {
            let newPage = {
                number: page.number - 1,
                size: page.size,
                keys: keys,
                visited: page.visited,
                client: selectedOrg == 0 ? true : false,
                reverse: page.reverse,
                search: page.search,
                sort: page.sort,
            };
            onPageChange(newPage);
        }
    };

    const onPageJump = (number) => {
        if (keys[number] !== undefined) {
            let newPage = {
                number: number,
                size: page.size,
                keys: keys,
                visited: page.visited,
                client: selectedOrg == 0 ? true : false,
                search: props.search,
                sort: page.sort,
            };
            onPageChange(newPage);
        }
    };

    const onPageNext = () => {
        // increment the page number, reuse page size, get the next page reference
        if (keys[page.number + 1] !== undefined) {
            let newPage = {
                number: page.number + 1,
                size: page.size,
                keys: keys,
                visited: page.visited.add(page.number + 1),
                client: selectedOrg == 0 ? true : false,
                reverse: page.reverse,
                search: page.search,
                sort: page.sort,
            };
            onPageChange(newPage);
        }
    };

    const onFilterChange = (filters) => {
        //the filters we want to use are the ones that are active
        const newFilters = {
            users: filters.users,
            machines: filters.machines,
            statuses: filters.statuses,
            priorities: filters.priorities,
            orgs: filters.orgs,
            groups: filters.groups,
        };

        setFilterState(filters);

        //if all orgs, then we need to filter on the client side
        let page = firstPage;
        if (selectedOrg == 0) {
            page.client = true;
        }

        //remember the current page sorting when we apply the filter
        page.reverse = selectedPageSort;

        //remember the provided job Id if it's there
        page.search = search;

        //remember the sort by field
        page.sort = selectedPageSortBy;

        onPageChange(page, newFilters);
    };

    const onPageChange = (page, filters) => {
        //if the filters were not passed in, then fall back to the current filterstate
        //this happens when we're paging next,previous,jumps
        if (filters == undefined || filters == null) {
            filters = filterState;
        }

        const newState = {
            ...paramState,
            selectedPageSize: page.size,
            selectedPageSort: page.reverse,
            selectedPageSortBy: page.sort,
            page: page,
            filters: filters,
            search: page.search,
        };

        //update the state
        setParamState(newState);

        fetchIfValid(newState, 0);

        //update the session information. page size is separate from the overall page dict to not have a delay when updating dropdown
        sessionStorage.setItem(tablePageSize, page.size);
        sessionStorage.setItem(tablePage, JSON.stringify(page));
        sessionStorage.setItem(tableFilters, JSON.stringify(filters));
        sessionStorage.setItem(tablePageSort, JSON.stringify(page.reverse));
        sessionStorage.setItem(tablePageSortBy, JSON.stringify(page.sort));
    };

    //show or hide the next button
    const isPageNextDisabled = keys != undefined && keys[page.number + 1] == undefined;

    //show or hide the prev button
    const isPagePrevDisabled = page.number <= 1;

    const fetchIfValid = (newState, timeOut = 1000) => {
        //set variables for dates
        //searching by job ID will ignore dates (which would be seen as error) unless we handle that
        let from = '';
        let to = '';
        if (newState.search == null || newState.search == '') {
            if (newState.dateError || newState.orgError) {
                return;
            }
            from = newState.from.toISOString();
            to = newState.to.toISOString();
        }
        if (fetchTimer.current) window.clearTimeout(fetchTimer.current);

        //default
        let org = props.organizations[newState.selectedOrg - 1]

        let type = null
        if (newState.selectedOrgType > 0 && 'type' in orgTypeOptions[newState.selectedOrgType]){
        
            type = orgTypeOptions[newState.selectedOrgType]['type']
        }
 
        fetchTimer.current = window.setTimeout(
            props.onChange,
            timeOut,
            org,
            from,
            to,
            newState.page,
            newState.filters,
            newState.search,
            type
        );

        // enable refresh button
        setRefreshEnabled(true);
    };

    useEffect(() => {

        //automatically fetch the data with the defaults for the selected mode

        //define the state of the request (need to provide some defaults like sorting to ensure consistency with current behavior)
        const newState = {
            ...paramState,
            selectedPageSize: page.size,
            selectedPageSort: page.reverse,
            selectedPageSortBy: page.sort,
            page: page
        };

        //update the state
        setParamState(newState);

        //defining fetchIfValid after we defined it above
        //this fires only once when the user clicks on the tab and navigates back to the tab
        fetchIfValid(newState, 0);
    
    }, []);

    const countFilterKey = (filterName) => {
        let count = 0;
        if (filterState.hasOwnProperty(filterName)) {
            let filters = filterState[filterName];
            if (filters !== undefined && Object.keys(filters).length != 0) {
                Object.keys(filters).forEach((s) => {
                    if (!filters[s]) {
                        count++;
                    }
                });
            }
        }
        return count;
    };

    const countFilters = () => {
        let status = countFilterKey('statuses');
        let machine = countFilterKey('machines');
        let priority = countFilterKey('priorities');
        let user = countFilterKey('users');
        let org = countFilterKey('orgs');
        let group = countFilterKey('groups');

        return status + machine + priority + user + org + group;
    };

    const resetFilters = () => {
        const newState = {
            users: {},
            machines: {},
            statuses: {},
            priorities: {},
            orgs: {},
            groups: {},
        };

        Object.keys(filterState).forEach((filterName) => {
            sessionStorage.setItem(tableFilter + filterName, JSON.stringify({}));
        });

        //go back to page 1
        onFilterChange(newState);
    };

    /**
     * Updates a object (initially empty) and returns a new object.
     * @param {Object} oldSet
     * @param {string} value
     * @param {boolean} active
     * @returns {Object}
     */
    const updateFilterSet = (oldSet, value, active, filterName, allSelection = false) => {
        // check if "All <filter-name>"" selection was done
        if (allSelection) {
            if (active) {
                oldSet = {};
            } else {
                Object.entries(props.filters[filterName]).forEach(([key, value]) => {
                    oldSet[key] = false;
                });
            }
        } else {
            oldSet[value] = active;
            if (!active) {
                oldSet[allFilterSelections[filterName]] = false;
            }
        }

        // update session storage value
        sessionStorage.setItem(tableFilter + filterName, JSON.stringify(oldSet));

        return oldSet;
    };

    const boolRenderer = (cell) => {
        const data = cell;
        if (data !== undefined) {
            return data;
        } else {
            //default to showing false
            return false;
        }
    };

    const dateRenderer = (cell, row) => {
        const data = cell;
        var createDate = '';

        if (data) {
            createDate = toISODate(data, 'detailed');
        }
        return <span>{createDate}</span>;
    };

    const costRenderer = (cell, row) => {
        const data = cell;
        var jobCost = 0;

        if (data) {
            jobCost = roundTwoDecimalPlaces(data);
        }

        return <span>{jobCost}</span>;
    };

    const statusRenderer = (cell) => {
        var jobStatus = cell;
        if (jobStatus && jobStatus == 'pending' && !isElevatedViewMode(props.mode)) {
            jobStatus = 'queued';
        }
        return <span>{jobStatus}</span>;
    };

    const batchIdRenderer = (cell, row) => {
        let batchId = cell;

        if ('user-batch-id' in row) {
            batchId = row['user-batch-id'];
        }

        const data = batchId;
        return <span className="ellipsis-field">{data}</span>;
    };

    const resultDateRenderer = (cell, row) => {
        let resultDate = cell;

        if ('result-date' in row) {
            resultDate = row['result-date'];
        } else {
            //show blank
            resultDate = '';
        }

        if (resultDate) {
            resultDate = toISODate(resultDate, 'detailed');
        }

        const data = resultDate;
        return <span className="ellipsis-field">{data}</span>;
    };

    const customCaret = (order, column) => {
        //get current sorting
        let fetchedSort = fetchSavedSort();

        // get the current sort by field
        let fetchedSortBy = fetchSavedSortBy();

        // For sorting by 'submit-date' we need to default to sorting by asc first since the results initially come back in desc order
        if (fetchedSort) {
            order = 'desc';
        } else {
            order = 'asc';
        }

        // check if the column we're processing matches the sort by field
        let activeSort = undefined;
        if (column && 'dataField' in column && fetchedSortBy == column['dataField']) {
            // update the value with the current column
            activeSort = column['dataField'];
        }

        // if the current column we're rendering matches the sort that was clicked and we found job data then set this column as active
        let isActiveSortColumn = activeSort !== undefined && activeSort == fetchedSortBy;

        // build the appropriate sort caret based on if the column we're rendering is actively sorted
        let sorted = <span className={isActiveSortColumn ? 'caret caret-active' : 'caret'}></span>;

        //define the 'desc' elements
        let activeDesc = <span className="dropup">{sorted}</span>;

        //define the 'asc' elements
        let activeAsc = <span className="dropdown">{sorted}</span>;

        //show the up and down arrows with their default (inactive) coloring
        let inactiveSort = (
            <span className="order">
                <span className="dropup">
                    <span className="caret"></span>
                </span>
                <span className="dropdown">
                    <span className="caret"></span>
                </span>
            </span>
        );

        if (!order)
            //if no order, return the default inactive sort
            return inactiveSort;
        else if (order === 'asc') {
            // show the active sort on this column in ascending order
            if (isActiveSortColumn) {
                return activeAsc;
            } else {
                //if the current column we're rendering doesn't match the applied sort, revert this to the default inactive sort so it's clear this is not be used
                return inactiveSort;
            }
        } else if (order === 'desc') {
            // show the active sort on this column in descending order
            if (isActiveSortColumn) {
                return activeDesc;
            } else {
                //if the current column we're rendering doesn't match the applied sort, revert this to the default inactive sort so it's clear this is not be used
                return inactiveSort;
            }
        }
        return null;
    };

    const onTableChange = (type, newState) => {
        //handle remote sorting
        if (type == 'sort') {
            if (newState.sortField == 'submit-date' || newState.sortField == 'result-date') {
                onSortBy(newState.sortField);
            }
        }
    };

    const onSortBy = (sort) => {
        //sort by submit date
        let newPage = firstPage;
        let reverse = !page.reverse;

        //if we were sorting on new field than the one we've previously sorted on, make sure we start by ordering in desc (newest)
        if (page.sort != sort) {
            reverse = true;
        }

        newPage.reverse = reverse; //!page.reverse
        //check if we need to enable client paging/sorting
        if (selectedOrg == 0) {
            newPage.client = true;
        }

        //hold on to the search criteria if it was defined
        if (search !== null) {
            newPage.search = search;
        }

        if (sort) {
            newPage.sort = sort;
        }

        //store the sort setting
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));

        // set this if we want to remember the sort by on page refresh
        sessionStorage.setItem(tablePageSortBy, JSON.stringify(newPage.sort));
        onPageChange(newPage);
    };

    const {
        from,
        to,
        orgError,
        orgTypeError,
        orgOptionsFiltered,
        selectedOrg,
        selectedOrgType,
        dateError,
        mode,
        selectedPageSize,
        selectedPageSort,
        selectedPageSortBy,
        search,
    } = paramState;

    const getFilterProps = (filterName) => {
        //helper function to return the current filters from the passed in props.
        if (props.filters !== undefined && props.filters.hasOwnProperty(filterName)) {
            return props.filters[filterName];
        } else {
            return {};
        }
    };

    const getActive = (filters, key) => {
        //determines the state of the checked/unchecked toggles

        //if no filters are selected (default)
        if (isNullOrEmpty(filters)) {
            return true;
        } else {
            // if some filters are selected, return the 'checked' status stored in the filters object
            if (filters[key] !== undefined) {
                return filters[key];
            } else {
                //otherwise show 'checked' since we haven't 'disabled/excluded' them from overall filters
                return true;
            }
        }
    };

    const getOrgType = (orgName) => {

        let type = ""
        
        if(orgName != 'All Orgs'){
            if (orgName == 'Azure'){
                type = 'azure'
            }
            else if(props.orgTypes[orgName]){
                type = props.orgTypes[orgName]
            }

        }

        return type
    }

    
    const hasOrgType = () =>{
        //check if there's an org type and it's not null (meaning not all orgs)
        if (selectedOrgType > 0){
            return true
        }else{
            return false
        }
    }

    const getSelectedOrgType = (orgName) =>{
        
        if (orgName == 'All Orgs'){
            if(selectedOrgType > 0){
                return orgTypeOptions[selectedOrgType]['type']
            }
        
        }
            
        return getOrgType(orgName)

    }


    // create filter options from the passed in filters.
    // we no longer want to create the filters from the passed in jobs
    // because as soon as we apply a filter, then we lose other filterable options
    // wasn't a problem until now since we're passing in the additional filters into the API request

    const statuses = getFilterProps('statuses');
    const priorities = getFilterProps('priorities');
    const machines = getFilterProps('machines');
    const users = getFilterProps('users');
    const orgs = getFilterProps('orgs');
    const groups = getFilterProps('groups');
    const allFilters = props.filters;

    // MACHINES
    const machineToggles = [];
    Object.keys(machines).forEach((mm) => {
        machineToggles.push({
            label: mm,
            active: getActive(filterState.machines, mm),
            all: mm == 'All Machines' ? true : false,
        });
    });
    // Push the 'select all' option to the very top
    const allMachinesIndex = machineToggles.findIndex((item) => item.label == 'All Orgs');
    machineToggles.push(...machineToggles.splice(0, allMachinesIndex));

    // USERS
    const userToggles = [];
    Object.keys(users).forEach((uu) => {
        userToggles.push({
            label: uu,
            active: getActive(filterState.users, uu),
            all: uu == 'All Users' ? true : false,
        });
    });
    // Push the 'select all' option to the very top
    const allUsersIndex = userToggles.findIndex((item) => item.label == 'All Users');
    userToggles.push(...userToggles.splice(0, allUsersIndex));

    // STATUSES
    const statusToggles = [];
    Object.keys(statuses).forEach((ss) => {
        statusToggles.push({
            label: ss,
            active: getActive(filterState.statuses, ss),
            all: ss == 'All Statuses' ? true : false,
        });
    });
    // Push the 'select all' option to the very top
    const allStatusIndex = statusToggles.findIndex((item) => item.label == 'All Statuses');
    statusToggles.push(...statusToggles.splice(0, allStatusIndex));

    // PRIORITIES
    const priorityToggles = [];
    Object.keys(priorities).forEach((pp) => {
        priorityToggles.push({
            label: pp,
            active: getActive(filterState.priorities, pp),
            all: pp == 'All Priorities' ? true : false,
        });
    });
    // Push the 'select all' option to the very top
    const allPriorityIndex = priorityToggles.findIndex((item) => item.label == 'All Priorities');
    priorityToggles.push(...priorityToggles.splice(0, allPriorityIndex));

    // ORGS
    const orgToggles = [];
    Object.keys(orgs).forEach((oo) => {
        //if 'all orgs' is selected, make sure the org filter has all orgs
        if(selectedOrgType == 0 ){
            orgToggles.push({
                label: oo,
                active: getActive(filterState.orgs, oo),
                all: oo == 'All Orgs' ? true : false
            });
        }else{
        
            if (selectedOrgType >=0 && (getSelectedOrgType(oo) == orgTypeOptions[selectedOrgType]['type'])){
              
                orgToggles.push({
                    label: oo,
                    active: getActive(filterState.orgs, oo),
                    all: oo == 'All Orgs' ? true : false,
                    type: getSelectedOrgType(oo)
                });
            }
        }
         
    });

     //if selecting all orgs, but all orgs isn't in list anymore
     const hasOrgIndex = orgToggles.findIndex((item) => item.label == 'All Orgs');
     if(selectedOrg == 0 && hasOrgIndex < 0){
       
        let name = 'All Orgs'
        //add it now so it's not missed
        orgToggles.push({
            label: name,
            active: getActive(filterState.orgs, name),
            all: true,
            type: getSelectedOrgType(name)
        });
     
    }

    const allOrgIndex = orgToggles.findIndex((item) => item.label == 'All Orgs');
    orgToggles.push(...orgToggles.splice(0, allOrgIndex));

    // GROUP
    const groupToggles = [];
    Object.keys(groups).forEach((gg) => {
        //exclude the 'undefined' or 'none' case since we're adding that as separate toggle
        if (gg !== 'undefined' && gg !== 'none') {
            groupToggles.push({
                label: gg,
                active: getActive(filterState.groups, gg),
                all: gg == 'All Groups' ? true : false,
            });
        }
    });
    
    // Push the 'select all' option to the very top
    const allGroupIndex = groupToggles.findIndex((item) => item.label == 'All Groups');
    groupToggles.push(...groupToggles.splice(0, allGroupIndex));

    const SearchBar = (props) => {
        const isEmpty = (value) => {
            return value == null || value.trim().length == 0;
        };
        const [search, setSearch] = useState(props.search);
        const [disabled, setDisabled] = useState(isEmpty(props.search));

        const onSearchEnter = (event) => {
            setSearch(event.target.value);
            setDisabled(isEmpty(event.target.value));
        };
        const handleSearch = () => {
            if (!disabled) {
                onSearch(search.trim());
            }
        };

        const onEnterKey = (e) => {
            //it triggers by pressing the enter key
            if (e.key === 'Enter') {
                handleSearch();
            }
        };

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <input
                    style={{
                        minWidth: '120px',
                        width: '320px',
                    }}
                    className="form-control"
                    type="text"
                    value={search}
                    onChange={onSearchEnter}
                    onKeyPress={onEnterKey}
                    placeholder="Search by Job ID or Name"
                />
                <Button
                    minWidth={120}
                    type="primary"
                    size="small"
                    icon={<Icon name="search" root="common" />}
                    content="search"
                    iconPosition="right"
                    disabled={disabled}
                    title="Please note: The search is case sensitive"
                    onClick={handleSearch}
                />
            </div>
        );
    };

    const tableOptions = {
        custom: true,
        sizePerPage: selectedPageSize,
        hidePageListOnlyOnePage: true,
        hideSizePerPage: true,
    };

    const JobsColumns = [
        {
            dataField: 'status',
            text: 'STATUS',
            hidden: allowedFields[mode].indexOf('status') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
            formatter: statusRenderer,
        },
        {
            dataField: 'id',
            text: 'JOB ID',
            hidden: allowedFields[mode].indexOf('id') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                width: '60px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'name',
            text: 'NAME',
            hidden: allowedFields[mode].indexOf('name') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'machine',
            text: 'MACHINE',
            hidden: allowedFields[mode].indexOf('machine') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'org',
            text: 'ORGANIZATION NAME',
            hidden: allowedFields[mode].indexOf('org') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'user',
            text: 'USER',
            hidden: allowedFields[mode].indexOf('user') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'group',
            text: 'GROUP',
            hidden: allowedFields[mode].indexOf('group') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                width: '60px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'priority',
            text: 'PRIORITY',
            hidden: allowedFields[mode].indexOf('priority') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'batch-id',
            text: 'BATCH ID',
            formatter: batchIdRenderer,
            hidden: allowedFields[mode].indexOf('batch-id') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                width: '60px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'submit-date',
            text: 'SUBMIT DATE',
            formatter: dateRenderer,
            hidden: allowedFields[mode].indexOf('submit-date') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'start-date',
            text: 'START DATE',
            formatter: dateRenderer,
            hidden: allowedFields[mode].indexOf('start-date') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'result-date',
            text: 'RESULT DATE',
            formatter: resultDateRenderer,
            hidden: allowedFields[mode].indexOf('result-date') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'count',
            text: 'SHOTS',
            hidden: allowedFields[mode].indexOf('count') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'last-shot',
            text: 'SHOTS COMPLETED',
            hidden: allowedFields[mode].indexOf('last-shot') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'cost',
            text: 'COST',
            formatter: costRenderer,
            hidden: allowedFields[mode].indexOf('cost') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: '',
            text: 'ACTIONS',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            formatter: jobActionsRenderer,
        },
    ];
    const [columns, setColumns] = useState([...JobsColumns]);

    const jobs = props.jobs;

    let completedJobs = props.jobs.filter((job) => END_STATUSES.includes(job.status)).map((job) => job.id);

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        nonSelectable: completedJobs,
        selected: [],
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                selectedJobs.add(row.id);
            } else if (!isSelect) {
                selectedJobs.delete(row.id);
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                rows.forEach((row) => selectedJobs.add(row.id));
            } else {
                rows.forEach((row) => selectedJobs.delete(row.id));
            }
        },
    };

    const isSelectNoneEnabled = (filterName) => {
        let filters = {};
        if (filterState.hasOwnProperty(filterName)) {
            filters = filterState[filterName];
        } else {
            return false;
        }

        let enabled = false;
        if (filters == {} || Object.keys(filters).length === 0) {
            enabled = true;
        }
        //if we haven't determined yet, dig deeper
        if (!enabled) {
            //get the enabled state using the 'none' key
            enabled = filterState[filterName]['none'];
        }

        return enabled;
    };

    const getISODate = (date, replace = false) => {
        return replace ? date.toISOString().split('T')[0].replace(/-/g, '') : date.toISOString().split('T')[0];
    };

    const fetchJobsReport = async () => {
        if (paramState.dateError) {
            missingDateToast();
            return;
        }

        if (paramState.orgError) {
            missingOrgToast();
            return;
        }

        let startTemp = moment(paramState.from).startOf('day');
        let endTemp = moment(paramState.to).endOf('day');
        //assign the date range
        let start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        let end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');

        //set the status to include
        let status = 'completed,canceled';

        //determine if it's alll orgs or a specific org
        let org = null;
        if (props.organizations.length == 1) org = props.organizations[0];
        else {
            //have to adjust for the 'all orgs' which is index 0 in admin/operator mode
            //minus 2 because we have 2 default values (all orgs and azure)
            org = props.organizations[selectedOrg - (orgOptions.length -1)];
            
        }

        // set the filename
        let filename = `job_usage_report_${getISODate(new Date(), true)}.csv`;

        return props.fetchJobsReport(filename, start, end, status, org);
    };

    const missingDateToast = () => {
        const title = 'Unable to generate a report';
        const details = 'Please select a valid date range';
        toast(<ToastNotification closeToast={false} title={title} details={details} severity="information" />);
    };

    const missingOrgToast = () => {
        const title = 'Unable to generate a report';
        const details = 'Please select an org';
        toast(<ToastNotification closeToast={false} title={title} details={details} severity="information" />);
    };

    const generateFilters = () => {
        return (
            <div>
                <Button type="link" onClick={resetFilters} content="Reset all filters" />
                <Accordion style={{ width: '285px' }}>
                    <Accordion.Content title="Machines">
                        <div style={{ maxHeight: maxFilterPanelHeight, overflow: 'auto' }}>
                            {machineToggles.map((mm) => (
                                <FilterPanel.ToggleItem
                                    key={mm.label}
                                    {...mm}
                                    type="checkbox"
                                    checked={mm.active}
                                    onChange={(active) => {
                                        onFilterChange({
                                            ...filterState,
                                            machines: updateFilterSet(
                                                filterState.machines,
                                                mm.label,
                                                active,
                                                'machines',
                                                mm.all,
                                            ),
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Content>
                    {/* Only display the orgs filters if we're in operator/admin mode and we are searching on some sort of 'all orgs'*/}
                    <Accordion.Content
                        title="Orgs"
                        style={{ display: isElevatedViewMode(props.mode) && orgToggles.length > 1  ? '' : 'none' }}>
                        <div style={{ maxHeight: maxFilterPanelHeight, overflow: 'auto' }}>
                            {orgToggles.map((oo) => (
                                <FilterPanel.ToggleItem
                                    key={oo.label}
                                    {...oo}
                                    type="checkbox"
                                    onChange={(active) => {
                                        onFilterChange({
                                            ...filterState,
                                            orgs: updateFilterSet(filterState.orgs, oo.label, active, 'orgs', oo.all),
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Content>
                    <Accordion.Content
                        title="Priorities"
                        style={{ display: isElevatedViewMode(props.mode) ? '' : 'none' }}>
                        <div style={{ maxHeight: maxFilterPanelHeight, overflow: 'auto' }}>
                            {priorityToggles.map((pp) => (
                                <FilterPanel.ToggleItem
                                    key={pp.label}
                                    {...pp}
                                    type="checkbox"
                                    onChange={(active) => {
                                        onFilterChange({
                                            ...filterState,
                                            priorities: updateFilterSet(
                                                filterState.priorities,
                                                pp.label,
                                                active,
                                                'priorities',
                                                pp.all,
                                            ),
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Content>
                    <Accordion.Content title="Statuses">
                        <div style={{ maxHeight: maxFilterPanelHeight, overflow: 'auto' }}>
                            {statusToggles.map((ss) => (
                                <FilterPanel.ToggleItem
                                    key={ss.label}
                                    {...ss}
                                    type="checkbox"
                                    onChange={(active) => {
                                        onFilterChange({
                                            ...filterState,
                                            statuses: updateFilterSet(
                                                filterState.statuses,
                                                ss.label,
                                                active,
                                                'statuses',
                                                ss.all,
                                            ),
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Content>
                    <Accordion.Content title="Users" style={{ display: mode !== 'user' ? '' : 'none' }}>
                        <div style={{ maxHeight: maxFilterPanelHeight, overflow: 'auto' }}>
                            {userToggles.map((uu) => (
                                <FilterPanel.ToggleItem
                                    key={uu.label}
                                    {...uu}
                                    type="checkbox"
                                    onChange={(active) => {
                                        onFilterChange({
                                            ...filterState,
                                            users: updateFilterSet(
                                                filterState.users,
                                                uu.label,
                                                active,
                                                'users',
                                                uu.all,
                                            ),
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Content>
                    <Accordion.Content title="Groups">
                        <div style={{ maxHeight: maxFilterPanelHeight, overflow: 'auto' }}>
                            {groupToggles.map((gg) => (
                                <FilterPanel.ToggleItem
                                    key={gg.label}
                                    {...gg}
                                    type="checkbox"
                                    onChange={(active) => {
                                        onFilterChange({
                                            ...filterState,
                                            groups: updateFilterSet(
                                                filterState.groups,
                                                gg.label,
                                                active,
                                                'groups',
                                                gg.all,
                                            ),
                                        });
                                    }}
                                />
                            ))}
                            <FilterPanel.ToggleItem
                                active={isSelectNoneEnabled('groups')}
                                label="None"
                                type="checkbox"
                                onChange={(active) => {
                                    onFilterChange({
                                        ...filterState,
                                        groups: updateFilterSet(filterState.groups, 'none', active, 'groups'),
                                    });
                                }}
                            />
                        </div>
                    </Accordion.Content>
                </Accordion>
            </div>
        );
    };

    const loadColumnToggles = (toggleProps) => {
        // initialze column toggles in session if not already there
        let columnToggles = {};
        if (sessionStorage.getItem(tableColumns) == undefined) {
            sessionStorage.setItem(tableColumns, JSON.stringify(columnToggles));
        } else {
            columnToggles = JSON.parse(sessionStorage.getItem(tableColumns));
        }

        toggleProps.columns.forEach((column) => {
            if (columnToggles[column.dataField] !== undefined) {
                column.hidden = columnToggles[column.dataField];
            }
        });
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div style={{ border: '1px solid #d0d0d0', height: '690px' }}>
            <ToolkitProvider keyField="id" data={jobs} columns={columns} columnToggle search>
                {(toolkitprops) => (
                    <div>
                        <div class="d-flex align-content-start flex-wrap justify-content-between">
                            <div
                                class="p-6"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '5px 0px 0px 5px',
                                }}>
                                {/*search bar doesn't work since we don't have all the data*/}
                                <div>
                                    <SearchBar
                                        className="hqs-custom-font"
                                        {...toolkitprops.searchProps}
                                        search={paramState.search}
                                    />
                                </div>

                                {
                                    /* we won't know the length unless we scan the entire database. will need to figure out what we want to do with this*/
                                    <div style={{ padding: '10px 0px 0px 10px' }}>
                                        {' '}
                                        {jobs.length + ' Total'}
                                        <span title="The total HQC for the current page">
                                            {' (' + getTotalCost(jobs) + ' HQCs)'}
                                        </span>
                                    </div>
                                }
                                
                                    {jobs.length > 0 ? (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '0px 0px 0px 0px',
                                        }}>
                                            <div style={{ padding: '10px 10px 0px 20px' }}>{'Showing'}</div>
                                            <div style={{
                                                        minWidth: '120px',
                                                        maxWidth: '120px',
                                                        
                                                    }}>
                                                            <Select   
                                                    style={{
                                                        minWidth: '120px',
                                                        maxWidth: '120px',
                                                        marginRight: '60px'
                                                    }}
                                                                placeholder="select page size"
                                                                options={pageOptions}
                                                                value={selectedPageSize}
                                                                onChange={onPageSizeSelect}
                                                            />
                                            </div>
                                            <div style={{ padding: '10px 0px 10px 10px' }}>
                                                {selectedPageSize > 0 ? 'items per page' : ''}
                                                </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                        </div>
                           
                            <div
                                id="hqs-admin-jobs-table-options"
                                className="p-6"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    padding: '5px 0px 0px 5px',
                                }}>
                                <div className="hqs-custom-table-option">
                                    <Popup
                                        element={
                                            <BadgedIcon
                                                root="common"
                                                name="filter"
                                                size="medium"
                                                badge={countFilters().toString()}
                                                color="blue"
                                            />
                                        }
                                        on="click"
                                        position="bottom right"
                                        hoverable={false}>
                                        {generateFilters()}
                                    </Popup>
                                </div>
                                <div className="hqs-custom-table-option">
                                    {loadColumnToggles(toolkitprops.columnToggleProps)}
                                    <ColumnToggleList
                                        {...toolkitprops.columnToggleProps}
                                        mode={mode}
                                        allowedColumns={allowedFields}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: isElevatedViewMode(props.mode) ? '' : 'none',
                                        minHeight: '0px',
                                    }}>
                                    <ResumeJobsForm jobs={selectedJobs} callback={onRefresh} />
                                </div>
                                <div
                                    style={{
                                        display: isElevatedViewMode(props.mode) ? '' : 'none',
                                        minHeight: '0px',
                                    }}>
                                    <PauseJobsForm jobs={selectedJobs} callback={onRefresh} />
                                </div>
                                <CancelJobsForm jobs={selectedJobs} callback={onRefresh} />
                                <div
                                    onClick={onRefresh}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '6px 0px 0px 5px',
                                    }}>
                                    <Icon root="building" name="refresh" size="large" loading={props.fetchingJobs} />
                                </div>

                                <div style={{ paddingLeft: '15px' }} className="hqs-table-dropdown-option">
                                    <DatePicker
                                        error={dateError}
                                        type="daterange"
                                        placeholder="select date range"
                                        rangeValue={{ from, to }}
                                        closeOnSelection={true}
                                        closeOnDocumentClick={true}
                                        disableFuture={true}
                                        onRangeSelect={onRangeSelect}
                                        onTextChange={(value, error) =>
                                            setParamState({
                                                ...paramState,
                                                dateError: error,
                                            })
                                        }
                                    />
                                </div>
                                <div
                                    style={{ display: isElevatedViewMode(props.mode) ? '' : 'none', minHeight: '0px' }}
                                    className="hqs-table-dropdown-option">
                                    <Select
                                        key={selectedOrgType}
                                        error={orgError}
                                        placeholder="select org"
                                        options={selectedOrgType == 0? orgOptions: orgOptionsFiltered}
                                        onChange={onOrgSelect}
                                        value={selectedOrg}
                                        search={true}
                                    />
                                </div>
                                <div
                                    style={{ display: isElevatedViewMode(props.mode) ? '' : 'none', minHeight: '0px'}}
                                    className="hqs-table-dropdown-option">
                                    <Select style={{
                                            minWidth: '120px',
                                            maxWidth: '120px',
                                            marginLeft: '60px'
                                        }}
                                        error={orgTypeError}
                                        placeholder="select org"
                                        options={orgTypeOptions}
                                        onChange={onOrgTypeSelect}
                                        value={selectedOrgType}
                                        search={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hqs-admin-jobs-data">
                            <BootstrapTable
                                remote={{ sort: true }} // need to set to allow backend paging
                                noDataIndication="No records found"
                                striped
                                hover
                                headerStyle={{ background: 'red' }}
                                height="515px"
                                rowClasses="hqs-custom-table-row"
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                selectRow={selectRow}
                                onTableChange={onTableChange}
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>

            {jobs.length > 0 ? (
                <div className="hqs-custom-table-pagination">
                    <Button
                        type="primary"
                        size="small"
                        icon={<Icon name="caret-left" root="common" />}
                        content="prev"
                        iconPosition="left"
                        disabled={isPagePrevDisabled}
                        onClick={onPagePrev}
                    />
                    <div style={{ fontWeight: 'bold' }}>{page.number}</div>
                    <Button
                        type="primary"
                        size="small"
                        icon={<Icon name="caret-right" root="common" />}
                        content="next"
                        iconPosition="right"
                        disabled={isPageNextDisabled}
                        onClick={onPageNext}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    );

    return (
        <div className="hqs-admin-view">
            <div className="hqs-admin-jobs-card">
                <div id="hqs-admin-jobs-wrapper">
                    <PaginationProvider pagination={paginationFactory(tableOptions)}>{contentTable}</PaginationProvider>
                </div>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    closeButton={false}
                    newestOnTop={true}
                    position="bottom-right"
                    toastClassName="toast-notification-wrap"
                />
            </div>
        </div>
    );
}
