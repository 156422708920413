/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020 - 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/
/* eslint no-restricted-globals:0 */

import React, { Component, useRef } from 'react';
import * as HQS_API from '../utils/api';
import moment from 'moment';
import CreateOrgForm from '../Forms/CreateOrganization';
import CreateUserForm from '../Forms/CreateUser';
import ManageAnyPlanForm from '../Forms/ManageAnyPlan';
import DeleteOrgForm from '../Forms/DeleteOrg';
import OrgAccessForm from '../Forms/OrgAccess';
import SetOrgQuotaForm from '../Forms/SetOrgQuota';
import AdminUserActionSelect from '../Other/AdminUserActionSelect';
import AdminMachineActionSelect from '../Other/AdminMachineActionSelect';
import PlanActionSelect from '../Other/PlanActionSelect';
import { ErrorCodes } from '../config';
import Papa from 'papaparse';
import {
    getFriendlyPermission,
    roundTwoDecimalPlaces,
    isNullOrEmpty,
    updateJobQueryParams,
    updateAuditQueryParams,
    generateFilters,
    generateAuditFilters,
    filterJobs,
    getFilterableOrgUsers,
    getFilterableOrgMachines,
    getFilterableUserGroups,
    getFilterableMachines,
    sortFiltersByKeys,
    updateSearchParams,
    isMachineFamily,
    toISODate,
    isLicensedPlan,
    softwareMap,
} from '../utils/helpers';
import { getRefreshSchedule, getDate, renderDate } from '../utils/anyPlan';
import { DataTable } from '@scuf/datatable';
import { Button, Select, Tab, Icon } from '@scuf/common';
import DeleteUserForm from '../Forms/DeleteUser';
import DeleteOrgPlanForm from '../Forms/DeleteOrgPlan';
import CalendarTab from '../Event/CalendarTab';
import CustomerID from '../PlanType/CustomerID';
import PendingCredits from '../PlanType/PendingCredits';
import OrgHqcQuota from '../PlanType/OrgHqcQuota';

import './HQSAdmin.css';
import { ToastContainer } from 'react-toastify';

import '../../main.scss'; // webpack must be configured to do this
import { JobsTab } from '../Jobs/JobsTab';
import { BatchRequestsTab } from './BatchRequestsTab';
import { FQTable } from './FQTable';
import { GenerateUsageReportForm } from '../Forms/GenerateUsageReport';
import ReportsTab from '../Reports/ReportsTab';
import GroupsTab from '../Groups/GroupsTab';
import DisableOrgQuotaForm from '../Forms/DisableOrgQuota';
import NextFQReset from './NextFQReset';
import Cron from 'cron-converter';
import SettingsTab from '../Settings/SettingsTab';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import OrgBatchMaxHQC from '../PlanType/OrgBatchMaxHQC';
import OrgDiagnostic from '../PlanType/OrgDiagnostic';
import OrgType from '../PlanType/OrgType';
import PlanTag from '../PlanType/PlanTag';
import { AuditTab } from '../Audit/AuditTab';

class HQSAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: [],
            selectedOrg: '',
            selectedOrgDetails: {},
            selectedOrgAdmins: [],
            selectedOrgUsers: [],
            selectedOrgPlans: [],
            selectedOrgAvailableHqc: 0.0,
            selectedOrgLicenses: {},
            selectedEntity: '',
            planExpandedRows: [],
            allMachines: [],
            allMachineRecords: [],
            allTags: [],
            machineNames: [],
            activeMachine: '',
            enabledMachines: [],
            allUsers: [],
            orgNames: [],
            orgMachines: [],
            orgPlans: [],
            orgUserGroups: [],
            orgLicenses: [],
            jobs: [],
            reports: [],
            hasBatchingPlan: false,
            hasPlanWithCredits: false,
            batchRequests: [],
            activeJobs: [],
            jobsSortInfo: { sortField: 'submit-date', sortOrder: -1 },
            batchRequestsSortInfo: { sortField: 'submit-date', sortOrder: -1 },
            logs: [],
            fetchingJobs: false,
            fetchingReports: false,
            fetchingBatchRequests: false,
            fetchingMachines: false,
            fetchingJobsReport: false,
            fetchingAuditLogs: false,
            fetchingPlans: false,
            fqMachineName: '',
            fqMachineError: ' ',
            fqOrgs: [],
            fetchingFQOrgs: false,
            nextFQReset: '',
            mode: props.mode,
            userRecordSelectedRow: null,
            machineRecordSelectedRow: null,
            planSelectedRow: null,
            selectedUser: '',
            selectedPlanId: '',
            page: {
                size: 25,
                number: 1,
                keys: { 1: null },
                visited: new Set([1]),
                client: false,
                reverse: true,
                search: null,
                sort: 'submit-date',
            },
            pageJobs: [],
            filters: {
                machines: { 'All Machines': true },
                statuses: { 'All Statuses': true },
                priorities: { 'All Priorities': true },
                users: { 'All Users': true },
                orgs: { 'All Orgs': true },
                groups: { 'All Groups': true },
            },
            auditActions: [],
            auditPage: {
                size: 25,
                number: 1,
                keys: { 1: null },
                visited: new Set([1]),
                client: false,
                reverse: true,
                sort: 'create-date',
            },
            auditFilters: {
                actions: { 'All Actions': true },
                requestors: { 'All Requestors': true },
            },
            search: null,
        };
        this.blobRef = React.createRef();
        this.updatedOrgs = this.updatedOrgs.bind(this);
        this.updatedUsers = this.updatedUsers.bind(this);
        this.updateAfterOrgDelete = this.updateAfterOrgDelete.bind(this);
        this.updateAfterUserDelete = this.updateAfterUserDelete.bind(this);
        this.updateAfterAdminDelete = this.updateAfterAdminDelete.bind(this);
        this.updateAfterUserCreate = this.updateAfterUserCreate.bind(this);
        this.updateAfterPlanChange = this.updateAfterPlanChange.bind(this);
        this.updateAfterSettingsChange = this.updateAfterSettingsChange.bind(this);
        this.fetchJobs = this.fetchJobs.bind(this);
        this.fetchReports = this.fetchReports.bind(this);
        this.fetchBatchRequests = this.fetchBatchRequests.bind(this);
        this.adminDeleteRenderer = this.adminDeleteRenderer.bind(this);
        this.statusRenderer = this.statusRenderer.bind(this);
        this.enableRenderer = this.enableRenderer.bind(this);
        this.planActionsRenderer = this.planActionsRenderer.bind(this);
        this.getAllMachines = this.getAllMachines.bind(this);
        this.renderQuotaActions = this.renderQuotaActions.bind(this);
        this.accumulatedHqcRenderer = this.accumulatedHqcRenderer.bind(this);
        this.genMachineOptions = this.genMachineOptions.bind(this);
        this.setMachineError = this.setMachineError.bind(this);
        this.fetchFQOrgs = this.fetchFQOrgs.bind(this);
        this.generateFQResetTime = this.generateFQResetTime.bind(this);
        this.fetchActiveJobs = this.fetchActiveJobs.bind(this);
        this.generateTabs = this.generateTabs.bind(this);
        this.handleUserRowSelect = this.handleUserRowSelect.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.filterAndSortJobs = this.filterAndSortJobs.bind(this);
        this.handlePlanRowSelect = this.handlePlanRowSelect.bind(this);
        this.getOrgMachines = this.getOrgMachines.bind(this);
        this.getSelectedOrgMachines = this.getSelectedOrgMachines.bind(this);
        this.getSelectedOrgUsers = this.getSelectedOrgUsers.bind(this);
        this.getOrgPlanType = this.getOrgPlanType.bind(this);
        this.generateActiveJobsMachines = this.generateActiveJobsMachines.bind(this);
        this.costRenderer = this.costRenderer.bind(this);
        this.dateRenderer = this.dateRenderer.bind(this);
        this.shortDateRenderer = this.shortDateRenderer.bind(this);
        this.ellipsisRenderer = this.ellipsisRenderer.bind(this);
        this.softwareRenderer = this.softwareRenderer.bind(this);
        this.fetchJobsReport = this.fetchJobsReport.bind(this);
        this.planExpanderTemplate = this.planExpanderTemplate.bind(this);
        this.planExpansionTemplate = this.planExpansionTemplate.bind(this);
        this.fetchAuditLogs = this.fetchAuditLogs.bind(this);
        this.fetchAuditActions = this.fetchAuditActions.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.downloadUsers = this.downloadUsers.bind(this);
        this.refreshPlans = this.refreshPlans.bind(this);
    }

    componentDidMount() {
        // TODO: might move these out of this hook and call them only when needed
        // via Tab onTabChange
        this.getOrganizations();
        this.getMachines();
        this.getUsers();
        this.getAllMachines();
        this.generateFQResetTime();
        this.fetchActiveJobs();
        this.getAllTags();
        this.fetchReports();
        this.fetchAuditActions();
    }

    updatedOrgs() {
        //refresh the licenses first since that's a quick call
        this.getOrgLicenses();
        this.getOrganizations();
    }

    updatedUsers(organization) {
        // A better way is to get the created user's org and only update that one
        if (this.state.expandedOrganizations.has(organization)) {
            this.getOrganizationDetails(organization);
        }
    }

    async fetchJobsReport(filename, start, end, status, org) {
        this.setState({ fetchingJobsReport: true });

        let queryParams = {
            start: start,
            end: end,
            status: status,
        };

        //add the org if we know the specific org
        if (org) {
            queryParams['org'] = org;
        }

        HQS_API.jobReporting(queryParams)
            .then((response) => {
                if (typeof this.blobRef.current === 'object') {
                    const blob = new Blob([response], { type: 'text/csv' });
                    if (blob.size > 2) {
                        const url = URL.createObjectURL(blob);
                        this.blobRef.current.href = url;
                        this.blobRef.current.download = filename;
                        this.blobRef.current.click();
                        URL.revokeObjectURL(url);

                        const title = 'Successfully generated report';
                        const details = filename;
                        toast(<ToastNotification closeToast={false} title={title} details={details} />);
                    } else {
                        const title = 'Unable to generate report';
                        const details = 'No records found';
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="information"
                            />,
                        );
                    }
                }
            })
            .catch((error) => {
                const title = 'Unable to generate report';
                const details = 'Access denied';
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
            })
            .finally(() => {
                this.setState({ fetchingJobsReport: true });
            });
    }

    genMachineOptions(excludeFamilies) {
        let machineList = [];
        this.state.allMachines.forEach((machine) => {
            let machineName = machine.name.toLowerCase();
            if (!machineName.includes('apival')) {
                //exclude the family machines from the result list
                if (excludeFamilies !== undefined && excludeFamilies == true && isMachineFamily(machine.name)) {
                    //skip this machine, move onto the next
                } else {
                    machineList.push({ value: machine.name, text: machine.name });
                }
            }
        });

        return (
            <div style={{ display: 'flex' }}>
                <Select
                    options={machineList}
                    onChange={(text) => this.handleMachine(text)}
                    search={true}
                    key="plan"
                    placeholder="Select Machine"
                    indicator="required"
                    value={this.state.fqMachineName}
                    error={this.state.fqMachineError}
                />
                <div
                    onClick={this.fetchFQOrgs}
                    style={{
                        cursor: 'pointer',
                        padding: '6px 0px 0px 5px',
                    }}>
                    <Icon root="building" name="refresh" size="large" loading={this.state.fetchingFQOrgs} />
                </div>
            </div>
        );
    }

    /**
     * Handles machine change via select element
     */
    handleMachine(machine) {
        this.setMachineError('');
        this.setState({ fqMachineName: machine }, this.fetchFQOrgs);
    }

    /**
     *
     * Handles machine select element errors
     */
    setMachineError(machineError) {
        this.setState({ fqMachineError: machineError });
    }

    /**
     * This helper functions generates a time object
     * from a cron string
     */
    generateFQResetTime() {
        let offset = moment().utcOffset();
        let cronInstance = new Cron();
        cronInstance.fromString('0 */2 * * *');
        let schedule = cronInstance.schedule(moment.utc());
        let fqNextReset = toISODate(schedule.next());
        this.setState({ fqNextReset: fqNextReset });
    }

    filterAndSortJobs(jobs, page, filters) {
        //apply any saved filters (like when we refresh the page and the user has already previously selected information)
        jobs = filterJobs(jobs, filters);

        //remember the sort that was applied if they clicked refresh
        let sortOrder = page.reverse == false ? 1 : -1; // descending by default
        jobs = this.sortJobs(jobs, { sortField: 'submit-date', sortOrder: sortOrder });

        return jobs;
    }

    onPageChange(jobs, page, filters) {
        //apply any filters to our jobs data before updating the page information
        jobs = this.filterAndSortJobs(jobs, page, filters);

        //handle client side pagination since it's expensive to scan/page for the all orgs case
        if (page !== undefined) {
            //define default values
            let pageJobs = [];
            let start = 0;
            let end = page.size;
            //if we're going to page 1, then just get the chunk of data. No need to do additional work
            if (page.number > 1) {
                //get the starting position of the page of data
                //start = page.keys[page.number]
                start = (page.number - 1) * page.size;

                //get the ending position of the page of data
                end = start + page.size;
            }

            //get the page of jobs
            pageJobs = jobs.slice(start, end);

            //update our page keys with pointer to next page
            let pageNext = jobs[end] !== undefined ? end : undefined;

            page.keys[page.number + 1] = pageNext;

            //don't update the original "jobs", we need to leave that intact for future paging
            this.setState({
                pageJobs: pageJobs,
                page: page,
            });
        }
    }

    /**
     * Async function to fetch orgs with plan details filtered by machine
     */
    async fetchFQOrgs() {
        if (this.state.fqMachineName !== '') {
            this.generateFQResetTime();
            this.setState({ fetchingFQOrgs: true });
            HQS_API.getOrganizationsPlansByMachine(this.state.fqMachineName)
                .then((response) => {
                    const tempOrgs = [];
                    response.forEach((org) => {
                        var newOrg = {
                            name: org.org_name,
                            customerId: org.customer_id,
                            plan: 'plans' in org ? org.plans[0] : [],
                            hqc_accumulation: org.hqc_accumulation,
                            available_hqc: org.available_hqc,
                        };
                        tempOrgs.push(newOrg);
                    });
                    tempOrgs.sort((a, b) => {
                        return b.plan.accumulated_hqc - a.plan.accumulated_hqc;
                    });
                    this.setState({ fqOrgs: tempOrgs });
                    this.setState({ fetchingFQOrgs: false });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    async getOrganizations() {
        HQS_API.getOrganizations()
            .then((response) => {
                const orgs = [];
                const orgNames = [];
                const orgPlans = [];
                const orgUserGroups = [];
                const orgLicenses = [];
                const orgTypes = {};
                const offset = moment().utcOffset();
                response.forEach((org) => {
                    if (['external', 'internal', 'myqos'].includes(org.type)) {
                        var newOrg = {
                            name: org.org_name,
                            created: 'create_date' in org ? org['create_date'] : '',
                            customerId: org.customer_id,
                            plans: 'plans' in org ? org.plans : [],
                            enabled: 'enabled' in org ? org['enabled'] : false,
                            user_groups: 'user-groups' in org ? org['user-groups'] : [],
                            id: org.id,
                        };
                        orgs.push(newOrg);
                    }
                    orgNames.push(org.org_name);
                    if ('type' in org) {
                        orgTypes[org.org_name] = org.type;
                    }
                    //we need to build up the list of org plans so we can select a non-native org (azure) and filter on it
                    if ('plans' in org) {
                        var newOrgPlan = {
                            name: org.org_name,
                            plans: 'plans' in org ? org.plans : [],
                        };
                        orgPlans.push(newOrgPlan);
                    }

                    if ('user-groups' in org) {
                        var newOrgUserGroup = {
                            name: org.org_name,
                            user_groups: 'user-groups' in org ? org['user-groups'] : [],
                        };
                        orgUserGroups.push(newOrgUserGroup);
                    }

                    if ('licenses' in org) {
                        var newOrgLicense = {
                            name: org.org_name,
                            licenses: 'licenses' in org ? org['licenses'] : [],
                        };
                        orgLicenses.push(newOrgLicense);
                    }
                });
                orgs.sort((a, b) => a.name.localeCompare(b.name));
                const orgMachines = this.getOrgMachines(orgs);

                this.setState({
                    organizations: orgs,
                    orgNames: orgNames,
                    orgMachines: orgMachines,
                    orgPlans: orgPlans,
                    orgUserGroups: orgUserGroups,
                    orgLicenses: orgLicenses,
                    orgTypes: orgTypes,
                });
                // get selected org plan type
                if (this.state.selectedOrg !== '') {
                    this.getOrgPlanType(this.state.selectedOrg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getOrgMachines(orgs) {
        let machines = [];
        //build up the list of unique machines assigned to each orgs plans
        if (orgs !== undefined) {
            orgs.forEach((o) => {
                o.plans.forEach((p) => {
                    machines = machines.concat(Object.keys(p.machine_name));
                });
            });
            //make the list of machines unique by converting them to a set
            machines = new Set(machines);
            //conver the set back into a list
            machines = [...machines];
            //sort the list
            machines.sort((a, b) => a.localeCompare(b));
        }

        return machines;
    }

    getSelectedOrgMachines(org) {
        let machines = [];
        let currentOrg = this.state.orgPlans.filter(function (e) {
            return e.name == org;
        });
        if (currentOrg !== undefined && currentOrg[0] !== undefined) {
            machines = getFilterableOrgMachines(currentOrg[0].plans, this.state.allMachines);
        }
        return machines;
    }

    getSelectedOrgUsers(org) {
        let users = [];
        let orgUsers = this.state.allUsers.filter(function (e) {
            return e.organization == org;
        });
        if (orgUsers !== undefined) {
            users = getFilterableOrgUsers(orgUsers);
        }
        return users;
    }

    getSelectedOrgUserGroups(org) {
        let groups = [];

        let orgGroups = this.state.orgUserGroups.filter(function (e) {
            return e.name == org;
        });

        if (orgGroups !== undefined && orgGroups.length > 0) {
            let userGroups = orgGroups[0].user_groups;
            groups = getFilterableUserGroups(userGroups);
        }

        return groups;
    }

    getSelectedOrgLicenses(org) {
        let licenses = [];

        let orgLicenses = this.state.orgLicenses.filter(function (e) {
            return e.name == org;
        });

        if (orgLicenses !== undefined && orgLicenses.length > 0) {
            licenses = orgLicenses[0]['licenses'];
        }

        return licenses;
    }

    async fetchJobs(org, start, end, page, filters, search, type) {
        this.setState({ fetchingJobs: true });

        let startTemp = moment(start).startOf('day');
        let endTemp = moment(end).endOf('day');
        start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');

        let queryParams = {
            mode: this.state.mode,
            start,
            end,
        };

        //ensure we either have the default page or the passed in page information
        if (page == undefined) {
            page = this.state.page;
        }

        let header_key = undefined;
        //if we know the org let's fetch the paged information. Otherwise, we have to perform a full scan and page on client side
        if (org) {
            queryParams.org = org;
        } else {
            // if we don't know the org, then check if we're filtering on a specific type of org (like azure)
            if (type) {
                queryParams.type = type;
            }
        }

        if (page.size > 0) {
            queryParams.limit = page.size;
        }
        //add key to parameters if we know what it is. If we don't pass a key it will default to the first page
        if (page.number > 1 && page.keys !== undefined) {
            //update the header page key
            header_key = page.keys[page.number];
        }

        //if search was passed in, then prepare the request
        if (search !== null && search != '') {
            // remove the org. The job Id there searching for could be for any org.
            let exclude = ['org', 'start', 'end'];
            // update the query params with the search criteria
            queryParams = updateSearchParams(queryParams, search, exclude);

            //assign the page the job Id we searched on
            page.search = search;
        }

        //check if we at least something to filter on and we've already queried jobs once
        //don't bother adding these additional filters to the "all orgs" case since we have to scan everything anyways
        if (!isNullOrEmpty(filters)) {
            //optionally add other filters selected after initially query
            queryParams = updateJobQueryParams(queryParams, filters);
        }

        //check if we need to fetch data in ascending order
        if (page.reverse == false) {
            queryParams.reverse = page.reverse;
        }

        // check how we're sorting this data
        if (page.sort) {
            queryParams.sort = page.sort;
        }

        /*} else {
            //we're searching on all orgs in this case
            //if we've specified that we need to page on the client and we already have data, then update the data on the frontend
            //the only time we don't want to page client in the all orgs case is when we need to refresh our data

            if (page.client && this.state.jobs.length > 0) {
                //if have data already, then let's filter and page on it
                if (this.state.jobs.length > 0) {
                    return this.onPageChange(this.state.jobs, page, filters)
                }
            }
        }
        */

        HQS_API.listJobs(queryParams, header_key)
            .then((response) => {
                let jobs = response['items'];

                //asume we don't have a pointer to the next page
                let pageNext = undefined;
                //assign the next page reference
                if (response['key'] !== undefined) {
                    pageNext = response['key'];
                }

                //update our page keys with pointer to next page
                page.keys[page.number + 1] = pageNext;

                //keep track of where we've been
                if (page.visited && !page.visited.has(page.number)) {
                    page.visited.add(page.number);
                }

                //hold on to the current state of the filters.
                let currentFilters = this.state.filters;
                //if the incoming filters are empty (first API call, refresh, filter reset) or we're still on the first page (meaning we aren't paging to subsequent pages)                //then regenerate
                //then regenerate
                if (isNullOrEmpty(filters) || page.number == 1) {
                    //assume we need a blank filter
                    let startingFilters = null;
                    //don't know the org (all orgs) or if we know the org and it's the same as the currently searched on org, reuse the filters
                    if (!org || (org && this.state.selectedOrg == org)) {
                        startingFilters = this.state.filters;
                    }

                    currentFilters = generateFilters(jobs, startingFilters, this.state.mode);

                    //if the user selected all orgs, let's give them the ability to filter on all orgs
                    if (!org) {
                        //if I don't do this, the selected filter gets moved to the top of the list, suggesting the item was removed from filter
                        this.state.orgNames.forEach((org) => {
                            currentFilters.orgs[org] = true;
                        });
                        //clean up the machines in case we're switching from  all orgs to specific org, or between orgs
                        getFilterableMachines(this.state.orgMachines, this.state.allMachines).forEach((m) => {
                            currentFilters.machines[m] = true;
                        });

                        this.state.allUsers.forEach((u) => {
                            //only add users that belong to an organization
                            if (u.organization) {
                                currentFilters.users[u.email] = true;
                            }
                        });

                        this.state.orgUserGroups.forEach((g) => {
                            //only add users that belong to an organization
                            if (g.user_groups) {
                                g.user_groups.forEach((g) => {
                                    currentFilters.groups[g] = true;
                                });
                            }
                        });
                    } else {
                        //otherwise let's just expose the org that they selected
                        currentFilters.orgs[org] = true;

                        //dynamically set the machines based on what this org has access to.
                        //will fill in gaps the first page won't have
                        this.getSelectedOrgMachines(org).forEach((m) => {
                            currentFilters.machines[m] = true;
                        });

                        this.getSelectedOrgUsers(org).forEach((u) => {
                            currentFilters.users[u] = true;
                        });

                        this.getSelectedOrgUserGroups(org).forEach((g) => {
                            currentFilters.groups[g] = true;
                        });
                    }

                    //sort the filters contents in case it isn't sorted already
                    currentFilters = sortFiltersByKeys(currentFilters);
                }

                this.setState({
                    jobs: jobs,
                    page: page,
                    filters: currentFilters,
                    selectedOrg: org ? org : '',
                });
            })
            .catch((error) => {
                console.log(error);
                // if there's an actual error code, display that message
                if (error.hasOwnProperty('code')) {
                    //catch any other error and display the message to user
                    const title = error.code;
                    const details = error.message;
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                } else {
                    //it's a timeout
                    const title = 'Search timed out';
                    const details = 'Please narrow down your search criteria and try again.';
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                }
            })
            .finally(() => {
                this.setState({ fetchingJobs: false, search: search });
            });

        this.setState({ fetchingJobs: true });
    }

    sortJobs(jobs, sortData) {
        if (!sortData) return;
        const { sortField, sortOrder } = sortData;
        return jobs.sort((a, b) => {
            a = a[sortField]?.toString() || '';
            b = b[sortField]?.toString() || '';
            return a.localeCompare(b) * sortOrder;
        });
    }

    fetchReports() {
        this.setState({
            fetchingReports: true,
        });
        HQS_API.lisAdminReports()
            .then((response) => {
                response.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });
                // format report text before passing to ReportsTab
                response.forEach(function (report) {
                    // format report type
                    let report_type = report['report_type'];
                    if (report_type === 'single') {
                        report['report_type'] = 'Single Day';
                    } else if (report_type === 'multi') {
                        report['report_type'] = 'Multiple Day';
                    }

                    // format record type
                    let record_type = report['record_type'];
                    if (record_type === 'org') {
                        report['record_type'] = 'organization';
                    }

                    // format date
                    let date = report['date'];
                    report['date'] = date.split(' ')[0];
                });
                this.setState({
                    reports: response,
                    fetchingReports: false,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingJobs: false });
            });
    }

    fetchActiveJobs(machine) {
        //check if the user is  selecting a machine or not
        if (machine == undefined) {
            // if not, meaning we're loading the page for the first time, check if we know the last machine they selected
            let selectedMachine = sessionStorage.getItem('hqs-' + this.state.mode + '-active-jobs-table-machine');

            // if we found a machine, let's assign the machine name
            if (selectedMachine) {
                machine = selectedMachine;
            }
        }
        let queryParams = {
            mode: this.state.mode,
            start: '2021-01-01T06:00:00',
            status: 'running,queued',
        };
        if (machine) {
            if (machine !== 'All Machines') {
                queryParams.machine = machine;
            }
            this.setState({ activeMachine: machine });
        }
        HQS_API.listJobs(queryParams)
            .then((response) => {
                this.setState({
                    activeJobs: this.sortJobs(response['items'], this.state.jobsSortInfo),
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingJobs: false });

                // remember the selected machine for next time
                if (machine) {
                    sessionStorage.setItem('hqs-' + this.state.mode + '-active-jobs-table-machine', machine);
                }
            });
        this.setState({ fetchingJobs: true });
    }

    async fetchBatchRequests(org, start, end) {
        let startTemp = moment(start).startOf('day');
        let endTemp = moment(end).endOf('day');
        start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        let queryParams = {
            mode: this.state.mode,
            start,
            end,
        };
        if (org) queryParams.org = org;
        HQS_API.listBatchRequests(queryParams)
            .then((response) => {
                this.setState({
                    batchRequests: this.sortJobs(response, this.state.batchRequestsSortInfo),
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingBatchRequests: false });
            });
        this.setState({ fetchingBatchRequests: true });
    }

    async getMachines() {
        HQS_API.listMachines()
            .then((response) => {
                let allMachines = [];
                let machineNames = [];
                response.forEach((machineName) => {
                    let machine = {
                        name: machineName,
                        description: machineName,
                        enabled: false,
                    };
                    let machineSelectOption = {
                        value: machineName,
                        text: machineName,
                    };
                    allMachines.push(machine);
                    machineNames.push(machineSelectOption);
                });
                allMachines.sort((a, b) => a.name.localeCompare(b.name));
                this.setState({ allMachines: allMachines, machineNames: machineNames });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getOrganizationDetails(organization) {
        const filters = {
            organization: organization,
            org_admin: true,
        };

        this.setState({
            selectedOrg: organization,
        });

        // get selected org details
        this.getOrgPlanType(organization);
        HQS_API.getUsers(filters)
            .then((response) => {
                let newUsers = [];
                const offset = moment().utcOffset();
                response.forEach((user) => {
                    let newUser = {
                        email: user['email'],
                        'create-date': 'create-date' in user ? user['create-date'] : '',
                    };
                    newUsers.push(newUser);
                });
                newUsers.sort((a, b) => a.email.localeCompare(b.email));
                this.setState({
                    selectedOrgAdmins: newUsers,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getOrgLicenses() {
        HQS_API.getOrganizations()
            .then((response) => {
                const orgLicenses = [];
                response.forEach((org) => {
                    if ('licenses' in org) {
                        var newOrgLicense = {
                            name: org.org_name,
                            licenses: 'licenses' in org ? org['licenses'] : [],
                        };
                        orgLicenses.push(newOrgLicense);
                    }
                });

                this.setState({
                    orgLicenses: orgLicenses,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    deleteOrganization(org) {
        HQS_API.deleteOrganization(org)
            .then((response) => {
                alert(`Deleted Organization ${org}`);
                this.setState({ selectedOrg: '' });
                this.getOrganizations();
            })
            .catch((error) => {
                error = error.response.data.error;
                if (error.code === ErrorCodes.OrganizationDoesNotExist) {
                    alert(error.text);
                }
                if (error.code === ErrorCodes.OrganizationContainsUsers) {
                    alert(error.text);
                }
            });
    }

    expandOrganization(org) {
        let expandedOrganizations = this.state.expandedOrganizations;
        if (expandedOrganizations.has(org)) {
            expandedOrganizations.delete(org);
        } else {
            this.getOrganizationDetails(org);
            expandedOrganizations.add(org);
        }
        this.setState({
            expandedOrganizations: expandedOrganizations,
        });
    }

    deleteUser(user) {
        HQS_API.deleteUser(user['email'])
            .then((_) => {
                alert(`Deleted User ${user['email']}`);
                this.getOrganizationDetails(user['organization']);
            })
            .catch((error) => {
                error = error.response.data.error;
                if (error.code === ErrorCodes.UserDoesNotExist) {
                    alert(error.text);
                }
                if (error.code === ErrorCodes.CannotDeleteSelf) {
                    alert(error.text);
                }
            });
    }

    updateAccess(org, machine, isAdd) {
        let action_machine;
        if (isAdd) {
            action_machine = { add: [machine] };
        } else {
            action_machine = { delete: [machine] };
        }
        HQS_API.updateOrgMachineAccess(org, action_machine)
            .then((_) => {
                this.getOrganizationDetails(org);
            })
            .catch((error) => {
                error = error.response.data.error;
                if (error.code === ErrorCodes.UserDoesNotExist) {
                    alert(error.text);
                }
                if (error.code === ErrorCodes.CannotDeleteSelf) {
                    alert(error.text);
                }
            });
    }

    async fetchAuditLogs(entity, action, requestor, start, end, page, filters) {
        this.setState({ fetchingAuditLogs: true });

        let startTemp = moment(start).startOf('day');
        let endTemp = moment(end).endOf('day');
        start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');

        let queryParams = {
            start,
            end,
        };

        //ensure we either have the default page or the passed in page information
        if (page == undefined) {
            page = this.state.page;
        }

        let header_key = undefined;
        //if we know the org let's fetch the paged information. Otherwise, we have to perform a full scan and page on client side
        if (entity) {
            queryParams.entity = entity;
        }

        //optional
        if (action) {
            queryParams.action = action;
        }

        //requestor
        if (requestor) {
            queryParams.requestor = requestor;
        }

        if (page.size > 0) {
            queryParams.limit = page.size;
        }
        //add key to parameters if we know what it is. If we don't pass a key it will default to the first page
        if (page.number > 1 && page.keys !== undefined) {
            //update the header page key
            header_key = page.keys[page.number];
        }

        //check if we at least something to filter on and we've already queried jobs once
        if (!isNullOrEmpty(filters)) {
            //optionally add other filters selected after initially query
            queryParams = updateAuditQueryParams(queryParams, filters);
        }

        //check if we need to fetch data in ascending order
        if (page.reverse == false) {
            queryParams.reverse = page.reverse;
        }

        HQS_API.listAuditLogs(queryParams, header_key)
            .then((response) => {
                let logs = response['items'];

                //asume we don't have a pointer to the next page
                let pageNext = undefined;
                //assign the next page reference
                if (response['key'] !== undefined) {
                    pageNext = response['key'];
                }

                //update our page keys with pointer to next page
                page.keys[page.number + 1] = pageNext;

                //keep track of where we've been
                if (page.visited && !page.visited.has(page.number)) {
                    page.visited.add(page.number);
                }

                //hold on to the current state of the filters.
                let currentFilters = this.state.auditFilters;
                //if the incoming filters are empty (first API call, refresh, filter reset) or we're still on the first page (meaning we aren't paging to subsequent pages)                //then regenerate
                //then regenerate
                if (isNullOrEmpty(filters) || page.number == 1) {
                    //assume we need a blank filter
                    let startingFilters = this.state.auditFilters;

                    if (entity && entity !== this.state.selectedEntity) {
                        startingFilters = {
                            actions: { 'All Actions': true },
                            requestors: { 'All Requestors': true },
                        };
                    }

                    //make sure we only build these filters if we know the audit actions have been populated
                    //otherwise, it'll fail
                    if (this.state.auditActions.length > 0 && startingFilters) {
                        let actions = this.state.auditActions[entity];

                        actions.forEach((aa) => {
                            startingFilters.actions[aa] = true;
                        });
                    }

                    currentFilters = generateAuditFilters(logs, startingFilters, this.state.mode);

                    this.state.allUsers.forEach((u) => {
                        //only add users that belong to an organization
                        if (u.organization) {
                            currentFilters.requestors[u.email] = true;
                        }
                    });
                }

                this.setState({
                    logs: logs,
                    auditPage: page,
                    auditFilters: currentFilters,
                    selectedEntity: entity,
                });
            })
            .catch((error) => {
                console.log(error);
                // if there's an actual error code, display that message
                if (error.hasOwnProperty('code')) {
                    //catch any other error and display the message to user
                    const title = error.code;
                    const details = error.message;
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                } else {
                    //it's a timeout
                    const title = 'Unable to fetch results';
                    const details = 'Please try again';
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                }
            })
            .finally(() => {
                this.setState({ fetchingAuditLogs: false });

                //if we haven't yet received the audit actions, let's make sure to fetch them now
                if (this.state.auditActions.length == 0) {
                    this.fetchAuditActions();
                }
            });
    }

    async fetchAuditActions() {
        // fetch a list of audit ctions so we can dynamically build the filters

        let queryParams = {};

        HQS_API.listAuditActions(queryParams)
            .then((response) => {
                let actions = response;

                this.setState({ auditActions: actions });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    statusRenderer(cellData) {
        const orgDetails = {
            name: cellData.rowData.name,
            enabled: cellData.rowData.enabled,
        };
        if (this.state.mode === 'admin') {
            return <OrgAccessForm org={orgDetails} callback={this.updatedOrgs} />;
        }

        return '';
    }

    dateRenderer = (cell, row) => {
        const data = cell.value;
        var date = '';

        if (data) {
            date = toISODate(data, 'detailed');
        }
        return <span>{date}</span>;
    };

    shortDateRenderer = (cell, row) => {
        const data = cell.value;
        var date = '';

        if (data) {
            date = toISODate(data, 'short');
        }
        return <span>{date}</span>;
    };

    adminDeleteRenderer(cellData) {
        const userDetails = {
            email: cellData.rowData.email,
        };
        if (this.state.mode === 'admin') {
            return (
                <div>
                    <DeleteUserForm show_icon={true} user={userDetails} callback={this.updateAfterAdminDelete} />
                </div>
            );
        }
        return '';
    }

    orgDeleteRenderer() {
        if (this.state.selectedOrg && this.state.selectedOrg.length !== 0) {
            return <DeleteOrgForm org={this.selectedOrg} callback={this.updateAfterOrgDelete} />;
        }
    }

    planActionsRenderer(cellData) {
        if (this.state.selectedOrg && this.state.selectedOrg.length !== 0) {
            if (this.state.mode === 'admin') {
                return (
                    <DeleteOrgPlanForm
                        org={this.state.selectedOrg}
                        id={cellData.value}
                        callback={this.updateAfterPlanChange}
                    />
                );
            }
            return '';
        }
    }

    machinesRenderer(cellData) {
        const data = cellData.value;
        return <span>{Object.keys(data).join(', ')}</span>;
    }

    softwareRenderer(cellData) {
        const data = cellData.value;

        if (isLicensedPlan(data)) {
            return <span>{softwareMap[data]}</span>;
        } else {
            return <span>{data}</span>;
        }
    }

    accumulatedHqcRenderer(cellData) {
        const data = cellData.value;
        var accumulatedHqc = 0;
        if (data) {
            accumulatedHqc = roundTwoDecimalPlaces(data);
        }
        return <span>{accumulatedHqc}</span>;
    }

    fqMultiplierRenderer(cellData) {
        const data = cellData.value;
        const row = cellData['rowData'];
        var multiplier = 0;
        let title = '';
        if (data) {
            multiplier = roundTwoDecimalPlaces(data);

            title += 'Active: ' + multiplier + '\n';

            let default_multiplier = '';
            let extended_multiplier = '';
            if ('raw' in row && 'fair-queuing' in row['raw'] && 'multipliers' in row['raw']['fair-queuing']) {
                let fq = row['raw']['fair-queuing']['multipliers'];
                if ('default' in fq && fq['default'] != undefined && fq['default'] !== '') {
                    default_multiplier = roundTwoDecimalPlaces(fq['default']);
                    title += 'Default: ' + default_multiplier + '\n';
                }

                if ('extended' in fq && fq['extended'] != undefined && fq['extended'] !== '') {
                    extended_multiplier = roundTwoDecimalPlaces(fq['extended']);
                    title += 'Extended: ' + extended_multiplier;
                }
            }
        }
        return <span title={title}>{multiplier}</span>;
    }

    availableHqcRenderer(cellData) {
        const row = cellData['rowData'];

        var credits = 'NA';
        var availableCredits = 0;

        if ('unlimited' in row && row['unlimited']) {
            credits = 'Unlimited';
        } else {
            if ('available_hqc' in row && row['available_hqc'] !== 'NA') {
                availableCredits = row['available_hqc'];
            }
            credits = roundTwoDecimalPlaces(availableCredits);
        }

        return <span>{credits}</span>;
    }

    pendingHqcRenderer(cellData) {
        const row = cellData['rowData'];
        var credits = 0;

        if ('pending_hqc' in row && row['pending_hqc'] !== 'NA') {
            credits = row['pending_hqc'];
        }

        return <span>{credits}</span>;
    }

    monthlyHqcRenderer(cellData) {
        const row = cellData['rowData'];
        var credits = 'NA';
        var monthlyCredits = 0;
        let title = '';
        if ('refresh_enabled' in row && row['refresh_enabled']) {
            // if refresh schedule is defined
            if ('monthly_hqc' in row && row['monthly_hqc'] !== 'NA') {
                monthlyCredits = row['monthly_hqc'];
            }
            credits = roundTwoDecimalPlaces(monthlyCredits);

            let date = '';
            if ('raw' in row && 'dates' in row['raw']) {
                let dates = row['raw']['dates'];

                if (
                    'refresh-start-date' in dates &&
                    dates['refresh-start-date'] != undefined &&
                    dates['refresh-start-date'] !== ''
                ) {
                    //date = toISODate(row['refresh_start_date']);

                    date = getRefreshSchedule(toISODate(dates['refresh-start-date']));
                    title = 'Refresh Schedule: ' + date;
                }
            }
        }

        return <span title={title}>{credits}</span>;
    }

    costRenderer = (cell) => {
        const data = cell.value;
        var jobCost = 0;

        if (data) {
            jobCost = roundTwoDecimalPlaces(data);
        }

        return <span>{jobCost}</span>;
    };

    activeBatchRenderer(cell) {
        const data = cell.value;
        const row = cell['rowData'];

        var flag = data;

        if (data == undefined) {
            flag = false;
        }
        let title = 'Active Batch ID: None';
        //check if we have an active batch id
        if ('active_batch_id' in row && row['active_batch_id'] !== '') {
            title = 'Active Batch ID: ' + row['active_batch_id'];
        }

        return (
            <span>
                <div title={title}>{flag.toString()}</div>
            </span>
        );
    }

    enableRenderer(cellData) {
        const row = cellData['rowData'];

        const enabled = cellData.value;
        let activation_date = '';
        let deactivation_date = 'None';
        if ('raw' in row && 'dates' in row['raw']) {
            let dates = row['raw']['dates'];

            if ('activation-date' in dates) {
                activation_date = renderDate(getDate(dates['activation-date']));
            } else {
                //use create date
                activation_date = renderDate(getDate(dates['create-date']));
            }

            if ('deactivation-date' in dates) {
                deactivation_date = renderDate(getDate(dates['deactivation-date']));
            }
        }

        let title = '';
        if (activation_date) {
            title += 'Activation Date: ' + activation_date;
        }

        if (deactivation_date) {
            if (title) {
                title += '\n';
            }
            title += 'Deactivation Date: ' + deactivation_date;
        }

        return <span title={title}>{enabled ? 'Yes' : 'No'}</span>;
    }

    ellipsisRenderer(cellData) {
        const data = cellData.value;
        return <span className="ellipsis-field">{data}</span>;
    }

    refreshPlans() {
        //get org licenses first since it's a smaller request
        //if a org was selected previouslly, then get the selected org details again so that the licensing information is accurate
        if (this.state.selectedOrg) {
            //set the loading to true
            this.setState({
                fetchingPlans: true,
            });
            this.getOrgPlanType(this.state.selectedOrg);
        }
    }

    updateAfterOrgDelete() {
        this.setState({ selectedOrg: '' });
        this.getOrganizations();
    }

    updateAfterUserDelete() {
        this.getUsers();
    }

    updateAfterPlanChange() {
        //get org licenses first since it's a smaller request
        //if a org was selected previouslly, then get the selected org details again so that the licensing information is accurate
        this.getOrgLicenses();
        this.getOrganizations();
    }

    updateAfterAdminDelete() {
        //refresh the licenses first since that's a quick call
        this.getOrgLicenses();

        this.getOrganizationDetails(this.state.selectedOrg);
        this.getUsers();
        //clear out the state of the selected row.
        //if we don't do this then it remembers the last selected row even though the UI no longer shows it checked
        //this was allowing a user to apply an update without clearly knowing what they are updating
        this.setState({ planSelectedRow: null, selectedPlanId: '' });
    }

    updateAfterUserCreate() {
        // refresh the license counts on the orgs after updating a user
        this.getOrgLicenses();
        //if a org was selected previouslly, then get the selected org details again so that the licensing information is accurate
        if (this.state.selectedOrg) {
            this.getOrganizationDetails(this.state.selectedOrg);
        }
        this.getUsers();
        this.setState({ userRecordSelectedRow: null });
    }

    updateMachineAccess(selectedMachines) {
        let filteredMachines = [];
        let isAdd;

        // check change in number of selected machines to determine if add or delete
        if (this.state.enabledMachines.length > selectedMachines.length) {
            filteredMachines = this.state.enabledMachines.filter((val) => !selectedMachines.includes(val));
            isAdd = false;
        } else {
            filteredMachines = selectedMachines.filter((val) => !this.state.enabledMachines.includes(val));
            isAdd = true;
        }

        filteredMachines.forEach((machine) => {
            this.updateAccess(this.state.selectedOrg, machine.name, isAdd);
        });

        this.setState({ selectedMachine: selectedMachines });
    }

    async getUsers() {
        HQS_API.getUsers().then((response) => {
            const offset = moment().utcOffset();
            if (response !== undefined) {
                response.forEach((user) => {
                    if ('groups' in user && Array.isArray(user['groups'])) {
                        var permissions = '';
                        user['groups'].forEach((permission, index) => {
                            let commaString = index <= user['groups'].length - 2 ? ', ' : '';
                            permissions += getFriendlyPermission(permission) + commaString;
                        });
                        user['groups'] = permissions;
                    }
                    if ('software' in user && Array.isArray(user['software'])) {
                        var allSoftware = '';
                        user['software'].forEach((software, index) => {
                            let commaString = index <= user['software'].length - 2 ? ', ' : '';
                            allSoftware += softwareMap[software] + commaString;
                        });

                        user['software'] = allSoftware;
                    }
                    user['create-date'] = 'create-date' in user ? user['create-date'] : '';
                    user['enabled'] = user['enabled'] ? 'Yes' : 'No';
                    user['login-status'] =
                        user['login-status'] === 'force_change_password'
                            ? 'password change pending'
                            : user['login-status'];
                    user['priority'] = 'priority' in user ? user['priority'] : 'none';
                });
                response.sort((a, b) => a.email.localeCompare(b.email));
                this.setState({ allUsers: response });
            }
        });
    }

    getOrgPlanType(orgName) {
        //always fetch latest org details
        HQS_API.getOrganization(orgName, this.state.mode)
            .then((response) => {
                let orgDetails = {
                    org_name: orgName,
                    customerId: response['customer_id'],
                    plans: response['plans'],
                    batchMax: response['batch_max_hqc'],
                    quota: 'quota' in response ? response['quota'] : 0,
                    quotaEnabled: 'quota_enabled' in response ? response['quota_enabled'] : false,
                    licenses: 'licenses' in response ? response['licenses'] : {},
                    type: 'type' in response ? response['type'] : '',
                };

                const orgPlans = response['plans'];

                // sum pending hqc from all machines/plans
                let totalPending = 0;

                let orgPlansMapped = [];

                orgPlans.forEach((plan) => {
                    let newPlan = {
                        id: plan['id'],
                        org_name: orgName,
                        org_type: orgDetails.type,
                        enabled: plan['enabled'],
                        unlimited:
                            'credits' in plan && 'unlimited' in plan['credits'] ? plan['credits']['unlimited'] : false,
                        available_hqc:
                            'credits' in plan && 'available' in plan['credits'] ? plan['credits']['available'] : 0,
                        hqc_accumulation:
                            'credits' in plan && 'accumulated' in plan['credits'] ? plan['credits']['accumulated'] : 0,
                        pending_hqc: 'credits' in plan && 'pending' in plan['credits'] ? plan['credits']['pending'] : 0,
                        monthly_hqc:
                            'credits' in plan && 'monthly' in plan['credits'] ? plan['credits']['monthly'] : 'NA',
                        refresh_enabled: 'refresh-enabled' in plan['dates'] ? plan['dates']['refresh-enabled'] : false,
                        fq_multiplier:
                            'active' in plan['fair-queuing']['multipliers']
                                ? plan['fair-queuing']['multipliers']['active']
                                : 1,
                        //need to agree on correct machine name convention
                        machine_name: plan['machines'],
                        software: plan['software'],
                        raw: plan, //the full object in case we need to acces more properties later
                    };

                    //might be temporary
                    if (newPlan['machine_name'] == undefined) {
                        newPlan['machine_name'] = {};
                    }
                    orgPlansMapped.push(newPlan);

                    let machines = {};

                    if ('machines' in plan) {
                        machines = plan['machines'];
                    }

                    //only do this if it's valid (defined)
                    if (machines) {
                        Object.keys(machines).forEach(function (machineName) {
                            let machine = machines[machineName];
                            totalPending = totalPending + machine['pending-hqc'];
                        });
                    }
                });

                //sort the plans by machine name so it's easy to find new plans when added
                if (orgPlansMapped.length > 0) {
                    orgPlansMapped.sort((a, b) => {
                        return Object.keys(a.machine_name)
                            .join(', ')
                            .localeCompare(Object.keys(b.machine_name).join(', '));
                    });
                }

                orgDetails['pendingHqc'] = totalPending;
                const orgLicenses = 'licenses' in response ? response['licenses'] : {};
                const diagnostic = 'run_diagnostic' in response ? response['run_diagnostic'] : false;

                this.setState({
                    selectedOrgDetails: orgDetails,
                    selectedOrgPlans: orgPlansMapped,
                    selectedOrgLicenses: orgLicenses,
                    selectedOrgDiagnostic: diagnostic,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({
                    fetchingPlans: false,
                });
            });
    }

    getAllMachines() {
        this.setState({ fetchingMachines: true });
        HQS_API.getMachines().then((response) => {
            this.setState({
                allMachineRecords: response,
                fetchingMachines: false,
            });
        });
    }

    updateAfterSettingsChange() {
        //refresh the available tags that can be assigned
        this.getAllTags();

        //refresh the orgs so that the manage tags/expanded plan views always show the current data
        //without this, if an 'allowed' value was removed it would show up in the 'manage tag' but it would have an empty value
        this.getOrganizations();
    }

    getAllTags() {
        this.setState({ fetchingTags: true });
        let queryParams = {
            mode: this.state.mode,
        };
        HQS_API.getTags(queryParams)
            .then((response) => {
                this.setState({
                    allTags: response,
                    fetchingTags: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    renderQuotaActions() {
        if (this.state.selectedOrgDetails.quotaEnabled == true) {
            return <DisableOrgQuotaForm org={this.state.selectedOrg} callBack={this.updateAfterPlanChange} />;
        } else {
            return <SetOrgQuotaForm org={this.state.selectedOrg} callBack={this.updateAfterPlanChange} />;
        }
    }

    handleUserRowSelect(selectedRow) {
        let selectedUser = '';
        if (selectedRow !== undefined) {
            /** handle case when selected row data is array */
            if (Array.isArray(selectedRow)) {
                if (selectedRow.length === 0) {
                    selectedRow = null;
                } else {
                    selectedUser = selectedRow[0]['email'];
                }
            } else if ('data' in selectedRow) {
                selectedRow = [selectedRow.data];
                selectedUser = selectedRow[0]['email'];
            } else {
                selectedRow = null;
            }

            this.setState({
                userRecordSelectedRow: selectedRow,
                selectedUser: selectedUser,
            });
        }
    }

    handleMachineRowSelect(selectedRow) {
        let selectedMachine = '';
        if (selectedRow !== undefined) {
            /** handle case when selected row data is array */
            if (Array.isArray(selectedRow)) {
                if (selectedRow.length === 0) {
                    selectedRow = null;
                } else {
                    selectedMachine = selectedRow[0]['email'];
                }
            } else if ('data' in selectedRow) {
                selectedRow = [selectedRow.data];
                selectedMachine = selectedRow[0]['name'];
            } else {
                selectedRow = null;
            }

            this.setState({
                machineRecordSelectedRow: selectedRow,
                selectedMachine: selectedMachine,
            });
        }
    }

    handlePlanRowSelect(selectedRow) {
        let selectedPlanId = '';
        if (selectedRow !== undefined) {
            /** handle case when selected row data is array */
            if (Array.isArray(selectedRow)) {
                if (selectedRow.length === 0) {
                    selectedRow = null;
                } else {
                    selectedPlanId = selectedRow[0]['id'];
                }
            } else if ('data' in selectedRow) {
                selectedRow = [selectedRow.data];
                selectedPlanId = selectedRow[0]['id'];
            } else {
                selectedRow = null;
            }

            this.setState({
                planSelectedRow: selectedRow,
                selectedPlanId: selectedPlanId,
            });
        }
    }

    generateActiveJobsMachines() {
        var machineOptions = [{ text: 'All Machines', value: 'All Machines' }];
        machineOptions.push(...this.state.machineNames);
        return machineOptions;
    }

    togglePlanExpand(data, open) {
        let expanded = this.state.planExpandedRows;
        if (open) {
            let index = expanded.findIndex((item) => JSON.stringify(item) === JSON.stringify(data));
            expanded.splice(index, 1);
        } else {
            expanded.push(data);
        }

        this.setState({ planExpandedRows: expanded });
    }

    planExpanderTemplate(data) {
        const open = this.state.planExpandedRows.includes(data.rowData);
        return (
            <div onClick={() => this.togglePlanExpand(data.rowData, open)}>
                <Icon root="common" name={open ? 'caret-down' : 'caret-right'} />
            </div>
        );
    }

    planExpansionTemplate(data) {
        const expanded = [];

        // see if we have tags
        let tags = 'tags' in data ? data['tags'] : [];
        let expandList = <div></div>;

        //add the tags to the overall expanded content (even if there aren't any)
        expanded.push(<PlanTag tags={tags} />);

        expandList = <div>{expanded}</div>;

        return expandList;
    }

    downloadUsers() {
        const csv = Papa.unparse(this.state.allUsers);

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        this.blobRef.current.href = url;
        this.blobRef.current.download = 'users.csv';
        this.blobRef.current.click();
        URL.revokeObjectURL(url);

        const title = 'Successfully downloaded users';
        const details = 'users.csv';
        toast(<ToastNotification closeToast={false} title={title} details={details} />);
    }

    generateTabs(mode) {
        var tabEntries = [];
        /** Orgs */
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="sites" size="small">
                        &nbsp;Organizations
                    </Icon>
                }>
                <div className="hqs-admin-view">
                    <div className="hqs-admin-card">
                        <DataTable
                            key="organizations-table-key"
                            data={this.state.organizations}
                            scrollable={true}
                            scrollHeight="590px"
                            onRowClick={(data) => this.getOrganizationDetails(`${data.data.name}`)}
                            search={true}
                            searchPlaceholder="Search Records">
                            <DataTable.HeaderBar>
                                <div
                                    style={{
                                        display: mode === 'admin' ? '' : 'none',
                                    }}>
                                    <CreateOrgForm callback={this.updatedOrgs} />
                                </div>
                            </DataTable.HeaderBar>
                            <DataTable.Column field="name" header="NAME" sortable={true} initialWidth="350px" />
                            <DataTable.Column
                                field="created"
                                header="DATE ADDED"
                                sortable={true}
                                renderer={this.shortDateRenderer}
                            />
                            <DataTable.Column field="customerId" header="Customer ID" sortable={true} />
                            <DataTable.Column
                                field="enabled"
                                header="ENABLED"
                                align="center"
                                sortable={true}
                                renderer={this.statusRenderer}
                            />
                        </DataTable>
                    </div>
                    <div className="hqs-admin-card">
                        <div
                            className="hqs-admin-centered"
                            style={{
                                display: this.state.selectedOrg !== '' ? 'none' : '',
                            }}>
                            Select an organization on the left to see more details
                        </div>
                        <div
                            style={{
                                fontSize: '18px',
                                display: this.state.selectedOrg !== '' ? '' : 'none',
                            }}>
                            {this.state.selectedOrg}
                            <div className="hqs-customer-info">
                                <div className="hqs-admin-subcard">
                                    <div className="hqs-customer-header">CUSTOMER ID</div>
                                    <CustomerID
                                        key={this.state.selectedOrgDetails.customerId}
                                        org={this.state.selectedOrg}
                                        customerID={this.state.selectedOrgDetails.customerId}
                                        mode={mode}
                                        callBack={this.updateAfterPlanChange}
                                    />
                                </div>
                                <div className="hqs-admin-subcard">
                                    <div className="hqs-customer-header">
                                        PENDING HQC&nbsp;
                                        <Icon
                                            name="badge-important"
                                            root="common"
                                            color="blue"
                                            title="Org's HQC total for jobs in running or queued state."
                                        />
                                    </div>
                                    <PendingCredits pendingHQC={this.state.selectedOrgDetails.pendingHqc} />
                                </div>
                                <div
                                    className="hqs-admin-subcard"
                                    style={{
                                        display: this.state.selectedOrgDetails.quotaEnabled === false ? 'none' : '',
                                    }}>
                                    <div className="hqs-customer-header">HQC QUOTA</div>
                                    <OrgHqcQuota
                                        org={this.state.selectedOrg}
                                        quota={this.state.selectedOrgDetails.quota}
                                        callBack={this.updateAfterPlanChange}
                                    />
                                </div>
                                <div className="hqs-admin-subcard">
                                    <div className="hqs-customer-header">BATCH MAX HQC</div>
                                    <OrgBatchMaxHQC
                                        org={this.state.selectedOrg}
                                        batchMax={this.state.selectedOrgDetails.batchMax}
                                        callBack={this.updateAfterPlanChange}
                                    />
                                </div>
                                <div className="hqs-admin-subcard">
                                    <div className="hqs-customer-header">DIAGNOSTICS</div>
                                    <OrgDiagnostic
                                        org={this.state.selectedOrg}
                                        diagnostic={this.state.selectedOrgDiagnostic}
                                        callBack={this.updateAfterPlanChange}
                                    />
                                </div>
                                <div className="hqs-admin-subcard">
                                    <div className="hqs-customer-header">ORG TYPE</div>
                                    <OrgType
                                        org={this.state.selectedOrg}
                                        type={this.state.selectedOrgDetails.type}
                                        callBack={this.updateAfterPlanChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: this.state.selectedOrg !== '' ? '' : 'none',
                            }}>
                            <div className="hqs-umui-card-subheader">
                                Plans{' '}
                                <span
                                    style={{
                                        cursor: 'pointer',
                                    }}>
                                    <Icon
                                        onClick={this.refreshPlans}
                                        root="building"
                                        name="refresh"
                                        size="medium"
                                        loading={this.state.fetchingPlans}
                                        title="Refresh Plans"
                                    />
                                </span>
                            </div>
                            <DataTable
                                data={this.state.selectedOrgPlans}
                                scrollable={true}
                                resizableColumns={true}
                                scrollHeight="250px"
                                scrollWidth="100%"
                                searchPlaceholder="Search Records"
                                selectionMode="single"
                                selection={this.state.planSelectedRow}
                                onSelectionChange={(e) => this.handlePlanRowSelect(e)}
                                onRowClick={(e) => this.handlePlanRowSelect(e)}
                                expandedRows={this.state.planExpandedRows}
                                rowExpansionTemplate={this.planExpansionTemplate}>
                                <DataTable.HeaderBar>
                                    <div style={{ display: mode === 'admin' || mode === 'operator' ? 'flex' : 'none' }}>
                                        <PlanActionSelect
                                            key={this.state.selectedPlanId}
                                            mode={this.state.mode}
                                            selectedRow={this.state.planSelectedRow}
                                            allMachines={this.state.allMachines}
                                            allTags={this.state.allTags}
                                            licenses={this.state.selectedOrgLicenses}
                                            callback={this.updateAfterAdminDelete}
                                        />
                                        <div style={{ display: mode === 'admin' ? 'flex' : 'none' }}>
                                            <ManageAnyPlanForm
                                                key={this.state.selectedOrg}
                                                mode={'create'}
                                                org={this.state.selectedOrg}
                                                orgType={this.state.selectedOrgDetails.type}
                                                allMachines={this.state.allMachines}
                                                allTags={this.state.allTags}
                                                allLicenses={this.state.selectedOrgLicenses}
                                                callback={this.updatedOrgs}
                                            />
                                        </div>
                                    </div>
                                </DataTable.HeaderBar>
                                {/* hiding this since we have summary*/}
                                {/*
                                <DataTable.Column
                                    initialWidth="3rem"
                                    field="plane"
                                    header="Show"
                                    renderer={this.planExpanderTemplate}
                                />*/}
                                <DataTable.Column
                                    field="machine_name"
                                    header="MACHINES"
                                    initialWidth="200px"
                                    renderer={this.machinesRenderer}
                                    sortable={true}
                                />
                                <DataTable.Column
                                    field="enabled"
                                    header="Enabled"
                                    initialWidth="120px"
                                    renderer={this.enableRenderer}
                                />
                                <DataTable.Column
                                    field="available_hqc"
                                    header="AVAILABLE HQC"
                                    initialWidth="160px"
                                    renderer={this.availableHqcRenderer}
                                />
                                <DataTable.Column
                                    field="monthly_hqc"
                                    header="MONTHLY HQC"
                                    initialWidth="150px"
                                    renderer={this.monthlyHqcRenderer}
                                />
                                <DataTable.Column
                                    field="pending_hqc"
                                    header="PENDING HQC"
                                    initialWidth="150px"
                                    renderer={this.pendinghqcRenderer}
                                />
                                <DataTable.Column
                                    field="hqc_accumulation"
                                    header="ACCUMULATED HQC"
                                    initialWidth="185px"
                                    renderer={this.accumulatedHqcRenderer}
                                />
                                <DataTable.Column
                                    field="fq_multiplier"
                                    header="FQ MULTIPLIER"
                                    initialWidth="160px"
                                    renderer={this.fqMultiplierRenderer}
                                />
                                <DataTable.Column
                                    field="software"
                                    header="SOFTWARE"
                                    initialWidth="150px"
                                    renderer={this.softwareRenderer}
                                    sortable={true}
                                />
                            </DataTable>
                            <div className="hqs-umui-card-subheader">Administrators</div>
                            <DataTable
                                data={this.state.selectedOrgAdmins}
                                scrollable={true}
                                scrollHeight="230px"
                                searchPlaceholder="Search Records">
                                <DataTable.Column field="email" header="LOGIN" sortable={true} />
                                <DataTable.Column
                                    field="create-date"
                                    header="DATE ADDED"
                                    sortable={true}
                                    renderer={this.shortDateRenderer}
                                />
                                <DataTable.Column
                                    field="enabled"
                                    header="ACTIONS"
                                    sortable={true}
                                    renderer={this.adminDeleteRenderer}
                                />
                            </DataTable>
                            <div className="hqs-button-group">
                                <GenerateUsageReportForm organization={this.state.selectedOrg} mode={this.state.mode} />
                                <div className="hqs-button-group" style={{ display: mode === 'admin' ? '' : 'none' }}>
                                    {this.renderQuotaActions()}
                                    <DeleteOrgForm
                                        org={this.state.selectedOrg}
                                        orgPlans={this.state.selectedOrgPlans}
                                        callBack={this.updateAfterOrgDelete}
                                    />
                                </div>
                            </div>
                        </div>
                        <ToastContainer
                            hideProgressBar={true}
                            closeOnClick={false}
                            closeButton={false}
                            newestOnTop={true}
                            position="bottom-right"
                            toastClassName="toast-notification-wrap"
                        />
                    </div>
                </div>
            </Tab.Pane>,
        );
        /** Fair Queuing */
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="descending" size="small">
                        &nbsp;Fair Queuing
                    </Icon>
                }>
                <div className="hqs-admin-view">
                    <div className="hqs-admin-fq-card">
                        <div className="hqs-fq-info">
                            <div className="hqs-fq-subcard">{this.genMachineOptions(true)}</div>
                            <div className="hqs-fq-subcard">
                                <NextFQReset nextFQReset={this.state.fqNextReset} />
                            </div>
                        </div>
                        <FQTable machineNames={this.state.allMachines} orgs={this.state.fqOrgs} />
                    </div>
                </div>
            </Tab.Pane>,
        );
        /** Users, only for hqs admins */
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="user-group" size="small">
                        &nbsp;Users
                    </Icon>
                }>
                <div className="hqs-admin-view">
                    <div className="hqs-admin-users-card">
                        <div className="hqs-umui-card-header" style={{ display: 'flex' }}>
                            <div style={{ paddingTop: '2px' }}>USERS&nbsp;&nbsp;</div>
                            <div
                                id="icon-refresh-users"
                                onClick={this.getUsers}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '0.8rem',
                                }}>
                                <Icon
                                    root="common"
                                    name="refresh"
                                    size="small"
                                    // loading={true}
                                />
                            </div>
                        </div>
                        <DataTable
                            key="users-table-key"
                            className="users-data-table"
                            data={this.state.allUsers}
                            scrollable={true}
                            scrollHeight="590px"
                            search={true}
                            searchPlaceholder="Search Records"
                            selection={this.state.userRecordSelectedRow}
                            selectionMode="single"
                            onSelectionChange={(e) => this.handleUserRowSelect(e)}
                            onRowClick={(e) => this.handleUserRowSelect(e)}>
                            <DataTable.HeaderBar>
                                <div style={{ display: ['admin', 'operator'].includes(mode) ? 'flex' : 'none' }}>
                                    <AdminUserActionSelect
                                        key={this.state.selectedUser}
                                        selectedRow={this.state.userRecordSelectedRow}
                                        orgLicenses={this.state.orgLicenses}
                                        callback={this.updateAfterUserCreate}
                                    />
                                    <CreateUserForm
                                        orgs={this.state.orgNames}
                                        orgLicenses={this.state.orgLicenses}
                                        showHqsAdmin={true}
                                        showProduct={true}
                                        showOperator={true}
                                        callback={this.updateAfterUserCreate}
                                    />
                                    <div>
                                        <Button
                                            id="btn-add-new-user"
                                            type="primary"
                                            size="small"
                                            onClick={this.downloadUsers}>
                                            <Icon root="common" name="file-download" size="small"></Icon>
                                            &nbsp;{'Download Users'}
                                        </Button>
                                    </div>
                                </div>
                            </DataTable.HeaderBar>

                            <DataTable.Column field="email" header="EMAIL" sortable={true} />

                            <DataTable.Column field="organization" header="ORGANIZATION NAME" sortable={true} />

                            <DataTable.Column
                                field="create-date"
                                header="DATE ADDED"
                                sortable={true}
                                renderer={this.shortDateRenderer}
                            />
                            <DataTable.Column field="enabled" header="ENABLED" sortable={true} initialWidth="140px" />
                            <DataTable.Column
                                field="login-status"
                                header="STATUS"
                                sortable={true}
                                initialWidth="170px"
                            />
                            <DataTable.Column field="priority" header="PRIORITY" sortable={true} initialWidth="140px" />
                            <DataTable.Column
                                field="groups"
                                header="PERMISSIONS"
                                sortable={true}
                                initialWidth="400px"
                            />
                            <DataTable.Column field="software" header="SOFTWARE" sortable={true} initialWidth="300px" />
                        </DataTable>
                        <ToastContainer
                            hideProgressBar={true}
                            closeOnClick={false}
                            closeButton={false}
                            newestOnTop={true}
                            position="bottom-right"
                            toastClassName="toast-notification-wrap"
                        />
                    </div>
                </div>
                <a style={{ display: 'none' }} href="" ref={this.blobRef} download="" />
            </Tab.Pane>,
        );
        /** Jobs */
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="communication" size="small">
                        &nbsp;Jobs
                    </Icon>
                }>
                <JobsTab
                    jobs={this.state.jobs}
                    mode={this.state.mode}
                    page={this.state.page}
                    search={this.state.search}
                    filters={this.state.filters}
                    organizations={this.state.orgNames}
                    orgTypes={this.state.orgTypes}
                    onChange={this.fetchJobs}
                    onSort={(sortData) => {
                        const newSort = this.sortJobs(this.state.jobs, sortData);
                        this.setState({
                            jobs: newSort,
                            jobsSortInfo: sortData,
                        });
                    }}
                    {...this.state.jobsSortInfo}
                    fetchingJobs={this.state.fetchingJobs}
                    fetchJobsReport={this.fetchJobsReport}
                />
                <a style={{ display: 'none' }} href="" ref={this.blobRef} download="" />
            </Tab.Pane>,
        );
        /** Batch Requests */
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="image-responsive" size="small">
                        &nbsp;Batch Requests
                    </Icon>
                }>
                <BatchRequestsTab
                    jobs={this.state.batchRequests}
                    mode={this.state.mode}
                    organizations={this.state.orgNames}
                    onChange={this.fetchBatchRequests}
                    onSort={(sortBatchData) => {
                        const newSort = this.sortJobs(this.state.batchRequests, sortBatchData);
                        this.setState({
                            batchRequests: newSort,
                            batchRequestsSortInfo: sortBatchData,
                        });
                    }}
                    {...this.state.batchRequestsSortInfo}
                    fetchingBatchRequests={this.state.fetchingBatchRequests}
                />
            </Tab.Pane>,
        );
        /** calendar */
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="calendar" size="small">
                        &nbsp;Calendar
                    </Icon>
                }>
                <CalendarTab
                    mode={this.state.mode}
                    allMachines={this.state.allMachines}
                    orgNames={this.state.orgNames}></CalendarTab>
            </Tab.Pane>,
        );
        /** Machines **/
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="display-adjust" size="small">
                        &nbsp;Machines
                    </Icon>
                }>
                <div className="hqs-admin-view">
                    <div className="hqs-admin-users-card">
                        <div className="hqs-umui-card-header" style={{ display: 'flex' }}>
                            <div style={{ paddingTop: '2px' }}>MACHINES&nbsp;&nbsp;</div>
                            <div
                                onClick={this.getAllMachines}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '0.8rem',
                                }}>
                                <Icon root="common" name="refresh" size="small" />
                            </div>
                        </div>
                        <DataTable
                            className="machine-data-table"
                            data={this.state.allMachineRecords}
                            scrollable={true}
                            scrollHeight="600px"
                            loading={this.state.fetchingMachines}
                            search={true}
                            selection={this.state.machineRecordSelectedRow}
                            selectionMode="single"
                            onSelectionChange={(e) => this.handleMachineRowSelect(e)}
                            onRowClick={(e) => this.handleMachineRowSelect(e)}
                            searchPlaceholder="Search Records">
                            <DataTable.HeaderBar>
                                <div style={{ display: mode === 'admin' ? 'flex' : 'none' }}>
                                    <AdminMachineActionSelect
                                        key={this.state.selectedMachine}
                                        selectedRow={this.state.machineRecordSelectedRow}
                                        callback={this.getAllMachines}
                                    />
                                </div>
                            </DataTable.HeaderBar>
                            <DataTable.Column field="name" header="NAME" sortable={true} />
                            <DataTable.Column field="state" header="STATE" sortable={true} />
                            <DataTable.Column field="qubits" header="QUBITS" sortable={true} />
                            <DataTable.Column field="total_jobs" header="TOTAL JOBS" sortable={true} />
                            <DataTable.Column
                                field="active_batch"
                                header="ACTIVE BATCH"
                                sortable={true}
                                renderer={this.activeBatchRenderer}
                            />
                        </DataTable>
                        <ToastContainer
                            hideProgressBar={true}
                            closeOnClick={false}
                            closeButton={false}
                            newestOnTop={true}
                            position="bottom-right"
                            toastClassName="toast-notification-wrap"
                        />
                    </div>
                </div>
            </Tab.Pane>,
        );
        /** Active Jobs **/
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="bolt" size="small">
                        &nbsp;Active Jobs
                    </Icon>
                }>
                <div>
                    <DataTable
                        data={this.state.activeJobs}
                        scrollable={true}
                        resizableColumns={true}
                        scrollHeight="540px"
                        search={true}
                        columnResizeMode="fit"
                        loading={this.state.fetchingJobs}>
                        <DataTable.HeaderBar>
                            <Select
                                placeholder="All Machines"
                                options={this.generateActiveJobsMachines()}
                                onChange={(value) => this.fetchActiveJobs(value)}
                                value={this.state.activeMachine}
                                search={true}
                            />
                            <DataTable.HeaderBar.Item
                                iconSize="small"
                                icon="refresh"
                                onClick={() => this.fetchActiveJobs(this.state.activeMachine)}
                            />
                        </DataTable.HeaderBar>
                        <DataTable.Column field="status" header="status" initialWidth="105px" sortable={true} />
                        <DataTable.Column
                            field="id"
                            header="job id"
                            sortable={true}
                            initialWidth="155px"
                            renderer={this.ellipsisRenderer}
                            className="hqs"
                        />
                        <DataTable.Column
                            field="name"
                            header="job name"
                            sortable={true}
                            initialWidth="120px"
                            renderer={this.ellipsisRenderer}
                        />
                        <DataTable.Column
                            field="machine"
                            header="machine"
                            sortable={true}
                            initialWidth="150px"
                            renderer={this.ellipsisRenderer}
                        />
                        <DataTable.Column field="org" header="organization name" sortable={true} initialWidth="180px" />
                        <DataTable.Column field="user" header="user" sortable={true} initialWidth="230px" />
                        <DataTable.Column field="group" header="group" sortable={true} initialWidth="150px" />
                        <DataTable.Column field="priority" header="priority" sortable={true} initialWidth="130px" />
                        <DataTable.Column
                            field="submit-date"
                            header="submit date"
                            initialWidth="215px"
                            renderer={this.dateRenderer}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="start-date"
                            header="start date"
                            initialWidth="215px"
                            renderer={this.dateRenderer}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="result-date"
                            header="result date"
                            initialWidth="215px"
                            renderer={this.dateRenderer}
                            sortable={true}
                        />
                        <DataTable.Column field="count" header="shots" sortable={true} initialWidth="100px" />
                        <DataTable.Column
                            field="last-shot"
                            header="shots completed"
                            sortable={true}
                            initialWidth="180px"
                        />
                        <DataTable.Column
                            field="cost"
                            header="cost"
                            initialWidth="100px"
                            renderer={this.costRenderer}
                            align="right"
                            sortable={true}
                        />
                    </DataTable>
                </div>
            </Tab.Pane>,
        );
        /** Reports Tab **/
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="folder" size="small">
                        &nbsp;Reports
                    </Icon>
                }>
                <ReportsTab
                    reports={this.state.reports}
                    orgs={this.state.orgNames}
                    mode={'admin'}
                    fetchingReports={this.state.fetchingReports}
                    callback={this.fetchReports}
                />
            </Tab.Pane>,
        );
        tabEntries.push(
            <Tab.Pane
                title={
                    <Icon root="common" name="grid" size="small">
                        &nbsp;User Groups
                    </Icon>
                }>
                <GroupsTab orgs={this.state.organizations} />
            </Tab.Pane>,
        );
        /** audit trail - only show on admin */
        {
            this.state.mode == 'admin' || this.state.mode == 'operator'
                ? tabEntries.push(
                      <Tab.Pane
                          title={
                              <Icon root="building" name="map" size="small">
                                  &nbsp;Audit Trail
                              </Icon>
                          }>
                          <AuditTab
                              key={this.state.auditActions.length}
                              logs={this.state.logs}
                              mode={this.state.mode}
                              page={this.state.auditPage}
                              filters={this.state.auditFilters}
                              onChange={this.fetchAuditLogs}
                              requestors={this.state.allUsers}
                              actions={this.state.auditActions}
                              fetchingAuditLogs={this.state.fetchingAuditLogs}
                          />
                      </Tab.Pane>,
                  )
                : tabEntries.push();
        }

        /** settings - only show on admin */
        {
            this.state.mode == 'admin'
                ? tabEntries.push(
                      <Tab.Pane
                          title={
                              <Icon root="common" name="settings" size="small">
                                  &nbsp;Settings
                              </Icon>
                          }>
                          <SettingsTab mode={this.state.mode} callback={this.updateAfterSettingsChange}></SettingsTab>
                      </Tab.Pane>,
                  )
                : tabEntries.push();
        }
        return tabEntries;
    }

    render() {
        let welcomeMessage =
            this.state.mode == 'admin' ? 'Welcome, Quantinuum Administrator' : 'Welcome, Quantinuum Operator';
        let view = (
            <div className="HQSAdmin">
                <div className="View-header">{welcomeMessage}</div>
                <Tab defaultActiveIndex={0}>{this.generateTabs(this.state.mode)}</Tab>
            </div>
        );

        return view;
    }
}

export default HQSAdmin;
