/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React from "react";
import { useMsal } from "@azure/msal-react";
import { MsalLoginRequest } from "../config";
const ms_signin = require('../CustomAuth/MicrosoftSignIn.svg');

function handleLogin(instance, action) {
    if (action === 'signup') {
        MsalLoginRequest['state'] = "signup";
    }

    instance.loginRedirect(MsalLoginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt for Microsoft 
 */
export const SignInAzure = (props) => {
    let action = 'signin'
    if ( 'action' in props) {
        action = props.action;
    }

    const { instance } = useMsal();

    return (
        <img className="ms-logo" src={ms_signin} onClick={() => handleLogin(instance, action)}></img>

    );
}