// *****************************************************************************

// 2020, 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Notification, Modal, Icon } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const DisableUserGroupQuotaForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleAction() {
        let body = {};
        body['quota_id'] = props.quotaId
        // disable user group quota
        HQS_API.disableUserGroupQuota(props.org_name, props.group_id, body)
            .then((response) => {
                const title = 'Group Quota Deleted';
                const details =
                    'Group HQC quota has been deleted successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />,
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });

        handleClose();
    }

    return (
        <div>
            <div onClick={handleShow}>
                <div style={{ cursor: 'pointer' }}>
                    <Icon
                        style={{ cursor: 'pointer' }}
                        root="common"
                        name="delete"
                        size="small"
                    ></Icon>
                </div>
            </div>
            <Modal
                open={show}
                className="hqs-umui-delete-modal"
                onClose={handleClose}
                size="small"
                style={{
                    padding: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon
                            style={{ cursor: 'pointer' }}
                            root="common"
                            name="tag-lost"
                            size="medium"
                        ></Icon>
                        &nbsp;&nbsp;Delete HQC quota on {props.group_name}
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>
                        This will disable HQC quota for &quot;{props.group_name}
                        &quot; and allow users to run without any restrictions.
                        Are you sure?
                    </p>
                    <Button type="primary" onClick={handleAction}>
                        Delete
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default DisableUserGroupQuotaForm;
