// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification, Checkbox, InputLabel } from '@scuf/common';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import ToastNotification from '../Notifications/ToastNotification';


const ManageUserPermissionsForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [isHqsAdmin, setIsHqsAdmin] = useState(props.permissions.includes("HQS Administrator"));
    const [isOperator, setIsOperator] = useState(props.permissions.includes("Operator"));
    const [isOrgAdmin, setIsOrgAdmin] = useState(props.permissions.includes("Organization Administrator"));

    const [permissionsError, setPermissionsError] = useState('');

    function handleIsHqsAdmin(checked) {
        setIsHqsAdmin(checked);
        setPermissionsError('');
    }

    function handleIsOperator(checked) {
        setIsOperator(checked);
        setPermissionsError('');
    }

    function handleIsOrgAdmin(checked) {
        setIsOrgAdmin(checked);
        setPermissionsError('');
    }


    function handleClose() {
        setPermissionsError('');
        setIsOrgAdmin(props.permissions.includes("Organization Administrator"));
        setIsHqsAdmin(props.permissions.includes("HQS Administrator"));
        setIsOperator(props.permissions.includes("Operator"));
        setShow(false);
    }

    function validateForm() {
        let validData = true;

        /** make sure only operator or hqs admin is selected. */
        if (isHqsAdmin && isOperator) {
            validData = false;
            setPermissionsError("User can't be both an Operator and HQS Admin!")
        }

        return validData;
    }

    function generatePermissionActions() {
        let actions = []
        /** operator */
        if (props.permissions.includes("Operator")) {
            if (!isOperator) {
                actions.push({ 'role': 'operators', 'action': 'demote' });
            }
        } else if (isOperator) {
            actions.push({ 'role': 'operators', 'action': 'promote' })
        }

        /** hqs admin */
        if (props.permissions.includes("HQS Administrator")) {
            if (!isHqsAdmin) {
                actions.push({ 'role': 'hqs-admins', 'action': 'demote' });
            }
        } else if (isHqsAdmin) {
            actions.push({ 'role': 'hqs-admins', 'action': 'promote' })
        }

        /** org admin */
        if (props.permissions.includes("Organization Administrator")) {
            if (!isOrgAdmin) {
                actions.push({ 'role': 'org-admins', 'action': 'demote' });
            }
        } else if (isOrgAdmin) {
            actions.push({ 'role': 'org-admins', 'action': 'promote' })
        }


        return actions;

    }


    async function handlePermissions() {
        if (validateForm()) {
            /** check which permissions changed */
            let permissionActions = generatePermissionActions();
            if (permissionActions.length > 0) {
                for (let i = 0; i < permissionActions.length; i++) {
                    await HQS_API.updateUserPermissions(props.user['email'], permissionActions[i])
                        .catch((error) => {
                            //catch and report any errors to the user
                            const title = 'Error!';
                            var details = 'Unknown Error!';
                            if (error.response !== undefined) {
                                details = error.response.data.error.text;
                            }
                            toast(
                                <ToastNotification
                                    closeToast={false}
                                    title={title}
                                    details={details}
                                    severity="critical"
                                />,
                            );
                        });
                }
            }
            const title = 'Success!';
            const details = 'Permissions updated successfully!';
            toast(
                <ToastNotification
                    closeToast={false}
                    title={title}
                    details={details}
                    severity="success"
                />
            );

            //close out the window and refresh the page
            if (props.callback) {
                props.callback();
            }
        }
    }

    return (
        <div>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="user-group-add"
                    size="small"></Icon>
                &nbsp;{props.children || 'Permissions'}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Manage Permissions</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        This will update the user&apos;s access. Here is a brief descpriton of what every role allows:
                    </p>
                    <p>
                        <u>Organization Administrator</u>: Can see any job for the organization and can manage access and quotas for user in the same organization.
                    </p>
                    <p>
                        <u>Operator</u>: Can see all screens, but can&apos;t perform any add/update/delete actions.
                    </p>
                    <p>
                        <u>HQS Administrator</u>: Can see all screens and can perform any add/update/delete action.
                    </p>
                    <Form.Group controlId="isOrgAdmin">
                        <Checkbox
                            label="Organization Administrator"
                            checked={isOrgAdmin}
                            onChange={(checked) => handleIsOrgAdmin(checked)}
                        />
                    </Form.Group>
                    <Form.Group controlId="isOperator">
                        <Checkbox
                            label="Operator"
                            checked={isOperator}
                            onChange={(checked) => handleIsOperator(checked)}
                        />
                    </Form.Group>
                    <Form.Group controlId="isHqsAdmin">
                        <Checkbox
                            label="HQS Administrator"
                            checked={isHqsAdmin}
                            onChange={(checked) => handleIsHqsAdmin(checked)}
                        />
                    </Form.Group>
                    <div className="below-text">
                        <span className="ui error-message">{permissionsError}</span>
                    </div>
                    
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" size="small" onClick={handlePermissions}>
                        Update
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ManageUserPermissionsForm;
