import React from 'react';
import UpdateUserGroupQuotaForm from '../Forms/UpdateUserGroupQuota';
import DisableUserGroupQuotaForm from '../Forms/DisableUserGroupQuota';
import UpdateUserQuotaForm from '../Forms/UpdateUserQuota';
import DisableUserQuotaForm from '../Forms/DisableUserQuota';
import { roundTwoDecimalPlaces } from '../utils/helpers';

import './UserGroupQuota.css';

function machinesRenderer(machinesList) {
    const data = machinesList;
    if(Array.isArray(machinesList)){
        return <span>{data.join(', ')}</span>;
    } else {
        return <span>{data}</span>;
    }
}

function UserGroupQuota(props) {
    let machines = machinesRenderer(props.machine);
    let updateComponent = undefined;
    let deleteComponent = undefined;
    // determine type
    if(props.type === "group") {
        updateComponent = <UpdateUserGroupQuotaForm
                                org_name={props.orgName}
                                group_name={props.groupName}
                                group_id={props.groupId}
                                quota={props.quota}
                                quotaId={props.quotaId}
                                callback={props.callback}
                            />;
        deleteComponent = <DisableUserGroupQuotaForm
                                org_name={props.orgName}
                                group_name={props.groupName}
                                group_id={props.groupId}
                                callback={props.callback}
                                quotaId={props.quotaId}
                            />;
    } else if(props.type === "user") {
        updateComponent = <UpdateUserQuotaForm
                            user={props.user}
                            quota={props.quota}
                            quotaId={props.quotaId}
                            callback={props.callback}
                        />;
        deleteComponent = <DisableUserQuotaForm 
                            user={props.user}
                            quota={props.quota}
                            quotaId={props.quotaId}
                            callback={props.callback}
                        />
    }

    return (
        <div className="umui-quota-record">
                <div className="umui-quota-header">MACHINE(s): </div>
                <div className="umui-quota-record-value">{machines}</div>
                <div className="umui-quota-header">CREDITS: </div>
                <div className="umui-quota-record-value">{roundTwoDecimalPlaces(props.quota)}</div>
                <div className="umui-quota-icons">{updateComponent}</div>&nbsp;&nbsp;
                <div className="umui-quota-icons">{deleteComponent}</div>
        </div>
       
    );
}

export default UserGroupQuota;
