// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React, { Component } from 'react';
import * as HQS_API from '../utils/api';
import moment from 'moment';
import forEach from 'lodash/forEach';
import map from 'lodash/map';

import './BatchRequests.css';


import { DataTable } from '@scuf/datatable';
import { Button, Tab, Icon, InputLabel, Input, Grid, BadgedIcon, Popup, Accordion } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import { roundTwoDecimalPlaces, toISODate } from '../utils/helpers';

const FilterPanel = DataTable.HeaderBar.FilterPanel;


class BatchRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            batchRequests: [],
            filteredBatchRequests: [],
            fetchingBatchRequests: false,
            filterState: {
                statuses: new Set()
            },
            statuses: new Set(),
            statusToggles: []
        };

        //batching functions
        this.getBatchRequests = this.getBatchRequests.bind(this);
        this.hqcRenderer = this.hqcRenderer.bind(this);
        this.generateFilters = this.generateFilters.bind(this);
        this.countFilters = this.countFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.handleAllFilterValues = this.handleAllFilterValues.bind(this);
        this.setFilterState = this.setFilterState.bind(this);
        this.setStatuses = this.setStatuses.bind(this);
        this.setStatusToggles = this.setStatusToggles.bind(this);


    }

    async componentDidMount() {

        this.getBatchRequests();
    }

    async getBatchRequests() {
        let queryParams = {
            mode: this.props.mode,
            org: this.props.org,
            batches: '200',
        };

        HQS_API.listBatchRequests(queryParams)
            .then((response) => {


                let statuses = this.setStatuses(response);
                this.setStatusToggles(statuses);

                this.setState({ batchRequests: response, filteredBatchRequests: response });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingBatchRequests: false });
            });
        this.setState({ fetchingBatchRequests: true });
    }

    batchIdRenderer(cellData) {
        let batchId = cellData.value
        if ("rowData" in cellData) {
            const row = cellData["rowData"]
            if ("user-batch-id" in row) {
                batchId = row["user-batch-id"]
            }
        }
        const data = batchId
        return <span className="ellipsis-field">{data}</span>;
    }



    ellipsisRenderer(cellData) {
        const data = cellData.value;
        return <span className="ellipsis-field">{data}</span>;
    }

    dateRenderer(cellData) {
        const data = cellData.value;
        var createDate = '';

        if (data) {
            createDate = toISODate(data)
        }
        return <span>{createDate}</span>;
    }

    statusRenderer(cellData) {
        const data = cellData.value;
        var jobStatus = data;

        if (data && data == 'pending') {
            jobStatus = 'queued';
        }
        return <span>{jobStatus}</span>;
    }

    hqcRenderer = (cellData) => {
        var hqcTotal = 0;
        var accumulated = 0
        var pending = 0
        if ("rowData" in cellData) {
            const row = cellData["rowData"]

            if ("accumulated-hqc" in row) {
                accumulated = row["accumulated-hqc"]
            }

            if ("pending-hqc" in row) {

                pending = row["pending-hqc"]
            }
        }

        hqcTotal = roundTwoDecimalPlaces(accumulated + pending);

        return <span>{hqcTotal}</span>;
    };

    costRenderer(cellData) {
        const data = cellData.value;
        var jobCost = 0;

        if (data) {
            jobCost = roundTwoDecimalPlaces(data);
        }

        return <span>{jobCost}</span>;
    }


    /*Start of batching content*/

    //get the saved filters from the session storage 
    fetchSavedFilters = (filterName) => {
        // fetch saved filters
        let savedFilters = sessionStorage.getItem(
            this.props.mode + '-batches-table-filter-' + filterName,
        );
        let savedFilterObject = JSON.parse(savedFilters);
        let filtersObjects = new Set();
        if (savedFilterObject !== null && savedFilterObject !== undefined) {
            if (savedFilterObject.hasOwnProperty(filterName)) {
                filtersObjects = new Set(savedFilterObject[filterName]);
            }
        }
        return filtersObjects;
    };


    //update the filter state, the displayed batch requests, and the status toggles
    setFilterState = (state, filters) => {
        //get the previous state
        let filterState = state;

        //update the status to what's saved in the session storage
        filterState.statuses = this.fetchSavedFilters('statuses');

        //filter the batches data using filters
        let batches = (!filters || filters.size === 0) ? this.state.batchRequests :
            this.state.batchRequests.filter((jj) => {
                if (filters.has(jj.status)) return false;
                return true;
            });

        //update the status toggles 
        let statusToggles = [];
        this.state.statuses.forEach((ss) => {
            statusToggles.push({
                label: ss,
                active: !filterState.statuses.has(ss),
            });
        });

        //update the various filter states 
        this.setState({ filterState: filterState, filteredBatchRequests: batches, statusToggles: statusToggles });

        return filterState;
    };



    /**
    *  Handles status change via checkbox element
    */
    handleFilters = (oldSet, value, active, filterName) => {
        const newSet = new Set(oldSet);
        if (active) newSet.delete(value);
        else newSet.add(value);
        // update session storage value
        sessionStorage.setItem(
            this.props.mode + '-batches-table-filter-' + filterName,
            '{"' + filterName + '":' + JSON.stringify([...newSet]) + '}',
        );

        //update the filter state with the new information
        this.setFilterState(this.state.filterState, newSet);
        return newSet;
    };

    handleAllFilterValues = (oldSet, active, filterName) => {
        let allFilterValues = new Set();
        if (!active) {
            switch (filterName) {
                case 'statuses':
                    allFilterValues = new Set(this.state.statuses);
                    break;
                default:
                    allFilterValues = new Set();
            }

            sessionStorage.setItem(
                this.props.mode + '-batches-table-filter-' + filterName,
                '{"' + filterName + '":' + JSON.stringify([...allFilterValues]) + '}',
            );

        } else {
            // update session storage value
            sessionStorage.setItem(
                this.props.mode + '-batches-table-filter-' + filterName,
                '{"' + filterName + '":' + JSON.stringify([]) + '}',
            );
        }

        //reset the filter state back to no filters
        //update the filter state with the new information
        this.setFilterState(oldSet, allFilterValues);
        return allFilterValues;
    };

    //update the filter count by checking the the number of statuses in the current filter state
    countFilters() {
        return this.state.filterState.statuses.size;
    }

    resetFilters() {
        //clear out the session state for statuses. 

        sessionStorage.removeItem(
            this.props.mode + '-batches-table-filter-' + 'statuses',
        );

        //reset the filter state back to no filters
        this.setFilterState({
            statuses: new Set()
        });

    }


    //set the statuses object based on the known batch information
    setStatuses(batches) {
        let statuses = new Set();
        batches.forEach((jj) => {
            statuses.add(jj.status);

        });

        this.setState({ statuses: statuses });
        return statuses;

    }

    //set the status toggles based on the set of incoming statuses
    setStatusToggles(statuses) {
        let statusToggles = [];
        statuses.forEach((ss) => {
            statusToggles.push({
                label: ss,
                active: !this.state.filterState.statuses.has(ss),
            });
        });

        this.setState({ statusToggles: statusToggles });
        return statusToggles;

    }


    //adjust the displayed text for a batch status
    formatStatus(value) {
        const data = value
        var batchStatus = data;

        if (data && data == 'pending') {
            batchStatus = 'queued';
        }
        return batchStatus
    }


    //generate the filter panel for the batch requests tab
    generateFilters() {
        return (
            <div>
                <Button
                    type="link"
                    onClick={this.resetFilters}
                    content="Reset all filters"
                />
                <Accordion>
                    <Accordion.Content title="Statuses">
                        <FilterPanel.ToggleItem
                            active={this.state.filterState.statuses.size === 0}
                            label="All Statuses"
                            type="checkbox"
                            checked={this.state.filterState.statuses.size === 0}
                            onChange={(active) => {
                                this.handleAllFilterValues(
                                    this.state.filterState.statuses,
                                    active,
                                    'statuses'
                                )
                            }
                            }
                        />
                        {
                            this.state.statusToggles.map((ss) => (
                                <FilterPanel.ToggleItem
                                    key={ss.label}
                                    {...ss}
                                    label={this.formatStatus(ss.label)}
                                    type="checkbox"
                                    checked={ss.active}
                                    onChange={(active) =>
                                        this.handleFilters(
                                            this.state.filterState.statuses,
                                            ss.label,
                                            active,
                                            'statuses',
                                        )}
                                />
                            ))}
                    </Accordion.Content>
                </Accordion>
            </div>
        )
    }

    /*End of batching content*/




    render() {
        return (
            <div className="hqs-org-view">
                <div className="hqs-umui-card">
                    <div
                        className="hqs-umui-card-header"
                        style={{ display: 'flex' }}
                    >
                        <div style={{ paddingTop: '2px' }}>
                            BATCH REQUESTS&nbsp;&nbsp;
                                    </div>
                        <div
                            onClick={this.getBatchRequests}
                            style={{
                                cursor: 'pointer',
                                fontSize: '0.8rem',
                            }}
                        >
                            <Icon
                                root="common"
                                name="refresh"
                                size="small"
                            // loading={true}
                            />
                        </div>
                    </div>
                    <DataTable
                        data={this.state.filteredBatchRequests}
                        scrollable={true}
                        resizableColumns={true}
                        scrollHeight="550px"
                        search={true}
                        loading={this.state.fetchingBatchRequests}
                    >
                        <DataTable.HeaderBar>
                            <div
                                id="hqs-admin-jobs-table-options"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    width: '100%',
                                    padding: '5px 0px 0px 5px',
                                }}
                            >
                                <div className="hqs-custom-table-option">

                                    <Popup
                                        element={
                                            <BadgedIcon
                                                root="common"
                                                name="filter"
                                                size="medium"
                                                badge={this.countFilters().toString()}
                                                color="blue"
                                            />
                                        }
                                        on="click"
                                        position="bottom right"
                                    >
                                        {this.generateFilters()}
                                    </Popup>
                                </div></div>
                        </DataTable.HeaderBar>
                        <DataTable.Column
                            field="batch-id"
                            header="Batch ID"
                            sortable={true}
                            initialWidth="135px"
                            renderer={this.batchIdRenderer}
                            className="hqs"
                        />
                        <DataTable.Column
                            field="machine"
                            header="MACHINE"
                            initialWidth="120px"
                            sortable={true}
                        />
                        <DataTable.Column
                            field="user"
                            header="USER"
                            initialWidth="110px"
                            sortable={true}
                        />
                        <DataTable.Column
                            field="submit-date"
                            header="Date Submitted"
                            initialWidth="180px"
                            renderer={this.dateRenderer}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="start-date"
                            header="Date Started"
                            initialWidth="180px"
                            renderer={this.dateRenderer}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="end-date"
                            header="Date Completed"
                            initialWidth="180px"
                            renderer={this.dateRenderer}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="accumulated-hqc"
                            header="Accum. HQC"
                            renderer={this.hqcRenderer}
                            initialWidth="120px"
                            sortable={true}
                        />
                        <DataTable.Column
                            field="batch-max-hqc"
                            header="Max HQC"
                            renderer={this.costRenderer}
                            initialWidth="120px"
                            sortable={true}
                        />
                        <DataTable.Column
                            field="status"
                            header="Status"
                            initialWidth="105px"
                            renderer={this.statusRenderer}
                            sortable={true}
                        />
                    </DataTable>
                    <ToastContainer
                        hideProgressBar={true}
                        closeOnClick={false}
                        closeButton={false}
                        newestOnTop={true}
                        position="bottom-right"
                        toastClassName="toast-notification-wrap"
                    />
                </div>
            </div>


        );
    }
}

export default BatchRequests;
