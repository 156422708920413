// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import Form from 'react-bootstrap/Form';
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Icon,
    InputLabel,
    Input,
    Radio,
    Notification,
    Modal,
    Select,
} from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import ToastNotification from '../Notifications/ToastNotification';

const CreateCalendarEventForm = (props) => {
    const [show, setShow] = useState(false);
    const [eventType, setEventType] = useState('maintenance');
    const [recurrenceType, setRecurrenceType] = useState('daily');
    const [recurrenceEndType, setRecurrenceEndType] = useState('date');
    const [eventOccurrences, setEventOccurrences] = useState(0);
    const [recurrenceEndDateError, setRecurrenceEndDateError] = useState('');
    const [org, setOrg] = useState('');
    const [orgError, setOrgError] = useState('');
    const [selectedMachine, setMachine] = useState();
    const [machineError, setMachineError] = useState();
    const [startTime, setStartTime] = useState(undefined);
    const [startTimeError, setStartTimeError] = useState();
    const [endTime, setEndTime] = useState();
    const [endTimeError, setEndTimeError] = useState();
    const [recurrenceEndTime, setRecurrenceEndTime] = useState();
    const [minuteStep, setMinuteStep] = useState(15);
    const [reservationType, setReservationType] = useState('native');
    const [tenantID, setTenantID] = useState('');
    const [tenantIDError, setTenantIDError] = useState('');
    const [recurringLabel, setRecurringLabel] = useState('No');

    const handleShow = () => setShow(true);

    const AZURE_ORG = 'azure';

    function handleClose() {
        //reset state props when closing form
        setEventType('maintenance');
        handleOrg('');
        setMachine();
        setStartTime();
        setEndTime();
        setTenantIDError('');
        setTenantID('');
        setShow(false);
        setRecurringLabel('No');
        setRecurrenceType('daily');
        setRecurrenceEndType('date');
    }

    function handleOrg(orgValue) {
        //clear error if any
        setOrgError('');
        setOrg(orgValue);

        //check if this is for azure
        if (orgValue === AZURE_ORG) {
            setReservationType('azure');
        } else {
            setReservationType('native');
        }
    }

    function handleMachine(e) {
        //clear error if any
        setMachineError('');
        setMachine(e);
    }

    function handleStartTime(date) {
        //clear error if any
        setStartTimeError('');
        setStartTime(date);
    }

    function handleEndTime(date) {
        //clear error if any
        setEndTimeError('');
        setEndTime(date);
    }

    function handleAllDayToggle(checked) {
        if (checked) {
            //set dates to start and end of day.
            //only set start time if it's not selected already
            if (startTime === undefined) {
                handleStartTime(moment().startOf('day').toDate());
            }
            handleEndTime(moment().endOf('day').toDate());
        } else {
            setEndTime(undefined);
            setStartTime(undefined);
        }
    }

    function handleRecurring(checked) {
        if (checked) {
            setRecurringLabel('Yes');
        } else {
            setRecurringLabel('No');
        }
    }

    function handleEventType(eventValue) {
        //clear org error if switching to maintenance
        if (eventValue === 'maintenance' || eventValue === 'online') {
            handleOrg('');
        }

        setEventType(eventValue);
    }

    function handleRecurrenceType(eventValue) {
        setRecurrenceType(eventValue);
    }

    function handleRecurrenceEndType(eventValue) {
        setRecurrenceEndType(eventValue);
    }

    function handleTenantID(tenantIdValue) {
        //clear error if any
        setTenantIDError('');
        setTenantID(tenantIdValue);
    }

    function genOrgOptions(orgs) {
        const formattedOrgs = [];
        orgs.forEach((org) => {
            let formattedOrg = { value: org, text: org };
            formattedOrgs.push(formattedOrg);
        });

        formattedOrgs.push({ value: 'azure', text: 'Azure' });
        formattedOrgs.sort((a, b) => a.value.localeCompare(b.value));

        return (
            <Select
                error={orgError}
                options={formattedOrgs}
                placeholder="No organizations needed for Maintenance events"
                onChange={(text) => handleOrg(text)}
                reserveSpace={true}
                fluid={true}
                value={org}
                search={true}
            />
        );
    }
    function genMachineOptions(machines) {
        const formattedMachines = [];
        machines.forEach((record) => {
            let formattedMachine = { value: record.name, text: record.name };
            formattedMachines.push(formattedMachine);
        });

        // formattedMachines.sort((a, b) => a.value.localeCompare(b.value));

        return (
            <Select
                error={machineError}
                options={formattedMachines}
                placeholder="Machine Name"
                onChange={(text) => handleMachine(text)}
                reserveSpace={true}
                fluid={true}
                value={selectedMachine}
                search={true}
            />
        );
    }

    // function to validate all form fields
    function valiteFormValues() {
        let validData = true;
        // start time
        if (startTime === undefined) {
            setStartTimeError('Start Time Missing');
            validData = false;
        }

        // end time
        if (endTime === undefined) {
            setEndTimeError('End Time Missing');
            validData = false;
        }

        // make sure end date is not before or equal to start date
        if (startTime !== undefined && endTime !== undefined) {
            var timeDiff = moment(endTime).diff(moment(startTime), 'minutes');
            if (timeDiff <= 0) {
                validData = false;
                setEndTimeError('Select time greater than start');
            }

            // make sure start time is not in the past
            if (moment(startTime).isBefore(moment(), 'minutes')) {
                validData = false;
                setStartTimeError('You can not select a date in the past');
            }
        }
        // org is required for reservation
        if (eventType === 'reservation') {
            if (org === undefined || org === '') {
                setOrgError('Organization Missing');
                validData = false;
            } else if (org === AZURE_ORG) {
                //make sure we have a subscription id
                if (tenantID === undefined || tenantID === '') {
                    validData = false;
                    setTenantIDError('Tenant ID is required for Azure reservations');
                } else {
                    //validate format
                    const regexPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/g;
                    if (!tenantID.match(regexPattern)) {
                        setTenantIDError('Invalid Tenant ID Format');
                        validData = false;
                    }
                }
            }
        }

        // machine
        if (selectedMachine === undefined || selectedMachine.length === 0) {
            setMachineError('Machine Missing');
            validData = false;
        }

        // check recurring info
        if (recurringLabel === 'Yes') {
            if (recurrenceEndType === 'date') {
                if (recurrenceEndTime === undefined) {
                    validData = false;
                    setRecurrenceEndDateError('End Date Missing');
                }
            }
        }
        return validData;
    }

    function handleCreate() {
        if (valiteFormValues()) {
            // calculate duration from start and end date
            let duration = Math.round((endTime.getTime() - startTime.getTime()) / 1000);
            let start_date = moment.utc(startTime).format('YYYY-MM-DD[T]HH:mm:ss');
            let end_date = moment.utc(endTime).format('YYYY-MM-DD[T]HH:mm:ss');

            let body = {
                org: reservationType == 'azure' ? tenantID : org,
                duration: duration,
                machine: selectedMachine,
                'event-type': eventType,
                'start-date': start_date,
                'end-date': end_date,
                'reservation-type': eventType == 'reservation' ? reservationType : '',
            };

            if (recurringLabel === 'Yes') {
                body['recurring'] = true;
                body['recurring-type'] = recurrenceType;

                if (recurrenceEndType === 'date') {
                    body['recurring-end-type'] = 'date';
                    body['recurring-end-date'] = moment
                        .utc(moment(recurrenceEndTime).endOf('day'))
                        .format('YYYY-MM-DD[T]HH:mm:ss');
                } else {
                    body['recurring-end-type'] = 'occurrence';
                    body['occurrences'] = parseInt(eventOccurrences);
                }
            }

            HQS_API.createMachineEvent(body)
                .then((response) => {
                    props.callBack();

                    const title = 'Event Added';
                    const details = 'A new event has been added to the calendar';
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="success" />);
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unknown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                });
            handleClose();
        }
    }

    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow}>
                <Icon root="common" name="slidercontrols-plus" size="small"></Icon>
                &nbsp;{props.children || 'Add New Event'}
            </Button>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                style={{
                    padding: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '850px',
                    maxHeight: '900px',
                    overflowY: 'auto',
                }}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="calendar" size="medium"></Icon>
                        &nbsp;&nbsp;Add New Event
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={true}>
                    <p>A new event will be placed on the calendar and all users can view the event.</p>
                    <Form.Group controlId="eventType">
                        <InputLabel label="Event Type" indicator="required" />
                        <Radio
                            label="Maintenance"
                            name="EventTypeGroup"
                            checked={eventType === 'maintenance'}
                            onChange={() => handleEventType('maintenance')}
                        />
                        <Radio
                            label="Reservation"
                            name="EventTypeGroup"
                            checked={eventType === 'reservation'}
                            onChange={() => handleEventType('reservation')}
                        />
                        <Radio
                            label="Online"
                            name="EventTypeGroup"
                            checked={eventType === 'online'}
                            onChange={() => handleEventType('online')}
                        />
                    </Form.Group>
                    <div style={{ display: eventType === 'reservation' ? '' : 'none' }}>
                        <InputLabel label="Select Organization" indicator="required" />
                        <div style={{ 'max-height': '50px', 'margin-bottom': '10px' }}>
                            {genOrgOptions(props.orgs || [])}
                        </div>
                    </div>
                    <div
                        style={{
                            display: reservationType === 'azure' ? '' : 'none',
                        }}>
                        <Input
                            placeholder="Tenant ID (UUID Version 4)"
                            onChange={(tenantIdValue) => handleTenantID(tenantIdValue)}
                            label="Tenant ID"
                            indicator="required"
                            value={tenantID}
                            fluid={true}
                            error={tenantIDError}
                        />
                    </div>

                    <InputLabel label="Select Machine" indicator="required" />
                    <div>{genMachineOptions(props.allMachines)}</div>
                    <Divider fitted />

                    <div>
                        <InputLabel label="Event Date and Time" />
                        <Checkbox label="All Day" toggle={true} onChange={handleAllDayToggle} />
                        <InputLabel label="Starts" indicator="required" />
                        <DatePicker
                            error={startTimeError}
                            key={startTime !== undefined ? startTime.toString() : 'start'}
                            type="datetime"
                            displayFormat="MM/DD/YY, h:mm a"
                            placeholder="MM/DD/YY, h:mm a"
                            minuteStep={minuteStep}
                            disablePast={true}
                            onChange={handleStartTime}
                            onTextChange={handleStartTime}
                            indicator="required"
                            value={startTime}
                        />
                        <InputLabel label="Ends" indicator="required" />
                        <DatePicker
                            error={endTimeError}
                            key={endTime !== undefined ? endTime.toString() : 'end'}
                            indicator="required"
                            type="datetime"
                            displayFormat="MM/DD/YY, h:mm a"
                            placeholder="MM/DD/YY, h:mm a"
                            minuteStep={minuteStep}
                            disablePast={true}
                            onChange={handleEndTime}
                            value={endTime}
                        />
                    </div>
                    <Divider fitted />

                    <div>
                        <InputLabel label="Recurring" />
                        <Checkbox label={recurringLabel} toggle={true} onChange={handleRecurring} />
                    </div>
                    <div
                        style={{
                            display: recurringLabel === 'Yes' ? '' : 'none',
                        }}>
                        <InputLabel label="Frequency" indicator="required" />
                        <Radio
                            label="Daily"
                            name="RecurrenceGroup"
                            checked={recurrenceType === 'daily'}
                            onChange={() => handleRecurrenceType('daily')}
                        />
                        <Radio
                            label="Weekly"
                            name="RecurrenceGroup"
                            checked={recurrenceType === 'weekly'}
                            onChange={() => handleRecurrenceType('weekly')}
                        />
                        <Radio
                            label="Monthly"
                            name="EventTypeGroup"
                            checked={recurrenceType === 'monthly'}
                            onChange={() => handleRecurrenceType('monthly')}
                        />
                        <InputLabel label="Ends" indicator="required" />
                        <Radio
                            label="By date"
                            name="EndRecurrenceGroup"
                            checked={recurrenceEndType === 'date'}
                            onChange={() => handleRecurrenceEndType('date')}
                        />
                        <Radio
                            label="By # of occurrences"
                            name="EndRecurrenceGroup"
                            checked={recurrenceEndType === 'occurrences'}
                            onChange={() => handleRecurrenceEndType('occurrences')}
                        />
                        <div>
                            {recurrenceEndType === 'date' ? (
                                <DatePicker
                                    error={recurrenceEndDateError}
                                    key={
                                        recurrenceEndTime !== undefined
                                            ? recurrenceEndTime.toString()
                                            : 'end-recurrence'
                                    }
                                    indicator="required"
                                    type="date"
                                    displayFormat="MM/DD/YY"
                                    placeholder="MM/DD/YY"
                                    disablePast={true}
                                    onChange={setRecurrenceEndTime}
                                    value={recurrenceEndTime}
                                />
                            ) : (
                                <Input
                                    error={recurrenceEndDateError}
                                    type="number"
                                    min="0"
                                    value={eventOccurrences}
                                    onChange={(value) => setEventOccurrences(value)}
                                />
                            )}
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleCreate}>
                        Add Event
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreateCalendarEventForm;
