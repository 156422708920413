import React from 'react';
import { roundTwoDecimalPlaces } from '../utils/helpers';
import './PlanType.css';

function PendingCredits(props) {
    let pendingHQC = props.pendingHQC !== undefined ? props.pendingHQC : 0;
    pendingHQC = roundTwoDecimalPlaces(pendingHQC);

    return (
        <div className="hqs-plan-container">
            <div className="hqs-plan-values">{pendingHQC}</div>
        </div>
    );
}

export default PendingCredits;
