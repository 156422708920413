// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import {
    Button,
    Icon,
    InputLabel,
    Notification,
    Modal,
    Input,
} from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details, severity  }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const SetOrgQuotaForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [quota, setQuota] = useState(0);

    function handleUpdate() {
        const body = {};
        body['hqc_quota'] = Number(quota);

        // enable org quota
        HQS_API.enableOrganizationQuota(props.org, body)
            .then((response) => {
                if (props.callBack) {
                    props.callBack();
                }
                const title = 'Org Quota Enabled';
                const details = 'Org HQC quota has been enabled successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />,
                );
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });

        handleClose();
    }

    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow}>
                Enable HQC Quota
            </Button>

            <Modal
                open={show}
                className="hqs-umui-information-modal"
                onClose={handleClose}
                size="small"
                style={{
                    padding: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon
                            root="common"
                            name="portfolio-cost"
                            size="medium"
                        ></Icon>
                        &nbsp;&nbsp;Enable HQC quota on {props.org}
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <InputLabel label="HQC Quota:" />
                    <Input
                        type="number"
                        min="0"
                        value={quota}
                        onChange={(updated_quota) =>
                            setQuota(updated_quota)
                        }
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleUpdate}>
                        Set
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SetOrgQuotaForm;
