// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Modal, Badge } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import { isVersionNumber } from '../utils/helpers';
import ToastNotification from '../Notifications/ToastNotification';

const logo = require('../icons/icon_alert_critical.svg');

// Custom hook for handling the delete user form
const useDeleteTagForm = (closeCallback, props) => {

    async function deleteTag(tag) {

        let queryParams = {
            mode: props.mode
        };

        let key = props.tag.key

        HQS_API.deleteTag(tag['id'], queryParams)
            .then((response) => {
                const title = 'Success!';
                const details = "Deleted tag: '" + key + "'";
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                    />
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unkown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
                
            });
    }

    const handleDelete = (event) => {
        if (event) {
            event.preventDefault();
        }
        deleteTag(props.tag);
        closeCallback();
    };

    return { handleDelete };
};

const DeleteTagForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [key, setKey] = useState(props.tag.key);


    const { handleDelete } = useDeleteTagForm(handleClose, props);
    const showIcon = (show_icon) => {
        if (show_icon) {
            return (
                <div>
                    <Icon
                style={{ cursor: 'pointer' }}
                root="common"
                name="delete"
                size="small"></Icon>
            &nbsp;{props.children || 'Delete'}
                </div>
            )
        } else {
            return (
                <div>Delete</div>
            )
        }
    }
    return (
        <div>
            <div onClick={handleShow}>
                {showIcon(props.show_icon)}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-delete-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Delete</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                    <Badge color="red">WARNING!</Badge> If there are any items actively tagged with this key those references will be automatically removed as well.
                    </p>
                    <p>
                    Are you sure you want to continue?
                    </p>
                    <Button type="primary" size="small" onClick={handleDelete}>
                        Confirm
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default DeleteTagForm;
