/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const logo = require('../icons/icon_alert_critical.svg');

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const PauseJobsForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (props.jobs.size > 0) {
            setShow(true);
        }
    };

    const pauseJobs = (jobs) => {
        jobs.forEach((job) => pauseJob(job));
        const title = 'Jobs Pausing';
        const details = 'Attempting to pause all selected jobs';
        if (props.callback) {
            props.callback();
        }
        toast(<ToastNotification closeToast={false} title={title} details={details} />);
    };

    async function pauseJob(jobId) {
        HQS_API.pauseJob(jobId)
            .then((response) => {
                // do nothing
            })
            .catch((error) => {
                var title = 'Error, job id: ' + jobId;
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.message;
                }
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
            });
    }

    const handlePause = (event) => {
        if (event) {
            event.preventDefault();
        }
        pauseJobs(props.jobs);
        handleClose();
    };

    return (
        <div>
            <div
                onClick={handleShow}
                style={{
                    cursor: 'pointer',
                    padding: '8px 0px 0px 5px',
                }}>
                <Icon root="common" name="playback-stop" size="medium" />
            </div>

            <Modal open={show} onClose={handleClose} size="small" className="hqs-umui-delete-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Pause Jobs</div>
                </Modal.Header>
                <Modal.Content>
                    <p>This will send a request to pause the selected jobs. Are you sure?</p>
                    <Button type="primary" size="small" onClick={handlePause}>
                        Yes
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default PauseJobsForm;
