// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import DeleteTagForm from '../Forms/DeleteTag';
import ManageTagForm from '../Forms/ManageTag';
import Dropdown from 'react-bootstrap/Dropdown';

const generateOptions = (selectedRow, mode, callback) => {
    let actionsList = [];

    if (selectedRow !== undefined && selectedRow !== null) {
        const tagDetails = {
            id: selectedRow[0]['id'],
            key: selectedRow[0]['key'],
            description: selectedRow[0]['description'] !== undefined ? selectedRow[0]['description']: "",
            allowed: selectedRow[0]['allowed'] !== undefined? selectedRow[0]['allowed']: []
        };


        const userMode = mode
        
        // only allow deletes for inactive terms and schedules
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <DeleteTagForm
                    show_icon={true}
                    tag={tagDetails}
                    mode={userMode}
                    callback={callback}
                />
            </Dropdown.Item>,
        );
        
        //  add manage permissions
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option">
                <ManageTagForm
                    tag={tagDetails}
                    mode={userMode}
                    callback={callback}
                />
            </Dropdown.Item>
        );

        
    } else {
        actionsList.push(
            <Dropdown.Item
                className="hqs-custom-actions-select-option"
                disabled={true}
            >
                <div>Select a row...</div>
            </Dropdown.Item>,
        );
    }

    return actionsList;
};

const AdminTagActionSelect = (props) => {
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className="ui small button primary hqs-custom-dropdown"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            &#x25bd;&nbsp;
            {children}
        </div>
    ));
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {generateOptions(
                    props.selectedRow,
                    props.mode,
                    props.callback,
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AdminTagActionSelect;
