/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/
import React, { useState, useRef } from 'react';
import { DataTable } from '@scuf/datatable';
import { ToastContainer } from 'react-toastify';
import { Button, Select, DatePicker, Checkbox, Icon, BadgedIcon, Popup, Accordion } from '@scuf/common';
import {
    isElevatedViewMode,
    isNullOrEmpty,
    allFilterSelections,
    toISODate,
} from '../utils/helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ColumnToggleList from '../Other/ColumnToggleList';
import './AuditTab.css';
import moment from 'moment';
import AuditDataForm from '../Forms/AuditData';

const FilterPanel = DataTable.HeaderBar.FilterPanel;

export function AuditTab(props) {

    const entityTypeOptions = [
        { value: 0, text: 'Org', type: 'org'},
        { value: 1, text: 'User', type: 'user'},
        { value: 2, text: 'User Group', type: 'user-group'},
        { value: 3, text: 'Access Plan', type: 'access-plan'},
        { value: 4, text: 'Job', type: 'job'},
        { value: 5, text: 'Calendar', type: 'calendar'}
    ]

    const actionOptions = [
        { value: 0, text: 'All Actions', type: null}
    ]

    const requestorOptions = [
        { value: 0, text: 'All Requestors', type: null}
    ]
        
    const pageOptions = [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 200, text: '200' },
    ];
    const page = props.page;
    const keys = props.page.keys;
    const visited = props.page.visited;
    const maxPages = 5;
    const maxFilterPanelHeight = '325px';

    // setup session storage names
    const rangeTo = 'hqs-' + props.mode + '-audit-table-range-to';
    const rangeFrom = 'hqs-' + props.mode + '-audit-table-range-from';
    const tableEntityType = 'hqs-' + props.mode + '-admin-audit-table-entity-type';
    const tableEntityAction = 'hqs-' + props.mode + '-admin-audit-table-action';
    const tableActionRequestor = 'hqs-' + props.mode + '-admin-audit-table-action-requestor';
    const tablePageSize = 'hqs-' + props.mode + '-admin-audit-table-page-size';
    const tablePageSort = 'hqs-' + props.mode + '-admin-audit-table-page-sort';
    const tablePageSortBy = 'hqs-' + props.mode + '-admin-audit-table-page-sortBy';
    const tablePage = 'hqs-' + props.mode + '-admin-audit-table-page';
    const tableFilter = 'hqs-' + props.mode + '-audit-table-filter-';
    const tableColumns = 'hqs-' + props.mode + '-audit-table-columns';
    const tableFilters = 'hqs-' + props.mode + '-audit-table-filters';

    // allowed fields for admin
    const adminFields = [
        'id',
        'entity-type',
        'action',
        'data',
        'requestor',
        'create-date'
    ];

    const allowedFields = {
        operator: adminFields,
        admin: adminFields,
    };

    //update the props.requestors
    const hasRequestor = (val) => {
        return requestorOptions.some(item => val.text === item.text);
    }
    
    const addSystemUsers = () => {
        
        let systemUsers = ['Layer4']

        systemUsers.forEach((item) => {

            //make sure the reqeustor list doesn't already have this user (to avoid duplication)
            if(!hasRequestor(item)){
                //set the value to length of the requestor options so that it appears at the end
                let value = requestorOptions.length
                requestorOptions.push({ value: value, text: item})
            }

        })

    }

    props.requestors.forEach((oo, ii) => {
        requestorOptions.push({ value: ii + 1, text: oo.email });
    });

    //update the reqeustor list to include the system users (do this after setting up the regular list of users)
    addSystemUsers()

    // Check if we have any values stored in session storage
    let toValue = sessionStorage.getItem(rangeTo) !== null ? moment(sessionStorage.getItem(rangeTo)) : '';
    let fromValue = sessionStorage.getItem(rangeFrom) !== null ? moment(sessionStorage.getItem(rangeFrom)) : '';

    let dateErrorValue = ' ';
    if (toValue !== '' && fromValue !== '') {
        dateErrorValue = '';
    }

    let selectedEntityTypeValue = sessionStorage.getItem(tableEntityType) !== null ? parseInt(sessionStorage.getItem(tableEntityType)) : ''; //default to all types

    let selectedEntityTypeError = ''
    if (isElevatedViewMode(props.mode)) {
        selectedEntityTypeError = selectedEntityTypeValue < 0 ? ' ' : '';
    }

    let selectedActionValue = sessionStorage.getItem(tableEntityAction) !== null ? parseInt(sessionStorage.getItem(tableEntityAction)) : ''; //default to all types

    let selectedActionError = ''
    if (isElevatedViewMode(props.mode)) {
        selectedActionError = selectedActionValue < 0 ? ' ' : '';
    }

    let selectedRequestorValue = sessionStorage.getItem(tableActionRequestor) !== null ? parseInt(sessionStorage.getItem(tableActionRequestor)) : ''; //default to all types

    let selectedRequestorError = ''
    if (isElevatedViewMode(props.mode)) {
        selectedRequestorError = selectedRequestorValue < 0 ? ' ' : '';
    }

    let defaultPage = {
        number: 1,
        size: 25,
        keys: { 1: null },
        visited: new Set([1]),
        client: false,
        reverse: true, // by default we want newest to oldest
        search: null,
    };

    let selectedPageSizeValue =
        sessionStorage.getItem(tablePageSize) !== null ? parseInt(sessionStorage.getItem(tablePageSize), 10) : 25; //default

    let selectedPageSortValue =
        sessionStorage.getItem(tablePageSort) !== null ? JSON.parse(sessionStorage.getItem(tablePageSort)) : true; //default is reverse

    let selectedPageSortByValue =
        sessionStorage.getItem(tablePageSortBy) !== null
            ? JSON.parse(sessionStorage.getItem(tablePageSortBy))
            : 'create-date'; //default is create-date

    let selectedPageValue =
        sessionStorage.getItem(tablePage) !== null ? JSON.parse(sessionStorage.getItem(tablePage)) : defaultPage;

    let selectedFilters =
        sessionStorage.getItem(tableFilters) !== 'undefined'
            ? JSON.parse(sessionStorage.getItem(tableFilters))
            : {
                  actions: {},
                  requestors: {}
              };



    //fill in the action options if we know the selected entity type from the session
    //make sure we know about the actions 
    if (selectedEntityTypeValue !== "" && selectedEntityTypeValue >= 0 && props.actions !== undefined){
        let entityType = entityTypeOptions[selectedEntityTypeValue]['type']
   
        //make sure we have an entity type and the entity type is a property of the passed in actions

        if (entityType && props.actions.hasOwnProperty(entityType)){
            // fill in the aption options for the selected type
            props.actions[entityType].forEach((oo, ii) => {
                actionOptions.push({ value: ii + 1, text: oo });
                    
            });
        }

       
    }

    const [paramState, setParamState] = useState({
        to: toValue,
        from: fromValue,
        dateError: dateErrorValue,
        entityTypeError: selectedEntityTypeError,
        selectedEntityType: selectedEntityTypeValue,
        actionError: selectedActionError,
        actionOptionsFiltered: actionOptions,
        selectedAction: selectedActionValue,
        requestorError: selectedRequestorError,
        selectedRequestor: selectedRequestorValue,
        selectedPageSize: selectedPageSizeValue,
        selectedPageSort: selectedPageSortValue,
        selectedPageSortBy: selectedPageSortByValue,
        mode: props.mode,
        entityTypeOptions,
        pageOptions,
        page: selectedPageValue,
        filters: selectedFilters,
    });
    const fetchTimer = useRef(-1);

    //define a quick representation of the first page
    const firstPage = {
        number: 1,
        size: paramState.selectedPageSize,
        keys: { 1: null },
        visited: new Set([1]),
        client: false,
        reverse: paramState.selectedPageSort,
        search: null,
        sort: paramState.selectedPageSortBy,
    };

    // This function will check if we have any filter values
    // currently stored in session storage
    const fetchSavedFilters = (filterName) => {
        // fetch saved filters
        let savedFilters = sessionStorage.getItem(tableFilter + filterName);
        let savedFilterObject = JSON.parse(savedFilters);
        if (savedFilterObject == null) {
            savedFilterObject = {};
        }
        return savedFilterObject;
    };

    const fetchSavedSort = () => {
        // fetch saved filters
        let savedFilters = sessionStorage.getItem(tablePageSort);
        let savedFilterObject = JSON.parse(savedFilters);
        if (savedFilterObject == null) {
            savedFilterObject = true;
        }
        return savedFilterObject;
    };

    const fetchSavedSortBy = () => {
        // fetch saved filters
        let savedFilters = sessionStorage.getItem(tablePageSortBy);
        let savedFilterObject = JSON.parse(savedFilters);
        if (savedFilterObject == null) {
            //default to submit-date if we couldn't find anything
            savedFilterObject = 'create-date';
        }
        return savedFilterObject;
    };

    const [filterState, setFilterState] = useState({
        requestors: fetchSavedFilters('requestors'),
        actions: fetchSavedFilters('actions')
    });


    const [refreshEnabled, setRefreshEnabled] = useState(false);

    const onRangeSelect = ({ to, from }) => {
        //if they are changing the date, we should always initialize it to reverse sorting
        let newPage = firstPage;
        newPage.reverse = true;
        const newState = {
            ...paramState,
            to: to,
            from: from,
            dateError: to && from ? '' : ' ',
            page: newPage,
            selectedPageSort: true,
            selectedPageSortBy: 'create-date',
            search: null,
        };
        setParamState(newState);
        fetchIfValid(newState);
        sessionStorage.setItem(rangeFrom, from !== undefined ? from.toISOString() : '');
        sessionStorage.setItem(rangeTo, to !== undefined ? to.toISOString() : '');
        //update the page sort
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));
    };

    const onRefresh = () => {
        //a refresh implies we are getting the latest (page 1).
        //we should make sure to keep any existing filters that have been applied instead of wiping them out
        let newPage = firstPage;
        if (logs == undefined || logs.length == 0) {
            //ensure default sort order when loading page for first time.
            newPage.reverse = true;
        }
        onPageChange(newPage);
    };

    const onEntityTypeSelect = (selectedEntityType) => {
        let filters = {
            actions: {},
            requestors: {}
        };

        let newPage = firstPage;
        newPage.reverse = true;

       //add the default all orgs, but give it a specific type since we know it refers to all orgs of this type

       let entityType = entityTypeOptions[selectedEntityType]['type']
       let actionsWithType = [{ value: 0, text: 'All Actions', type: entityType}]

        //if we have a valid entity type
        if(entityType && props.actions.hasOwnProperty(entityType)){
            
            //process each org and filter out hte ones that don't match this type
            props.actions[entityType].forEach((oo, ii) => {
                actionsWithType.push({ value: ii + 1, text: oo });
                    
            });
        }
 
        const newState = {
            ...paramState,
            selectedEntityType,
            selectedAction: 0, //reset the actions
            selectedRequestor: 0,// reset the requestors
            selectedPageSort: true,
            selectedPageSortBy: 'create-date',
            actionOptionsFiltered: actionsWithType,
            entityTypeError: selectedEntityType < 0 ? ' ' : '',
            page: newPage,
            filters: filters,
            search: null,
        };

        setParamState(newState);
        setFilterState(filters);
        fetchIfValid(newState);

        

        //update the session state
        sessionStorage.setItem(tableEntityType, selectedEntityType);
        sessionStorage.setItem(tableEntityAction, 0); //reset the action to all actions
        sessionStorage.setItem(tableActionRequestor, 0); //reset the requestor to all requestors
        sessionStorage.setItem(tablePage, JSON.stringify(newPage));
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));
    };


    const onActionSelect = (selectedAction) => {
        let filters = {
            actions: {},
            requestors: {}
        };

        let newPage = firstPage;
        newPage.reverse = true;

        const newState = {
            ...paramState,
            selectedAction,
            selectedPageSort: true,
            selectedPageSortBy: 'create-date',
            actionError: selectedAction < 0 ? ' ' : '',
            page: newPage,
            filters: filters,
            search: null,
        };

        setParamState(newState);
        setFilterState(filters);
        fetchIfValid(newState);

        //update the session state
        sessionStorage.setItem(tableEntityAction, selectedAction);
        sessionStorage.setItem(tablePage, JSON.stringify(newPage));
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));
    };

    const onRequestorSelect = (selectedRequestor) => {
        let filters = {
            actions: {},
            requestors: {}
        };

        let newPage = firstPage;
        newPage.reverse = true;

        const newState = {
            ...paramState,
            selectedRequestor,
            selectedPageSort: true,
            selectedPageSortBy: 'create-date',
            requestorError: selectedRequestor < 0 ? ' ' : '',
            page: newPage,
            filters: filters,
            search: null,
        };

        setParamState(newState);
        setFilterState(filters);
        fetchIfValid(newState);

        
        //update the session state
        sessionStorage.setItem(tableActionRequestor, selectedRequestor);
        sessionStorage.setItem(tablePage, JSON.stringify(newPage));
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));
    };







   

    const onPageSizeSelect = (size) => {
        //if we're on the first page already and the next page doesn't point to anything, then we know we have all the data.
        //when this happens there's no need to fetch new data until the page size is smaller than the total logs found
        //otherwise, just fetch new data
        if (page.number == 1 && page.keys[page.number + 1] == undefined && size > logs.length) {
            const newState = {
                ...paramState,
                selectedPageSize: size,
            };

            //update the state
            setParamState(newState);

            //update the session's page size
            sessionStorage.setItem(tablePageSize, size);
        } else {
            //jump to first page to get the latest and the new page keys
            //Reset the visited lookup since there could be new data

            let newPage = firstPage;
            newPage.size = size;

            //set the reverse setting to be the current
            newPage.reverse = page.reverse;

            //fall back on client page sizing when we don't know the org
            if (selectedEntityType == 0) {
                newPage.client = true;
            }

            onPageChange(newPage);
        }
    };

    const onPagePrev = () => {
        // decrement the page number, reuse page size, get the prev page reference
        if (keys[page.number - 1] !== undefined) {
            let newPage = {
                number: page.number - 1,
                size: page.size,
                keys: keys,
                visited: page.visited,
                client: selectedEntityType == 0 ? true : false,
                reverse: page.reverse,
                search: page.search,
                sort: page.sort,
            };
            onPageChange(newPage);
        }
    };

    const onPageNext = () => {
        // increment the page number, reuse page size, get the next page reference
        if (keys[page.number + 1] !== undefined) {
            let newPage = {
                number: page.number + 1,
                size: page.size,
                keys: keys,
                visited: page.visited.add(page.number + 1),
                client: selectedEntityType == 0 ? true : false,
                reverse: page.reverse,
                search: page.search,
                sort: page.sort,
            };
            onPageChange(newPage);
        }
    };

    const onFilterChange = (filters) => {
        //the filters we want to use are the ones that are active
        const newFilters = {
            actions: filters.actions,
            requestors: filters.requestors
        };

        setFilterState(filters);

        //if all orgs, then we need to filter on the client side
        let page = firstPage;
        if (selectedEntityType == 0) {
            page.client = true;
        }

        //remember the current page sorting when we apply the filter
        page.reverse = selectedPageSort;

        //remember the sort by field
        page.sort = selectedPageSortBy;

        onPageChange(page, newFilters);
    };

    const onPageChange = (page, filters) => {
        //if the filters were not passed in, then fall back to the current filterstate
        //this happens when we're paging next,previous,jumps
        if (filters == undefined || filters == null) {
            filters = filterState;
        }

        const newState = {
            ...paramState,
            selectedPageSize: page.size,
            selectedPageSort: page.reverse,
            selectedPageSortBy: page.sort,
            page: page,
            filters: filters
        };

        //update the state
        setParamState(newState);

        fetchIfValid(newState, 0);

        //update the session information. page size is separate from the overall page dict to not have a delay when updating dropdown
        sessionStorage.setItem(tablePageSize, page.size);
        sessionStorage.setItem(tablePage, JSON.stringify(page));
        sessionStorage.setItem(tableFilters, JSON.stringify(filters));
        sessionStorage.setItem(tablePageSort, JSON.stringify(page.reverse));
        sessionStorage.setItem(tablePageSortBy, JSON.stringify(page.sort));
    };

    //show or hide the next button
    const isPageNextDisabled = keys != undefined && keys[page.number + 1] == undefined;

    //show or hide the prev button
    const isPagePrevDisabled = page.number <= 1;

    const fetchIfValid = (newState, timeOut = 1000) => {
        //set variables for dates
        let from = '';
        let to = '';

        //check if the date or entity have not been selected yet. 
        if (newState.dateError || newState.entityTypeError) {
            return;
        }

        //the entity and dates have been selected, prepare the data
        from = newState.from.toISOString();
        to = newState.to.toISOString();
        
        if (fetchTimer.current) window.clearTimeout(fetchTimer.current);

        //get the entity type
        let entity = entityTypeOptions[newState.selectedEntityType]['type']

        //get an action (optional)
        let action = null
        if(newState.selectedAction){
            action = newState.actionOptionsFiltered[newState.selectedAction]['text']
        }

        //get a requestor (optional)
        let requestor = null
        if(newState.selectedRequestor){
            requestor = requestorOptions[newState.selectedRequestor]['text']
        }

        //fetch the new data
        fetchTimer.current = window.setTimeout(
            props.onChange,
            timeOut,
            entity,
            action,
            requestor,
            from,
            to,
            newState.page,
            newState.filters
        );

        // enable refresh button
        setRefreshEnabled(true);
    };

    const countFilterKey = (filterName) => {
        let count = 0;
        if (filterState.hasOwnProperty(filterName)) {
            let filters = filterState[filterName];
            if (filters !== undefined && Object.keys(filters).length != 0) {
                Object.keys(filters).forEach((s) => {
                    if (!filters[s]) {
                        count++;
                    }
                });
            }
        }
        return count;
    };

    const countFilters = () => {
        let actions = countFilterKey('actions');
        let requestors = countFilterKey('requestors');
       

        return actions + requestors;
    };

    const resetFilters = () => {
        const newState = {
            actions: {},
            requestors: {}
        };

        Object.keys(filterState).forEach((filterName) => {
            sessionStorage.setItem(tableFilter + filterName, JSON.stringify({}));
        });

        //go back to page 1
        onFilterChange(newState);
    };

    const resetSearchFilters = () => {
    
        let newPage = firstPage;
        newPage.reverse = true;

        const newState = {
            ...paramState,
            selectedAction: 0,
            selectedRequestor: 0,
            selectedPageSort: true,
            selectedPageSortBy: 'create-date',
            actionError: selectedAction < 0 ? ' ' : '',
            requestorError: selectedRequestor < 0 ? ' ': '',
            page: newPage,
            search: null,
        };

        setParamState(newState);
        fetchIfValid(newState);


        //update the session state
        sessionStorage.setItem(tableEntityAction, 0);
        sessionStorage.setItem(tableActionRequestor, 0);
    };

    /**
     * Updates a object (initially empty) and returns a new object.
     * @param {Object} oldSet
     * @param {string} value
     * @param {boolean} active
     * @returns {Object}
     */
    const updateFilterSet = (oldSet, value, active, filterName, allSelection = false) => {
        // check if "All <filter-name>"" selection was done
        if (allSelection) {
            if (active) {
                oldSet = {};
            } else {
                Object.entries(props.filters[filterName]).forEach(([key, value]) => {
                    oldSet[key] = false;
                });
            }
        } else {
            oldSet[value] = active;
            if (!active) {
                oldSet[allFilterSelections[filterName]] = false;
            }
        }

        // update session storage value
        sessionStorage.setItem(tableFilter + filterName, JSON.stringify(oldSet));

        return oldSet;
    };

    const dateRenderer = (cell, row) => {
        const data = cell;
        var createDate = '';

        if (data) {
            createDate = toISODate(data, 'detailed');
        }
        return <span>{createDate}</span>;
    };

   
    const customCaret = (order, column) => {
        //get current sorting
        let fetchedSort = fetchSavedSort();

        // get the current sort by field
        let fetchedSortBy = fetchSavedSortBy();

        // For sorting by 'submit-date' we need to default to sorting by asc first since the results initially come back in desc order
        if (fetchedSort) {
            order = 'desc';
        } else {
            order = 'asc';
        }

        // check if the column we're processing matches the sort by field
        let activeSort = undefined;
        if (column && 'dataField' in column && fetchedSortBy == column['dataField']) {
            // update the value with the current column
            activeSort = column['dataField'];
        }

        // if the current column we're rendering matches the sort that was clicked and we found audit log data then set this column as active
        let isActiveSortColumn = activeSort !== undefined && activeSort == fetchedSortBy;

        // build the appropriate sort caret based on if the column we're rendering is actively sorted
        let sorted = <span className={isActiveSortColumn ? 'caret caret-active' : 'caret'}></span>;

        //define the 'desc' elements
        let activeDesc = <span className="dropup">{sorted}</span>;

        //define the 'asc' elements
        let activeAsc = <span className="dropdown">{sorted}</span>;

        //show the up and down arrows with their default (inactive) coloring
        let inactiveSort = (
            <span className="order">
                <span className="dropup">
                    <span className="caret"></span>
                </span>
                <span className="dropdown">
                    <span className="caret"></span>
                </span>
            </span>
        );

        if (!order)
            //if no order, return the default inactive sort
            return inactiveSort;
        else if (order === 'asc') {
            // show the active sort on this column in ascending order
            if (isActiveSortColumn) {
                return activeAsc;
            } else {
                //if the current column we're rendering doesn't match the applied sort, revert this to the default inactive sort so it's clear this is not be used
                return inactiveSort;
            }
        } else if (order === 'desc') {
            // show the active sort on this column in descending order
            if (isActiveSortColumn) {
                return activeDesc;
            } else {
                //if the current column we're rendering doesn't match the applied sort, revert this to the default inactive sort so it's clear this is not be used
                return inactiveSort;
            }
        }
        return null;
    };

    const onTableChange = (type, newState) => {
        //handle remote sorting
        if (type == 'sort') {
            if (newState.sortField == 'create-date') {
                onSortBy(newState.sortField);
            }
        }
    };


    const onSortBy = (sort) => {
        //sort by create date
        let newPage = firstPage;
        let reverse = !page.reverse;

        //if we were sorting on new field than the one we've previously sorted on, make sure we start by ordering in desc (newest)
        if (page.sort != sort) {
            reverse = true;
        }

        newPage.reverse = reverse; //!page.reverse
        //check if we need to enable client paging/sorting
        if (selectedEntityType == 0) {
            newPage.client = true;
        }

        if (sort) {
            newPage.sort = sort;
        }

        //store the sort setting
        sessionStorage.setItem(tablePageSort, JSON.stringify(newPage.reverse));

        // set this if we want to remember the sort by on page refresh
        sessionStorage.setItem(tablePageSortBy, JSON.stringify(newPage.sort));
        onPageChange(newPage);
    };

    const {
        from,
        to,
        entityTypeError,
        selectedEntityType,
        actionError,
        selectedAction,
        actionOptionsFiltered,
        requestorError,
        selectedRequestor,
        dateError,
        mode,
        selectedPageSize,
        selectedPageSort,
        selectedPageSortBy,
    } = paramState;

    const getFilterProps = (filterName) => {
        //helper function to return the current filters from the passed in props.
        if (props.filters !== undefined && props.filters.hasOwnProperty(filterName)) {
            return props.filters[filterName];
        } else {
            return {};
        }
    };

    const getActive = (filters, key) => {
        //determines the state of the checked/unchecked toggles

        //if no filters are selected (default)
        if (isNullOrEmpty(filters)) {
            return true;
        } else {
            // if some filters are selected, return the 'checked' status stored in the filters object
            if (filters[key] !== undefined) {
                return filters[key];
            } else {
                //otherwise show 'checked' since we haven't 'disabled/excluded' them from overall filters
                return true;
            }
        }
    };

    const getEntityType = (entity) => {

        let type = ""
        
        if(entity != 'All Types'){
           if(props.entityTypes[entity]){
                type = props.entityTypes[entity]
            }

        }

        return type
    }

    

    const getSelectedEntityType = (entity) =>{
        
        if (entity == 'All Types'){
            if(selectedEntityType > 0){
                return entityTypeOptions[selectedEntityType]['type']
            }
        
        }
            
        return getEntityType(entity)

    }


    // create filter options from the passed in filters.
    // we no longer want to create the filters from the passed in audit logs
    // because as soon as we apply a filter, then we lose other filterable options
    // wasn't a problem until now since we're passing in the additional filters into the API request

    const actions = getFilterProps('actions');
    const requestors = getFilterProps('requestors');
    const allFilters = props.filters;

    // ACTIONS
    const actionToggles = [];
    Object.keys(actions).forEach((aa) => {
        actionToggles.push({
            label: aa,
            active: getActive(filterState.actions, aa),
            all: aa == 'All Actions' ? true : false,
        });
    });
    // Push the 'select all' option to the very top
    const allActionsIndex = actionToggles.findIndex((item) => item.label == 'All Actions');
    actionToggles.push(...actionToggles.splice(0, allActionsIndex));

    // REQUESTORS
    const requestorToggles = [];
    Object.keys(requestors).forEach((rr) => {
        requestorToggles.push({
            label: rr,
            active: getActive(filterState.requestors, rr),
            all: rr == 'All Requestors' ? true : false,
        });
    });
    // Push the 'select all' option to the very top
    const allRequestorsIndex = requestorToggles.findIndex((item) => item.label == 'All Requestors');
    requestorToggles.push(...requestorToggles.splice(0, allRequestorsIndex));

    const tableOptions = {
        custom: true,
        sizePerPage: selectedPageSize,
        hidePageListOnlyOnePage: true,
        hideSizePerPage: true,
    };


    // Renderers

    const auditActionsRenderer = (cell, row) => {
        const auditId = row.id;
        const action =  row.action
        const date = row['create-date']
        const requestor = row.requestor
        const entity = row['entity-type']
        const data = JSON.parse(row.data);
        var audit = <AuditDataForm id={auditId} entity={entity} action={action} date={date} data={data} requestor={requestor} />

        return (
            <div style={{ display: 'flex' }}>
                {audit}
            </div>
        );
    };

    const AuditLogColumns = [
        {
            dataField: '',
            text: '',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            formatter: auditActionsRenderer,
        },
        {
            dataField: 'id',
            text: 'ACTION ID',
            hidden: allowedFields[mode].indexOf('id') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                width: '60px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'action',
            text: 'ACTION',
            hidden: allowedFields[mode].indexOf('action') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'create-date',
            text: 'ACTION DATE',
            formatter: dateRenderer,
            hidden: allowedFields[mode].indexOf('create-date') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'requestor',
            text: 'REQUESTOR',
            hidden: allowedFields[mode].indexOf('requestor') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'entity-type',
            text: 'ENTITY TYPE',
            hidden: allowedFields[mode].indexOf('entity-type') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        },
        {
            dataField: 'data',
            text: 'DATA',
            hidden: allowedFields[mode].indexOf('data') > -1 ? false : true,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'left',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: false,
            sortCaret: customCaret,
        }
        
    ];
    const [columns, setColumns] = useState([...AuditLogColumns]);

    const logs = props.logs;



    const generateFilters = () => {
        return (
            <div>
                <Button type="link" onClick={selectedAction == 0 || selectedRequestor == 0? resetFilters: resetSearchFilters} content="Reset all filters" />
                <Accordion style={{ width: '325px' }}>
                    <Accordion.Content title="Actions"  style={{ display: isElevatedViewMode(props.mode) && selectedAction == 0  ? '' : 'none' }}>
                        <div style={{ maxHeight: maxFilterPanelHeight, overflow: 'auto'}}>
                            {actionToggles.map((aa) => (
                                <FilterPanel.ToggleItem
                                    key={aa.label}
                                    {...aa}
                                    type="checkbox"
                                    checked={aa.active}
                                    onChange={(active) => {
                                        onFilterChange({
                                            ...filterState,
                                            actions: updateFilterSet(
                                                filterState.actions,
                                                aa.label,
                                                active,
                                                'actions',
                                                aa.all,
                                            ),
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Content>
                    {/* Only display the orgs filters if we're in operator/admin mode and we are searching on some sort of 'all orgs'*/}
                    
                    
                    
                    <Accordion.Content title="Requestors" style={{ display: isElevatedViewMode(props.mode) && selectedRequestor == 0  ? '' : 'none' }} >
                        <div style={{ maxHeight: maxFilterPanelHeight, overflow: 'auto'} }>
                            {requestorToggles.map((rr) => (
                              
                                <FilterPanel.ToggleItem
                                    key={rr.label}
                                    {...rr}
                                    type="checkbox"
                                    onChange={(active) => {
                                        onFilterChange({
                                            ...filterState,
                                            requestors: updateFilterSet(
                                                filterState.requestors,
                                                rr.label,
                                                active,
                                                'requestors',
                                                rr.all,
                                            ),
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Content>
                    
                </Accordion>
            </div>
        );
    };

    const loadColumnToggles = (toggleProps) => {
        // initialze column toggles in session if not already there
        let columnToggles = {};
        if (sessionStorage.getItem(tableColumns) == undefined) {
            sessionStorage.setItem(tableColumns, JSON.stringify(columnToggles));
        } else {
            columnToggles = JSON.parse(sessionStorage.getItem(tableColumns));
        }

        toggleProps.columns.forEach((column) => {
            if (columnToggles[column.dataField] !== undefined) {
                column.hidden = columnToggles[column.dataField];
            }
        });
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div style={{ border: '1px solid #d0d0d0', height: '690px' }}>
            <ToolkitProvider keyField="id" data={logs} columns={columns} columnToggle search>
                {(toolkitprops) => (
                    <div>
                        <div class="d-flex align-content-start flex-wrap justify-content-between">
                            <div
                                class="p-6"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '5px 0px 0px 5px',
                                }}>
                                {/*search bar doesn't work since we don't have all the data*/}
                                

                                    {logs.length > 0 ? (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '0px 0px 0px 0px',
                                        }}>
                                            <div style={{ padding: '10px 10px 0px 10px' }}>{'Showing'}</div>
                                            <div style={{
                                                        minWidth: '120px',
                                                        maxWidth: '120px',
                                                        
                                                    }}>
                                                            <Select   
                                                    style={{
                                                        minWidth: '120px',
                                                        maxWidth: '120px',
                                                        marginRight: '60px'
                                                    }}
                                                                placeholder="select page size"
                                                                options={pageOptions}
                                                                value={selectedPageSize}
                                                                onChange={onPageSizeSelect}
                                                            />
                                            </div>
                                            <div style={{ padding: '10px 0px 10px 10px' }}>
                                                {selectedPageSize > 0 ? 'items per page' : ''}
                                                </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                        </div>
                           
                            <div
                                id="hqs-admin-jobs-table-options"
                                className="p-6"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    padding: '5px 0px 0px 5px',
                                }}>
                                <div className="hqs-custom-table-option">
                                    <Popup
                                        element={
                                            <BadgedIcon
                                                root="common"
                                                name="filter"
                                                size="medium"
                                                badge={countFilters().toString()}
                                                color="blue"
                                            />
                                        }
                                        on="click"
                                        position="bottom right"
                                        hoverable={false}>
                                        {generateFilters()}
                                    </Popup>
                                </div>
                                <div className="hqs-custom-table-option">
                                    {loadColumnToggles(toolkitprops.columnToggleProps)}
                                    <ColumnToggleList
                                        {...toolkitprops.columnToggleProps}
                                        mode={mode}
                                        allowedColumns={allowedFields}
                                    />
                                </div>
                                
                                
                                <div
                                    onClick={onRefresh}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '6px 0px 0px 5px',
                                    }}>
                                    <Icon root="building" name="refresh" size="large" loading={props.fetchingAuditLogs} />
                                </div>

                                <div style={{ paddingLeft: '15px' }} className="hqs-table-dropdown-option">
                                    <DatePicker
                                        error={dateError}
                                        type="daterange"
                                        placeholder="select date range"
                                        rangeValue={{ from, to }}
                                        closeOnSelection={true}
                                        closeOnDocumentClick={true}
                                        disableFuture={true}
                                        onRangeSelect={onRangeSelect}
                                        onTextChange={(value, error) =>
                                            setParamState({
                                                ...paramState,
                                                dateError: error,
                                            })
                                        }
                                    />
                                </div>
                                    <div
                                            style={{ width: '250px', marginLeft: '15px', display: isElevatedViewMode(props.mode) ? '' : 'none', minHeight: '0px'}}
                                            className="hqs-table-dropdown-option">
                                            <Select style={{
                                                    minWidth: '250px',
                                                    maxWidth: '250px',
                                                    
                                                }}
                                                error={requestorError}
                                                placeholder="Select a Requestor (Optional)"
                                                options={requestorOptions}
                                                onChange={onRequestorSelect}
                                                value={selectedRequestor}
                                                search={true}
                                                
                                            />
                                        </div>
                                        <div
                                            style={{ width: '250px', marginLeft: '15px', display: isElevatedViewMode(props.mode) ? '' : 'none', minHeight: '0px'}}
                                            className="hqs-table-dropdown-option">
                                            <Select style={{
                                                    minWidth: '250px',
                                                    maxWidth: '250px',
                                                    
                                                }}
                                                error={actionError}
                                                placeholder="Select an Action (Optional)"
                                                options={actionOptionsFiltered}
                                                onChange={onActionSelect}
                                                value={selectedAction}
                                                search={true}
                                                
                                            />
                                        </div>
                                <div
                                    style={{  width: '200px', marginLeft: '15px', display: isElevatedViewMode(props.mode) ? '' : 'none', minHeight: '0px'}}
                                    className="hqs-table-dropdown-option">
                                    <Select style={{
                                            minWidth: '200px',
                                            maxWidth: '200px',
                                            paddingRight: '15px'
                                            
                                        }}
                                        error={entityTypeError}
                                        placeholder="Selelct an Entity Type"
                                        options={entityTypeOptions}
                                        onChange={onEntityTypeSelect}
                                        value={selectedEntityType}
                                        search={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hqs-admin-jobs-data">
                            <BootstrapTable
                                remote={{ sort: true }} // need to set to allow backend paging
                                noDataIndication="No records found"
                                striped
                                hover
                                headerStyle={{ background: 'red' }}
                                height="515px"
                                rowClasses="hqs-custom-table-row"
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                onTableChange={onTableChange}
                                
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>

            {logs.length > 0 ? (
                <div className="hqs-custom-table-pagination">
                    <Button
                        type="primary"
                        size="small"
                        icon={<Icon name="caret-left" root="common" />}
                        content="prev"
                        iconPosition="left"
                        disabled={isPagePrevDisabled}
                        onClick={onPagePrev}
                    />
                    <div style={{ fontWeight: 'bold' }}>{page.number}</div>
                    <Button
                        type="primary"
                        size="small"
                        icon={<Icon name="caret-right" root="common" />}
                        content="next"
                        iconPosition="right"
                        disabled={isPageNextDisabled}
                        onClick={onPageNext}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    );

    return (
        <div className="hqs-admin-view">
            <div className="hqs-admin-jobs-card">
                <div id="hqs-admin-jobs-wrapper">
                    <PaginationProvider pagination={paginationFactory(tableOptions)}>{contentTable}</PaginationProvider>
                </div>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    closeButton={false}
                    newestOnTop={true}
                    position="bottom-right"
                    toastClassName="toast-notification-wrap"
                />
            </div>
        </div>
    );
}
