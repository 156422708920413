// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import {
    Button,
    Input,
    InputLabel,
    Icon,
    Notification,
    Modal,
    Select,
} from '@scuf/common';
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { orgTypes } from '../utils/helpers';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const CreateOrgForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [orgName, setOrgName] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [orgType, setOrgType] = useState('external');
    const handleClose = () => {
        setOrgType('external');
        setShow(false)
    };


    function clearInputs() {
        setOrgName('');
        setCustomerID('');
        setOrgType('external')
    }

    function handleCreate() {
        HQS_API.createOrganization(orgName, customerID, orgType)
            .then(() => {
                const title = 'Organization was created!';
                const details = `${orgName} was created successfully!`;
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />
                );
                clearInputs();
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unkown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            });

        handleClose();
    }

    function generateOrgTypes() {
        let orgTypeList = <div></div>;
        const o = [];

        let options = [];

        if (orgTypes !== undefined){
        
            Object.keys(orgTypes).map((key, index) => {
                //skip azure since only azure jobs will create an org with this type
                if (key != 'azure'){
                    if (key == 'external'){
                        options.push({ value: key, text: orgTypes[key] + " (Default)"})
                    }else{
                        options.push({ value: key, text: orgTypes[key] })
                    
                    }
                }
            });
        }
        
        o.push(
            <Select
                options={options}
                value={orgType}
                onChange={(text) => setOrgType(text)}
                fluid={true}
                search={true}
                id="short-select"
                placeholder="Select Org Type"
                label="Org Type"
            />,
        );

        orgTypeList = (
            <div
                style={{
                    'padding-right': '20px',
                    width: '50%',
                }}
            >
                {o}
            </div>
        );

    
        return orgTypeList;
    }

    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow}>
                <Icon root="common" name="slidercontrols-plus" size="small"></Icon>
                &nbsp;&nbsp;Create Org
            </Button>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
                <Modal.Header closeButton>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="sites" size="medium"></Icon>
                        &nbsp;&nbsp;Create Organization
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>A new Organization will be created.</p>
                    <Form.Group controlId="formOrgname">
                        <Input
                            type="text"
                            label="Organization Name"
                            placeholder="Enter organization name"
                            name="orgName"
                            onChange={(text) => setOrgName(text)}
                            value={orgName}
                            fluid={true}
                            indicator="required"
                        />
                    </Form.Group>
                    <Form.Group controlId="formOrgCustomerID">
                        <Input
                            type="text"
                            label="Customer ID"
                            placeholder="Enter organization's Customer ID"
                            name="customerID"
                            onChange={(text) => setCustomerID(text)}
                            value={customerID}
                            fluid={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="formOrgType">
                       {generateOrgTypes()}
                    </Form.Group>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleCreate}>
                        Create
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreateOrgForm;
