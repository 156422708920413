/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2021-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React, { useEffect } from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Input, InputLabel, Modal, Select } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const UpdateDefaultGroupForm = (props) => {
    const [show, setShow] = useState(false);
    const [userGroups, setUserGroups] = useState(props.userGroups);
    const [defaultGroup, setDefaultGroup] = useState(props.defaultUserGroup.id);
    const [defaultGroupError, setDefaultGroupError] = useState('');

    const handleClose = () => {
        setShow(false);
        setUserGroups(props.userGroups); 
        setDefaultGroup(props.defaultUserGroup.id);
        setDefaultGroupError('');
    };
    const handleShow = () => {
        setShow(true);
        return true;
    };

    useEffect(() => {
        setDefaultGroup(defaultGroup);
    }, [defaultGroup]);


    function genDefaultGroupOptions() {
        const formattedGroups = [];
       
        userGroups.forEach((group) => {
            let formattedGroup = { value: group.id, text: group.name };
            formattedGroups.push(formattedGroup);
        });
        formattedGroups.sort((a, b) => a.text.localeCompare(b.text));
        return (
            <Select
                options={formattedGroups}
                placeholder="Select Default Group"
                onChange={(id) => handleDefaultGroup(id)}
                reserveSpace={true}
                fluid={true}
                search={true}
                error={defaultGroupError}
                value={defaultGroup}
            />
        );
    }

    function handleDefaultGroup(groupId) {
        setDefaultGroup(groupId);
    }
     
    function handleUpdate() {
        // set user group id
        let body = {};
        // extract group ids
        let groupIds = []
        userGroups.forEach((group) => {
            groupIds.push(group.id);
        });
        body['user-groups'] = groupIds;
        body['default-user-group'] = defaultGroup;
        
        // update user group
        HQS_API.updateUserGroupId(props['user'], body)
            .then((response) => {
                const title = 'Default Group Updated';
                const details = 'Default Group has been updated successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />,
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });

            handleClose();
        
    }

    return (
        <div>
            <div style={{display:'flex'}}>
                <span>{props.defaultUserGroup.name}</span>
                <div onClick={handleShow} style={{ cursor: 'pointer', paddingLeft:'15px', marginTop:'-1px'}} className="hqs-blue-icon">
                    update
                </div>
            </div>
            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="grid" size="medium"></Icon>
                        &nbsp;&nbsp;Update Default Group
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>
                        Update your Default Group
                    </p>
                    <Form.Group>
                        <InputLabel label="Default Group" indicator="required" />
                        <div style={{ height: '90px' }}>
                            {genDefaultGroupOptions()}
                        </div>
                        <div></div>
                    </Form.Group>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={() => handleUpdate()}>
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateDefaultGroupForm;
