/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, {
    Component,
    useContext,
    useRef,
    useState,
    useEffect,
} from 'react';
import { Button, Input, InputLabel, Notification } from '@scuf/common';
import { SignIn } from 'aws-amplify-react';
import './CustomAuth.css';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import { ToastContainer } from 'react-toastify';
import {
    BrowserRouter,
    Switch,
    Route,
    NavLink,
    Redirect,
    Routes,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import * as API_LOGIN from '../utils/api-login';
import { AmplifyConfig, MsalLoginRequest } from '../config';
import {
    authChallenges,
    configureAuth,
    agreementChallenges,
    isLicensedPlan,
    getSoftwareFromChallenge,
    recoveryActions,
    handleSignIn,
    findLocalItems,
} from '../utils/helpers';
import { MsalContext } from '@azure/msal-react';
import { SignInAzure } from '../Forms/SignInAzure';
import AuthContext from '../../context/AuthProvider';
import '../App/App.css';
import './CustomAuth.css';

const logo = require('./QuantinuumLogo.svg');
// const MACHINE_API = new HQS_MACHINE_API();

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

class CustomSignIn extends Component {
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            federated: 'false',
        };
        this.tryCustomAuthLogin = this.tryCustomAuthLogin.bind(this);
    }

    recoverPassword(){
        sessionStorage.setItem("recover-action", recoveryActions.forgotPwd)
        window.location.replace("/recover")
    }

    tryCustomAuthLogin() {
        //check if the user has been authenticated with Microsoft (or a federated provider)
        const accounts = findLocalItems('msal.account.keys');
        const isAuthenticated = accounts.length > 0;

        if (isAuthenticated) {
            let account = findLocalItems(accounts[0]['val'][0])[0]['val'];
            localStorage.setItem('federated', 'true');
            let username = account.username;
            this.setState({ username: username, federated: 'true' });

            // let idToken = accounts[0].idToken;
            let idToken = undefined;
            let tokenKeys = findLocalItems('msal.token.keys');
            if (tokenKeys.length > 0) {
                idToken = JSON.parse(
                    sessionStorage.getItem(tokenKeys[0]['val']['idToken'][0]),
                )['secret'];
            }

            API_LOGIN.login(username, idToken, 'true')
                .then((response) => {
                    localStorage.setItem('username', username);
                    handleSignIn(response);
                })
                .catch((err) => {
                    console.log(err);
                    if (err !== undefined) {
                        err = err.response.data.error;
                        const title = 'Unable to login';
                        let details = err.text;
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="critical"
                            />,
                        );
                    }
                });
        }
    }

    async signIn() {
        //override the native sign in logic handle

        const username = this.state.username;
        //get the confirmed password since we know they match now
        const password = this.state.password;

        //if missing username or password, return an error
        if (!username || !password) {
            //catch any other error and display the message to user
            const title = 'Unable to login';
            const details = 'Missing username or password. Please try again';
            toast(
                <ToastNotification
                    closeToast={false}
                    title={title}
                    details={details}
                    severity="critical"
                />,
            );
        } else {
            // make sure the auth flow is not using "CUSTOM_AUTH"
            // configureAuth()
            API_LOGIN.login(username, password)
                .then((response) => {
                    sessionStorage.setItem('username', this.state.username);
                    sessionStorage.setItem('pwd', this.state.password);

                    handleSignIn(response, this.state.username);
                })
                .catch((err) => {
                    console.log(err);
                    if (err !== undefined) {
                        if (err.response.status === 401) {
                            sessionStorage.setItem(
                                'username',
                                this.state.username,
                            );
                            sessionStorage.setItem('pwd', this.state.password);
                            handleSignIn(err.response, this.state.username);
                        } else {
                            err = err.response.data.error;
                            const title = 'Unable to login';
                            let details = err.text;
                            toast(
                                <ToastNotification
                                    closeToast={false}
                                    title={title}
                                    details={details}
                                    severity="critical"
                                />,
                            );
                        }
                    }
                });
        }
    }

    render() {
        this.tryCustomAuthLogin();
        return (
            <div className="custom-sign-in">
                <div className="sign-in-form">
                    <div className="form-header">
                        <img className="logo" src={logo}></img>
                    </div>
                    {/* <div>{userInfo.name}</div> */}
                    <div className="credentials">
                        <div className="username">
                            <Input
                                className="input-form"
                                fluid="true"
                                id="username"
                                key="username"
                                name="username"
                                S
                                type="text"
                                autoFocus
                                pattern="[^\s]+"
                                onChange={(value) =>
                                    this.setState({ username: value.trim() })
                                }
                                label="Username"
                            />
                        </div>
                        <div className="password">
                            <Input
                                className="input-form"
                                type="password"
                                fluid="true"
                                onChange={(value) =>
                                    this.setState({ password: value.trim() })
                                }
                                label="Password"
                                onEnter={() => this.signIn()}
                            />
                            <p className="forgot-password">
                                <a
                                    className="forgot-password-link"
                                    onClick={() =>
                                        this.recoverPassword()
                                    }
                                >
                                    Forgot password?
                                </a>
                            </p>
                        </div>
                        <div className="submit-creds">
                            <Button
                                type="primary"
                                onClick={() => this.signIn()}
                                content="Login"
                            />
                        </div>
                        <div className="federated">
                            <SignInAzure />
                        </div>
                    </div>
                    <TermsOfUse />
                </div>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    closeButton={false}
                    newestOnTop={true}
                    position="bottom-right"
                    toastClassName="toast-notification-wrap"
                />
            </div>
        );
    }
}

export default CustomSignIn;
