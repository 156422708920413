/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { Component } from 'react';
import * as HQS_API from '../utils/api';
import moment from 'moment';
import TreeView from './Treeview';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import AuthContext from '../../context/AuthProvider';

import { ErrorCodes } from '../config';
import ToastNotification from '../Notifications/ToastNotification';
import './user.css';
import Form from 'react-bootstrap/Form';

import { DataTable } from '@scuf/datatable';
import { Button, Tab, Icon, InputLabel, Input, Select } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

import CalendarTab from '../Event/CalendarTab';
import { JobsTab } from '../Jobs/JobsTab';
import ChangePasswordForm from '../Forms/ChangePassword';
import EmailChangeForm from '../Forms/EmailChange';
import ManageMFAForm from '../Forms/ManageMFA';
import ReportsTab from '../Reports/ReportsTab';

import {
    jobEndStates,
    fileExtensionMap,
    roundTwoDecimalPlaces,
    isNullOrEmpty,
    updateJobQueryParams,
    generateFilters,
    getFilterableOrgMachines,
    getFilterableUserGroups,
    sortFiltersByKeys,
    updateSearchParams,
    agreementTypes,
    isLicensedPlan,
    softwareDocs,
    toISODate,
} from '../utils/helpers';
import { getRefreshSchedule } from '../utils/anyPlan';
import { APIExamplesBucket } from '../config';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import CodeViewer from './CodeViewer';
import BatchRequests from '../Batch/BatchRequests';
import UpdateDefaultGroupForm from '../Forms/UpdateDefaultGroup';
import { MsalContext } from '@azure/msal-react';

const AWS = window.AWS;

class User extends Component {
    // static contextType = MsalContext;
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            userAlias: '',
            email: '',
            userNewEmail: '',
            phoneNumber: '',
            jobs: [],
            reports: [],
            notificationPreferences: {},
            accountButton: true,
            notificationsButton: true,
            passwordButton: true,
            saveButton: true,
            orgDetails: {},
            orgPlans: [],
            orgList: [],
            allMachines: [],
            fetchingJobs: false,
            fetchingJobsReport: false,
            selectedFileData: '',
            selectedFileFormat: 'none',
            selectedFileURL: '',
            defaultUserGroup: '',
            userGroups: [],
            isOrgAdmin: false,
            software: [],
            mode: 'user',
            page: {
                size: 25,
                number: 1,
                keys: { 1: null },
                visited: new Set([1]),
                reverse: true,
                search: null,
                sort: 'submit-date',
            },
            filters: {
                machines: { 'All Machines': true },
                statuses: { 'All Statuses': true },
                groups: { 'All Groups': true },
            },
            search: null,
            phoneError: '',
            isFederated: false,
            mfaType: 'none',
            mfaEnabled: false,
        };
        this.blobRef = React.createRef();

        this.getUserDetails = this.getUserDetails.bind(this);
        this.getUserJobs = this.getUserJobs.bind(this);
        this.getNotificationPreferences = this.getNotificationPreferences.bind(this);
        this.updateNotificationPreferences = this.updateNotificationPreferences.bind(this);
        this.updateAlias = this.updateAlias.bind(this);
        this.handleNotificationPreferences = this.handleNotificationPreferences.bind(this);
        this.handleNotificationsChange = this.handleNotificationsChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAliasChange = this.handleAliasChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.parseJwt = this.parseJwt.bind(this);
        this.getCognitoGroups = this.getCognitoGroups.bind(this);

        //AWS helper functions
        this.getAsyncNodes = this.getAsyncNodes.bind(this);
        this.getNodeName = this.getNodeName.bind(this);
        this.getObject = this.getObject.bind(this);
        this.handleExamplesDownload = this.handleExamplesDownload.bind(this);
        this.getMachines = this.getMachines.bind(this);
        this.getOrgDetails = this.getOrgDetails.bind(this);
        this.fetchJobsReport = this.fetchJobsReport.bind(this);
        this.isFederatedLogin = this.isFederatedLogin.bind(this);
        this.getSecurity = this.getSecurity.bind(this);
        this.getSoftware = this.getSoftware.bind(this);
        this.getExamplesAccess = this.getExamplesAccess.bind(this);
        this.fetchReports = this.fetchReports.bind(this);
    }

    async componentDidMount() {
        this.getUserDetails();
        this.getNotificationPreferences();
        this.getMachines();
        this.getSecurity();
        this.getSoftware();
        this.getExamplesAccess();
        this.fetchReports();
        this.getCognitoGroups();
    }

    parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    getCognitoGroups() {
        let accessGroups = [];
        let storedToken = localStorage.getItem('id-token');
        if (storedToken !== 'undefined' && storedToken !== null) {
            let idToken = this.parseJwt(storedToken);
            if ('cognito:groups' in idToken) {
                accessGroups = idToken['cognito:groups'];
            }
        }

        if (accessGroups.includes('org-admins')) {
            this.setState({ isOrgAdmin: true });
        }
    }

    getExamplesAccess() {
        // get temp access to fetch examples files
        HQS_API.examples()
            .then((response) => {
                AWS.config.update({
                    credentials: response,
                    region: 'us-west-2',
                });
                this.s3 = new AWS.S3({
                    apiVersion: '2006-03-01',
                    params: {
                        Bucket: APIExamplesBucket,
                        Prefix: '',
                    },
                });
            })
            .catch((error) => {
                console.error(JSON.stringify(error));
            });
    }

    /**
     * getNodeName - sets the name of the node in the tree view
     * removes "/" that comes from the S3 API
     */
    getNodeName(name, isFolder) {
        let pathStrings = name.split('/');
        return pathStrings[pathStrings.length - (isFolder ? 2 : 1)];
    }

    /**
     * getNodes - separates the response from the getAsyncNodes into files and folders
     * decorates the response for use in the Tree view
     */
    getNodes(parent) {
        // separate files from the response. All folders have a size = 0
        // the if conditions only allows files if any folders are in the response.
        let contents = [];
        forEach(parent.Contents, (val) => {
            if (val.Size > 0)
                contents.push({
                    name: this.getNodeName(val.Key, false),
                    key: val.Key,
                    level: parent.level + 1,
                    isFolder: false,
                });
        });

        // isolates the folders from the response and generates a list.
        return [
            ...map(parent.CommonPrefixes, (val) => {
                return {
                    name: this.getNodeName(val.Prefix, true),
                    prefix: val.Prefix,
                    level: parent.level + 1,
                    isFolder: true,
                };
            }),
            ...contents,
        ];
    }

    /**
     * getAsyncNodes - gets the list of objects on the S3 bucket
     * with the help of the Delimiter and Prefix, the response is sorted
     * into respective files and folders
     */
    getAsyncNodes(parent) {
        if (!parent) parent = { level: 0 };
        let prefix = parent.level === 0 ? '' : parent.prefix;

        return new Promise((resolve, reject) => {
            this.s3.listObjectsV2(
                {
                    Delimiter: '/',
                    Prefix: prefix,
                },
                (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(this.getNodes(Object.assign({}, parent, data)));
                    }
                },
            );
        });
    }

    /**
     * getObject - generates a url (url will expired after (60 seconds) that can be used to get the object data
     * stores the object's data into state.
     * TODO: handle files that can't be displayed as text
     */
    getObject(key) {
        let fileHighlightValue = fileExtensionMap[key.substring(key.lastIndexOf('.') + 1)];
        console.log('File format: ' + fileHighlightValue);
        let fileFormat = fileHighlightValue !== undefined ? fileHighlightValue : 'none';
        let url = this.s3.getSignedUrl('getObject', { Key: key, Expires: 60 });
        fetch(url)
            .then((response) => response.text())
            .then((data) => {
                this.setState({
                    selectedFileData: data,
                    selectedFileFormat: fileFormat,
                    selectedFileURL: url,
                });
            });
    }

    async getUserDetails() {
        HQS_API.getSelfUser()
            .then((response) => {
                this.setState({
                    // first_name: response['first-name'],
                    // last_name: response['last-name'],
                    email: response['email'],
                    phoneNumber: response['phone-number'],
                    userAlias: response['user-alias'],
                    organization: response['organization'],
                    priority: 'priority' in response ? response['priority'] : 'none',
                    defaultUserGroup: 'default-user-group' in response ? response['default-user-group'] : 'none',
                    userGroups: 'user-groups' in response ? response['user-groups'] : [],
                    orgList: [response['organization']],
                });
                this.getOrgDetails();
                //set the federation state
                // this.isFederatedLogin()
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getOrgDetails() {
        HQS_API.getOrganization(this.state.organization, 'user')
            .then((response) => {
                let orgPlans = response['plans'];

                let orgPlansMapped = [];

                orgPlans.forEach((plan) => {
                    let newPlan = {
                        id: plan['id'],
                        enabled: plan['enabled'],
                        unlimited: 'unlimited' in plan && plan['unlimited'] ? ['unlimited'] : false,
                        available_hqc: 'available_hqc' in plan ? plan['available_hqc'] : 0,
                        create_date: 'create_date' in plan ? plan['create_date'] : '',
                        activation_date: 'activation_date' in plan ? plan['activation_date'] : '',
                        refresh_start_date: 'refresh_start_date' in plan ? plan['refresh_start_date'] : '',
                        refresh_enabled: 'refresh_enabled' in plan ? plan['refresh_enabled'] : false,
                        deactivation_date: 'deactivation_date' in plan ? plan['deactivation_date'] : '',
                        //need to agree on correct machine name convention
                        machine_name: plan['machine_name'],
                        software: plan['software'],
                        raw: plan, //the full object in case we need to acces more properties later
                    };

                    //might be temporary
                    if (newPlan['machine_name'] == undefined) {
                        newPlan['machine_name'] = {};
                    }
                    orgPlansMapped.push(newPlan);
                });

                orgPlansMapped.sort((a, b) => a['create_date'].localeCompare(b['create_date']));

                this.setState({
                    orgDetails: response,
                    orgPlans: orgPlansMapped,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getNotificationPreferences() {
        HQS_API.getSelfNotificationPreferences()
            .then((response) => {
                this.setState({ notificationPreferences: response });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getMachines() {
        HQS_API.listMachines()
            .then((response) => {
                let allMachines = [];
                response.forEach((machineName) => {
                    let machine = {
                        name: machineName,
                        description: machineName,
                        enabled: false,
                    };

                    allMachines.push(machine);
                });
                allMachines.sort((a, b) => a.name.localeCompare(b.name));
                this.setState({ allMachines: allMachines });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getUserJobs(org, start, end, page, filters, search) {
        this.setState({ fetchingJobs: true });

        let startTemp = moment(start).startOf('day');
        let endTemp = moment(end).endOf('day');
        start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');

        let queryParams = { mode: 'user', start, end };

        //ensure we either have the default page or the passed in page information
        if (page == undefined) {
            page = this.state.page;
        }

        //add limit to parameters if it's a positive number
        if (page.size > 0) {
            queryParams.limit = page.size;
        }

        let header_key = undefined;
        //add key to parameters if we know what it is. If we don't pass a key it will default to the first page
        if (page.number > 1 && page.keys !== undefined) {
            header_key = page.keys[page.number];
        }

        //check if we need to fetch data in ascending order
        if (page.reverse == false) {
            queryParams.reverse = page.reverse;
        }

        // check how we're sorting this data
        if (page.sort) {
            queryParams.sort = page.sort;
        }

        //if search was passed in, then prepare the request
        if (search !== null && search != '') {
            // remove the org. The job Id there searching for could be for any org.
            let exclude = ['start', 'end'];
            // update the query params with the search criteria
            queryParams = updateSearchParams(queryParams, search, exclude);

            //assign the page the job Id we searched on
            page.search = search;
        }

        //check if we at least something to filter on
        if (!isNullOrEmpty(filters)) {
            //optionally add other filters selected after initially query
            queryParams = updateJobQueryParams(queryParams, filters);
        }

        HQS_API.listJobs(queryParams, header_key)
            .then((response) => {
                let jobs = response['items'];
                let pageNext = undefined;
                //assign the next page reference
                if (response['key'] !== undefined) {
                    pageNext = response['key'];
                }

                //update our page keys with pointer to next page
                page.keys[page.number + 1] = pageNext;

                //keep track of where we've been
                if (page.visited && !page.visited.has(page.number)) {
                    page.visited.add(page.number);
                }

                //hold on to the current state of the filters.
                let currentFilters = this.state.filters;
                //if the incoming filters are empty (first API call, refresh, filter reset) and we're still on the first page (meaning we aren't paging to subsequent pages)                //then regenerate
                //then regenerate
                if (isNullOrEmpty(filters) || page.number == 1) {
                    currentFilters = generateFilters(jobs, this.state.filters, this.state.mode);

                    //dynamically set the machines based on what this org has access to.
                    //will fill in gaps the first page won't have
                    getFilterableOrgMachines(this.state.orgPlans, this.state.allMachines).forEach((m) => {
                        currentFilters.machines[m] = true;
                    });

                    //add user group filters for this specific user
                    getFilterableUserGroups(this.state.userGroups).forEach((g) => {
                        currentFilters.groups[g] = true;
                    });

                    //sort the filters contents in case it isn't sorted already
                    currentFilters = sortFiltersByKeys(currentFilters);
                }

                this.setState({
                    jobs: jobs,
                    page: page,
                    filters: currentFilters,
                });
            })
            .catch((error) => {
                console.log(error);
                // if there's an actual error code, display that message
                if (error.hasOwnProperty('code')) {
                    //catch any other error and display the message to user
                    const title = error.code;
                    const details = error.message;
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                } else {
                    //it's a timeout
                    const title = 'Search timed out';
                    const details = 'Please narrow down your search criteria and try again.';
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                }
            })
            .finally(() => {
                this.setState({ fetchingJobs: false, search: search });
            });
        this.setState({ fetchingJobs: true });
    }

    async fetchJobsReport(filename, start, end, status, org) {
        this.setState({ fetchingJobsReport: true });

        let queryParams = {
            start: start,
            end: end,
            status: status,
            org: org,
            user: this.state.email,
        };

        HQS_API.jobReporting(queryParams)
            .then((response) => {
                if (typeof this.blobRef.current === 'object') {
                    const blob = new Blob([response], { type: 'text/csv' });
                    if (blob.size > 2) {
                        const url = URL.createObjectURL(blob);
                        this.blobRef.current.href = url;
                        this.blobRef.current.download = filename;
                        this.blobRef.current.click();
                        URL.revokeObjectURL(url);

                        const title = 'Successfully generated report';
                        const details = filename;
                        toast(<ToastNotification closeToast={false} title={title} details={details} />);
                    } else {
                        const title = 'Unable to generate report';
                        const details = 'No records found';
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="information"
                            />,
                        );
                    }
                }
            })
            .catch((error) => {
                const title = 'Unable to generate report';
                const details = 'Access denied';
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
            })
            .finally(() => {
                this.setState({ fetchingJobsReport: true });
            });
    }

    async updateNotificationPreferences() {
        const notificationPreferences = Object.assign(
            {},
            ...Object.keys(this.state.notificationPreferences).map((key) => ({
                [key]: this.state.notificationPreferences[key] === 'true',
            })),
        );

        HQS_API.updateSelfNotificationPreferences(notificationPreferences)
            .then((response) => {
                const title = 'Change Successful';
                const details = 'The system has successfully updated your preferences.';
                toast(<ToastNotification closeToast={false} title={title} details={details} />);
                this.setState({ notificationPreferences: response });
            })
            .catch((error) => {
                console.log(error);
                if ('sms' in notificationPreferences && notificationPreferences['sms']) {
                    if (this.state.phoneNumber == undefined || this.state.phoneNumber == '') {
                        const title = 'Unable to save preferences';
                        const details = 'Phone number is required when enabling SMS notifications';
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="critical"
                            />,
                        );
                    }
                }
            });
    }

    async updateUser(userDetails, notify = false) {
        HQS_API.updateSelfUser(userDetails)
            .then((response) => {
                if (notify) {
                    const title = 'Change Successful';
                    const details = 'The system has successfully updated your preferences.';
                    toast(<ToastNotification closeToast={false} title={title} details={details} />);
                }
            })
            .catch((error) => {
                console.log(error);
                error = error.response.data.error;
                if (error.code === ErrorCodes.UserAlreadyExists) {
                    alert(error.text);
                } else if (error.code === ErrorCodes.UserDoesNotExist) {
                    alert(error.text);
                } else if (error.code === ErrorCodes.BadEmailAddress) {
                    alert(error.text);
                } else if (error.code === ErrorCodes.UnconfirmedEmailSubscription) {
                    alert(error.text);
                } else if (error.code === ErrorCodes.BadPhoneNumber) {
                    alert(error.text);
                }
            })
            .finally((_) => {
                this.getUserDetails();
            });
    }

    handleChecked(preferenceName) {
        const preference = this.state.notificationPreferences[preferenceName];
        return preference === 'pending' || preference === 'true';
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handlePhoneNumberChange(value) {
        //enable the save button when changing phone number if it's in a valid format
        //valid formats +11234567890,+EXT 000-000-0000, (+EXT) 000-000-0000
        const regexPattern = /\+\d{11,13}|\+\d{1,3}\s\d{3}\-\d{3}\-\d{4}|\(\+\d{1,3}\)\s\d{3}\-\d{3}\-\d{4}/g;
        let disabled = false; //enabled by default if making a change (need this so that we can clear the phone number)
        let error = '';

        if (value !== undefined && value.length > 0) {
            var digits = (value.match(/\d/g) || []).length;
            let max = 14; //max numbers in a phone number
            if (!value.match(regexPattern) || digits > max) {
                error = 'Invalid formatting. Expected +12345678910 or (+EXT) 000-000-0000';
                disabled = true;
            }
        }

        this.setState({ phoneNumber: value, notificationsButton: false, saveButton: disabled, phoneError: error });
    }

    handleNotificationsChange(event) {
        const oldPreference = this.handleChecked(event.target.name);
        let newNotificationPreferences = {
            ...this.state.notificationPreferences,
        };
        newNotificationPreferences[event.target.name] = (!oldPreference).toString();
        this.setState({
            notificationPreferences: newNotificationPreferences,
            notificationsButton: false,
            saveButton: false,
        });
    }

    handleAliasChange(value) {
        this.setState({
            userAlias: value,
            accountButton: false,
            saveButton: false,
        });
    }

    handleEmailChange(value) {
        this.setState({
            userNewEmail: value,
            accountButton: false,
            saveButton: false,
        });
    }

    handleNotificationPreferences() {
        //we need to call both functions as updates
        //are handled separaterly
        this.updateNotificationPreferences();

        //cleanup the phone number
        let phone = this.state.phoneNumber;
        if (this.state.phoneNumber !== undefined) {
            // clean up phone number if it's formatted with extra characters (+EXT) 000-000-0000 so it's consistent in db and cognito
            phone = phone.replace(/[\(\)\-\s]/g, '');
        }
        this.updateUser({ 'phone-number': phone });
        this.setState({ notificationsButton: true });
    }

    updateAlias() {
        this.updateUser({ 'user-alias': this.state.userAlias }, true);
        this.setState({ accountButton: true });
    }

    updateAccountInfo() {
        // check if alias was updated
        if (!this.state.accountButton) {
            this.updateAlias();
        }

        // check if notifications were updated
        if (!this.state.notificationsButton) {
            this.handleNotificationPreferences();
        }

        this.setState({ saveButton: true });
    }

    displayPreference(preferenceName) {
        const preference = this.state.notificationPreferences[preferenceName];
        if (preference === 'true') {
            return 'Active';
        } else if (preference === 'pending') {
            return 'Pending confirmation';
        } else {
            return 'Inactive';
        }
    }

    dateRenderer(cellData) {
        const data = cellData.value;
        var createDate = '';

        if (data) {
            createDate = toISODate(data);
        }
        return <span>{createDate}</span>;
    }

    shortDateRenderer(cellData) {
        const data = cellData.value;
        var createDate = '';

        if (data) {
            createDate = toISODate(data, 'short');
        }
        return <span>{createDate}</span>;
    }

    documentationRenderer(cellData) {
        const data = cellData.value;
        var link = '';

        if (data) {
            if (isLicensedPlan(data)) {
                var customer = 'reseller'; //default

                const row = cellData.rowData;
                if ('customer' in row && row['customer'] !== '') {
                    customer = row['customer'];
                }
                //check if the user is compliant with this softwarwe
                link = softwareDocs[data][customer];
            }
        }

        if (link !== '') {
            return (
                <span>
                    <a href={link} target="_blank">
                        View
                    </a>
                </span>
            );
        } else {
            return <span>None</span>;
        }
    }

    machinesRenderer(cellData) {
        const data = cellData.value;
        return <span>{Object.keys(data).join(', ')}</span>;
    }

    creditsRenderer = (cellData) => {
        var credits = 'NA';
        var availableCredits = 0;
        if ('rowData' in cellData) {
            const row = cellData['rowData'];

            if ('unlimited' in row && row['unlimited']) {
                credits = 'Unlimited';
            } 

            // check if available hqc is there and not = 'NA', 
            // even if it's unlimited we need to display this number since it reflects a quota on the user or user group
            if ('available_hqc' in row && row['available_hqc'] !== 'NA') {
                availableCredits = row['available_hqc'];
                credits = roundTwoDecimalPlaces(availableCredits);
            }
            
        }

        return <span>{credits}</span>;
    };

    enableRenderer(cellData) {
        const data = cellData.value;
        return <span>{data ? 'True' : 'False'}</span>;
    }

    startDateRenderer(cellData) {
        const row = cellData['rowData'];

        let createDate = toISODate(row['create_date']);
        if ('activation_date' in row && row['activation_date'] != undefined && row['activation_date'] !== '') {
            createDate = toISODate(row['activation_date']);
        }

        return <span>{createDate}</span>;
    }

    refreshStartDateRenderer(cellData) {
        const row = cellData['rowData'];

        let date = '';
        if (
            'refresh_enabled' in row &&
            row['refresh_enabled'] === true &&
            'refresh_start_date' in row &&
            row['refresh_start_date'] != undefined &&
            row['refresh_start_date'] !== ''
        ) {
            //date = toISODate(row['refresh_start_date']);

            date = getRefreshSchedule(toISODate(row['refresh_start_date']));
        }

        return <span title="The credits will reset to their plan defaults">{date}</span>;
    }

    softwareRenderer = (cellData) => {
        const data = cellData.value;
        if (data !== undefined) {
            return <span>{agreementTypes[data.toString()]}</span>;
        }
        //default to showing false
        return <span>{data}</span>;
    };

    boolRenderer = (cellData) => {
        const data = cellData.value;
        if (data !== undefined) {
            return <span>{data.toString()}</span>;
        }
        //default to showing false
        return <span>false</span>;
    };

    async handleExamplesDownload() {
        //get all objects in the bucket first
        this.s3.listObjectsV2(
            {
                Bucket: APIExamplesBucket,
            },
            async (err, data) => {
                if (err) {
                    console.error(err);
                } else {
                    const zip = new JSZip();
                    // this sync loop is important as we want
                    // to wait for all files to be downloaded
                    // before creating the .zip file
                    for (const file of data.Contents) {
                        if (!file.Key.endsWith('/')) {
                            let url = this.s3.getSignedUrl('getObject', {
                                Key: file.Key,
                                Expires: 60,
                            });
                            let response = await fetch(url);
                            let fileData = await response.blob();
                            // add file to zip object
                            zip.file(file.Key, fileData);
                        }
                    }
                    // zip all files into single .zip file
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, 'hqs-api-examples.zip');
                    });
                }
            },
        );
    }

    sortJobs(jobs, sortData) {
        if (!sortData) return;
        const { sortField, sortOrder } = sortData;
        return jobs.sort((a, b) => {
            a = a[sortField]?.toString() || '';
            b = b[sortField]?.toString() || '';
            return a.localeCompare(b) * sortOrder;
        });
    }

    isFederatedLogin() {
        let isAuthenticated = this.context.accounts.length > 0;

        //expand this as we add more identity providers
        this.setState({ isFederated: isAuthenticated });
    }

    async getSecurity() {
        HQS_API.getSelfSecurityPreferences()
            .then((response) => {
                if ('mfa' in response) {
                    let mfa = response['mfa'];

                    let enabled = mfa['enabled'];
                    let type = enabled ? mfa['type'] : 'none';

                    this.setState({ mfaEnabled: enabled, mfaType: type });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getSoftware() {
        HQS_API.getSelfSoftware()
            .then((response) => {
                this.setState({ software: response });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    fetchReports() {
        this.setState({
            fetchingReports: true,
        });
        HQS_API.listReports()
            .then((response) => {
                response.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });
                // format report text before passing to ReportsTab
                response.forEach(function (report) {
                    // format report type
                    let report_type = report['report_type'];
                    if (report_type === 'single') {
                        report['report_type'] = 'Single Day';
                    } else if (report_type === 'multi') {
                        report['report_type'] = 'Multiple Day';
                    }

                    // format record type
                    let record_type = report['record_type'];
                    if (record_type === 'org') {
                        report['record_type'] = 'organization';
                    }

                    // format date
                    let date = report['date'];
                    report['date'] = date.split(' ')[0];
                });
                this.setState({
                    reports: response,
                    fetchingReports: false,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingJobs: false });
            });
    }

    render() {
        let subHeaderStyle = {
            width: 'auto',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            margin: '1em 0.4em 0.5em 0',
        };

        return (
            <div className="hqs-user">
                <div className="View-header">{`Welcome, ${this.state.userAlias}`}</div>
                <div style={{ display: 'flex', paddingLeft: '5px' }}>
                    <div>
                        <div className="hqs-umui-card-subheader" style={subHeaderStyle}></div>
                    </div>
                </div>
                <Tab defaultActiveIndex={0}>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="communication" size="small">
                                &nbsp;Jobs
                            </Icon>
                        }>
                        <JobsTab
                            mode={this.state.mode}
                            jobs={this.state.jobs}
                            page={this.state.page}
                            search={this.state.search}
                            filters={this.state.filters}
                            organizations={this.state.orgList}
                            onChange={this.getUserJobs}
                            onSort={(sortData) => {
                                const newSort = this.sortJobs(this.state.jobs, sortData);
                                this.setState({
                                    jobs: newSort,
                                    jobsSortInfo: sortData,
                                });
                            }}
                            {...this.state.jobsSortInfo}
                            fetchingJobs={this.state.fetchingJobs}
                            fetchJobsReport={this.fetchJobsReport}
                        />
                        <a style={{ display: 'none' }} href="" ref={this.blobRef} download="" />
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="folder" size="small">
                                &nbsp;Reports
                            </Icon>
                        }>
                        <ReportsTab
                            reports={this.state.reports}
                            orgs={this.state.orgList}
                            fetchingReports={this.state.fetchingReports}
                            mode="user"
                            isOrgAdmin={this.state.isOrgAdmin}
                            callback={this.fetchReports}
                        />
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="image-responsive" size="small">
                                &nbsp;Batch Requests
                            </Icon>
                        }>
                        <BatchRequests mode={this.state.mode}></BatchRequests>
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="calendar" size="small">
                                &nbsp;Calendar
                            </Icon>
                        }>
                        <CalendarTab
                            mode={this.state.mode}
                            allMachines={this.state.allMachines}
                            orgNames={this.state.orgNames}
                            organization={this.state.organization}></CalendarTab>
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="user-operations" size="small">
                                &nbsp;Account
                            </Icon>
                        }>
                        <div className="hqs-user-view">
                            <div className="hqs-user-card medium-card hqs-custom-card">
                                <div className="hqs-umui-card-header-gray">ACCOUNT</div>
                                <Form.Group>
                                    <Input
                                        name="userAlias"
                                        label="User Alias"
                                        placeholder="User Alias"
                                        onChange={this.handleAliasChange}
                                        value={this.state.userAlias}
                                        fluid={true}
                                        disabled={this.state.isFederated}
                                        title={
                                            this.state.isFederated
                                                ? 'User alias cannot be changed while logged in with a federated or social account'
                                                : ''
                                        }
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}></div>
                                    <Input
                                        name="userEmail"
                                        label="User Email"
                                        placeholder=""
                                        value={this.state.email}
                                        fluid={true}
                                        disabled={true}
                                    />
                                </Form.Group>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {/* the key ensures that when the mfaType changes it re-renders this component*/}
                                    <ChangePasswordForm key={this.state.mfaType} mfaEnabled={this.state.mfaEnabled} />
                                    <EmailChangeForm email={this.state.email} />
                                </div>

                                <div className="hqs-umui-card-header-gray">NOTIFICATIONS</div>
                                <Form.Group>
                                    <InputLabel label="How would you like to be notified?" />
                                    <Form.Check
                                        type="checkbox"
                                        label="Email"
                                        name="email"
                                        onChange={this.handleNotificationsChange}
                                        checked={this.handleChecked('email')}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        type="checkbox"
                                        label="SMS"
                                        name="sms"
                                        onChange={this.handleNotificationsChange}
                                        checked={this.handleChecked('sms')}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Input
                                        name="phoneNumber"
                                        placeholder="(+EXT) 000-000-0000"
                                        onChange={(value) => this.handlePhoneNumberChange(value)}
                                        value={this.state.phoneNumber}
                                        fluid={true}
                                        error={this.state.phoneError}
                                    />
                                </Form.Group>
                                <div className="hqs-umui-card-header-gray">USER GROUP(S)</div>
                                <Form.Group>
                                    <InputLabel label="Your Group(s)" />
                                    <span>{this.state.userGroups.map((element) => element.name).join(', ')}</span>
                                </Form.Group>
                                <Form.Group>
                                    <InputLabel label="Default Group" />
                                    <UpdateDefaultGroupForm
                                        key={this.state.defaultUserGroup.id}
                                        user={this.state.email}
                                        defaultUserGroup={this.state.defaultUserGroup}
                                        userGroups={this.state.userGroups}
                                        callback={this.getUserDetails}
                                    />
                                </Form.Group>
                                <div className="hqs-umui-card-header-gray">JOB EXECUTION PRIORITY</div>
                                <Form.Group>
                                    <span>{this.state.priority}</span>
                                </Form.Group>
                                <div className="hqs-umui-card-header-gray">SECURITY</div>
                                <Form.Group>
                                    <InputLabel label="Multi-factor Authentication" />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <ManageMFAForm
                                            key={this.state.mfaEnabled}
                                            enabled={this.state.mfaEnabled}
                                            type={this.state.mfaType}
                                            email={this.state.email}
                                            callback={this.getSecurity}
                                        />
                                    </div>
                                </Form.Group>
                                <div className="hqs-form-button-wrapper">
                                    <Button
                                        disabled={this.state.saveButton}
                                        type="primary"
                                        onClick={() => this.updateAccountInfo()}>
                                        Save Changes
                                    </Button>
                                </div>
                            </div>
                            <div className="hqs-user-card">
                                <div className="hqs-umui-card-header" style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: '2px' }}>MACHINES&nbsp;&nbsp;</div>
                                    <div
                                        onClick={this.getOrgDetails}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '0.8rem',
                                        }}>
                                        <Icon root="common" name="refresh" size="small" loading={true} />
                                    </div>
                                </div>
                                <DataTable data={this.state.orgPlans} search={true} scrollHeight="600px">
                                    <DataTable.Column
                                        field="machine_name"
                                        header="MACHINE"
                                        initialWidth="120px"
                                        renderer={this.machinesRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="enabled"
                                        header="ENABLED"
                                        initialWidth="60px"
                                        renderer={this.enableRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="activation_date"
                                        header="ACTIVATION DATE"
                                        renderer={this.startDateRenderer}
                                        initialWidth="100px"
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="refresh_start_date"
                                        header="REFRESH SCHEDULE"
                                        renderer={this.refreshStartDateRenderer}
                                        initialWidth="120px"
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="deactivation_date"
                                        header="DEACTIVATION DATE"
                                        renderer={this.dateRenderer}
                                        initialWidth="100px"
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="available_hqc"
                                        header="CREDITS"
                                        initialWidth="60px"
                                        renderer={this.creditsRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="software"
                                        header="SOFTWARE"
                                        initialWidth="80px"
                                        renderer={this.softwareRenderer}
                                        sortable={true}
                                    />
                                </DataTable>

                                <div className="hqs-umui-card-header" style={{ display: 'flex', paddingTop: '10px' }}>
                                    <div style={{ paddingTop: '2px' }}>SOFTWARE&nbsp;&nbsp;</div>
                                    <div
                                        onClick={this.getSoftware}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '0.8rem',
                                        }}>
                                        <Icon root="common" name="refresh" size="small" loading={true} />
                                    </div>
                                </div>
                                <DataTable data={this.state.software} scrollHeight="600px">
                                    <DataTable.Column
                                        field="software"
                                        header="software"
                                        initialWidth="100px"
                                        sortable={true}
                                        renderer={this.softwareRenderer}
                                    />
                                    <DataTable.Column
                                        field="status"
                                        header="activation status"
                                        initialWidth="100px"
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="issued"
                                        header="Issued"
                                        initialWidth="100px"
                                        sortable={true}
                                        renderer={this.boolRenderer}
                                    />
                                    <DataTable.Column
                                        field="enabled"
                                        header="enabled"
                                        initialWidth="100px"
                                        sortable={true}
                                        renderer={this.boolRenderer}
                                    />
                                    <DataTable.Column
                                        field="license-expiration-date"
                                        header="EXPIRATION"
                                        renderer={this.shortDateRenderer}
                                        initialWidth="100px"
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="software"
                                        header="Documentation"
                                        renderer={this.documentationRenderer}
                                        initialWidth="100px"
                                        sortable={true}
                                    />
                                </DataTable>
                            </div>
                            <ToastContainer
                                hideProgressBar={true}
                                closeOnClick={false}
                                closeButton={false}
                                newestOnTop={true}
                                position="bottom-right"
                                toastClassName="toast-notification-wrap"
                            />
                        </div>
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="folder" size="small">
                                &nbsp;Examples
                            </Icon>
                        }>
                        <div className="hqs-user-view">
                            <div className="hqs-user-card medium-card hqs-custom-card">
                                <div
                                    style={{
                                        overflow: 'auto',
                                        padding: '0px 7px 0px 7px',
                                    }}>
                                    <TreeView
                                        treeLoading={this.state.treeLoading}
                                        getAsyncNodes={this.getAsyncNodes}
                                        getObject={this.getObject}
                                    />
                                </div>
                                <div>
                                    {' '}
                                    <Button
                                        type="primary"
                                        size="small"
                                        content="&nbsp;Download"
                                        icon="file-download"
                                        onClick={this.handleExamplesDownload}
                                    />
                                </div>
                            </div>

                            <div className="hqs-user-card editor-card">
                                <div
                                    style={{
                                        overflow: 'auto',
                                        width: '100%',
                                    }}>
                                    <CodeViewer
                                        fileFormat={this.state.selectedFileFormat}
                                        fileData={this.state.selectedFileData}
                                        fileURL={this.state.selectedFileURL}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                </Tab>
            </div>
        );
    }
}

export default User;
