// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';

const logo = require('../icons/icon_alert_critical.svg');


// Custom hook for handling the delete user form
const usePromoteUserForm = (closeCallback, props) => {

    async function promoteUser(user) {
        const requestPayload = { action: 'promote', role: props.userRole };
        HQS_API.updateUserPermissions(user['email'], requestPayload)
            .then((response) => {
                const title = 'Success!';
                const details = `Admin priviledges have been given to ${user['email']}!`;
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                    />
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                error = error.response.data.error;
                if (error.code === ErrorCodes.UserDoesNotExist) {
                    alert(error.text);
                }
                if (error.code === ErrorCodes.CannotDeleteSelf) {
                    alert(error.text);
                }
            });
    }

    const handlePromote = (event) => {
        if (event) {
            event.preventDefault();
        }
        promoteUser(props.user);
        closeCallback();
    };

    return { handlePromote };
};

const PromoteUserForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handlePromote } = usePromoteUserForm(handleClose, props);

    return (
        <div>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="user-group-add"
                    size="small"></Icon>
                &nbsp;{props.children || 'Make Admin'}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Make Admin</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        This will give the selected user admin priviledges . Are you sure?
                    </p>
                    <Button type="primary" size="small" onClick={handlePromote}>
                        Make Admin
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default PromoteUserForm;
