// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Modal, Badge } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const useDeleteUserGroupForm = (closeCallback, props) => {

    const deleteUserGroup = async (org_name, group_id) => {
        HQS_API.deleteUserGroup(org_name, group_id)
            .then((response) => {
                const title = 'Success!';
                const details =
                    'The system has successfully removed the user group';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity={'success'}
                    />,
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unkown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });
    };

    const handleDelete = (event) => {
        if (event) {
            event.preventDefault();
        }
        deleteUserGroup(props.org_name, props.group_id);
        closeCallback();
    };

    return { handleDelete };
};

const DeleteUserGroupForm = (props) => {
    const [show, setShow] = useState(props.show);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleDelete } = useDeleteUserGroupForm(handleClose, props);

    return (
        <div>
            <div onClick={handleShow}>
                <div style={{ cursor: 'pointer' }}>
                    <Icon
                        style={{ cursor: 'pointer' }}
                        root="common"
                        name="delete"
                        size="small"
                    ></Icon>
                    &nbsp;{props.children || 'Delete'}
                </div>
            </div>
            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-delete-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="delete" size="medium"></Icon>
                        &nbsp;&nbsp;Delete User Group
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                    <Badge color="red">WARNING!! </Badge> This action will remove the user group from your organization. This will also update users 'Default Group' and/or 'Groups' if they belong to this group.
                    This will have an impact on submissions targetting this specific group. 
                        Are you sure you want to continue?
                    </p>
                    <Button type="primary" size="small" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default DeleteUserGroupForm;
