/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { Component } from 'react';
import {
    Button,
    Input,
    InputLabel,
    Modal,
    Icon,
    Notification,
} from '@scuf/common';
import './CustomAuth.css';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import * as API_LOGIN from '../utils/api-login';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import {
    authChallenges,
    configureAuth,
    recoveryActions,
    handleSignIn,
} from '../utils/helpers';
const logo = require('./QuantinuumLogo.svg');

class CustomRequireMFA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mfaCode: '',
            disabled: true,
        };
        this.verifyMFA = this.verifyMFA.bind(this);
        this.handleMFACode = this.handleMFACode.bind(this);
        this.tryEnableVerify = this.tryEnableVerify.bind(this);
        this.recoverDevice = this.recoverDevice.bind(this);
    }

    async verifyMFA() {
        //verify the multi-factor authentication code in Cognito
        let code = this.state.mfaCode;
        let username = sessionStorage.getItem('username');
        let pwd = sessionStorage.getItem('pwd');

        API_LOGIN.login(username, pwd, false, '', '', code)
            .then((response) => {
                handleSignIn(response, useState);
            })
            .catch((err) => {
                console.log(err);
                if (err !== undefined) {
                    if (err.response.status === 401) {
                        if (err.response.data.error.code === 74) {
                            err = err.response.data.error;
                            const title = 'Unable to login';
                            let details = err.text;
                            toast(
                                <ToastNotification
                                    closeToast={false}
                                    title={title}
                                    details={details}
                                    severity="critical"
                                />,
                            );
                        } else {
                            sessionStorage.setItem('username', username);
                            sessionStorage.setItem('pwd', pwd);
                            sessionStorage.setItem('code', this.state.mfaCode);
                            sessionStorage.setItem('mfa', 'true');
                            handleSignIn(err.response, username);
                        }
                    } else {
                        err = err.response.data.error;
                        const title = 'Unable to login';
                        let details = err.text;
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="critical"
                            />,
                        );
                    }
                }
            });
    }

    handleMFACode(value) {
        this.setState({ mfaCode: value });
        this.tryEnableVerify(value);
    }

    tryEnableVerify(code) {
        //verify the code is 6 digits
        let enabled = code.length == 6;
        this.setState({ disabled: !enabled });
    }

    async completeSignIn(user) {
        let answer = 'True';
        Auth.sendCustomChallengeAnswer(user, answer)
            .then((user) => {
                //reset the the Auth configuation to original flow type
                configureAuth();

                // allow them to sign in successfully
                super.changeState('signedIn', user);
            })
            .catch((err) => {
                //failed the challenge, let them try again?
                console.log(err);
                //route to terms and conditions
            });
    }

    backToSignIn() {
        //before we return to the sign in page, make sure properly sign out of cognito and change the state
       
        window.location.replace("/login")
    }

    recoverDevice() {
      sessionStorage.setItem("recover-action", recoveryActions.forgotMfa)
      window.location.replace("/recover")
    }

    render() {
        return (
            <div className="custom-sign-in">
                <div className="mfa-sign-in-form">
                    <div className="form-header">
                        <img className="logo" src={logo}></img>
                    </div>
                    <div className="credentials">
                        <div className="mfa-confirm">
                            <h4>Almost there!</h4>
                            <p>We just need a little more information</p>
                            <ol className="mfa-steps">
                                <li>
                                    Open the authenticator app using the phone
                                    or mobile device you used when setting up
                                    multi-factor authentication (MFA)
                                </li>
                                <li>
                                    Enter the verification code below to sign in
                                </li>
                            </ol>
                        </div>
                        <div className="verification-code">
                            <Input
                                className="input-form"
                                type="text"
                                fluid="true"
                                onChange={(value) => this.handleMFACode(value)}
                                label="Verification Code"
                                placeholder="6-digit code"
                                onEnter={() => this.verifyMFA()}
                            />
                        </div>
                        <div className="verify-code">
                            <br />
                            <Button
                                type="primary"
                                onClick={() => this.verifyMFA()}
                                content="Verify"
                                disabled={this.state.disabled}
                            />
                            <br />
                            <p className="resend-password center-text">
                                Unable to use device? Click{' '}
                                <a
                                    className="resend-code-link"
                                    onClick={() =>
                                      this.recoverDevice()
                                    }
                                >
                                    here
                                </a>
                            </p>
                            <p className="forgot-password center-text">
                                <a
                                    className="forgot-password-link"
                                    onClick={() => this.backToSignIn()}
                                >
                                    Back to sign in?
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    closeButton={false}
                    newestOnTop={true}
                    position="bottom-right"
                    toastClassName="toast-notification-wrap"
                />
            </div>
        );
    }
}

export default CustomRequireMFA;
