/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React, { useState, useEffect } from "react";
import {  useMsal, useIsAuthenticated } from "@azure/msal-react";
import { BrowserRouter as Router, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Auth from '@aws-amplify/auth';

const SignOutAzure = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
  
    const handleLogout = async() => {
        const currentAccount = accounts[0]
        // Extract login hint to use as logout hint
        // promptless logout
        //make sure to sign out of cognito first and then out of Microsoft 
        signOutCognito()

        //sign out of microsoft, this will ensure users have to re-enter their password
        await instance.logoutRedirect({ account: currentAccount });
   
    }

    //helper function also sign the user out of cognito
    const signOutCognito = async() =>  {
 
        localStorage.clear()
        sessionStorage.clear()
    }


    /**
     * Renders a button which, when selected, will redirect the page to the logout prompt for Microsoft 
     */
    return (
         <NavLink className='hqs-menu-item' to='#'  onClick={() => handleLogout()}>Logout</NavLink>
    );
}



export default SignOutAzure;