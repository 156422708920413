// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import './index.css';
import * as HQS_API from './components/utils/api';

// import './prism.css';
import '@scuf/datatable/honeywell-compact/theme.css';
import '@scuf/common/honeywell-compact/theme.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { MsalConfig } from '../src/components/config';
import Auth from '@aws-amplify/auth';
import ToastNotification from '../src/components/Notifications/ToastNotification';
import { toast } from 'react-toastify';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import AuthProvider from './context/AuthProvider';
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(MsalConfig);
// const api = new API();

//helper function to sign the user out of cognito
const signOutCognito = async () => {
    await Auth.signOut().catch((err) => console.log(err));
};

// helper function that runs after we've redirected the user from a login or logout event
function handleResponse(response) {
    if (response !== null) {
        // call registratio complete if we're doing a signup action
        if (response.state === 'signup') {
            handleRegistration(response.idToken);
        }
    } else {
        // In case multiple accounts exist, you can select
        const currentAccounts = msalInstance.getAllAccounts();
        if (currentAccounts.length === 0) {
            // no accounts signed-in, attempt to finish signing out a user through Cognito
        }
    }
}

function handleRegistration(idToken = '') {
    const queryParams = new URLSearchParams(window.location.search);
    const inviteId = queryParams.get('id');
    const homePage = window.location.protocol + '//' + window.location.host;
    const body = {
        'invite-id': inviteId,
        'id-token': idToken,
    };
    HQS_API.userRegistration(body)
        .then((response) => {
            // redirect user to registration completed path
            window.location.replace("/registration-completed");

        })
        .catch((error) => {
            if (error !== undefined) {
                error = error.response.data.error
                const title = "Unable to complete registration!";
                let details = error.text
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            }
        });
}

function handleError(error) {
    //catch any other error and display the message to user
    const title = 'An error has occurred';
    const details = error.message;
    toast(
        <ToastNotification
            closeToast={false}
            title={title}
            details={details}
            severity="critical"
        />,
    );
}

// define what should happen after a successful or failed redirect action
msalInstance.handleRedirectPromise().then(handleResponse).catch(handleError);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
// ReactDOM.render(<MsalProvider instance={msalInstance}><App /></MsalProvider>, document.getElementById('root'));
// ReactDOM.render(<AuthProvider><App /></AuthProvider>, document.getElementById('root'));

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <AuthProvider>
            <App />
        </AuthProvider>
    </MsalProvider>
    ,
    document.getElementById('root'),
);
