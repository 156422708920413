/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Input, InputLabel, Modal, Select } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import { arrayRange } from '../utils/helpers';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const UpdateUserGroupPriorityForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [priority, setPriority] = useState(props.priority);

    const updateUserGroup = async () => {
        // update user priority
        let body = { priority: Number(priority) };
        HQS_API.updateUserGroup(props.org_name, props.group_id, body)
            .then((response) => {
                if (props.callback) {
                    props.callback();
                }
                const title = 'Priority Updated';
                const details = 'Priority has been updated successfully';
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="success" />);
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
            });
    };

    const handleUpdate = (event) => {
        if (event) {
            event.preventDefault();
        }
        updateUserGroup();
        handleClose();
    };

    const handleClose = () => {
        setShow(false);
        setPriority(props.priority);
    };

    const genPriorityOptions = () => {
        const formattedPriorities = [];
        const priorityArray = arrayRange(1, 10, 1);
        priorityArray.forEach((priority) => {
            let formattedPriority = { value: priority, text: priority };
            formattedPriorities.push(formattedPriority);
        });

        return (
            <Select
                id="selectPriority"
                options={formattedPriorities}
                placeholder="Select Priority"
                onChange={(text) => setPriority(text)}
                reserveSpace={true}
                fluid={true}
                search={true}
                indicator="required"
                value={priority}
            />
        );
    };

    return (
        <div>
            <div onClick={handleShow}>
                <Icon style={{ cursor: 'pointer' }} root="common" name="edit" size="small"></Icon>
                &nbsp;{props.children || 'Update Priority'}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
                style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="node-sequence" size="medium"></Icon>
                        &nbsp;&nbsp;Update User Group Priority
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>
                        Update priority value for &quot;
                        {props.group_name}&quot;.
                    </p>
                    <InputLabel label="Job Execution Priority" indicator="required" />
                    <p>
                        Select a priority value between 1 and 10.
                        <br /> 1 is the "highest" priority and 10 is the "lowest" priority
                    </p>
                    {genPriorityOptions()}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateUserGroupPriorityForm;
