import React from 'react';
import UpdateHqcQuotaForm from '../Forms/UpdateOrgQuota';
import { roundTwoDecimalPlaces } from '../utils/helpers';
import './PlanType.css';


function OrgHqcQuota(props) {
    let hqcQuota = props.quota !== undefined ? props.quota : 0;
    hqcQuota = roundTwoDecimalPlaces(hqcQuota);

    return (
        <div className="hqs-plan-container">
            <div className="hqs-plan-values">{hqcQuota}</div>
            <UpdateHqcQuotaForm
                org={props.org}
                quota={hqcQuota}
                callBack={props.callBack}
            />
        </div>
    );
}

export default OrgHqcQuota;
