/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React, { createContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = (props) => {
    const[auth, setAuth] = useState();
    // function customSetAuth(value) {
    //     console.log("I'm being called with: " + JSON.stringify(value))
    //     setAuth(value)
    // }

    return (
        <AuthContext.Provider value={{auth, setAuth}}>{props.children}</AuthContext.Provider>
    )
}

export default AuthContext;