import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import * as HQS_API from '../utils/api';
import './GroupsTab.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { Select, Icon, Button } from '@scuf/common';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { roundTwoDecimalPlaces, toISODate } from '../utils/helpers';
import { GenerateUserGroupUsageReportForm } from '../Forms/GenerateUserGroupUsageReport';
import AdminGroupActionSelect from '../Other/AdminGroupActionSelect';
import { ToastContainer } from 'react-toastify';

const tableOptions = {
    custom: true,
    sizePerPage: 30,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
};

class GroupsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userGroups: [],
            quotasMap: {},
            selectedRow: undefined,
            selectedRows: [],
            isPagePrevDisabled: true,
            isPageNextDisabled: false,
            page: {
                number: 0,
                nextKey: '',
                prevKey: '',
            },
            pageKey: '',
            selectedOrg: '',
            selectedOrgId: '',
            fetchingGroups: false,
            orgs: [{ value: 0, text: 'All Orgs' }],
            columns: [
                {
                    dataField: 'name',
                    text: 'GROUP NAME',
                    style: {
                        whiteSpace: 'nowrap',
                        width: '60px',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    sort: false,
                },
                {
                    dataField: 'priority',
                    text: 'PRIORITY',
                    style: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    sort: false,
                },
                {
                    dataField: 'orgName',
                    text: 'ORGANIZATION NAME',
                    style: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    sort: false,
                },
                {
                    dataField: 'create-date',
                    text: 'DATE ADDED',
                    style: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    sort: true,
                    formatter: this.dateRenderer,
                },
                {
                    dataField: 'quota-enabled',
                    text: 'QUOTA ENABLED',
                    style: {
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                    },
                    headerClasses: 'hqs-custom-table-header',
                    sort: true,
                },
            ],
        };
        this.onPagePrev = this.onPagePrev.bind(this);
        this.onPageNext = this.onPageNext.bind(this);
        this.fetchUserGroups = this.fetchUserGroups.bind(this);
        this.onOrgSelect = this.onOrgSelect.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.formatOrgOptions = this.formatOrgOptions.bind(this);
        this.dateRenderer = this.dateRenderer.bind(this);
        this.formatOrgOptions(props.orgs);
    }

    componentWillReceiveProps(nextProps) {
        this.formatOrgOptions(nextProps.orgs);
    }

    formatOrgOptions(orgsList) {
        let orgs = this.state.orgs;
        let count = 1;
        orgsList.forEach((org) => {
            let orgOption = {
                value: count,
                text: org['name'],
                id: org['id'],
            };
            orgs.push(orgOption);
            count = count + 1;
        });

        this.setState({
            orgs: orgs,
        });
    }

    dateRenderer(cellData) {
        const data = cellData;
        var date = '';

        if (data) {
            date = toISODate(data);
        }
        return <span>{date}</span>;
    }

    onPageNext() {
        if (this.state.fetchingGroups === false) {
            this.fetchUserGroups(this.state.page['nextKey'], true);
        }
    }

    onPagePrev() {
        if (this.state.fetchingGroups === false) {
            this.fetchUserGroups(this.state.page['prevKey'], false);
        }
    }

    onRefresh() {
        let page = {
            number: 0,
            nextKey: '',
            prevKey: '',
        };
        this.setState({
            page: page,
            isPageNextDisabled: false,
            isPagePrevDisabled: true,
        });
        this.fetchUserGroups('', true);
    }

    onOrgSelect(index) {
        let selectedOrgId = this.state.orgs.find((org) => org.value == index)['id'];

        this.setState(
            {
                selectedOrg: index,
                selectedOrgId: selectedOrgId,
            },
            () => {
                this.onRefresh();
            },
        );
    }

    getSelectedOrgName(index) {
        // if not selecting 'All Orgs' and we have actual org data
        if (index > 0 && this.state.orgs.length > 0 && this.state.orgs[index]) {
            return this.state.orgs[index].text;
        }
        return '';
    }

    expandRow = {
        className: 'user-group-expanded-row',
        renderer: (row, rowIndex) => (
            <div>
                {this.state.quotasMap[row.name].map(function (quota) {
                    return (
                        <div className="umui-quota-record">
                            <div className="umui-quota-header">MACHINE(s): </div>
                            <div className="umui-quota-record-value">{Object.keys(quota['machines']).join(', ')}</div>
                            <div className="umui-quota-header">CREDITS: </div>
                            <div className="umui-quota-record-value">{roundTwoDecimalPlaces(quota['amount'])}</div>
                        </div>
                    );
                })}
            </div>
        ),
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Icon root="common" name="caret-down" size="small" />
                    </div>
                );
            }
            return (
                <div style={{ textAlign: 'center' }}>
                    <Icon root="common" name="caret-right" size="small" />
                </div>
            );
        },
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return '';
        },
    };

    fetchUserGroups(pageKey, isNext) {
        // if org isn't selected do not query
        if (this.state.selectedOrgId === '') {
            return;
        }

        this.setState({
            fetchingGroups: true,
        });

        let queryParams = {
            isForward: isNext,
        };

        if (this.state.selectedOrg !== 0) {
            queryParams['org-id'] = this.state.selectedOrgId;
        }

        HQS_API.getUserGroupsAdmin(queryParams, pageKey)
            .then((response) => {
                let page = this.state.page;
                let orgsList = this.state.orgs;
                let nextDisabled = false;
                let prevDisabled;

                if (isNext) {
                    page['prevKey'] = page['nextKey'];
                    page['number'] = page['number'] + 1;

                    if (page.number === 1) {
                        prevDisabled = true;
                    } else {
                        prevDisabled = false;
                    }

                    if ('key' in response) {
                        page['nextKey'] = response['key'];
                        nextDisabled = false;
                    } else {
                        nextDisabled = true;
                    }
                } else {
                    page['nextKey'] = page['prevKey'];
                    page['number'] = page['number'] - 1;

                    if ('key' in response) {
                        page['prevKey'] = response['key'];
                        prevDisabled = false;
                    } else {
                        prevDisabled = true;
                    }
                }

                // create map of group name -> quotas for expanded row
                let quotasMap = {};
                response['items'].forEach(function (group, index) {
                    if ('quotas' in group) {
                        group['quotas'].forEach((quota) => {
                            let machines = quota['machine'];
                            if (Array.isArray(machines)) {
                                machines = machines.join(', ');
                            }
                            quota['machines'] = machines;
                        });
                        quotasMap[group['name']] = group['quotas'];
                    } else {
                        quotasMap[group['name']] = [];
                    }

                    // map org-id to org-name
                    let matchingOrg = orgsList.find((org) => org.id == group['org-id']);
                    if (matchingOrg !== undefined) {
                        group['orgName'] = matchingOrg['text'];
                    } else {
                        group['orgName'] = 'None';
                    }
                });

                this.setState({
                    userGroups: response['items'],
                    page: page,
                    quotasMap: quotasMap,
                    fetchingGroups: false,
                    isPageNextDisabled: nextDisabled,
                    isPagePrevDisabled: prevDisabled,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fetchingGroups: false,
                });
            });
    }

    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectAll: true,
            selected: this.state.selectedRows,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect) {
                    console.log(row);
                    this.setState(() => ({
                        selectedRows: [row.id],
                        selectedRow: row,
                    }));
                } else {
                    this.setState(() => ({
                        selectedRows: [],
                        selectedRow: undefined,
                    }));
                }
            },
        };
        return (
            <div className="hqs-admin-view">
                <div className="hqs-admin-jobs-card">
                    <div id="product-table-wrapper">
                        <PaginationProvider pagination={paginationFactory(tableOptions)}>
                            {({ paginationProps, paginationTableProps }) => (
                                <div style={{ border: '1px solid #d0d0d0', height: '682px' }}>
                                    <ToolkitProvider
                                        keyField="id"
                                        data={this.state.userGroups}
                                        columns={this.state.columns}
                                        columnToggle
                                        search={true}>
                                        {(toolkitprops) => (
                                            <div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        height: '50px',
                                                        flexDirection: 'row-reverse',
                                                    }}>
                                                    <div
                                                        onClick={this.onRefresh}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '6px 0px 0px 5px',
                                                            padding: '5px 0px 0px 5px',
                                                        }}>
                                                        <Icon
                                                            root="building"
                                                            name="refresh"
                                                            size="large"
                                                            loading={this.state.fetchingGroups}
                                                        />
                                                    </div>

                                                    <div style={{ padding: '5px 0px 0px 5px' }}>
                                                        <Select
                                                            placeholder="select org"
                                                            options={this.state.orgs}
                                                            onChange={this.onOrgSelect}
                                                            value={this.state.selectedOrg}
                                                            search={true}
                                                        />
                                                    </div>
                                                    <div style={{ padding: '5px 0px 0px 5px' }}>
                                                        <GenerateUserGroupUsageReportForm
                                                            key={this.state.fetchingGroups}
                                                            userGroups={this.state.userGroups}
                                                            org_name={this.getSelectedOrgName(this.state.selectedOrg)}
                                                            disabled={this.state.userGroups.length == 0}
                                                            allGroups={true}
                                                        />
                                                    </div>
                                                    <div style={{ padding: '5px 0px 0px 5px' }}>
                                                        <AdminGroupActionSelect
                                                            key={
                                                                this.state.selectedRow !== undefined
                                                                    ? this.state.selectedRow.id
                                                                    : this.state.selectedRow
                                                            }
                                                            selectedRow={this.state.selectedRow}
                                                            disabled={this.state.selectedRow === undefined}
                                                            callback={this.onRefresh}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="hqs-admin-fq-data">
                                                    <BootstrapTable
                                                        remote={{ sort: true }}
                                                        noDataIndication="No records found"
                                                        striped
                                                        hover
                                                        headerStyle={{ background: 'red' }}
                                                        height="515px"
                                                        rowClasses="hqs-custom-table-row"
                                                        defaultSortName="date"
                                                        {...toolkitprops.baseProps}
                                                        {...paginationTableProps}
                                                        expandRow={this.expandRow}
                                                        bordered={false}
                                                        selectRow={selectRow}
                                                    />
                                                    <ToastContainer
                                                        hideProgressBar={true}
                                                        closeOnClick={false}
                                                        closeButton={false}
                                                        newestOnTop={true}
                                                        position="bottom-right"
                                                        toastClassName="toast-notification-wrap"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                    {this.state.userGroups.length > 0 ? (
                                        <div className="hqs-custom-table-pagination">
                                            <Button
                                                type="primary"
                                                size="small"
                                                icon={<Icon name="caret-left" root="common" />}
                                                content="prev"
                                                iconPosition="left"
                                                disabled={this.state.isPagePrevDisabled}
                                                onClick={this.onPagePrev}
                                            />
                                            <div style={{ fontWeight: 'bold' }}>{this.state.page.number}</div>
                                            <Button
                                                type="primary"
                                                size="small"
                                                icon={<Icon name="caret-right" root="common" />}
                                                content="next"
                                                iconPosition="right"
                                                disabled={this.state.isPageNextDisabled}
                                                onClick={this.onPageNext}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className="hqs-custom-table-pagination">
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>
                                </div>
                            )}
                        </PaginationProvider>
                    </div>
                </div>
            </div>
        );
    }
}

export default GroupsTab;
