// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React, { Component } from 'react';
import * as HQS_API from '../utils/api';
import moment from 'moment';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import Pdf from '../../../public/user_terms_and_conditions.pdf';

import './SettingsTab.css';


import { DataTable } from '@scuf/datatable';
import { Button, Tab, Icon, InputLabel, Input, Grid, BadgedIcon, Popup, Accordion, toggleTheme, Badge } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AdminSettingsActionSelect from '../Other/AdminSettingsActionSelect';
import AdminTagActionSelect from '../Other/AdminTagActionSelect';

import {
    isElevatedViewMode,
    isNullOrEmpty,
    getVersionCategory,
    agreementTypes,
    toISODate,
    getEula,
    getSoftwareFromChallenge
} from '../utils/helpers';

import CreateTermsAndConditionsForm from '../Forms/CreateTermsAndConditions';
import CreateTagForm from '../Forms/CreateTag';
import VersionCategoryPopup from '../Forms/VersionCategoryPopup'

const FilterPanel = DataTable.HeaderBar.FilterPanel;


class SettingsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: props.mode,
            activeTerms: {},
            scheduledTerms: {},
            terms: [],
            fetchingTerms: false,
            selectedTerms: {},
            termsRecordSelectedRow: null,
            selectedTag: {},
            tagRecordSelectedRow: null,
            selectedDate: moment().format('dddd, MMMM DD yyyy'),
            isElevatedViewMode: isElevatedViewMode(props.mode),
            fetchingActiveTerms: true,
            fetchingScheduledTerms: true,
            showTerms: true,
            showTags: false
        };
        this.getActiveTermsAndConditions = this.getActiveTermsAndConditions.bind(this)
        this.getScheduledTermsAndConditions = this.getScheduledTermsAndConditions.bind(this)
        this.generateTermsSettings = this.generateTermsSettings.bind(this);
        this.generateTermsTable = this.generateTermsTable.bind(this);
        this.refresh = this.refresh.bind(this);
        this.refreshTags = this.refreshTags.bind(this);
        this.dateRenderer = this.dateRenderer.bind(this)
        this.categoryRenderer = this.categoryRenderer.bind(this)
        this.showTerms = this.showTerms.bind(this)
        this.showTags = this.showTags.bind(this)

    }

    async componentDidMount() {
        this.getActiveTermsAndConditions();
        this.getScheduledTermsAndConditions();
        this.getTags()
    }


    async getActiveTermsAndConditions() {
        let queryParams = {
            mode: this.props.mode,
            active: true
        };

        HQS_API.getTermsAndConditions(queryParams)
            .then((response) => {

                let active = {}
                if (response !== undefined) {
                    active = response
                }
                this.setState({ activeTerms: active });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingActiveTerms: false });
            });
        this.setState({ fetchingActiveTerms: true });

    }


    async getScheduledTermsAndConditions() {
        let queryParams = {
            mode: this.props.mode,
            status: 'ready'
        };

        HQS_API.getTermsAndConditions(queryParams)
            .then((response) => {
                let scheduled = {}

                if (response !== undefined) {
                    scheduled = response
                }
                this.setState({ scheduledTerms: scheduled });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingScheduledTerms: false });

            });
        this.setState({ fetchingScheduledTerms: true });

    }

    async getTags() {
        let queryParams = {
            mode: this.props.mode,
        };

        HQS_API.getTags(queryParams)
            .then((response) => {

                let data = {}
                if (response !== undefined) {
                    data = response
                }
                this.setState({ tags: data });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingTags: false });
            });
        this.setState({ fetchingTags: true });

    }

    dateRenderer = (cell, row) => {
        var format = '';
        if (cell !== undefined && cell.value !== undefined) {
            const data = cell.value;

            if (data) {
                format = toISODate(data)
            }
        }
        return <span>{format}</span>;
    };

    categoryRenderer = (cell) => {
        var cat = ''
        var version = ''
        if (cell !== undefined && cell.value !== undefined) {
            const data = cell.value;

            if (data) {
                cat = getVersionCategory(data)
                version = data
            }
        }

        return <span><VersionCategoryPopup version={version}/></span>;
    };

    agreementRenderer = (cell) => {
        var cat = ''
        var agreement = 'Hardware'
        var software = ''
        var auth_challenge = ''
        if (cell !== undefined && cell.value !== undefined) {
            const data = cell.value;
          
            if (data) {

                agreement = agreementTypes[data]

                if ("rowData" in cell) {
                    const row = cell["rowData"]
                    console.log(row)
                    if ("auth-challenge" in row) {
                        auth_challenge = row["auth-challenge"]
                        software = getSoftwareFromChallenge(auth_challenge)
                    }
                }
            }
        }

        if (software && auth_challenge){
            //software EULA
            return (<span>
                    <a className="forgot-password-link"
                            href={getEula(software, auth_challenge)}
                            target="_blank">{agreement}</a>
                    </span>);
        } else if (agreement == 'Hardware'){
            
            //it's a hardware agreement
            return (<a className="forgot-password-link" href={Pdf} target="_blank" title="View the current policy and terms of use document">{agreement} </a>)
        }else {
            //default
            return <span>{agreement}</span>;
        }
    };

    lastUpdatedDateRenderer(cellData) {
        let lastUpdate = cellData.value
        if ("rowData" in cellData) {
            const row = cellData["rowData"]
            if ("last-updated-date" in row) {
                lastUpdate = row["last-updated-date"]
            }
        }
        let format = ''
        const data = lastUpdate
        if (data) {
            format = toISODate(data)
        }
        return <span>{format}</span>;
    }

    showTerms(){
        let show = this.state.showTerms;
        this.setState({ showTerms: !show });

    }

    showTags(){
        let show = this.state.showTags;
        this.setState({ showTags: !show });

    }



    refresh() {
        this.getActiveTermsAndConditions();
        this.getScheduledTermsAndConditions();
        this.setState({ termsRecordSelectedRow: null, selectedTerms: {} });
    }

    refreshTags() {
        this.getTags();
        this.setState({ tagRecordSelectedRow: null, selectedTag: {} });
        if (this.props.callback) {
            this.props.callback();
        }
    }
    
    


    generateMissingTermsCreate() {
        return (
            <div className="hqs-settings-info">
                <br />
                <p>There are currently no active or scheduled terms and conditions. Let's get started.</p>
                <CreateTermsAndConditionsForm
                    mode={this.state.mode}
                    callback={this.refresh}
                    disabled={false}
                    activeTerms={this.state.activeTerms}
                />
            </div>
        )
    }

    generateTermsSettings() {
        if (this.state.fetchingActiveTerms || this.state.fetchingScheduledTerms) {
            return (
                <div>
                    <br />
                    <br />
                    <div className="hqs-settings-info">
                        <Icon name='refresh' size='large' loading={true} color="#1274B7"></Icon>
                    </div>
                </div>
            )

        } else {

            let active = this.state.activeTerms
            let scheduled = this.state.scheduledTerms

            //check if the active and scheduled record are the same

            if (isNullOrEmpty(active) && isNullOrEmpty(scheduled)) {
                return this.generateMissingTermsCreate()
            } else {


                return (
                    <div>
                        {this.generateTermsTable("Active", active, true)}
                        <br/>
                        {this.generateTermsTable("Scheduled", scheduled, false)}
                       
                            <div className="hqs-settings-info">
                                <br />
                                <p>Need users to accept changes to our terms and conditions? Let's get started!</p>
                                <CreateTermsAndConditionsForm
                                    mode={this.state.mode}
                                    callback={this.refresh}
                                    disabled={false}
                                    activeTerms={this.state.activeTerms}
                                />
                            </div>
                      

                    </div>
                )

            }
        }
    }


    generateTermsTable(label, data, isActive) {
     
        if (!isNullOrEmpty(data)) {
            //convert data into list for datatable
            let table = data
            return (
                <div>
                    <InputLabel label={label} />
                    <DataTable
                        data={table}
                        scrollable={true}
                        scrollHeight="590px"
                        resizableColumns={true}
                        selection={this.state.termsRecordSelectedRow}
                        selectionMode="single"
                        onSelectionChange={(e) =>
                            this.handleTermsRowSelect(e)
                        }
                        onRowClick={(e) =>
                            this.handleTermsRowSelect(e)
                        }
                    >
                        <DataTable.HeaderBar>
                            <DataTable.HeaderBar.Item
                                iconSize="small"
                                icon="refresh"
                                onClick={this.refresh}
                            />
                            <div style={{ display: this.state.mode === 'admin' ? 'flex' : 'none' }}>
                                <AdminSettingsActionSelect
                                    key={this.state.selectedTermsId}
                                    mode={this.state.mode}
                                    active={isActive}
                                    callback={this.refresh}
                                    selectedRow={this.state.termsRecordSelectedRow}
                                    
                                />
                            </div>
                        </DataTable.HeaderBar>
                        <DataTable.Column
                            field="id"
                            header="id"
                            initialWidth="320px"
                        />
                        <DataTable.Column
                            field="agreement-type"
                            header="Agreement"
                            align="center"
                            initialWidth="150px"
                            renderer={this.agreementRenderer}
                        />
                        <DataTable.Column
                            field="status"
                            header="Status"
                            align="center"
                            initialWidth="120px"
                        />
                        <DataTable.Column
                            field="version"
                            header="Version"
                            align="center"
                            initialWidth="100px"
                        />
                         <DataTable.Column
                            field="version"
                            header="Impact"
                            align="center"
                            initialWidth="120px"
                            renderer={this.categoryRenderer}
                        />
                        <DataTable.Column
                            field="create-date"
                            header="CREATE DATE"
                            align="center"
                            renderer={this.dateRenderer}
                            initialWidth="190px"

                        />
                        <DataTable.Column
                            field="effective-date"
                            header="Effective Date"
                            align="center"
                            renderer={this.dateRenderer}
                            initialWidth="190px"

                        />
                        <DataTable.Column
                            field="grace-period-date"
                            header="GRACE PERIOD"
                            align="center"
                            renderer={this.dateRenderer}
                            initialWidth="190px"

                        />
                        <DataTable.Column
                            field="end-date"
                            header="END DATE"
                            align="center"
                            renderer={this.dateRenderer}
                            initialWidth="190px"
                        />
                        <DataTable.Column
                            field="last-updated-date"
                            header="LAST UPDATED"
                            align="center"
                            renderer={this.dateRenderer}
                            initialWidth="190px"
                        />
                    </DataTable>
                </div>)

        } else {
            return ""
        }
    }

    createNewTag() {
        return (
            <div className="hqs-settings-info">
                <br />
                <p>Need a new tag? Click here to get started.</p>
                <CreateTagForm
                    mode={this.state.mode}
                    callback={this.refreshTags}
                    disabled={false}
                    tags={this.state.tags}
                />
            </div>
        )
    }


    generateTagSettings() {
        if (this.state.fetchingTags) {
            return (
                <div>
                    <br />
                    <br />
                    <div className="hqs-settings-info">
                        <Icon name='refresh' size='large' loading={true} color="#1274B7"></Icon>
                    </div>
                </div>
            )

        } else {

            let tags = this.state.tags

            
            return (
                <div>
                    {this.generateTagTable("Tags", tags)}
                        
                    {this.createNewTag()}
                       
                    <br/>
                    

                </div>
            )

            
        }
    }

    handleTermsRowSelect(selectedRow) {
        let selectedTermsId = ""
        if (selectedRow !== undefined) {
            /** handle case when selected row data is array */
            if (Array.isArray(selectedRow)) {
                if (selectedRow.length === 0) {
                    selectedRow = null;
                } else {
                    selectedTermsId = selectedRow[0]['id']
                }
            } else if ('data' in selectedRow) {
                selectedRow = [selectedRow.data]
                selectedTermsId = selectedRow[0]['id']

            } else {
                selectedRow = null;
            }

            this.setState({
                termsRecordSelectedRow: selectedRow,
                selectedTermsId: selectedTermsId,
            });
        }
    }

    handleTagRowSelect(selectedRow) {
        let selectedTagId = ""
        if (selectedRow !== undefined) {
            /** handle case when selected row data is array */
            if (Array.isArray(selectedRow)) {
                if (selectedRow.length === 0) {
                    selectedRow = null;
                } else {
                    selectedTagId = selectedRow[0]['id']
                }
            } else if ('data' in selectedRow) {
                selectedRow = [selectedRow.data]
                selectedTagId = selectedRow[0]['id']

            } else {
                selectedRow = null;
            }

            this.setState({
                tagRecordSelectedRow: selectedRow,
                selectedTagId: selectedTagId,
            });
        }
    }


    generateTagTable(label, data) {
        if (!isNullOrEmpty(data)) {
            //convert data into list for datatable
            let table = data

            return (
                <div>
                    <InputLabel label={label} />
                    <DataTable
                        data={table}
                        scrollable={true}
                        scrollHeight="590px"
                        resizableColumns={true}
                        selection={this.state.tagRecordSelectedRow}
                        selectionMode="single"
                        onSelectionChange={(e) =>
                            this.handleTagRowSelect(e)
                        }
                        onRowClick={(e) =>
                            this.handleTagRowSelect(e)
                        }
                    >
                        <DataTable.HeaderBar>
                            <DataTable.HeaderBar.Item
                                iconSize="small"
                                icon="refresh"
                                onClick={this.refreshTags}
                            />
                            <div style={{ display: this.state.mode === 'admin' ? 'flex' : 'none' }}>
                            <AdminTagActionSelect
                                key={this.state.selectedTagId}
                                selectedRow={this.state.tagRecordSelectedRow}
                                mode={this.state.mode}
                                callback={this.refreshTags}
                            />
                            </div>
                        </DataTable.HeaderBar>
                        <DataTable.Column
                            field="key"
                            header="Key"
                            align="center"
                            initialWidth="120px"
                        />
                        <DataTable.Column
                            field="description"
                            header="Description"
                            align="center"
                            initialWidth="250px"
                        />
                        <DataTable.Column
                            field="create-date"
                            header="Last Updated"
                            align="center"
                            renderer={this.lastUpdatedDateRenderer}
                            initialWidth="190px"
                        />
                        <DataTable.Column
                            field="last-updated-by"
                            header="Last Updated By"
                            align="center"
                            initialWidth="200px"
                        />
                    </DataTable>
                </div>)

        } else {
            return ""
        }
    }


    render() {





        return (
            <div className="hqs-terms-view">
                <div className="hqs-terms-card">
                   
                        <Accordion>
                            <Accordion.Content title="Terms and Conditions"  arrowPosition="left" active={this.state.showTerms} onClick={() => this.showTerms()}>
                                <div>
                                    <p>Manage various settings regarding our <a className="forgot-password-link" href={Pdf} target="_blank" title="View the current policy and terms of use document"> Policy & Terms of Use </a> including:</p>
                                    <ul>
                                        <li>Viewing the currently active terms and conditions for our users</li>
                                        <li>Scheduling when new terms and conditions should take effect</li>
                                    </ul>
                                    {this.generateTermsSettings()}
                                </div>
                            </Accordion.Content>
                            <Accordion.Content title="Tags"  arrowPosition="left" active={this.state.showTags} onClick={() => this.showTags()}>
                                <div>
                                    <p>Manage the available keys that can be used to tag data records</p>
                                    {this.generateTagSettings()}
                                </div>
                            </Accordion.Content>
                        </Accordion>
                        <ToastContainer
                            hideProgressBar={true}
                            closeOnClick={false}
                            closeButton={false}
                            newestOnTop={true}
                            position="bottom-right"
                            toastClassName="toast-notification-wrap"
                        />
                </div>
            </div >


        );
    }
}

export default SettingsTab;
