import React from 'react';
import { Divider, Icon } from '@scuf/common';
import moment from 'moment';
import DeleteEventForm from '../Forms/DeleteEvent';
import UpdateCalendarEvent from '../Forms/UpdateCalendarEvent';
import { toISODate } from '../utils/helpers';
import './CalendarEvent.css';

function CalendarEvent(props) {
    let dividerClass = 'umui-maintenance-divider';
    let eventClass = 'umui-calendar-event';
    if ('deleted' in props) {
        if (props.deleted) {
            eventClass = eventClass + ' disabled';
        }
    }

    if (props.eventType === 'reservation') {
        dividerClass = 'umui-reserve-divider';
    } else if (props.eventType === 'online') {
        dividerClass = 'umui-online-divider';
    }

    const updateComponent = (
        <UpdateCalendarEvent
            id={props.id}
            title={props.title}
            startTime={props.startTime}
            endTime={props.endTime}
            eventType={props.eventType}
            reservationType={props.reservationType}
            orgs={props.orgs}
            allMachines={props.allMachines}
            selectedOrg={props.selectedOrg}
            machine={props.machine}
            recurringID={props.recurringID}
            callBack={props.callBack}
        />
    );

    const deleteComponent = (
        <DeleteEventForm
            id={props.id}
            recurringID={props.recurringID}
            startTime={props.startTime}
            endTime={props.endTime}
            canceledDate={props.canceledDate}
            callBack={props.callBack}
        />
    );

    //determine if edit and delete should be availabe based on end-date
    let updateEvent = '';
    let deleteEvent = deleteComponent;
    let canceled = '';

    if (!moment(props.endTime).isBefore(moment().format(), 'second')) {
        updateEvent = updateComponent;
    }
    if (props.canceledDate !== undefined) {
        canceled = ' (canceled on: ' + toISODate(props.canceledDate) + ')';
    }

    return (
        <div className="umui-calendar-event">
            <div className="event-times">
                <div>{toISODate(props.startTime)}</div>
                <div>{toISODate(props.endTime)}</div>
            </div>
            <Divider direction="vertical" className={dividerClass}></Divider>
            <div className="event-details">
                <div>{props.title.replace('(canceled)', canceled)}</div>
                <div>&nbsp;</div>
                <div className="umui-actions">
                    {updateEvent} &nbsp;&nbsp;
                    {deleteEvent}
                </div>
            </div>
        </div>
    );
}

export default CalendarEvent;
