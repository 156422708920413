import React from 'react';
import ChangeCustomerIdForm from '../Forms/ChangeCustomerID';
import './PlanType.css';

function CustomerID(props) {
    let displayId =
        props.customerID !== '' && props.customerID !== undefined
            ? props.customerID
            : 'Not set';

    return (
        <div className="hqs-customer-container">
            <div className="hqs-customer-values">{displayId}</div>
                <div style={{display: props.mode === 'admin' ? '' : 'none'}}>
                    <ChangeCustomerIdForm
                        org={props.org}
                        customerID={props.customerID}
                        callBack={props.callBack}
                    />
                </div>
        </div>
    );
}

export default CustomerID;
