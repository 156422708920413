import { propStyle } from 'aws-amplify-react';
import React from 'react';
import { useState, useRef } from 'react';
import './CalendarLegend.css';
import { isElevatedViewMode } from '../utils/helpers';


const CalendarLegend = (props) => {
    const name = props.name
    const label = props.label
    const checked = props.checked
    const isElevated =  isElevatedViewMode(props.mode)

    const fetchTimer = useRef(-1);

    function capitalize(str){
        if (str){
            return str.charAt(0).toUpperCase() + str.slice(1);
        }else{
            return str
        }
    }

    function handleChecked(event) {
       
        let checked = event.target.checked
        let value = false
        if (checked) {
            value = true
        }
        
        refresh(checked)
    }


    const refresh = (checked, timeOut = 0) => {

        if (fetchTimer.current) window.clearTimeout(fetchTimer.current);

        fetchTimer.current = window.setTimeout(
            props.onChange,
            timeOut,
            name,
            checked

        );
      
    }

    function getName(name){
        let key = capitalize(name)
        
        // user and org modes will only show their active reservations. 
        if (!isElevated && name == 'reservation'){
            key = key + " (You)"
        }
        return key
    }

    function getColor(name, checked){
        
        // update the name if mode is not an elevated view mode (is not admin or operator)
        if (!isElevated && name == 'reservation_you'){
            name = "reservation-you"
        }
        
        let checkedStyle = (checked)? "" : " unchecked"
        return "checkmark " + name + checkedStyle
       
    }

     

    return (
        <label class="calendar-legend">
            <div>{label}</div>
            <input type="checkbox" onChange={(checked) => handleChecked(checked)} checked={checked} />
            <span className={getColor(name, checked)}>
            </span>
        </label>
    );
}

export default CalendarLegend;
