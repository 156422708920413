import React from 'react';
import { Divider, Icon } from '@scuf/common';
import moment from 'moment';
import { toISODate } from '../utils/helpers'
import './CalendarEvent.css';

function CalendarEventUser(props) {
    var dividerClass = '';
    if (props.eventType === 'reservation') {
        if (props.userEvent) {
            dividerClass = 'umui-user-reserve-divider';
        } else {
            dividerClass = 'umui-reserve-divider';
        }
    } else if (props.eventType === 'online') {
        dividerClass = 'umui-online-divider';
    } else {
        dividerClass = 'umui-maintenance-divider';
    }

    return (
        <div className="umui-calendar-event">
            <div className="event-times">
                <div>{toISODate(props.startTime)}</div>
                <div>{toISODate(props.endTime)}</div>
            </div>
            <Divider direction="vertical" className={dividerClass}></Divider>
            <div className="event-details">
                <div>{props.title}</div>
            </div>
        </div>
    );
}

export default CalendarEventUser;
