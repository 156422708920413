/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import UpdateOrgTypeForm from '../Forms/UpdateOrgType';
import './PlanType.css';

function OrgType(props) {
    let type = props.type

    return (
        <div className="hqs-plan-container">
            <div className="hqs-plan-values">{type}</div>
            <UpdateOrgTypeForm org={props.org} type={props.type} callBack={props.callBack} />
        </div>
    );
}

export default OrgType;
