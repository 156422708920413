/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import axios from 'axios';
import { AmplifyConfig } from '../config';

export const API_LOGIN = axios.create({
    baseURL: AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_MACHINE_API').endpoint,
    timeout: 30000,
})
 

const passError = (error) =>{
    //log the error
    console.log(error);

    // throw the error and include the response to let the calling functoins handle this
    // necessary to show toast messages

    //using in a select few places while we find a long-term solution
    
    throw {
        response: error.response,
        error: new Error()
    };
    
}

export const login = async (username, password, federated = 'false', challenge = '', challenge_answer = 'False', mfa_code = '') => {
    let body = {'email':username};
    if (federated === 'true'){
        body['provider-token'] = password
    } else {
        body['password'] = password
    }
    if (challenge) {
        body['challenge'] = challenge
    }

    if (challenge_answer) {
        body['challenge-answer'] = challenge_answer
    }

    if (mfa_code) {
        body['code'] = mfa_code
    }

    let headers = {}

    //check if we have an ongoing auth session 
    let session = sessionStorage.getItem("auth-session");
    if (session) {
        //pass the session in the headers so we know how far we are in the process
        headers['session'] = session
    }
    console.log("calling normal login")
    try {
        const response = await API_LOGIN.post('/login', body, { headers: headers});
        console.log("inside login method")
        console.log(response.data)
        
        return response;
    } catch (err) {
        console.log(err);
        //make sure we handle the auth challenges (terms and conditions, etc.)
        if ('challenge-name' in err.response.data){
            //this will enter the 'then' block and then we can handle the sign in behavior accordingly
            return err.response;
        }else{
            //this will enter the 'catch' block so we can display the appropriate error message
            passError(err)
        }
    }
}

