// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';

const JobErrorForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <div
                onClick={handleShow}
                style={{ display: 'flex', cursor: 'pointer' }}
            >
                {' '}
                <div>
                    <Icon
                        root="common"
                        color="#ee3123"
                        name="badge-warning"
                        size="small"
                    ></Icon>
                </div>
                &nbsp;
                <div style={{ paddingTop: '2px', color: '#ee3123' }}>
                    Status
                </div>
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-delete-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Job Error</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        <div>
                            {props.jobError ? (
                                <div>
                                    <p>
                                        <u>Error Code</u>
                                        {': '}
                                        {props.jobError.code}
                                    </p>
                                    <p>
                                        <u>Error Message</u>
                                        {': '}
                                        {props.jobError.text}
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </p>
                    <Button type="primary" size="small" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default JobErrorForm;
