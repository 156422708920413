// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import { Popup, Badge, Card } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { getVersionCategory } from '../utils/helpers';



const VersionCategoryPopup = (props) => {
    const category = getVersionCategory(props.version);

    return (
        <div>
            {category !== undefined && category !== "" ?
        <Popup element={<Badge color={category == 1 ? "red" : "blue"}>{"Category " + category}</Badge>} on="hover" position="right center" >
            <Card>
                <Card.Header title={"Category " + category} style={{ fontWeight: 'bold' }}/>
                <Card.Content style={{ width: '400px' }}>
                    {(category == 1 ?
                        <ul>
                            <li>Infrequent, but represents <b>signficant</b> compliance related changes.</li>
                            <li><b>Prompts</b> all users to accept terms and conditions</li>
                            <li><b>Disrupts</b> service for API users until they've accepted new terms and conditions</li>
                            <li>Denoted by a <b>"major"</b> version number</li>
                            <li>Example: 1.0, 2.0, 3.0, etc.</li>
                        </ul>
                        : <ul>
                            <li>Represents <b>minor</b> changes (verbiage, formatting, etc.)</li>
                            <li>Does <b>not prompt</b> all users to accept terms and conditions</li>
                            <li>Does <b>not disrupt</b> service for API users</li>
                            <li>Denoted by <b>"major.minor" </b> version numbers</li>
                            <li>Example: 1.1, 1.2, 2.1, etc.</li>
                        </ul>)}
                </Card.Content>
            </Card>
        </Popup>: <Badge color="grey" title="Please specify a version number to determine the overall impact">TBD</Badge>}
        </div>
      
    );
};

export default VersionCategoryPopup;
