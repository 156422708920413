/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { useState, useRef } from 'react';
import { DataTable } from '@scuf/datatable';
import { ToastContainer } from 'react-toastify';
import {
    Button,
    Select,
    DatePicker,
    Checkbox,
    Icon,
    BadgedIcon,
    Popup,
    Accordion,
} from '@scuf/common';

import { roundTwoDecimalPlaces, toISODate } from '../utils/helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ColumnToggleList from '../Other/ColumnToggleList';
import BatchActionSelect from '../Other/BatchActionSelect';

import moment from 'moment';

const FilterPanel = DataTable.HeaderBar.FilterPanel;

export function BatchRequestsTab(props) {
    const orgOptions = [{ value: 0, text: 'All Orgs' }];
    const END_STATUSES = ['canceled', 'completed', 'failed', 'canceling'];
    
    props.organizations.sort((a, b) =>
        a.localeCompare(b, 'en', { sensitivity: 'base' }),
    );
    props.organizations.forEach((oo, ii) => {
        orgOptions.push({ value: ii + 1, text: oo });
    });

    const mode = props.mode;

    // Check if we have any values stored in session storage for batches
    let toValue =
        sessionStorage.getItem('hqs-' + mode + '-batches-table-range-to') !== null
            ? moment(sessionStorage.getItem('hqs-' + mode + '-batches-table-range-to'))
            : '';
    let fromValue =
        sessionStorage.getItem('hqs-' + mode + '-batches-table-range-from') !== null
            ? moment(sessionStorage.getItem('hqs-' + mode + '-batches-table-range-from'))
            : '';
    let dateErrorValue = ' ';
    if (toValue !== '' && fromValue !== '') {
        dateErrorValue = '';
    }

    let selectedOrgValue =
        sessionStorage.getItem('hqs-' + mode + '-batches-table-org') !== undefined
            ? parseInt(sessionStorage.getItem('hqs-' + mode + '-batches-table-org'))
            : -1;

    let selectedOrgError = selectedOrgValue < 0 ? ' ' : '';
    const [paramState, setParamState] = useState({
        to: toValue,
        from: fromValue,
        dateError: dateErrorValue,
        orgError: selectedOrgError,
        selectedOrg: selectedOrgValue,
        orgOptions,
    });
    
    const [selectedRow, setSelectedRow] = useState([])
    const [selectedRowStatus, setSelectedRowStatus] = useState(undefined)
    // let selectedBatches = new Set()
    const fetchTimer = useRef(-1);

    // This function will check if we have any filter values
    // currently stored in session storage
    const fetchSavedFilters = (filterName) => {
        // fetch saved filters
        let savedFilters = sessionStorage.getItem(
            'hqs-' + mode + '-batches-table-filter-' + filterName,
        );
        let savedFilterObject = JSON.parse(savedFilters);
        let filtersObjects = new Set();
        if (savedFilterObject !== null && savedFilterObject !== undefined) {
            if (savedFilterObject.hasOwnProperty(filterName)) {
                filtersObjects = new Set(savedFilterObject[filterName]);
            }
        }
        return filtersObjects;
    };

    const [filterState, setFilterState] = useState({
        users: fetchSavedFilters('users'),
        machines: fetchSavedFilters('machines'),
        statuses: fetchSavedFilters('statuses'),
        orgs: fetchSavedFilters('orgs'),
    });

    const [refreshEnabled, setRefreshEnabled] = useState(false);
    const onRangeSelect = ({ to, from }) => {
        const newState = {
            ...paramState,
            to,
            from,
            dateError: to && from ? '' : ' ',
        };
        setParamState(newState);
        fetchIfValid(newState);
        sessionStorage.setItem(
            'hqs-' + mode + '-batches-table-range-from',
            from !== undefined ? from.toISOString() : '',
        );
        sessionStorage.setItem(
            'hqs-' + mode + '-batches-table-range-to',
            to !== undefined ? to.toISOString() : '',
        );
    };

    const onRefresh = () => {
        fetchIfValid(paramState, 0);
        setSelectedRow([])
        setSelectedRowStatus(undefined)
    };

    const onFilter = () => {
        alert('filter here later');
    };


    const formatStatus = (value) => {
        const data = value
        var batchStatus = data;

        if (data && data == 'pending') {
            batchStatus = 'queued';
        }
        return batchStatus
    }

    const onOrgSelect = (selectedOrg) => {
        const newState = {
            ...paramState,
            selectedOrg,
            orgError: selectedOrg < 0 ? ' ' : '',
        };
        setParamState(newState);
        fetchIfValid(newState);
        sessionStorage.setItem('hqs-' + mode + '-batches-table-org', selectedOrg);
    };
    const fetchIfValid = (newState, timeOut = 1000) => {
        if (newState.dateError || newState.orgError) return;
        if (fetchTimer.current) window.clearTimeout(fetchTimer.current);
        fetchTimer.current = window.setTimeout(
            props.onChange,
            timeOut,
            props.organizations[newState.selectedOrg - 1],
            newState.from.toISOString(),
            newState.to.toISOString(),
        );

        // enable refresh button
        setRefreshEnabled(true);
    };
    const countFilters = () =>
        filterState.machines.size +
        filterState.statuses.size +
        filterState.users.size +
        filterState.orgs.size;
    const resetFilters = () =>
        setFilterState({
            users: new Set(),
            machines: new Set(),
            statuses: new Set(),
            orgs: new Set(),
        });
    /**
     * Updates a set by removing or adding value based on value of active.  Returns a new set.
     * @param {Set<string>} oldSet
     * @param {string} value
     * @param {boolean} active
     * @returns {Set<string>}
     */
    const updateFilterSet = (oldSet, value, active, filterName) => {
        const newSet = new Set(oldSet);
        if (active) newSet.delete(value);
        else newSet.add(value);
        // update session storage value
        sessionStorage.setItem(
            'hqs-' + mode + '-batches-table-filter-' + filterName,
            '{"' + filterName + '":' + JSON.stringify([...newSet]) + '}',
        );
        return newSet;
    };
    const dateRenderer = (cell, row) => {
        const data = cell;
        var createDate = '';

        if (data) {
            createDate = toISODate(data, 'detailed')
        }
        return <span>{createDate}</span>;
    };


    const hqcRenderer = (cell, row) => {
        const data = cell;
        var hqcTotal = 0;
        var accumulated = 0
        var pending = 0

        if("accumulated-hqc" in row){
            accumulated = row["accumulated-hqc"]
        }

        if("pending-hqc" in row){
            pending = row["pending-hqc"]
        }

        hqcTotal = roundTwoDecimalPlaces(accumulated + pending);
 
        return <span>{hqcTotal}</span>;
    };

    const costRenderer = (cell, row) => {
        const data = cell;
        var jobCost = 0;

        if (data) {
            jobCost = roundTwoDecimalPlaces(data);
        }

        return <span>{jobCost}</span>;
    };

    const batchIdRenderer = (cell, row) => {
        let batchId = cell
       
        if ("user-batch-id" in row) {
            batchId = row["user-batch-id"]
        }else{
            //if there isn't a custom batch id, then show the default internal one
            if('batch-id' in row){
                batchId = row["batch-id"]
            }
        }
        
        const data = batchId
        return <span className="ellipsis-field">{data}</span>;
    }

    const customCaret = (order, column) => {
        if (!order)
            return (
                <span className="order">
                    <span className="dropdown">
                        <span className="caret"></span>
                    </span>
                    <span className="dropup">
                        <span className="caret"></span>
                    </span>
                </span>
            );
        else if (order === 'asc') return <span className="caret"></span>;
        else if (order === 'desc')
            return (
                <span className="dropup">
                    <span className="caret"></span>
                </span>
            );
        return null;
    };

    const { from, to, orgError, selectedOrg, dateError } = paramState;

    // create filter options
    const statuses = new Set();
    const machines = new Set();
    const users = new Set();
    const orgs = new Set();
    props.jobs.forEach((jj) => {
        statuses.add(jj.status);
        machines.add(jj.machine);
        users.add(jj.user);
        orgs.add(jj.org);
    });
    const machineToggles = [];
    machines.forEach((mm) => {
        machineToggles.push({
            label: mm,
            active: !filterState.machines.has(mm),
        });
    });
    const userToggles = [];
    users.forEach((uu) => {
        userToggles.push({ label: uu, active: !filterState.users.has(uu) });
    });
    const statusToggles = [];
    statuses.forEach((ss) => {
        statusToggles.push({
            label: ss,
            active: !filterState.statuses.has(ss),
        });
    });

    const orgToggles = [];
    orgs.forEach((oo) => {
        orgToggles.push({ label: oo, active: !filterState.orgs.has(oo) });
    });

    const { SearchBar } = Search;
    const tableOptions = {
        // pageStartIndex: 0,
        custom: true,
        sizePerPage: 30,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
    };
    const BatchRequestsColumns = [
        {
            dataField: 'batch-id',
            text: 'INTERNAL',
            hidden: true,
        },
        {
            dataField: 'user-batch-id',//batchId
            text: 'BATCH ID',
            formatter: batchIdRenderer,
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                width: '60px',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'machine',
            text: 'MACHINE',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'org-name',
            text: 'ORGANIZATION NAME',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'user',
            text: 'USER',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'submit-date',
            text: 'SUBMIT DATE',
            formatter: dateRenderer,
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'start-date',
            text: 'START DATE',
            formatter: dateRenderer,
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'last-job-date',
            text: 'LAST JOB DATE',
            formatter: dateRenderer,
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'end-date',
            text: 'COMPLETED DATE',
            formatter: dateRenderer,
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: '',
            text: 'ACCUM. HQC',
            formatter: hqcRenderer,
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'batch-max-hqc',
            text: 'MAX HQC',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        {
            dataField: 'status',
            text: 'STATUS',
            hidden: false,
            style: {
                whiteSpace: 'nowrap',
                textAlign: 'center',
            },
            headerClasses: 'hqs-custom-table-header',
            sort: true,
            sortCaret: customCaret,
        },
        
    ];
    const [columns, setColumns] = useState([...BatchRequestsColumns]);
    // filter jobs
    const jobs =
        countFilters() === 0
            ? props.jobs
            : props.jobs.filter((jj) => {
                  if (filterState.statuses.has(jj.status)) return false;
                  if (filterState.users.has(jj.user)) return false;
                  if (filterState.machines.has(jj.machine)) return false;
                  if (filterState.orgs.has(jj.org)) return false;
                  return true;
              });


    const selectAllFilterValues = (filterName, active) => {
        let allFilterValues = new Set();
        if (!active) {
            switch (filterName) {
                case 'machines':
                    allFilterValues = new Set(machines);
                    break;
                case 'orgs':
                    allFilterValues = new Set(orgs);
                    break;
                case 'statuses':
                    allFilterValues = new Set(statuses);
                    break;
                case 'users':
                    allFilterValues = new Set(users);
                    break;
                default:
                    allFilterValues = new Set();
            }
        } else {
            // update session storage value
            sessionStorage.setItem(
                'hqs-' + mode + '-batches-table-filter-' + filterName,
                '{"' + filterName + '":' + JSON.stringify([]) + '}',
            );
        }

        return allFilterValues;
    };

    const handleSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedRow([row['batch-id']])
            setSelectedRowStatus(row['status'])
        } else if (!isSelect) {
            setSelectedRow([])
            setSelectedRowStatus(undefined)
        }
        
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selected: selectedRow,
        onSelect: handleSelect,
    };

    const generateFilters = () => {
        return (
            <div>
                <Button
                    type="link"
                    onClick={resetFilters}
                    content="Reset all filters"
                />
                <Accordion>
                    <Accordion.Content title="Machines">
                        <FilterPanel.ToggleItem
                            active={filterState.machines.size === 0}
                            label="All Machines"
                            type="checkbox"
                            onChange={(active) => {
                                setFilterState({
                                    ...filterState,
                                    machines: selectAllFilterValues(
                                        'machines',
                                        active,
                                    ),
                                });
                            }}
                        />
                        {machineToggles.map((mm) => (
                            <FilterPanel.ToggleItem
                                key={mm.label}
                                {...mm}
                                type="checkbox"
                                checked={mm.active}
                                onChange={(active) => {
                                    setFilterState({
                                        ...filterState,
                                        machines: updateFilterSet(
                                            filterState.machines,
                                            mm.label,
                                            active,
                                            'machines',
                                        ),
                                    });
                                }}
                            />
                        ))}
                    </Accordion.Content>
                    <Accordion.Content title="Orgs">
                        <FilterPanel.ToggleItem
                            active={filterState.orgs.size === 0}
                            label="All Orgs"
                            type="checkbox"
                            onChange={(active) => {
                                setFilterState({
                                    ...filterState,
                                    orgs: selectAllFilterValues('orgs', active),
                                });
                            }}
                        />
                        {orgToggles.map((oo) => (
                            <FilterPanel.ToggleItem
                                key={oo.label}
                                {...oo}
                                type="checkbox"
                                onChange={(active) => {
                                    setFilterState({
                                        ...filterState,
                                        orgs: updateFilterSet(
                                            filterState.orgs,
                                            oo.label,
                                            active,
                                            'orgs',
                                        ),
                                    });
                                }}
                            />
                        ))}
                    </Accordion.Content>
                    <Accordion.Content title="Statuses">
                        <FilterPanel.ToggleItem
                            active={filterState.statuses.size === 0}
                            label="All Statuses"
                            type="checkbox"
                            onChange={(active) => {
                                setFilterState({
                                    ...filterState,
                                    statuses: selectAllFilterValues(
                                        'statuses',
                                        active,
                                    ),
                                });
                            }}
                        />
                        {statusToggles.map((ss) => (
                            <FilterPanel.ToggleItem
                                key={ss.label}
                                {...ss}
                                label = {formatStatus(ss.label)}
                                type="checkbox"
                                onChange={(active) => {
                                    setFilterState({
                                        ...filterState,
                                        statuses: updateFilterSet(
                                            filterState.statuses,
                                            ss.label,
                                            active,
                                            'statuses',
                                        ),
                                    });
                                }}
                            />
                        ))}
                    </Accordion.Content>
                    <Accordion.Content title="Users">
                        <FilterPanel.ToggleItem
                            active={filterState.users.size === 0}
                            label="All Users"
                            type="checkbox"
                            onChange={(active) => {
                                setFilterState({
                                    ...filterState,
                                    users: selectAllFilterValues(
                                        'users',
                                        active,
                                    ),
                                });
                            }}
                        />
                        {userToggles.map((uu) => (
                            <FilterPanel.ToggleItem
                                key={uu.label}
                                {...uu}
                                type="checkbox"
                                onChange={(active) => {
                                    setFilterState({
                                        ...filterState,
                                        users: updateFilterSet(
                                            filterState.users,
                                            uu.label,
                                            active,
                                            'users',
                                        ),
                                    });
                                }}
                            />
                        ))}
                    </Accordion.Content>
                </Accordion>
            </div>
        );
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div style={{ border: '1px solid #d0d0d0', height: '682px' }}>
            <ToolkitProvider
                keyField="batch-id"
                data={jobs}
                columns={columns}
                columnToggle
                search
            >
                {(toolkitprops) => (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '50px',
                                borderBottom: '1px solid #d0d0d0',
                            }}
                            id="hqs-admin-jobs-table-options-wrapper"
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '400px',
                                    padding: '5px 0px 0px 5px',
                                }}
                            >
                                <SearchBar
                                    className="hqs-custom-font"
                                    {...toolkitprops.searchProps}
                                />
                                <div style={{ padding: '10px 0px 0px 10px' }}>
                                    {' '}
                                    {jobs.length + ' Total'}
                                </div>
                            </div>
                            <div
                                id="hqs-admin-jobs-table-options"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    width: '100%',
                                    padding: '5px 0px 0px 5px',
                                }}
                            >
                                <div className="hqs-custom-table-option">
                                    <Popup
                                        element={
                                            <BadgedIcon
                                                root="common"
                                                name="filter"
                                                size="medium"
                                                badge={countFilters().toString()}
                                                color="blue"
                                            />
                                        }
                                        on="click"
                                        position="bottom right"
                                    >
                                        {generateFilters()}
                                    </Popup>
                                </div>
                                <div className="hqs-custom-table-option">
                                    <ColumnToggleList
                                        {...toolkitprops.columnToggleProps}
                                        mode={mode}
                                    />
                                </div>
                                <div
                                    onClick={onRefresh}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '6px 0px 0px 5px',
                                    }}
                                >
                                    <Icon
                                        root="building"
                                        name="refresh"
                                        size="large"
                                        loading={props.fetchingBatchRequests}
                                    />
                                </div>
                                <div>
                                    <BatchActionSelect 
                                        selectedRow = {selectedRow}
                                        selectedRowStatus = {selectedRowStatus}
                                        callback={onRefresh}
                                    />
                                </div>
                                <div style={{ paddingLeft: '15px' }}>
                                    <DatePicker
                                        error={dateError}
                                        type="daterange"
                                        placeholder="select date range"
                                        rangeValue={{ from, to }}
                                        closeOnSelection={true}
                                        closeOnDocumentClick={true}
                                        disableFuture={true}
                                        onRangeSelect={onRangeSelect}
                                        onTextChange={(value, error) =>
                                            setParamState({
                                                ...paramState,
                                                dateError: error,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <Select
                                        error={orgError}
                                        placeholder="select org"
                                        options={orgOptions}
                                        onChange={onOrgSelect}
                                        value={selectedOrg}
                                        search={true}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hqs-admin-jobs-data">
                            <BootstrapTable
                                keyField="batch-id"
                                noDataIndication="No records found"
                                striped
                                hover
                                headerStyle={{ background: 'red' }}
                                height="515px"
                                rowClasses="hqs-custom-table-row"
                                selectRow={selectRow}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
            <div className="hqs-custom-table-pagination">
                <PaginationListStandalone {...paginationProps} />
            </div>
        </div>
    );

    return (
        <div className="hqs-admin-view">
            <div className="hqs-admin-jobs-card">
                <div id="hqs-admin-jobs-wrapper">
                    <PaginationProvider
                        pagination={paginationFactory(tableOptions)}
                    >
                        {contentTable}
                    </PaginationProvider>
                </div>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    closeButton={false}
                    newestOnTop={true}
                    position="bottom-right"
                    toastClassName="toast-notification-wrap"
                />
            </div>
        </div>
    );
}