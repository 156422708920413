// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Notification, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details }) => (
    <Notification
        className="toast-notification"
        severity="success"
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const DisableOrgQuotaForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleAction() {
        const body = {};
        // disable org quota
        HQS_API.disableOrganizationQuota(props.org, body)
            .then((response) => {
                if (props.callBack) {
                    props.callBack();
                }
                const title = 'Org Quota Disabled';
                const details = 'Org HQC quota has been disabled successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />,
                );
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });

        handleClose();
    }

    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow}>
                Disable HQC Quota
            </Button>

            <Modal
                open={show}
                className="hqs-umui-delete-modal"
                onClose={handleClose}
                size="small"
                style={{
                    padding: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        &nbsp;&nbsp;Disable HQC quota
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>
                        This will disable HQC quota for &quot;{props.org}&quot; and allow users to run
                        without any restrictions. Are you sure?
                    </p>
                    <Button type="primary" onClick={handleAction}>
                        Disable
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default DisableOrgQuotaForm;
