// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React, { useState, useRef } from 'react';
import {
    Button,
    DatePicker,
    Modal,
    Checkbox,
    Icon,
    Loader,
    InputLabel,
    Select
} from '@scuf/common';
import { AmplifyConfig } from '../config';
import { getIdToken } from '../utils/helpers';
import { Notification } from '@scuf/common';
import moment from 'moment';


export const GenerateUserGroupUsageReportForm = (props) => {
    const [show, setShow] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState({
        error: false,
        severity: 'important',
        title: '',
        text: '',
    });
    const [paramState, setParamState] = useState({
        to: '',
        from: '',
        org: props['org_name'],
        group: ('group' in props? props['group']: []),
        userGroups: props['userGroups'],
        dateError: 'select range',
        allGroups: ('allGroups' in props? props['allGroups']: false), // should we default to selecting the all groups option
        disabled: ('disabled' in props? props['disabled'] : false), //should the usage report button be disabled
    });

    const blobRef = useRef();

    function handleClose() {
        //reset the date range, selected groups, and error state when a user clicks close
        const newState = {
            ...paramState,
            group: [],
            to: '',
            from: '',
            dateError: 'select range',
            allGroups: true

        };
        setParamState(newState);
        setShow(false);
        setError({
            error: false,
            severity: 'important',
            title: '',
            text: '',
        })
    }

    const handleShow = () => setShow(true);
    const getISODate = (date, replace = false) =>
        replace
            ? date.toISOString().split('T')[0].replace(/-/g, '')
            : date.toISOString().split('T')[0];
    const fetchCsv = async (endpoint, filename) => {
        setFetching(true);
        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    Authorization: await getIdToken(),
                },
            });
            if (!response.ok) throw (await response.json()).error.text;
            if (typeof blobRef.current === 'object') {
                const blob = await response.blob();
                if (blob.size > 2) {
                    const url = URL.createObjectURL(blob);
                    blobRef.current.href = url;
                    blobRef.current.download = filename;
                    blobRef.current.click();
                    URL.revokeObjectURL(url);
                    setError({
                        ...error,
                        error: false,
                    });
                } else {
                    setError({
                        error: true,
                        severity: 'important',
                        title: 'No records found',
                        text: '',
                    });
                }
            }
        } catch (err) {
            setError({
                error: true,
                severity: 'critical',
                title: `Report generation failed: ${err}`,
                text: '',
            });
        }
        setFetching(false);
    };
    const fetchJobs = async () => {
        const { dateError, from, to, org, group, userGroups, allGroups } = paramState;
        if (dateError) {
            setError({
                error: true,
                title: 'Date Error',
                severity: 'critical',
                text: dateError,
            });
            return;
        }
        let startTemp = moment(from).startOf('day')
        let endTemp = moment(to).endOf('day')
        const start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        const end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        let endpoint = `${
            AmplifyConfig.API.endpoints.find((ee) => ee.name === 'HQS_MACHINE_API')
                .endpoint
        }/job/reporting?start=${start}&end=${end}&status=completed,canceled`;
        if (org) endpoint += '&org=' + encodeURIComponent(org);

        // determine if we need to filter by a specific list of groups
        if (!allGroups){
            endpoint += '&group=' + encodeURIComponent(group);
        } else{
            //or across all groups
            endpoint += '&groupBy=group';
        }
        await fetchCsv(endpoint, `job_usage_report_${getISODate(new Date(), true)}.csv`);
    };
   
    const onRangeSelect = ({ to, from }) => {
        const newState = {
            ...paramState,
            to,
            from,
            dateError: to && from ? '' : 'select range',
        };
        setParamState(newState);
    };

    const isGenerateDisabled = ()=>{

        let disabled = true
        if (!dateError && (allGroups || (group !== undefined && group.length > 0))){
            disabled = false
        }
        return disabled
    }

    const genUserGroupOptions = () => {
        let groups = [];

        if (userGroups != null){
            userGroups.forEach((g) => {
                groups.push({ key: g['id'], value: g['name'].toString(), text: g['name'].toString() });
            });
            return (
                <div className="hqs-form-group">
                    <Select
                        label="User Group(s)"
                        multiple={true}
                        disabled={allGroups}
                        options={groups}
                        value={group}
                        onChange={(group) =>
                            setParamState({
                                ...paramState,
                                group: group,
                            })
                        }
                        fluid={true}
                        search={true}
                        placeholder="Select User Group(s)"
                        indicator="required"

                    />
                </div>
            );
        }
    }


    const { to, from, dateError, org, group, userGroups, allGroups, disabled } = paramState;
    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow} disabled={disabled}>
                Usage Report
            </Button>
            <Modal
                open={show}
                onClose={handleClose}
                className="hqs-umui-modal"
                size="small">
                <Modal.Header>
                    <Icon
                        root="common"
                        name="document-report"
                        size="medium"
                        style={{ paddingRight: '14px' }}></Icon>
                    <div className="hqs-umui-modal-header ">
                        Usage Report by User Groups
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <Loader
                        loading={fetching}
                        overlayColor="#a0a0a0"
                        text="Fetching report"
                        className="hqs-umui-loader-wrap">
                        <div className="hqs-form-group">
                            {error.error && (
                                <Notification
                                    severity={error.severity}
                                    hasIcon={true}
                                    title={error.title}
                                    onCloseClick={() =>
                                        setError({ ...error, error: false })
                                    }>
                                    {error.text}
                                </Notification>
                            )}
                        </div>
                        <p>
                            The usage reports include records for{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {org? org : 'All Orgs'}
                            </span>{' '}
                            within the date range. Checking the all groups box will include
                            records for all user groups.
                        </p>
                        <div className="hqs-form-group">
                            <DatePicker
                                label="Report Dates"
                                type="daterange"
                                indicator="required"
                                placeholder="select date range"
                                error={dateError}
                                rangeValue={{ from, to }}
                                closeOnSelection={false}
                                closeOnDocumentClick={true}
                                onRangeSelect={onRangeSelect}
                                onTextChange={(value, error) =>
                                    setParamState({
                                        ...paramState,
                                        dateError: error,
                                    })
                                }
                            />
                            {genUserGroupOptions()}
                            <Checkbox
                                label="All Groups"
                                checked={allGroups}
                                onChange={(checked) =>
                                    setParamState({
                                        ...paramState,
                                        allGroups: checked,
                                    })
                                }
                            />
                            
                        </div>
                    </Loader>
                </Modal.Content>
                <Modal.Footer>
                    <a style={{ display: 'none' }} href="" ref={blobRef} download="" />
                    <Button type="primary" onClick={fetchJobs} disabled={isGenerateDisabled()}>
                        Generate
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
