/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { useEffect } from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Checkbox, InputLabel, Modal, Badge } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details }) => (
    <Notification
        className="toast-notification"
        severity="success"
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const UpdateDiagnosticForm = (props) => {
    const { diagnostic } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [diagnosticValue, setDiagnostic] = useState(diagnostic);

    useEffect(() => {
        setDiagnostic(diagnostic);
    }, [diagnostic]);

    function handleUpdate() {
        // increment available HQC
        let body = {};
        body['diagnostic'] = diagnosticValue;

        // update org
        HQS_API.updateOrganization(props.org, body)
            .then((response) => {
                if (props.callBack) {
                    props.callBack();
                }
                const title = 'Diagnostic Updated';
                const details = 'Diagnostic has been updated successfully';
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="success" />);
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
            });
        handleClose();
    }

    return (
        <div>
            <div onClick={handleShow}>
                <div style={{ cursor: 'pointer' }} className="hqs-blue-icon">
                    update
                </div>
                &nbsp;
            </div>
            <Modal open={show} onClose={handleClose} size="small" className="hqs-umui-information-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="descending" size="medium"></Icon>
                        &nbsp;&nbsp;Update Diagnostic
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <p>Update the Diagnostics value for &quot;{props.org}&quot;.</p>
                    <p>
                        <Badge color="red">WARNING!</Badge> This will allow all users under this organization to submit
                        jobs with a 'diagnostic' priority. <b>Note: </b> Make sure the plan type for target machines is
                        set to 'internal'
                    </p>
                    <InputLabel label="Run Diagnostics" />
                    <Checkbox
                        label="Enabled"
                        checked={diagnosticValue}
                        toggle={true}
                        onChange={(checked) => setDiagnostic(checked)}
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateDiagnosticForm;
