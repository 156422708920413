// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

switch (process.env.REACT_APP_CUSTOM_ENV) {
    case 'local':
        module.exports = require('./config.local.js');
        break;
    case 'dev':
        module.exports = require('./config.dev.js');
        break;
    case 'qa':
        module.exports = require('./config.qa.js');
        break;
    case 'prod':
        module.exports = require('./config.prod.js');
        break;
    default:
        module.exports = require('./config.local.js');
}
