/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import { useState } from 'react';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';


const AuditDataForm = (props) => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState(props.id);
    const [data, setData] = useState(props.data);
    const [entity, setEntity] = useState(props.entity);
    const [action, setAction] = useState(props.action);
    const [date, setDate] = useState(props.date);
    const [requestor, setRequestor] = useState(props.requestor);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <div onClick={handleShow} style={{display: 'flex', cursor: 'pointer' }}>
                <div>
                    <Icon
                        root="building"
                        name="doc-search"
                        size="small">    
                    </Icon>
                </div>
                &nbsp;
                <div style={{ paddingTop: '2px' }}>
                    
                </div>
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Audit Details</div>
                </Modal.Header>
                <Modal.Content>
                    <p><b>Id:</b> {id}</p>
                    <p><b>Entity:</b> {entity}</p>
                    <p><b>Action:</b> {action}</p>
                    <p><b>Date:</b> {date}</p>
                    <p><b>Requestor:</b> {requestor}</p>
                    <p><b>Data:</b></p>
                    <div><pre>{ JSON.stringify(data, null, 2) }</pre></div>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" size="small" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AuditDataForm;
