/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2021-2022 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import DeleteUserForm from '../Forms/DeleteUser';
import PromoteUserForm from '../Forms/PromoteUser';
import DemoteUserForm from '../Forms/DemoteUser';
import ManageUserSoftwareForm from '../Forms/ManageUserSoftware';
import ResendInviteForm from '../Forms/ResendInvite';
import EnableUserQuotaForm from '../Forms/EnableUserQuota';
import UpdateUserQuotaForm from '../Forms/UpdateUserQuota';
import DisableUserQuotaForm from '../Forms/DisableUserQuota';
import UpdateUserPriorityForm from '../Forms/UpdateUserPriority';
import UpdateUserGroupNameForm from '../Forms/UpdateUserGroupName';
import Dropdown from 'react-bootstrap/Dropdown';

const generateOptions = (selectedRow, orgName, orgPlans, org_groups, org_licenses, callback) => {
    let actionsList = [];

    if (selectedRow !== undefined && selectedRow !== null) {
        const userDetails = {
            email: selectedRow[0].email,
            organization: selectedRow[0].organization,
            permissions: selectedRow[0].permissions,
            status: selectedRow[0].status,
            quotaEnabled: selectedRow[0]['quota-enabled'],
            quota: selectedRow[0].quota,
            user_groups: selectedRow[0].user_groups,
            default_group: selectedRow[0].default_group,
            software: selectedRow[0].software,
            priority: 'priority' in selectedRow[0] ? selectedRow[0].priority : 0,
        };

        //find the licenses for the selected user's org
        let licenses =
            org_licenses !== undefined && org_licenses.length > 0 && 'licenses' in org_licenses[0]
                ? org_licenses[0]['licenses']
                : {};

        // always show delete
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" key="deleteUser">
                <DeleteUserForm show_icon={true} user={userDetails} callback={callback} />
            </Dropdown.Item>,
        );

        // add group update actions
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" key="updateUserGroup">
                <UpdateUserGroupNameForm
                    key={userDetails['email']}
                    user={userDetails}
                    org_groups={org_groups}
                    callback={callback}
                />
            </Dropdown.Item>,
        );

        const permissionsList = userDetails.permissions.split(',').map((item) => item.trim());
        var promoteDemote = (
            <Dropdown.Item className="hqs-custom-actions-select-option" key="promoteUser">
                <PromoteUserForm user={userDetails} callback={callback} userRole="org-admins" />
            </Dropdown.Item>
        );
        if (permissionsList.includes('Organization Administrator')) {
            promoteDemote = (
                <Dropdown.Item className="hqs-custom-actions-select-option" key="demoteUser">
                    <DemoteUserForm user={userDetails} callback={callback} userRole="org-admins" />
                </Dropdown.Item>
            );
        }
        //  add promote/demote actions
        actionsList.push(promoteDemote);

        const softwareList = userDetails.software.split(',').map((item) => item.trim());

        //only add the ability to manage software if this org has any licenses in their plan
        if (licenses) {
            actionsList.push(
                <Dropdown.Item
                    className="hqs-custom-actions-select-option"
                    key={'promoteUserSoftware-' + userDetails.email}>
                    <ManageUserSoftwareForm
                        user={userDetails}
                        callback={callback}
                        software={softwareList}
                        licenses={licenses}
                    />
                </Dropdown.Item>,
            );
        }

        var resendInvite = '';
        if (userDetails.status !== 'confirmed') {
            resendInvite = (
                <Dropdown.Item className="hqs-custom-actions-select-option" key="resendInvite">
                    <ResendInviteForm user={userDetails} callback={callback} />
                </Dropdown.Item>
            );
            // add resend password action
            actionsList.push(resendInvite);
        }

        // add quota action
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" key="enableUserQuota">
                <EnableUserQuotaForm
                    user={userDetails.email}
                    quota={userDetails.quota}
                    org_plans={orgPlans}
                    callBack={callback}
                />
            </Dropdown.Item>,
        );

        // add priority action
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" key="enableUserQuota">
                <UpdateUserPriorityForm user={userDetails.email} priority={userDetails.priority} callback={callback} />
            </Dropdown.Item>,
        );
    } else {
        actionsList.push(
            <Dropdown.Item className="hqs-custom-actions-select-option" disabled={true} key="selectRow">
                <div>Select a row...</div>
            </Dropdown.Item>,
        );
    }

    return actionsList;
};

const OrgUserActionSelect = (props) => {
    // eslint-disable-next-line react/display-name
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className="ui small button primary hqs-custom-dropdown"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>
            &#x25bd;&nbsp;
            {children}
        </div>
    ));
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {generateOptions(
                    props.selectedRow,
                    props.org_name,
                    props.org_plans,
                    props.org_groups,
                    props.org_licenses,
                    props.callback,
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default OrgUserActionSelect;
