// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React, { useEffect } from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Icon, Input, InputLabel, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const UpdateUserGroupQuotaForm = (props) => {
    const { quota } = props;
    const [show, setShow] = useState(false);
    const [hqcQuota, setHqcQuota] = useState(quota);
    const handleClose = () => {
        setShow(false);
        setHqcQuota(quota);
    };
    const handleShow = (event) => {
        setShow(true);
        return true;
    };

    useEffect(() => {
        setHqcQuota(quota);
    }, [quota]);

    function handleUpdate() {
        // set user group hqc quota
        let body = {};
        body['hqc_quota'] = Number(hqcQuota);
        body['quota_id'] = props.quotaId;

        // update user group
        HQS_API.updateUserGroupQuota(props.org_name, props.group_id, body)
            .then((response) => {
                const title = 'Group Quota Updated';
                const details = 'Group HQC quota has been updated successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />,
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />,
                );
            });

        handleClose();
    }

    return (
        <div>
            <div onClick={handleShow}>
                <div style={{ cursor: 'pointer' }}>
                    <Icon
                        style={{ cursor: 'pointer' }}
                        root="common"
                        name="edit"
                        size="small"
                    ></Icon>
                </div>
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon
                            root="common"
                            name="portfolio-savings"
                            size="medium"
                        ></Icon>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        Update the existing HQC quota value for &quot;
                        {props.group_name}&quot;.
                    </p>
                    <InputLabel label="HQC Quota:" />
                    <Input
                        key={props.group_id}
                        type="number"
                        min="0"
                        value={hqcQuota}
                        onChange={(quotaValue) => setHqcQuota(quotaValue)}
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button
                        type="primary"
                        onClick={() => handleUpdate(hqcQuota)}
                    >
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateUserGroupQuotaForm;
