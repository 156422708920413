// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import { isVersionNumber, toISODate } from '../utils/helpers';

const logo = require('../icons/icon_alert_critical.svg');

const ToastNotification = ({ closeToast, title, details }) => (
    <Notification
        className="toast-notification"
        severity="success"
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

// Custom hook for handling the delete user form
const useDeleteTermsAndConditionsForm = (closeCallback, props) => {

    async function deleteTermsAndConditions(terms) {

        let queryParams = {
            mode: props.mode
        };

        HQS_API.deleteTermsAndConditions(terms['id'], queryParams)
            .then((response) => {
                const title = 'Success!';
                console.log(terms)
                const details = "Unscheduled terms and conditions for: " + toISODate(terms['effective_date']);
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                    />
                );
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                console.log(error)
                /*error = error.response.data.error;
                if (error.code === ErrorCodes.UserDoesNotExist) {
                    alert(error.text);
                }
                if (error.code === ErrorCodes.CannotDeleteSelf) {
                    alert(error.text);
                }
                */
            });
    }

    const handleDelete = (event) => {
        if (event) {
            event.preventDefault();
        }
        deleteTermsAndConditions(props.terms);
        closeCallback();
    };

    return { handleDelete };
};

const DeleteTermsAndConditionsForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleDelete } = useDeleteTermsAndConditionsForm(handleClose, props);
    const showIcon = (show_icon) => {
        if (show_icon) {
            return (
                <div>
                    <Icon
                style={{ cursor: 'pointer' }}
                root="common"
                name="delete"
                size="small"></Icon>
            &nbsp;{props.children || 'Delete'}
                </div>
            )
        } else {
            return (
                <div>Delete</div>
            )
        }
    }
    return (
        <div>
            <div onClick={handleShow}>
                {showIcon(props.show_icon)}
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-delete-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Delete</div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        This will unschedule and delete this terms and conditions record.
                        Are you sure?
                    </p>
                    <Button type="primary" size="small" onClick={handleDelete}>
                        Confirm
                    </Button>
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default DeleteTermsAndConditionsForm;
