import React from 'react';

import './PlanTag.css';

function PlanTag(props) {

    let tags = props.tags
    let tagList = <div></div>;
    const planTags = []
    if (tags !== undefined && tags.length > 0) {

        tags.sort((a, b) => {

            //sort on the first tag key in the plan 
            if ('key' in a && 'key' in b) {
                if (a['key'] < b['key']) return -1;
                if (a['key'] > b['key']) return 1;
                return 0;
            }

        });

        tags.forEach((item) => {
            planTags.push(
                <div className="umui-tag-record">
                    <div className="umui-tag-header">KEY:</div>
                    <div className="umui-tag-record-value">{item['key']}</div>
                    <div className="umui-tag-header">VALUE:</div>
                    <div className="umui-tag-record-value">{item['value']}</div>
                </div>
            )
        });

        tagList = <div className="">
            <div className="umui-tag-record">
                <div className="umui-tag-header">Tags: </div>
            </div>
            {planTags}
        </div>;

    
    }else{
         //if no tags yet then tell this to the user
         tagList = <div className="">
            <div className="umui-tag-record">
                <div className="umui-tag-header">Tags:</div>
                <div className="umui-tag-record-value">None</div>

            </div>
            {planTags}
            
        </div>;
    }


    return tagList
}

export default PlanTag;
