// *****************************************************************************

// 2021 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Input, InputLabel, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';

const logo = require('../icons/icon_alert_critical.svg');

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}
    >
        {details}
    </Notification>
);

const useUpdateUserQuotaForm = (closeCallback, props) => {
    const [quota, setQuota] = useState(0);

    const updateUser = async (user, quota, event) => {
        let body = {};
        body['hqc_quota'] = Number(quota);
        body['quota_id'] = props.quotaId;
        // increment available HQC
        HQS_API.updateQuota(user, 'update', body)
            .then((response) => {
                if (props.callback) {
                    props.callback();
                }
                const title = 'Quota Enabled';
                const details = 'Quota has been updated successfully';
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="success"
                    />
                );
            })
            .catch((error) => {
                const title = 'Error!';
                var details = 'Unknown Error!';
                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                }
                toast(
                    <ToastNotification
                        closeToast={false}
                        title={title}
                        details={details}
                        severity="critical"
                    />
                );
            });
    };

    const handleUpdate = (event) => {
        if (event) {
            event.preventDefault();
        }
        updateUser(props.user, quota);
        closeCallback();
    };

    const handleQuotaChange = (val) => {
        setQuota(val);
    };

    return { handleUpdate, handleQuotaChange };
};

const UpdateUserQuotaForm = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { handleUpdate, handleQuotaChange } = useUpdateUserQuotaForm(handleClose, props);

    return (
        <div>
            <div onClick={handleShow}>
                <Icon
                    style={{ cursor: 'pointer' }}
                    root="common"
                    name="edit"
                    size="small"
                ></Icon>
            </div>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                className="hqs-umui-information-modal"
            >
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon
                            root="common"
                            name="portfolio-savings"
                            size="medium"
                        ></Icon>
                        &nbsp;&nbsp;Update HQC quota
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <p>
                        Update the existing HQC quota value for &quot;
                        {props.user}&quot;.
                    </p>
                    <InputLabel label="Quota Amount:" />
                    <Input
                        type="number"
                        placeholder={props.quota}
                        min="0"
                        onChange={(quota) => handleQuotaChange(quota)}
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleUpdate}>
                        Update
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UpdateUserQuotaForm;
