/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { Button, Input, DatePicker, Checkbox, InputLabel, Icon, Notification, Modal, Select } from '@scuf/common';
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import moment from 'moment';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const CreateReportForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [reportName, setReportName] = useState('');
    const [nameError, setNameError] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateError, setDateError] = useState('');
    const [azure, setAzure] = useState(false);
    const [allOrgs, setAllOrgs] = useState(false);
    const [org, setOrg] = useState('');
    const [orgError, setOrgError] = useState('');
    const [expiration, setExpiration] = useState(30);

    function clearInputs() {
        setOrg('');
        setReportName('');
        setNameError('');
        setDateError('');
        setAllOrgs(false);
        setAzure(false);
        setFromDate('');
        setToDate('');
        setExpiration(30);
        setDateError('');
        setOrgError('');
    }

    function handleClose() {
        setShow(false);
        clearInputs();
    }

    function handleOrg(orgValue) {
        //clear error if any
        setOrgError('');
        setOrg(orgValue);
        setAllOrgs(false);
        setAzure(false);
    }

    function handleAllOrgs(allOrgsValue) {
        setAllOrgs(allOrgsValue);
        setAzure(false);
        setOrg('');
    }

    function handleAzure(azureValue) {
        setAzure(azureValue);
        setAllOrgs(false);
        setOrg('');
    }

    function onRangeSelect({ to, from }) {
        setToDate(to);
        setFromDate(from);
        setDateError('');
    }

    function hadleReportName(reportNameValue) {
        setReportName(reportNameValue);
        setNameError('');
    }

    function genOrgOptions(orgs) {
        const formattedOrgs = [];
        orgs.forEach((org) => {
            let formattedOrg = { value: org, text: org };
            formattedOrgs.push(formattedOrg);
        });

        formattedOrgs.push({ value: 'azure', text: 'Azure' });
        formattedOrgs.sort((a, b) => a.value.localeCompare(b.value));

        return (
            <Select
                error={orgError}
                options={formattedOrgs}
                placeholder="Select an organization"
                onChange={(text) => handleOrg(text)}
                reserveSpace={true}
                fluid={true}
                value={org}
                search={true}
            />
        );
    }

    function genExpirationOptions() {
        const expirationOptions = [
            { value: 30, text: '30' },
            { value: 60, text: '60' },
        ];

        return (
            <Select
                options={expirationOptions}
                onChange={(value) => setExpiration(value)}
                fluid={true}
                value={expiration}
            />
        );
    }

    function validateFormValues() {
        let validData = true;

        if (fromDate === undefined || fromDate === '') {
            setDateError('Please select a date range.');
            validData = false;
        }

        if (toDate === undefined || toDate === '') {
            setDateError('Please select a date range.');
            validData = false;
        }

        if (reportName === undefined || reportName === '') {
            setNameError('Please enter a report name.');
            validData = false;
        }

        if (props.mode == 'admin') {
            if (allOrgs !== true && azure !== true) {
                if (org === undefined || org === '') {
                    setOrgError('Please select an org name.');
                    validData = false;
                }
            }
        }
        return validData;
    }

    function generateAdminFields() {
        if (props.mode == 'admin') {
            return (
                <div>
                    <Form.Group>
                        <Checkbox label="Azure Usage" checked={azure} onChange={(checked) => handleAzure(checked)} />
                    </Form.Group>
                    <Form.Group>
                        <Checkbox label="All Orgs" checked={allOrgs} onChange={(checked) => handleAllOrgs(checked)} />
                    </Form.Group>
                    <Form.Group>
                        <InputLabel label="Select Organization" indicator="required" />
                        <div style={{ 'max-height': '50px', 'margin-bottom': '10px' }}>
                            {genOrgOptions(props.orgs || [])}
                        </div>
                    </Form.Group>
                </div>
            );
        }
    }

    function generateOrgName() {
        let orgName = 'None';

        if (props.mode === 'admin') {
            if (azure !== true && allOrgs !== true) {
                orgName = org;
            }
        } else if (props.mode === 'org') {
            orgName = props.orgs[0];
        } else if (props.mode === 'user') {
            if (props.isOrgAdmin === true) {
                orgName = props.orgs[0];
            }
        }

        return orgName;
    }

    function handleCreate() {
        if (validateFormValues()) {
            let startTemp = moment(fromDate).startOf('day');
            let endTemp = moment(toDate).endOf('day');
            const start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
            const end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');
            const orgName = generateOrgName();

            HQS_API.createReport(reportName, start, end, orgName, azure, expiration)
                .then(() => {
                    const title = 'Report was submitted!';
                    const details = 'Report was created successfully!';
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="success" />);
                    if (props.callback) {
                        props.callback();
                    }
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unkown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                });

            handleClose();
        }
    }

    return (
        <div>
            <Button type="primary" size="small" onClick={handleShow}>
                <Icon root="common" name="slidercontrols-plus" size="small"></Icon>
                &nbsp;&nbsp;Create Report
            </Button>

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                // style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}
                className="hqs-umui-modal">
                <Modal.Header closeButton>
                    {/* <div className="hqs-umui-modal-header "> */}
                    <Icon root="common" name="document-report" size="medium" style={{ paddingRight: '14px' }}></Icon>
                    <div className="hqs-umui-modal-header ">Usage Report</div>
                    {/* </div> */}
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>A new report request will be created.</p>
                    <Form.Group>
                        <Input
                            type="text"
                            label="Report Name"
                            placeholder="Name your report"
                            name="reportName"
                            onChange={(text) => hadleReportName(text)}
                            value={reportName}
                            fluid={true}
                            indicator="required"
                            error={nameError}
                        />
                    </Form.Group>
                    <Form.Group>
                        <InputLabel label="Expiration Date (days)" indicator="required" />
                        {genExpirationOptions()}
                    </Form.Group>
                    <Form.Group>
                        <DatePicker
                            label="Report Dates"
                            type="daterange"
                            indicator="required"
                            placeholder="select date range"
                            error={dateError}
                            rangeValue={{ from: fromDate, to: toDate }}
                            closeOnSelection={true}
                            closeOnDocumentClick={true}
                            onRangeSelect={onRangeSelect}
                            onTextChange={(value, error) => console.log('Text change: ' + value)}
                        />
                    </Form.Group>
                    {generateAdminFields()}
                </Modal.Content>
                <Modal.Footer>
                    <Button type="primary" onClick={handleCreate}>
                        Create
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreateReportForm;
