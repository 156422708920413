/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React from 'react';
import UpdateOrgBatchMaxHQCForm from '../Forms/UpdateOrgBatchMaxHQC';
import './PlanType.css';


function OrgBatchMaxHQC(props) {
    let batchMax = props.batchMax !== undefined ? props.batchMax : 0;

    return (
        <div className="hqs-plan-container">
            <div className="hqs-plan-values">{batchMax}</div>
            <UpdateOrgBatchMaxHQCForm
                org={props.org}
                batchMax={batchMax}
                callBack={props.callBack}
            />
        </div>
    );
}

export default OrgBatchMaxHQC;
