// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { ErrorCodes } from '../config';
import { Button, Icon, Modal } from '@scuf/common';
import 'react-toastify/dist/ReactToastify.css';
import { Notification } from '@scuf/common';
import { toast } from 'react-toastify';
import moment from 'moment';

const logo = require('../icons/icon_alert_critical.svg');

const ToastNotification = ({ closeToast, title, details }) => (
    <Notification
        className="toast-notification"
        severity="success"
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const DeleteEventForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    function handleClose() {
        setShow(false);
    }

    async function deleteEvent(id, delete_series = false) {
        HQS_API.deleteMachineEvent(id, delete_series)
            .then((response) => {
                props.callBack();

                const title = 'Event Deleted';
                const details = 'The system has successfully removed the event';
                toast(<ToastNotification closeToast={false} title={title} details={details} />);
                if (props.callback) {
                    props.callback();
                }
            })
            .catch((error) => {
                var details = 'Unknown Error!';

                if (error.response !== undefined) {
                    details = error.response.data.error.text;
                    console.log(details);
                } else {
                    console.log(error);
                }
            });
    }

    function handleDelete(event) {
        if (event) {
            event.preventDefault();
        }
        deleteEvent(props.id);
        handleClose();
    }

    function handleDeleteSeries(event) {
        if (event) {
            event.preventDefault();
        }
        deleteEvent(props.id, true);
        handleClose();
    }

    function renderDeletionType() {
        var deleteText = 'Delete Event';
        if (props.recurringID !== undefined) {
            deleteText = 'Delete Occurrence';

            if (moment().isBetween(props.startTime, props.endTime, 'seconds')) {
                deleteText = 'Cancel Occurrence';
            }
        } else {
            if (moment().isBetween(props.startTime, props.endTime, 'seconds')) {
                deleteText = 'Cancel Event';
            }
        }

        return deleteText;
    }

    function renderSeriesDeletionType() {
        var deleteText = 'Delete Series';
        if (moment().isBetween(props.startTime, props.endTime, 'seconds')) {
            deleteText = 'Cancel Series';
        }

        return deleteText;
    }

    return (
        <div>
            <div onClick={handleShow}>
                <Icon style={{ cursor: 'pointer' }} root="common" name="delete" size="small"></Icon>
                &nbsp;
            </div>

            <Modal open={show} onClose={handleClose} size="small" className="hqs-umui-delete-modal">
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">Delete Event</div>
                </Modal.Header>
                <Modal.Content>
                    <p>This will delete the selected event from the system. Are you sure?</p>
                    {props.recurringID !== undefined ? (
                        <p>This entry is part of a recurring event. You can delete the whole series from here.</p>
                    ) : (
                        ''
                    )}
                    <Button type="primary" size="small" onClick={handleDelete}>
                        {renderDeletionType()}
                    </Button>
                    {props.recurringID !== undefined ? (
                        <Button
                            type="primary"
                            className="hqs-custom-button-error"
                            size="small"
                            onClick={handleDeleteSeries}>
                            {renderSeriesDeletionType()}
                        </Button>
                    ) : (
                        ''
                    )}
                    <Button type="secondary" size="small" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default DeleteEventForm;
