// *****************************************************************************

// 2020 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

const AmplifyConfig = {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_Eerjnl0pq',
        userPoolWebClientId: '3qpehkrit9ujpmdelrpr8kq292',
        identityPoolId: 'us-west-2:b107a901-2cd4-472d-9019-f395f5a3b6e2',
    },
    API: {
        endpoints: [
            {
                name: 'HQS_UI_API',
                endpoint: 'https://ui.hqsapi.quantinuum.com/beta',
            },
            {
                name: 'HQS_MACHINE_API',
                endpoint: 'https://hqsapi.quantinuum.com/v1',
            },
        ],
    },
};

const MsalConfig = {
    auth: {
        clientId: "b8ff96f9-d72d-42d0-86b1-82da866afbe6", //Quantinuum Dev
        authority: "https://login.microsoftonline.com/common", //all users,
        redirectUri: "http://localhost:3000",
        postLogoutRedirectUri:  "http://localhost:3000",

    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const MsalLoginRequest = {
    scopes: ["User.Read"], // allows app to read profile
    prompt: "select_account" // provides the user with the option to select an account if already in session. Otherwise prompt for username/password
};


const ErrorCodes = {
    OrganizationAlreadyExists: 24,
    UserAlreadyExists: 25,
    OrganizationDoesNotExist: 26,
    UserDoesNotExist: 29,
    BadEmailAddress: 30,
    UnconfirmedEmailSubscription: 31,
    BadPhoneNumber: 32,
    OrganizationContainsUsers: 33,
    NotAuthorizedException: 34,
    CannotDeleteSelf: 36,
};

const APIExamplesBucket = 'hqs-api-examples'

const Domain = "um.hqsapi.quantinuum.com"

export { AmplifyConfig, ErrorCodes, APIExamplesBucket, MsalConfig, MsalLoginRequest, Domain};

